export const apiUrl = {
  beneficiary_detail: "beneficiary/v1/beneficiary-details",
  login: "auth/v1/login",
  change_password: "account/v1/change-password",
  get_account: "account/v1/get-all-account",
  reset_user_account: "account/v1/reset-password",
  create_account: "account/v1/create-account",
  get_all_coach: "account/v1/get-all-coach",

  // coach plan api start here
  get_coach_plan: "coach-plan/v1/get-plan-list",
  get_all_coach: "account/v1/get-all-coach",
  change_coach: "coach-plan/v1/change-coach",
  add_coach_plan: "coach-plan/v1/add-plan",
  get_session_list: "coach-plan/v1/get-plan-session",
  add_session: "coach-plan/v1/add-plan-session",
  edit_coach_plan: "coach-plan/v1/edit-plan",
  delete_coach_plan: "coach-plan/v1/delete-plan",
  delete_coach_plan_session: "coach-plan/v1/delete-plan-session",
  get_date_time_validation: "coach-plan/v1/get-plan-times",
  session_update_status: "coach-plan/v1/update-plan-status",
  edit_coach_plan_session_detail: "coach-plan/v1/edit-plan-session",
  coach_plan_status_change: "coach-plan/v1/changes-plan-status",
  // coach plan api ends here

  // dashboard api starts here

  subvention_monitoring: "dashboard/v1/subvention-monitoring",
  get_latest_coach: "dashboard/v1/latest-coach-history",
  get_beneficiary_monitoring_graph: "dashboard/v1/beneficiary-monitoring-graph",

  get_beneficiary_data: "beneficiary/v1/beneficiary-monitoring",

  get_beneficiary_monitoring: "dashboard/v1/beneficiary-monitoring",
  get_beneficiary_monitoring_coaching_list: "dashboard/v1/latest-coach-history",
  get_coach_monitoring_dashboard: "dashboard/v1/coach-monitoring",
  get_coach_monitoring_chart: "dashboard/v1/coach-monitoring-graph",
  get_latest_coach_sessions: "dashboard/v1/latest-coach-sessions",
  get_dashboard_coaching_sessions: "dashboard/v1/coaching-session-list",
  get_ben_monitoring_session_list: "dashboard/v1/coaching-plan-list",
  // dashboard api ends here

  update_account: "account/v1/update-account",
  get_account_detail: "account/v1/get-account-details",
  create_account_profile: "account/v1/update-coach-profile",
  add_beneficiary: "beneficiary/v1/add-beneficiary",
  beneficiary_list: "beneficiary/v1/get-all-beneficiary",
  beneficiary_list_coah: "beneficiary/v1/get-all-beneficiary",
  get_beneficiary_profile: "beneficiary/v1/beneficiary-details",
  edit_coach: "account/v1/update-account",
  assigned_unassigned_coach: "account/v1/all-coach-as-assignment",
  edit_beneficiary: "beneficiary/v1/update-beneficiary",
  delete_beneficiary: "beneficiary/v1/delete-beneficiary",
  logout: "auth/v1/log-out",
  get_coach_profile: "account/v1/get-coach-profile",
  assign_beneficiary: "beneficiary/v1/beneficiaries-assignment",
  get_assigned_beneficiary: "beneficiary/v1/get-beneficiary-by-coachId",
  add_equipment: "equipment/v1/add-equipment",
  get_equipment_list: "equipment/v1/get-all-equipment",
  get_equipment_detail: "equipment/v1/equipment-details",
  edit_equipment: "equipment/v1/update-equipment",
  update_equipment_status: "equipment/v1/update-status",
  delete_equipment: "equipment/v1/delete-equipment",
  download_equipment: "equipment/v1/download-equipments",
  update_beneficiary_status: "beneficiary/v1/update-status",
  upload_beneficiaries: "beneficiary/v1/upload-beneficiaries",
  download_beneficiaries: "beneficiary/v1/download-beneficiaries",
  upload_equipments: "equipment/v1/upload-equipments",
  get_subvention_list: "subvention/v1/subvention-list",
  get_subvention_list_by_beneficiary: "subvention/v1/get-subvention",
  edit_assign_beneficiary: "beneficiary/v1/edit-assigned-beneficiary",
  get_subvention_detail: "subvention/v1/get-subvention",
  create_checkout: "subvention/v1/create-checkout",
  checkout_list: "subvention/v1/checkout-list",
  delete_checkout: "subvention/v1/delete-checkout",
  update_checkout: "subvention/v1/update-checkout",
  create_subvention: "subvention/v1/create-subvention",
  edit_subvention: "subvention/v1/edit-subvention",
  create_grant_settings: "setting/v1/add-grant-amount",
  get_grant_settings: "setting/v1/get-grant-amount",
  grant_access: "beneficiary/v1/grant-approval",
  update_subvention_status: "subvention/v1/update-status",
  delete_subvention: "subvention/v1/delete-subvention",
  add_master_business_plan: "business-plan/v1/add-plan-master",
  edit_master_business_plan: "business-plan/v1/edit-plan-master",
  get_business_canvas: "business-plan/v1/get-canvas?business_master_id=",
  add_business_canvas: "business-plan/v1/add-or-edit-canvas",
  get_business_plan: "business-plan/v1/get",
  get_production: "business-plan/v1/get-production?business_master_id=",
  add_production: "business-plan/v1/add-or-edit-production",
  get_market_analysis: "business-plan/v1/market-analysis?business_master_id=",
  add_market_analysis: "business-plan/v1/add-market-analysis",
  delete_market_analysis: "business-plan/v1/delete-market-analysis",
  edit_market_analysis: "business-plan/v1/edit-market-analysis",
  get_material_resources:
    "business-plan/v1/material-resources?business_master_id=",
  add_material_resources: "business-plan/v1/add-material-resources",
  delete_material_resources:
    "business-plan/v1/delete-material-resources?material_resource_id=",
  edit_material_resources: "business-plan/v1/edit-material-resources",
  get_human_resources: "business-plan/v1/human-resources?business_master_id=",
  add_human_resources: "business-plan/v1/add-human-resources",
  edit_human_resources: "business-plan/v1/edit-human-resources",
  delete_human_resources:
    "business-plan/v1/delete-human-resources?human_resource_id=",
  get_production_unit: "business-plan/v1/production-unit?business_master_id=",
  add_production_unit: "business-plan/v1/add-production-unit",
  edit_production_unit: "business-plan/v1/edit-production-unit",
  delete_production_unit:
    "business-plan/v1/delete-production-unit?production_unit_id=",
  get_fixed_cost: "business-plan/v1/fixed-costs?business_master_id=",
  delete_fixed_cost: "business-plan/v1/delete-fixed-cost?fixed_cost_id=",
  edit_fixed_cost: "business-plan/v1/edit-fixed-cost",
  add_fixed_cost: "business-plan/v1/add-fixed-cost",
  get_variable_cost: "business-plan/v1/variable-costs?business_master_id=",
  add_variable_cost: "business-plan/v1/add-variable-cost",
  edit_variable_cost: "business-plan/v1/edit-variable-cost",
  delete_variable_cost:
    "business-plan/v1/delete-variable-cost?variable_cost_id=",
  get_calculation: "business-plan/v1/calculation?business_master_id=",
  change_currency: "account/v1/change-currency",
  add_edit_conversion_rate: "setting/v1/add-or-edit-rate",
  get_conversion_rate: "setting/v1/get-conversion-rate",
  add_business_plan_margin: "business-plan/v1/add-or-edit-margin",
  notification_list: "notification/v1/notification-list",

  // settings api url starts here
  time_zone_change: "account/v1/change-time-zone",
  // settings api url ends here

  get_cooperatives: "coach-plan/v1/cooperative-list",
  delete_cooperatives: "coach-plan/v1/delete-cooperative",
  get_cooperative_detail: "coach-plan/v1/get-cooperative",
  add_cooperatives: "coach-plan/v1/create-cooperative",
  update_cooperatives: "coach-plan/v1/update-cooperative",
  add_coop_member: "coach-plan/v1/add-coop-member",
  delete_coop_member: "coach-plan/v1/delete-coop-member",

  get_suppliers: "coach-plan/v1/supplier-list",
  add_suppliers: "coach-plan/v1/addOrUpdate-supplier",
  delete_suppliers: "coach-plan/v1/delete-supplier",
  supplier_detail: "coach-plan/v1/get-supplier",
  assign_equipments: "coach-plan/v1/add-supplierEquipment",
  unassign_equipments: "coach-plan/v1/delete-supplierEquipment",

  update_language: "account/v1/change-language",
  clear_all_data: "account/v1/clear-all-data",

  get_report: "dashboard/v1/download-excel-dashboard",
};
