import { useEffect, useState } from "react";
import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AssignBeneficiaryCard from "../../../Components/CommonElements/AssignBeneficiaryCard";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import RoleType from "../../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useTranslation } from "react-i18next";

const AssignBeneficiary = () => {
  const dispatch = useDispatch();
  let role = Cookies.get("emp_role");
  const navigate = useNavigate();
  const location = useLocation();
  const [inputCategoryData, setInputCategoryData] = useState("");
  const [searchOptionData, setSearchOptionData] = useState("global_search");
  const [filterData, setFilterData] = useState();
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [inputTypeData, setInputTypeData] = useState("text");
  const [callApi, setCallApi] = useState(true);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [pages, setPages] = useState();
  const { t } = useTranslation();

  const listHeading = [
    { data: `${t("reference_id")}` },
    { data: `${t("full_name")}` },
    { data: `${t("contact_detail")}` },
    { data: `${t("province")}` },
    { data: `${t("grant_status")}` },
    { data: `${t("status")}` },
  ];

  const searchOption1Array = [
    { name: `${t("search_by")}`, value: "global_search", type: "text" },
    { name: `${t("reference_id")}`, value: "reference_id", type: "text" },
    { name: `${t("name")}`, value: "fullname", type: "text" },
    { name: `${t("contact_detail")}`, value: "telephone_pref", type: "text" },
    { name: `${t("province")}`, value: "province", type: "text" },
    { name: `${t("commune")}`, value: "commune", type: "text" },
    { name: `${t("quartier")}`, value: "quartier", type: "text" },
    { name: `${t("status")}`, value: "status", type: "select" },
    {
      name: `${t("grant_status")}`,
      value: "grant_eligibility",
      type: "select",
    },
    { name: `${t("sector")}`, value: "sector", type: "select" },
  ];

  const searchOptionStatusArray = [
    // { name: "Status", value: "", type: "status" },
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
    { name: "Allowed", value: 1, type: "grant_eligibility" },
    { name: "Not Allowed", value: 0, type: "grant_eligibility" },
    { name: "Unassigned", value: 2, type: "grant_eligibility" },
    { name: "Unassigned", value: 0, type: "assignment" },
    { name: "Assigned", value: 1, type: "assignment" },
    { name: "Agri Business", value: "agribusiness", type: "sector" },
    { name: "Manufacturing", value: "manufacturing", type: "sector" },
    { name: "Services", value: "services", type: "sector" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  const [assignedBeneficiaryList, setAssignedBeneficiaryList] = useState([]);

  const handlePageChange = (pageitem) => {
    setBeneficiaryList([]);
    setActivePage(pageitem);
    getBeneficiaryList(pageitem);
  };

  const AssignCheckboxBeneficiaries = (e, data) => {
    if (e.target.checked === true) {
      setAssignedBeneficiaryList((prevData) => [...prevData, data]);
    } else {
      let new_beneficiarierList = assignedBeneficiaryList.filter(
        (item) => item?.id !== data?.id
      );

      setAssignedBeneficiaryList(new_beneficiarierList);
    }
  };

  const submitAssignmentHandler = () => {
    setPageIsLoading(true);
    let bene = {
      coach_id: location?.state?.id,
      new_beneficiaries: assignedBeneficiaryList.map((item) => item.id),
    };
    let unassignbene = {
      delete_assignment: assignedBeneficiaryList.map((item) => item.id),
      new_beneficiaries: [],
      coach_id: location?.state?.id,
    };
    {
      location?.state?.type === "assign" &&
        handleFetchRequest(`${apiUrl?.assign_beneficiary}`, "POST", bene).then(
          (response) => {
            if (response?.status === true) {
              setPageIsLoading(false);
              toast.success(response?.message);
              setAssignedBeneficiaryList([]);
              setCallApi(true);
              // backButtonHandler();
              if (location.state.param === "profile") {
                navigate(RoutesLink.coach_profile, {
                  state: { id: location.state.id, tabid: "2" },
                });
              } else {
                navigate(-1);
              }
            } else if (response?.messageCode === 3) {
              Cookies.remove("emp_token");
              Cookies.remove("emp_role");
              Cookies.remove("emp_name");
              Cookies.remove("emp_currency");
              Cookies.remove("emp_profile");
              Cookies.remove("emp_data");
              Cookies.remove("emp_conversion");
              Cookies.remove("emp_conversion");
              dispatch(setUserLoggedInDetails({}));
              dispatch(profileImage(""));
              setIsLoading(false);
              navigate(RoutesLink.login);
            } else {
              setPageIsLoading(false);
              toast.error(response?.message);
            }
          }
        );
    }

    {
      location?.state?.type === "unassign" &&
        handleFetchRequest(
          `${apiUrl?.edit_assign_beneficiary}`,
          "PUT",
          unassignbene
        ).then((response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            toast.success(response?.message);
            setAssignedBeneficiaryList([]);
            setCallApi(true);
            if (location.state.param === "profile") {
              navigate(RoutesLink.coach_profile, {
                state: { id: location.state.id, tabid: "2" },
              });
            } else {
              navigate(-1);
            }
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        });
    }
  };

  const submitAssignmentCooperativeHandler = () => {
    setPageIsLoading(true);
    let bene = {
      cooperative_id: location?.state?.id,
      beneficiary_ids: assignedBeneficiaryList.map((item) => item.id),
    };
    let unassignbene = {
      cooperative_id: location?.state?.id,
      beneficiary_ids: assignedBeneficiaryList.map((item) => item.id),
    };
    {
      location?.state?.type === "assign" &&
        handleFetchRequest(`${apiUrl?.add_coop_member}`, "POST", bene).then(
          (response) => {
            if (response?.status === true) {
              setPageIsLoading(false);
              toast.success(response?.message);
              setAssignedBeneficiaryList([]);
              setCallApi(true);
              // backButtonHandler();
              if (location.state.pageurl) {
                navigate(location.state.pageurl, {
                  state: { id: location.state.id, tabid: "2" },
                });
              } else {
                navigate(-1);
              }
            } else if (response?.messageCode === 3) {
              Cookies.remove("emp_token");
              Cookies.remove("emp_role");
              Cookies.remove("emp_name");
              Cookies.remove("emp_currency");
              Cookies.remove("emp_profile");
              Cookies.remove("emp_data");
              Cookies.remove("emp_conversion");
              Cookies.remove("emp_conversion");
              dispatch(setUserLoggedInDetails({}));
              dispatch(profileImage(""));
              setIsLoading(false);
              navigate(RoutesLink.login);
            } else {
              setPageIsLoading(false);
              toast.error(response?.message);
            }
          }
        );
    }

    {
      location?.state?.type === "unassign" &&
        handleFetchRequest(
          `${apiUrl?.delete_coop_member}`,
          "Delete",
          unassignbene
        ).then((response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            toast.success(response?.message);
            setAssignedBeneficiaryList([]);
            setCallApi(true);
            if (location.state.pageurl) {
              navigate(location.state.pageurl, {
                state: { id: location.state.id, tabid: "2" },
              });
            } else {
              navigate(-1);
            }
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        });
    }
  };

  const assignAllBeneficiaryHandler = (e) => {
    if (e.target.checked === true) {
      const all_assigned_list = [...beneficiaryList];
      setAssignedBeneficiaryList(all_assigned_list);
    } else {
      setAssignedBeneficiaryList([]);
    }
  };

  const filters = [];

  const applyFilter = () => {
    const filterValue = filterData ? `&${searchOptionData}=${filterData}` : "";
    filters.push(filterValue);
  };

  // if (searchOptionData === "contact_detail") {
  //   if (filterData) {
  //     filters.push(`&telephone_pref=${filterData}`)
  //   }
  // }
  // if (searchOptionData === "quartier") {
  //   if (filterData) {
  //     filters.push(`&quartier=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "commune") {
  //   if (filterData) {
  //     filters.push(`&commune=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "province") {
  //   if (filterData) {
  //     filters.push(`&province=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "fullname") {
  //   if (filterData) {
  //     filters.push(`&fullname=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "reference_id") {
  //   if (filterData) {
  //     filters.push(`&reference_id=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "status") {
  //   if (filterData) {
  //     filters.push(`&status=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "grant") {
  //   if (filterData) {
  //     filters.push(`&grant_eligibility=${filterData}`)
  //   }
  // }
  // if (searchOptionData === "assignment") {
  //   if (filterData) {
  //     filters.push(`&assignment=${filterData}`)
  //   }
  // }

  const deleteBeneficiaryHandler = (id) => {
    let new_beneficiarierList = assignedBeneficiaryList.filter(
      (item) => item?.id !== id
    );
    setAssignedBeneficiaryList(new_beneficiarierList);
  };

  const cancelAssignedListHandler = () => {
    setAssignedBeneficiaryList([]);
  };

  const backButtonHandler = () => {
    if (location.state.pageurl) {
      navigate(location.state.pageurl, {
        state: { id: location.state.id, tabid: "2" },
      });
      return;
    } else {
      navigate(-1);
    }

    if (location.state.param === "profile") {
      navigate(RoutesLink.coach_profile, {
        state: { id: location.state.id, tabid: "2" },
      });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    getBeneficiaryList(activePage);
  }, []);

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setActivePage(1);
    setSearchOptionData("global_search");
    setFilterData("");
    setInputCategoryData("###select");
    setBeneficiaryList([]);
    let apiUrl1;
    if (location.state.page == "cooperative") {
      apiUrl1 = `${
        apiUrl.beneficiary_list_coah
      }?page_no=${1}&page_size=10&cooperative=0`;
    } else {
      apiUrl1 = `${
        apiUrl.beneficiary_list
      }?page_no=${1}&page_size=10&assignment=0`;
    }

    {
      location?.state?.type === "assign" &&
        handleFetchRequest(apiUrl1).then((response) => {
          console.log(response);
          if (response?.status === true) {
            setIsLoading(false);
            setBeneficiaryList(response.data);
            setCallApi(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setCallApi(false);
            setIsLoading(false);
            console.log(response);
            toast.error(response.message);
          }
        });
    }
    {
      if (RoleType.coach_value === role) {
        let apiUrl1;
        if (location.state.page == "cooperative") {
          apiUrl1 = `${
            apiUrl.beneficiary_list_coah
          }?page_no=${1}&page_size=10&cooperative=1&cooperative_id=${
            location.state.id
          }`;
        } else {
          apiUrl1 = `${
            apiUrl?.get_assigned_beneficiary
          }?page_no=${1}&page_size=10}`;
        }
        location?.state?.type === "unassign" &&
          handleFetchRequest(
            `${apiUrl?.get_assigned_beneficiary}?page_no=1&page_size=10`
          ).then((response) => {
            if (response?.status === true) {
              setBeneficiaryList(response?.data);
              setCallApi(false);
              setIsLoading(false);
              setPages(response?.pageInfo);
            } else if (response?.messageCode === 3) {
              Cookies.remove("emp_token");
              Cookies.remove("emp_role");
              Cookies.remove("emp_name");
              Cookies.remove("emp_currency");
              Cookies.remove("emp_profile");
              Cookies.remove("emp_data");
              Cookies.remove("emp_conversion");
              Cookies.remove("emp_conversion");
              dispatch(setUserLoggedInDetails({}));
              dispatch(profileImage(""));
              setIsLoading(false);
              navigate(RoutesLink.login);
            }
          });
      } else if (RoleType.admin_value === role) {
        let apiUrl1;
        if (location.state.page == "cooperative") {
          apiUrl1 = `${
            apiUrl.beneficiary_list_coah
          }?page_no=${1}&page_size=10&cooperative=1&cooperative_id=${
            location.state.id
          }`;
        } else {
          apiUrl1 = `${apiUrl?.get_assigned_beneficiary}?coach_id=${
            location?.state?.id
          }&page_no=${1}&page_size=10`;
        }
        location?.state?.type === "unassign" &&
          handleFetchRequest(apiUrl1).then((response) => {
            if (response.status === true) {
              setBeneficiaryList(response?.data);
              setCallApi(false);
              setIsLoading(false);
              setPages(response?.pageInfo);
            } else if (response?.messageCode === 3) {
              Cookies.remove("emp_token");
              Cookies.remove("emp_role");
              Cookies.remove("emp_name");
              Cookies.remove("emp_currency");
              Cookies.remove("emp_profile");
              Cookies.remove("emp_data");
              Cookies.remove("emp_conversion");
              Cookies.remove("emp_conversion");
              dispatch(setUserLoggedInDetails({}));
              dispatch(profileImage(""));
              setIsLoading(false);
              navigate(RoutesLink.login);
            }
          });
      }
    }
  };

  const getBeneficiaryList = (pageItem) => {
    applyFilter();
    setIsLoading(true);
    let apiUrl1;
    if (location.state.page == "cooperative") {
      apiUrl1 = `${
        apiUrl.beneficiary_list_coah
      }?page_no=${pageItem}&page_size=10&cooperative=0${filters.join("&")}`;
    } else {
      apiUrl1 = `${
        apiUrl.beneficiary_list
      }?page_no=${pageItem}&page_size=10&assignment=0${filters.join("&")}`;
    }

    {
      location?.state?.type === "assign" &&
        handleFetchRequest(apiUrl1).then((response) => {
          console.log(response);
          if (response?.status === true) {
            setIsLoading(false);
            setBeneficiaryList(response.data);
            setCallApi(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setCallApi(false);
            setIsLoading(false);
            console.log(response);
            toast.error(response.message);
          }
        });
    }
    {
      if (RoleType.coach_value === role) {
        let apiUrl1;
        if (location.state.page == "cooperative") {
          apiUrl1 = `${
            apiUrl.beneficiary_list_coah
          }?page_no=${pageItem}&page_size=10&cooperative=1&cooperative_id=${
            location.state.id
          }${filters.join("&")}`;
        } else {
          apiUrl1 = `${
            apiUrl?.get_assigned_beneficiary
          }?page_no=${pageItem}&page_size=10${filters.join("&")}`;
        }
        location?.state?.type === "unassign" &&
          handleFetchRequest(apiUrl1).then((response) => {
            if (response?.status === true) {
              setBeneficiaryList(response?.data);
              setCallApi(false);
              setIsLoading(false);
              setPages(response?.pageInfo);
            } else if (response?.messageCode === 3) {
              Cookies.remove("emp_token");
              Cookies.remove("emp_role");
              Cookies.remove("emp_name");
              Cookies.remove("emp_currency");
              Cookies.remove("emp_profile");
              Cookies.remove("emp_data");
              Cookies.remove("emp_conversion");
              Cookies.remove("emp_conversion");
              dispatch(setUserLoggedInDetails({}));
              dispatch(profileImage(""));
              setIsLoading(false);
              navigate(RoutesLink.login);
            }
          });
      } else if (RoleType.admin_value === role) {
        let apiUrl1;
        if (location.state.page == "cooperative") {
          apiUrl1 = `${
            apiUrl.beneficiary_list_coah
          }?page_no=${pageItem}&page_size=10&cooperative=1&cooperative_id=${
            location.state.id
          }${filters.join("&")}`;
        } else {
          apiUrl1 = `${apiUrl?.get_assigned_beneficiary}?coach_id=${
            location?.state?.id
          }&page_no=${pageItem}&page_size=10${filters.join("&")}`;
        }
        location?.state?.type === "unassign" &&
          handleFetchRequest(apiUrl1).then((response) => {
            if (response.status === true) {
              setBeneficiaryList(response?.data);
              setCallApi(false);
              setIsLoading(false);
              setPages(response?.pageInfo);
            } else if (response?.messageCode === 3) {
              Cookies.remove("emp_token");
              Cookies.remove("emp_role");
              Cookies.remove("emp_name");
              Cookies.remove("emp_currency");
              Cookies.remove("emp_profile");
              Cookies.remove("emp_data");
              Cookies.remove("emp_conversion");
              Cookies.remove("emp_conversion");
              dispatch(setUserLoggedInDetails({}));
              dispatch(profileImage(""));
              setIsLoading(false);
              navigate(RoutesLink.login);
            }
          });
      }
    }
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading
                    backButtonHandler={backButtonHandler}
                    searchOption1Array={searchOption1Array}
                    //  addLink={RoutesLink?.add_beneficiary}
                    //  btnName={"Add Beneficiary"}
                    name={
                      location?.state.type === "assign"
                        ? "Assign Beneficiary"
                        : "Unassign Beneficiary"
                    }
                    useraccount={true}
                    setInputTypeData1={setInputTypeData}
                    setMainCallApi={() => getBeneficiaryList(1)}
                    inputTypeData={inputTypeData}
                    //  downloadToggle={downloadModalToggle}
                    //  addArray={addAction1}
                    searchOption2Array={searchOption2Array}
                    setData={setBeneficiaryList}
                    setFilterData={setFilterData}
                    setSearchOptionData={setSearchOptionData}
                    filterData={filterData}
                    setActivePage={setActivePage}
                    ResetAccountHandler={ResetAccountHandler}
                    inputCategoryData={inputCategoryData}
                    setInputCategoryData={setInputCategoryData}
                  />
                  <div className="ptable px-0">
                    <div
                      className={
                        assignedBeneficiaryList?.length > 0
                          ? "tablcont active"
                          : "tablcont"
                      }
                    >
                      <PrimaryTable
                        assignedList={assignedBeneficiaryList}
                        listData={beneficiaryList}
                        listHeading={listHeading}
                        editPage={RoutesLink?.add_beneficiary}
                        checkboxFunction={AssignCheckboxBeneficiaries}
                        assignAllCheckbox={assignAllBeneficiaryHandler}
                        // handleDelete={deletHandler}
                        // addData={addHeadingAction}
                        assignBeneficiary={true}
                        beneficiary={true}
                        activePage={activePage}
                        pages={pages}
                        handlePageChange={handlePageChange}
                        isLoading={isLoading}
                        idClickLink={RoutesLink?.beneficiary_profile}
                      />
                    </div>
                    {assignedBeneficiaryList?.length > 0 && (
                      <AssignBeneficiaryCard
                        assignedBeneficiaryList={assignedBeneficiaryList}
                        deleteBeneficiaryHandler={deleteBeneficiaryHandler}
                        cancelAssignedListHandler={cancelAssignedListHandler}
                        submitAssignmentHandler={() => {
                          if (location.state.page == "cooperative") {
                            console.log("khkfghkdf");
                            submitAssignmentCooperativeHandler();
                          } else {
                            console.log("in in in");
                            submitAssignmentHandler();
                          }
                        }}
                        isLoading={pageIsLoading}
                        type={location?.state?.type === "unassign" && "delete"}
                      />
                    )}
                  </div>

                  {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
                       {!isLoading && beneficiaryList?.length===0 ? <h4 style={{ textAlign: "center" }}>No Data Found</h4>:"" } */}
                  {/* {beneficiaryList?.length>=10||currentPage>1?<Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} setListData={setBeneficiaryList}/>:""}     */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageIsLoading && <PageLoader />}
    </Layout>
  );
};
export default AssignBeneficiary;
