import { Modal } from "reactstrap"

const DownloadModal = ({ openModal, modalToggle }) => {
  return (<Modal isOpen={openModal} toggle={modalToggle} centered  >
    <div className="d-flex flex-column align-items-center p-4 gap-5">
      <div>
        <h4 className="text-center">
          Click Download button to download data in csv format.
          Else click Cancel to discard this action
        </h4>
      </div>
      <div className="d-flex flex-column w-100 px-4 gap-2">
        <button className="btn btn-light" onClick={modalToggle}>Cancel</button>
        <button className="btn btn-primary">Download</button>
      </div>
    </div>
  </Modal>)
}
export default DownloadModal;