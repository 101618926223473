import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout"
import PrimaryTable from "../../../Components/Tables/PrimaryTable";

const Coaching = () => {

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading addLink={"/add-Coaching"} name={"Coaching"} />
                  <PrimaryTable
                  // listData={coachList} listHeading={listHeading}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </Layout>
  )
}
export default Coaching;