import { useForm } from "react-hook-form";
import PrimaryInput from "../../../Components/Inputs/PrimaryInput";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { useLocation, useNavigate } from "react-router-dom";
import FormLayout from "../../../Components/Layout/FormLayout";
import AppHeading from "../../../Components/CommonElements/Headings";
import {
  valChar,
  valOptionalChar,
  validateAge,
  validateMobileNumber,
  vallatitude,
  vallongitude,
} from "../../../ApiHelper/FormValidation";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { useTranslation } from "react-i18next";

const AddBeneficiary = () => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [startDate, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const [fetchedItems, setFetchedItems] = useState([]);
  // const [coachId, setCoachId] = useState(null);
  // const [coachName, setCoachName] = useState(null);
  const [imagePreview, setImagePreview] = useState();
  const [imageUrl, setImageUrl] = useState();

  // const selectDateHandler = (d) => {
  //   setDate(d)
  // }

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const validateReferenceId = (value, string) => {
    // Use a regular expression to check if the username contains any spaces
    if (/\s/.test(value)) {
      return `${string} should not contain spaces`;
    }
    return true;
  };

  console.log(location);

  const imageUploadHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (500KB)
      const maxSizeInBytes = 500 * 1024;
      if (file.size > maxSizeInBytes) {
        toast.error(
          "File size exceeds the limit of 500KB. Please choose a smaller file."
        );
        // alert('File size exceeds the limit of 500KB. Please choose a smaller file.');
        // You may want to reset the input value to clear the selected file
        e.target.value = null;
        return;
      }
    }
    const preview = URL.createObjectURL(e?.target?.files?.[0]);

    setImagePreview(preview);
    setImageUrl(e?.target?.files?.[0]);
  };

  const imageDeleteHandler = () => {
    setImagePreview();
    setImageUrl();
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      handleFetchRequest(
        `${apiUrl.beneficiary_detail}?beneficiary_id=${location?.state?.id}`
      ).then((response) => {
        if (response?.status === true) {
          console.log(response.data);
          setImagePreview(response?.data?.image);
          setImageUrl(response?.data?.image);
          reset({
            registration_site: response?.data?.registration_site,
            se_id: response?.data?.se_id,
            number_eligible_activities:
              response?.data?.number_eligible_activities,
            eligible_activities: response?.data?.eligible_activities,
            a2_company_name: response?.data?.a2_company_name,
            a2_commune: response?.data?.a2_commune,
            a2_quartier: response?.data?.a2_quartier,
            a2_rccm_number: response?.data?.a2_rccm_number,
            main_sample: response?.data?.main_sample,
            replacement_sample: response?.data?.replacement_sample,
            replacement_order: response?.data?.replacement_order,
            number: response?.data?.number,
            presence: response?.data?.presence,
            loterie_site: response?.data?.loterie_site,
            statut_loterie: response?.data?.statut_loterie,
            sector: response?.data?.sector,
            // grant_eligibility:response?.data?.grant_eligibility,
            status: response?.data?.status,
            // grant_amount:response?.data?.grant_amount,
            // grant_balance:response?.data?.grant_balance,
            a1_company_name: response?.data?.a1_company_name,
            a1_commune: response?.data?.a1_commune,
            a1_quartier: response?.data?.a1_quartier,
            a1_rccm_number: response?.data?.a1_rccm_number,
            loterie: response?.data?.loterie,
            commune_id: response?.data?.commune_id,
            province_id: response?.data?.province_id,
            firstname: response?.data?.firstname,
            reference_id: response?.data?.reference_id,
            fullname: response?.data?.fullname,
            nickname: response?.data?.nickname,
            surname: response?.data?.surname,
            sex: response?.data?.sex,
            age: response?.data?.age,
            education_level: response?.data?.education_level,
            maritial_status: response?.data?.maritial_status,
            province: response?.data?.province,
            quartier: response?.data?.quartier,
            quartier_id: response?.data?.quartier_id,
            commune: response?.data?.commune,
            telephone_pref: response?.data?.telephone_pref,
            telephone_alt1: response?.data?.telephone_alt1,
            telephone_alt2: response?.data?.telephone_alt2,
            relative1_name: response?.data?.relative1_name,
            relative1_relation: response?.data?.relative1_relation,
            relative1_telephone: response?.data?.relative1_telephone,
            relative2_name: response?.data?.relative2_name,
            relative2_relation: response?.data?.relative2_relation,
            relative2_telephone: response?.data?.relative2_telephone,
            latitude: response?.data?.latitude ? response?.data?.latitude : "",
            longitude: response?.data?.longitude
              ? response?.data?.longitude
              : "",
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      });
      //  setImageUrl(location?.state?.image);
      //  setImagePreview(location?.state?.image);
      // setCoachName(`${location?.state?.coach?.first_name} ${location?.state?.coach?.last_name}`)
      // setCoachId(location?.state?.coach?.id)
    } else {
      reset({ status: 1 });
    }
  }, []);

  const backButtonHandler = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    console.log(data);
    // let address=JSON.stringify({address_line_1:data?.address_line_1,address_line_2:data?.address_line_2,city:data?.address_city,state:data?.address_state,country:data?.address_country,zip_code:data?.address_zip_code});
    const formData = new FormData();
    setIsLoading(true);
    //latest form data starts here

    formData.append("reference_id", data?.reference_id?.toUpperCase());
    formData.append("registration_site", data?.registration_site);
    formData.append("se_id", data?.se_id);
    formData.append("loterie", data?.loterie?.toUpperCase());
    formData.append("province", data?.province);
    formData.append("province_id", data?.province_id);
    formData.append("commune", data?.commune?.toUpperCase());
    formData.append("commune_id", data?.commune_id);
    formData.append("quartier", data?.quartier?.toUpperCase());
    formData.append("quartier_id", data?.quartier_id);
    formData.append("fullname", data?.fullname?.toUpperCase());
    formData.append("firstname", data?.firstname?.toUpperCase());
    formData.append("surname", data?.surname?.toUpperCase());
    formData.append("nickname", data?.nickname?.toUpperCase());
    formData.append("telephone_pref", data?.telephone_pref);
    formData.append("telephone_alt1", data?.telephone_alt1);
    formData.append("telephone_alt2", data?.telephone_alt2);
    formData.append("relative1_name", data?.relative1_name?.toUpperCase());
    formData.append("relative1_telephone", data?.relative1_telephone);
    formData.append(
      "relative1_relation",
      data?.relative1_relation.charAt(0).toUpperCase() +
        data?.relative1_relation.slice(1)
    );
    formData.append("relative2_name", data?.relative2_name?.toUpperCase());
    formData.append("relative2_telephone", data?.relative2_telephone);
    formData.append(
      "relative2_relation",
      data?.relative2_relation?.charAt(0).toUpperCase() +
        data?.relative1_relation.slice(1)
    );
    formData.append("sex", data?.sex);
    formData.append("age", data?.age);
    formData.append("education_level", data?.education_level);
    formData.append("maritial_status", data?.maritial_status);
    formData.append(
      "number_eligible_activities",
      data?.number_eligible_activities
    );
    formData.append("status", data?.status);
    formData.append(
      "eligible_activities",
      data?.eligible_activities?.toUpperCase()
    );
    formData.append("a1_company_name", data?.a1_company_name);
    formData.append("a1_commune", data?.a1_commune);
    formData.append("a1_quartier", data?.a1_quartier);
    formData.append("a1_rccm_number", data?.a1_rccm_number);
    formData.append("a2_company_name", data?.a2_company_name);
    formData.append("a2_commune", data?.a2_commune);
    formData.append("a2_quartier", data?.a2_quartier);
    formData.append("a2_rccm_number", data?.a2_rccm_number);
    formData.append("main_sample", data?.main_sample);
    formData.append("replacement_sample", data?.replacement_sample);
    formData.append("replacement_order", data?.replacement_order);
    formData.append("number", data?.number);
    formData.append("presence", data?.presence);
    formData.append("statut_loterie", data?.statut_loterie);
    formData.append("loterie_site", data?.loterie_site);
    formData.append("sector", data?.sector);
    formData.append("latitude", data?.latitude);
    formData.append("longitude", data?.longitude);
    if (imageUrl) {
      formData.append("image", imageUrl);
    }
    //latest form data ends here

    if (location?.state?.id) {
      formData.append("beneficiary_id", location?.state?.id);
    }
    if (location?.state?.id) {
      handleFetchRequest(
        apiUrl.edit_beneficiary,
        "PUT",
        formData,
        null,
        true
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response.message, {
            position: "top-right",
          });
          // reset();
          navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message, {
            position: "top-right",
          });
        }
      });
    } else {
      handleFetchRequest(
        apiUrl.add_beneficiary,
        "POST",
        formData,
        null,
        true
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response.message, {
            position: "top-right",
          });
          reset();
          navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message, {
            position: "top-right",
          });
        }
      });
    }
  };

  return (
    <FormLayout name={"Add Beneficiary"} backButtonHandler={backButtonHandler}>
      <form
        className="form-inline"
        //  class="form-sample"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row mt-4 mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label htmlFor="reference_id" className="input-label ps-3">
              {t("Reference_ID")}
              <span className="text-danger ps-3"> *</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              id="reference_id"
              className="form-control"
              placeholder="Reference ID"
              register={register("reference_id", {
                required: "Please enter Reference Id",
                validate: (value) => validateReferenceId(value, "Reference ID"),
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.reference_id?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3"> {t("reg_date")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Registration Site"
              register={register("registration_site", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.registration_site?.message}
            </span>
          </div>
        </div>

        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("reg_sideId")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Registration Site ID"
              register={register("se_id", {
                // required: "Please enter site ID",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">{errors?.se_id?.message}</span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">Lottery</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Lottery"
              register={register("loterie", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.loterie?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              Sector <span className="text-danger ps-3">*</span>
            </label>
            <div className="form-group">
              <select
                className="form-control"
                {...register("sector", {
                  required: "Please Select Sector *",
                })}
              >
                <option value={""}>Select Sector </option>
                <option value={"agribusiness"}>Agri Business</option>
                <option value={"manufacturing"}>Manufacturing</option>
                <option value={"services"}>Services</option>
              </select>
            </div>
            <span className="text-danger ps-3 ">{errors?.sector?.message}</span>
          </div>
        </div>

        <div className="mt-4">
          <AppHeading title={"Address"} />
          <div className="row mt-4 mb-2 row-gap-2">
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">
                {t("province")} <span className="text-danger ps-3">*</span>
              </label>
              <PrimaryInput
                // defaultValue={profileData?.coach_profile?.email}
                type="text"
                className="form-control"
                placeholder="Province"
                register={register("province", {
                  required: "Please enter Province",
                  validate: valChar,
                })}
              />
              <span className="text-danger ps-3 ">
                {errors?.province?.message}
              </span>
            </div>
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">Province ID</label>
              <PrimaryInput
                // defaultValue={profileData?.coach_profile?.email}
                type="number"
                className="form-control"
                placeholder="Province ID"
                register={register("province_id", {
                  // required: "Please enter Province Id",
                  validate: valOptionalChar,
                })}
              />
              <span className="text-danger ps-3 ">
                {errors?.province_id?.message}
              </span>
            </div>
          </div>
          <div className="row mb-2 row-gap-2">
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">
                {t("commune")} <span className="text-danger ps-3">*</span>
              </label>
              <PrimaryInput
                // defaultValue={profileData?.coach_profile?.email}
                type="text"
                className="form-control"
                placeholder="Commune"
                register={register("commune", {
                  required: "Please enter commune",
                  validate: valChar,
                })}
              />
              <span className="text-danger ps-3 ">
                {errors?.commune?.message}
              </span>
            </div>
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">{t("communeId")} </label>
              <PrimaryInput
                // defaultValue={profileData?.coach_profile?.email}
                type="number"
                className="form-control"
                placeholder="Commune ID"
                register={register("commune_id", {
                  // required: "Please enter Commune ID",
                  validate: valOptionalChar,
                })}
              />
              <span className="text-danger ps-3 ">
                {errors?.commune_id?.message}
              </span>
            </div>
          </div>
          <div className="row mb-2 row-gap-2">
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">
                {t("quartier")} <span className="text-danger ps-3">*</span>
              </label>
              <PrimaryInput
                // defaultValue={profileData?.coach_profile?.email}
                type="text"
                className="form-control"
                placeholder="Quartier"
                register={register("quartier", {
                  required: "Please enter Quartier",
                  validate: valChar,
                })}
              />
              <span className="text-danger ps-3 ">
                {errors?.quartier?.message}
              </span>
            </div>
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">{t("quertierId")} </label>
              <PrimaryInput
                // defaultValue={profileData?.coach_profile?.email}
                type="number"
                className="form-control"
                placeholder="Quartier ID"
                register={register("quartier_id", {
                  // required: "Please enter quartier ID",
                  validate: valOptionalChar,
                })}
              />
              <span className="text-danger ps-3 ">
                {errors?.quartier_id?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Full_Name")}
              <span className="text-danger ps-3">*</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Full Name"
              register={register("fullname", {
                required: "Please enter Full Name",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.fullname?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("First_Name")}
              <span className="text-danger ps-3">*</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="First Name"
              register={register("firstname", {
                required: "Please enter First Name",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.firstname?.message}
            </span>
          </div>
        </div>
        <div class="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Surname")}
              <span className="text-danger ps-3">*</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Surname"
              register={register("surname", {
                required: "Please enter Surname",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.surname?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Nickname")}
              <span className="text-danger ps-3">*</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Nickname"
              register={register("nickname", {
                required: "Please enter nickname",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.nickname?.message}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <AppHeading title={"Telephone"} />
        </div>
        <div className="row mt-4 mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Telephone")}
              <span className="text-danger ps-3">*</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Telephone"
              register={register("telephone_pref", {
                required: "Please enter Telephone No.",
                validate: validateMobileNumber,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.telephone_pref?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("telephone_alt1")}
              <span className="text-danger ps-3">*</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Telephone Alternate 1"
              register={register("telephone_alt1", {
                required: "Please enter Alternate Telephone No.",
                validate: validateMobileNumber,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.telephone_alt1?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("telephone_alt2")} <span className="text-danger ps-3">*</span>
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Telephone Alternate 2"
              register={register("telephone_alt2", {
                required: "Please enter Alternate Telephone No.",
                validate: validateMobileNumber,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.telephone_alt2?.message}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <AppHeading title={"Relatives"} />
        </div>
        <div className="row mt-4 mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("relatives1_name")}
              <span className="text-danger ps-3">*</span>{" "}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Relative 1 Name"
              register={register("relative1_name", {
                required: "Please enter Relative 1 Name",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.relative1_name?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("relatives1_telephone")}{" "}
              <span className="text-danger ps-3">*</span>{" "}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Relative 1 Telephone"
              register={register("relative1_telephone", {
                required: "Please enter Relative 1 Telephone",
                validate: validateMobileNumber,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.relative1_telephone?.message}{" "}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("relative1_relation")}{" "}
              <span className="text-danger ps-3">*</span>{" "}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Relative 1 Relation"
              register={register("relative1_relation", {
                required: "Please enter Relation",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.relative1_relation?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("relative2_name")} <span className="text-danger ps-3">*</span>{" "}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Relative 2 Name"
              register={register("relative2_name", {
                required: "Please enter Relative 2 Name",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.relative2_name?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("relative2_tel")} <span className="text-danger ps-3">*</span>{" "}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Relative 2 Telephone"
              register={register("relative2_telephone", {
                required: "Please enter Telephone No.",
                validate: validateMobileNumber,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.relative2_telephone?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("relative2_rel")} <span className="text-danger ps-3">*</span>{" "}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Relative 2 Relation"
              register={register("relative2_relation", {
                required: "Please enter Relation",
                validate: valChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.relative2_relation?.message}
            </span>
          </div>
        </div>

        <div className="mt-4">
          <AppHeading title={"Other Information"} />
        </div>

        <div className="row mt-4 mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("sex")} <span className="text-danger ps-3">*</span>{" "}
            </label>
            <select
              className="form-control"
              {...register("sex", {
                required: "Please enter sex",
              })}
            >
              <option>Female</option>
              <option>Male</option>
              <option>Other</option>
            </select>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Age")} <span className="text-danger ps-3">*</span>{" "}
            </label>
            <PrimaryInput
              type="number"
              className="form-control"
              placeholder="Age"
              register={register("age", {
                required: "Please enter Age",
                validate: validateAge,
              })}
            />
            <span className="text-danger ps-3 ">{errors?.age?.message}</span>
          </div>
        </div>

        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Education_Level")}
              <span className="text-danger ps-3">*</span>
            </label>
            <select
              className="form-control"
              {...register("education_level", {
                required: "Please enter education level",
                validate: valChar,
              })}
            >
              <option>Primary</option>
              <option>Secondary</option>
              <option>Graduate</option>
              <option>Post Graduate</option>
            </select>
          </div>
          <div className="col-lg-col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Marital_Status")} <span className="text-danger ps-3">*</span>
            </label>

            <select
              className="form-control"
              {...register("maritial_status", {
                required: "Please enter Marital Status",
              })}
            >
              <option>Single</option>
              <option>Married</option>
              <option>Unmarried</option>
            </select>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Number_of_eligible_activities")}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Number Of Eligible Activities"
              register={register("number_eligible_activities", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.number_eligible_activities?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Eligible_Activities")}
            </label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="string"
              className="form-control"
              placeholder="Eligible Activities"
              register={register("eligible_activities", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.eligible_activities?.message}
            </span>
          </div>
        </div>

        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Address_1_Company_Name")}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 1 Company Name"
              register={register("a1_company_name", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a1_company_name?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Address_1_Commune")}</label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 1 Commune"
              register={register("a1_commune", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a1_commune?.message}
            </span>
          </div>
        </div>

        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Address_1_Quartier")}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 1 Quartier"
              register={register("a1_quartier", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a1_quartier?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Address_1_RCCM_Number")}
            </label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 1 RCCM Number"
              register={register("a1_rccm_number", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a1_rccm_number?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Address_2_Company_Name")}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 2 Company Name"
              register={register("a2_company_name", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a2_company_name?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Address_2_Commune")}</label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 2 Commune"
              register={register("a2_commune", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a2_commune?.message}
            </span>
          </div>
        </div>

        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Address_2_Quartier")}
            </label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 2 Quartier"
              register={register("a2_quartier", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a2_quartier?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Address_2_RCCM_Number")}
            </label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Address 2 RCCM Number"
              register={register("a2_rccm_number", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.a2_rccm_number?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Main_Sample")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Main Sample"
              register={register("main_sample", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.main_sample?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("Replacement_Sample")}
            </label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Replacement Sample"
              register={register("replacement_sample", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.replacement_sample?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Replacement_Order")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Replacement Order"
              register={register("replacement_order", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.replacement_order?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Number")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Number"
              register={register("number", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">{errors?.number?.message}</span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Presence")}</label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="string"
              className="form-control"
              placeholder="Presence"
              register={register("presence", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.presence?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Loterie_Status")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Lottery Status"
              register={register("statut_loterie", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.statut_loterie?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Loterie_Site")}</label>

            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Lottery Site"
              register={register("loterie_site", {
                // required: "Please enter mobile",
                validate: valOptionalChar,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.loterie_site?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Latitude")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="number"
              className="form-control"
              placeholder="Latitude"
              step="any"
              register={register("latitude", {
                // required: "Please enter Province Id",
                validate: vallatitude,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.latitude?.message}
            </span>
          </div>

          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("Longitude")}</label>
            <PrimaryInput
              // defaultValue={profileData?.coach_profile?.email}
              type="text"
              className="form-control"
              placeholder="Longitude"
              step="any"
              register={register("longitude", {
                // required: "Please enter Province Id",
                validate: vallongitude,
              })}
            />
            <span className="text-danger ps-3 ">
              {errors?.longitude?.message}
            </span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("status")}</label>
            <select
              className="form-control"
              {...register("status", {
                required: "Please enter Status",
              })}
            >
              <option>Select Status</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
            <span className="text-danger ps-3 ">{errors?.status?.message}</span>
          </div>
        </div>
        {/* <div className="row mt-4">
                  <div className="col-lg-6">                     
                          <label className="input-label ps-3">Grant Eligibility</label>
                         <select className="form-control" {...register("grant_eligibility" ,{
                            // required: "Please enter mobile",
                          })}>
                          <option>Select Eligibility</option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                         </select>
                          </div>
                          <div className="col-lg-6">                     
                          <label className="input-label ps-3">Grant Amount</label>
                          
                          <PrimaryInput 
                           
                            type="number" 
                            className="form-control"
                            placeholder="Grant Amount"
                            register= {register("grant_amount", {
                              // required: "Please enter mobile",
                            })}
                          />
                          </div>
                  </div> */}
        {/* <div className="row mt-4">
               
                          <div className="col-lg-12">                     
                          <label className="input-label ps-3">Grant Balance</label>
                          
                          <PrimaryInput 
                            // defaultValue={profileData?.coach_profile?.email}
                            type="number" 
                            className="form-control"
                            placeholder="Grant Balance"
                            register= {register("grant_balance", {
                              // required: "Please enter mobile",
                            })}
                          />
                          </div>
                  </div> */}
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <div className="d-flex flex-column align-items-start gap-2">
              <div className="image-preview">
                {imagePreview ? (
                  <img
                    height={"100%"}
                    width={"100%"}
                    alt="img"
                    //  className="image-preview"
                    src={imagePreview}
                  />
                ) : (
                  <>
                    <div>
                      <span className="mdi mdi-upload"></span>
                    </div>
                    <div>Upload Photo</div>
                    <p className="m-0">(jpeg/png/svg max-size:500kb)</p>
                  </>
                )}
              </div>
              {imagePreview && (
                <div>
                  {/* <button className="correct-btn"><span class="mdi mdi-check"></span></button> */}
                  <button
                    className="mx-1 close-btn"
                    onClick={imageDeleteHandler}
                  >
                    <span class="mdi mdi-close "></span>
                  </button>
                </div>
              )}
              <input
                ref={fileInputRef}
                id="img"
                type="file"
                accept="image/*"
                onChange={imageUploadHandler}
                style={{ display: "none" }}
              />
              <label for="img" className="btn btn-light p-2">
                <p className="m-0">Choose Photo</p>
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-end pt-2 pt-md-3 pt-lg-5 gap-2">
          <button type="submit" className="btn btn-primary">
            {isLoading ? <ButtonLoader /> : "Submit"}
          </button>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-light"
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </FormLayout>
  );
};

export default AddBeneficiary;
