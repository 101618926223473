import { useEffect, useState } from "react";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import {
  DownloadReportHandler,
  handleFetchRequest,
} from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
// import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Roles from "../../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
// import { CurrencyConverter } from "../../../ApiHelper/CurrencyConverter";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Equipments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = Cookies.get("emp_role");
  const { t } = useTranslation();

  const updateStatusHandler = (data) => {
    let status;
    let confirmText;
    if (data?.status === 1) {
      status = 0;
      confirmText = "Do you want to Deactivate this Equipment?";
    } else {
      status = 1;
      confirmText = "Do you want to Activate this Equipment?";
    }
    const equipmentStatus = {
      equipment_id: data?.id,
      status: status,
    };
    Swal.fire({
      text: confirmText,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        setEquipmentList([]);
        handleFetchRequest(
          `${apiUrl.update_equipment_status}`,
          "PUT",
          equipmentStatus
        ).then((response) => {
          if (response?.status === true) {
            setIsLoading(false);
            toast.success(response?.message);
            getEquipment(currentPage);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
            toast.error(response?.message);
          }
        });
      }
    });
  };

  const downloadEquipmentHandler = () => {
    DownloadReportHandler(
      "Equipment",
      apiUrl?.download_equipment,
      setPageIsLoading
    ).then((response) => {
      if (response?.status === true) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    });
  };

  const deleteHandler = (data) => {
    // console.log(data);
    let status;
    if (data?.status === 1) {
      status = 0;
    } else {
      status = 1;
    }
    // const beneficiaryStatus = {
    //   beneficiary_id: data?.id,
    //   status: status,
    // }
    Swal.fire({
      text: "Do you want to Delete this Equipment?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        setEquipmentList([]);
        handleFetchRequest(
          `${apiUrl.delete_equipment}?equipment_id=${data?.id}`,
          "DELETE"
        ).then((response) => {
          if (response?.status === true) {
            setIsLoading(false);
            toast.success(response?.message);
            // setCallApi(true);
            getEquipment(currentPage);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
            toast.error(response?.message);
          }
        });
      }
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [equipmentList, setEquipmentList] = useState([]);
  const [inputCategoryData, setInputCategoryData] = useState("");
  const [pages, setPages] = useState();

  let listHeading = [
    { classType: "", data: `${t("name")}` },
    { classType: "text-truncate", data: `${t("detail")}` },
    { classType: "text-end", data: `${t("price")}` },
    { classType: "text-end", data: `${t("quantity")}` },
    { classType: "", data: `${t("status")}` },
    { classType: "", data: `${t("action")}` },
  ];
  if (Roles.coach_value === role || Roles.viewer_value === role) {
    listHeading = [
      { data: `${t("name")}` },
      { classType: "text-truncate", data: `${t("detail")}` },
      { classType: "text-end", data: `${t("price")}` },
      { classType: "text-end", data: `${t("quantity")}` },
      { data: `${t("status")}` },
    ];
  }
  const [inputTypeData, setInputTypeData] = useState("text");
  const [searchOptionData, setSearchOptionData] = useState();
  let addHeadingAction;
  if (Roles.admin_value === role) {
    addHeadingAction = [
      { name: "Edit Equipment", path: RoutesLink?.add_equipment },
      {
        name: "Delete",
        type: "function",
        path: deleteHandler,
        value: "always",
      },
      {
        name: "Update Status",
        type: "function",
        path: updateStatusHandler,
        value: "always",
      },
    ];
  }

  const [isLoading, setIsLoading] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  // const [callApi, setCallApi] = useState(true);
  const [filterData, setFilterData] = useState();
  const searchOption1Array = [
    { name: `${t("search_by")}`, value: "", type: "select" },
    { name: `${t("name")}`, value: "name", type: "text" },
    { name: `${t("status")}`, value: "status", type: "select" },
  ];
  const searchOptionStatusArray = [
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
  ];

  let addAction1;

  if (Roles.admin_value === role) {
    addAction1 = [
      {
        name: "Upload Equipment",
        path: RoutesLink?.upload_data,
        state: "equipments",
      },
      {
        name: "Download Equipment",
        type: "function",
        function: downloadEquipmentHandler,
      },
    ];
  }

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  const handlePageChange = (pageitem) => {
    setEquipmentList([]);
    setCurrentPage(pageitem);
    getEquipment(pageitem);
  };

  const filters = [];

  if (searchOptionData === "status") {
    filters.push(`&status=${filterData}`);
  }
  if (searchOptionData === "name") {
    filters.push(`&name=${filterData}`);
  }

  useEffect(() => {
    getEquipment(currentPage);
  }, []);

  const ResetAccountHandler = () => {
    setIsLoading(true);

    setCurrentPage(1);
    setSearchOptionData("");
    setFilterData("");
    setInputCategoryData("###select");
    setEquipmentList([]);
    handleFetchRequest(
      `${apiUrl?.get_equipment_list}?page_no=1&page_size=10`
    ).then((response) => {
      if (response.status === true) {
        setEquipmentList(response?.data);
        setPages(response?.pageInfo);
        // setCallApi(false);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const getEquipment = (pageitem) => {
    setIsLoading(true);
    handleFetchRequest(
      `${
        apiUrl?.get_equipment_list
      }?page_no=${pageitem}&page_size=10${filters.join("&")}`
    ).then((response) => {
      if (response.status === true) {
        setEquipmentList(response?.data);
        setPages(response?.pageInfo);
        // setCallApi(false);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading
                    inputTypeData={inputTypeData}
                    setInputTypeData1={setInputTypeData}
                    searchOption1Array={searchOption1Array}
                    // addLink={RoutesLink?.add_equipment}
                    addLink={{ link: RoutesLink?.add_equipment }}
                    setSearchOptionData={setSearchOptionData}
                    addArray={addAction1}
                    btnName={
                      Roles?.admin_value === role && `${t("add_equipment")}`
                    }
                    name={t("equipments")}
                    searchOption2Array={searchOption2Array}
                    inputCategoryData={inputCategoryData}
                    setInputCategoryData={setInputCategoryData}
                    setFilterData={setFilterData}
                    setMainCallApi={() => getEquipment(1)}
                    setData={setEquipmentList}
                    filterData={filterData}
                    setActivePage={setCurrentPage}
                    ResetAccountHandler={ResetAccountHandler}
                  />
                  <PrimaryTable
                    listData={equipmentList}
                    listHeading={listHeading}
                    equipment={true}
                    addData={addHeadingAction}
                    activePage={currentPage}
                    pages={pages}
                    handlePageChange={handlePageChange}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageIsLoading && <PageLoader />}
    </Layout>
  );
};
export default Equipments;
