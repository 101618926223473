import { useEffect, useState } from "react";
import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import {
  DownloadReportHandler,
  handleFetchRequest,
} from "../../../ApiHelper/ApiHelper";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DownloadModal from "../../../Components/DownloadModal";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
// import { Roles } from "../../../ApiHelper/Nomanclatures";
import Roles from "../../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BeneficiaryList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let role = Cookies.get("emp_role");
  // console.log("dfajksfjosnnnnn",Roles);
  const changeStatusHandler = (data) => {
    console.log(data);
    let status;
    let confirmText;
    if (data?.status === 1) {
      status = 0;
      confirmText = "Do you want to Deactivate this user?";
    } else {
      confirmText = "Do you want to Activate this user?";
      status = 1;
    }
    const beneficiaryStatus = {
      beneficiary_id: data?.id,
      status: status,
    };
    Swal.fire({
      text: confirmText,
      customClass: {
        confirmButton: "confirm-button-class",
        title: "title-class",
        icon: "icon-class",
      },
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setPageIsLoading(true);
        handleFetchRequest(
          `${apiUrl.update_beneficiary_status}`,
          "PUT",
          beneficiaryStatus
        ).then((response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            toast.success(response?.message);
            getBeneficiaryList(activePage);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        });
      }
    });
  };

  const downloadBeneficiaryHandler = () => {
    setPageIsLoading(true);
    DownloadReportHandler(
      "Beneficiary",
      apiUrl?.download_beneficiaries,
      setPageIsLoading
    ).then((response) => {
      if (response?.status === true) {
        toast.success(response?.message);
        setPageIsLoading(false);
      } else {
        setPageIsLoading(false);
        toast.error(response?.message);
      }
    });
  };

  const [searchOptionData, setSearchOptionData] = useState("global_search");
  const [filterData, setFilterData] = useState();
  const [pages, setPages] = useState();
  const [activePage, setActivePage] = useState(1);
  const [openDownloadModal, SetOpenDownloadModal] = useState(false);
  const downloadModalToggle = () => SetOpenDownloadModal(!openDownloadModal);
  const [inputTypeData, setInputTypeData] = useState("text");
  const [isLoading, setIsLoading] = useState(false);
  const [inputCategoryData, setInputCategoryData] = useState("");
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const { t } = useTranslation();

  const listHeading = [
    { data: `${t("reference_id")}` },
    { data: `${t("full_name")}` },
    { data: `${t("contact_detail")}` },
    { data: `${t("province")}` },
    { data: `${t("grant_status")}` },
    { data: `${t("status")}` },
    { data: `${t("action")}` },
  ];

  const searchOption1Array = [
    { name: `${t("search_by")}`, value: "global_search", type: "text" },
    { name: `${t("reference_id")}`, value: "reference_id", type: "text" },
    { name: `${t("name")}`, value: "fullname", type: "text" },
    { name: `${t("contact_detail")}`, value: "telephone_pref", type: "text" },
    { name: `${t("province")}`, value: "province", type: "text" },
    { name: `${t("commune")}`, value: "commune", type: "text" },
    { name: `${t("quartier")}`, value: "quartier", type: "text" },
    { name: `${t("status")}`, value: "status", type: "select" },
    {
      name: `${t("grant_status")}`,
      value: "grant_eligibility",
      type: "select",
    },
    { name: `${t("sector")}`, value: "sector", type: "select" },
  ];

  let addAction1 = "";
  if (role === Roles.coach_value) {
    // addAction1 = [
    //   // {name:"Upload Beneficiary",path:RoutesLink?.upload_data,state:"beneficiary"}
    //   // ,{name:"Download Beneficiary",type:"function",function:downloadBeneficiaryHandler},
    //   // { name: "Assign Beneficiary", path: RoutesLink?.assign_beneficiary, state: "assign" }, { name: "Unassign Beneficiary", path: RoutesLink?.assign_beneficiary, state: "unassign" }
    // ];
  } else if (role === Roles.admin_value) {
    addAction1 = [
      {
        name: "Upload Beneficiary",
        path: RoutesLink?.upload_data,
        state: "beneficiary",
      },
      {
        name: "Download Beneficiary",
        type: "function",
        function: downloadBeneficiaryHandler,
      },
    ];
  }

  let addHeadingAction;

  if (role === Roles.admin_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink.beneficiary_profile },
      { name: `${t("edit")}`, path: RoutesLink?.add_beneficiary },
      {
        name: "Update Status",
        type: "function",
        path: changeStatusHandler,
        value: "always",
      },
    ];
  } else if (role === Roles.viewer_value || role === Roles.coach_value) {
    addHeadingAction = [{ name: "View", path: RoutesLink.beneficiary_profile }];
  }
  // const searchOption1Array=[{name:"Search By",value:""},{name:"Status",value:"status",type:"select"},{name:"Assign Beneficiary",value:"assign",type:"select"},{name:"Username",value:"username",type:"text"},{name:"Alias Id",value:"aliasid",type:"text"}];
  const searchOptionStatusArray = [
    // { name: "Status", value: "", type: "status" },
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
    { name: "Allowed", value: 1, type: "grant_eligibility" },
    { name: "Not Allowed", value: 0, type: "grant_eligibility" },
    { name: "Unassigned", value: 2, type: "grant_eligibility" },
    { name: "Unassigned", value: 0, type: "assignment" },
    { name: "Assigned", value: 1, type: "assignment" },
    { name: "Agri Business", value: "agribusiness", type: "sector" },
    { name: "Manufacturing", value: "manufacturing", type: "sector" },
    { name: "Services", value: "services", type: "sector" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  const filters = [];

  const applyFilter = () => {
    const filterValue = filterData ? `&${searchOptionData}=${filterData}` : "";
    filters.push(filterValue);
  };

  // if (searchOptionData === "contact_detail") {
  //   if (filterData) {
  //     filters.push(`&telephone_pref=${filterData}`)
  //   }
  // }
  // if (searchOptionData === "quartier") {
  //   if (filterData) {
  //     filters.push(`&quartier=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "commune") {
  //   if (filterData) {
  //     filters.push(`&commune=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "province") {
  //   if (filterData) {
  //     filters.push(`&province=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "fullname") {
  //   if (filterData) {
  //     filters.push(`&fullname=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "reference_id") {
  //   if (filterData) {
  //     filters.push(`&reference_id=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "status") {
  //   if (filterData) {
  //     filters.push(`&status=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "grant") {
  //   if (filterData) {
  //     filters.push(`&grant_eligibility=${filterData}`)
  //   }
  // }
  // if (searchOptionData === "assignment") {
  //   if (filterData) {
  //     filters.push(`&assignment=${filterData}`)
  //   }
  // }

  const handlePageChange = (pageitem) => {
    setActivePage(pageitem);
    setIsLoading(true);
    setBeneficiaryList([]);
    getBeneficiaryList(pageitem);
  };

  useEffect(() => {
    getBeneficiaryList(activePage);
  }, []);

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setActivePage(1);
    setSearchOptionData("global_search");
    setFilterData("");
    setBeneficiaryList([]);
    setInputCategoryData("###select");
    if (role === Roles.admin_value || Roles?.viewer_value === role) {
      handleFetchRequest(
        `${apiUrl.beneficiary_list}?page_no=1&page_size=10`
      ).then((response) => {
        if (response?.status === true) {
          setPages(response?.pageInfo);
          setBeneficiaryList(response.data);
          setIsLoading(false);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      });
    } else if (role === Roles.coach_value) {
      handleFetchRequest(
        `${apiUrl.get_assigned_beneficiary}?page_no=${activePage}&page_size=10`
      ).then((response) => {
        console.log(response);
        if (response?.status === true) {
          setIsLoading(false);

          setPages(response?.pageInfo);
          //  const itemsPerPage=10;
          //  const indexOfLastItem = activePage * itemsPerPage;
          //  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          //  const currentItems = response.data.slice(indexOfFirstItem, indexOfLastItem);
          setBeneficiaryList(response?.data);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          console.log(response);
          toast.error(response.message);
        }
      });
    }
  };

  const getBeneficiaryList = (pageitem) => {
    setIsLoading(true);
    applyFilter();
    if (Roles?.admin_value === role || Roles?.viewer_value === role) {
      handleFetchRequest(
        `${
          apiUrl.beneficiary_list
        }?page_no=${pageitem}&page_size=10${filters.join("&")}`
      ).then((response) => {
        console.log(response);
        if (response?.status === true) {
          setIsLoading(false);
          setPages(response?.pageInfo);
          //  const itemsPerPage=10;
          //  const indexOfLastItem = activePage * itemsPerPage;
          //  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          //  const currentItems = response.data.slice(indexOfFirstItem, indexOfLastItem);
          setBeneficiaryList(response?.data);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          console.log(response);
          toast.error(response.message);
        }
      });
    }

    {
      Roles?.coach_value === role &&
        handleFetchRequest(
          `${
            apiUrl.get_assigned_beneficiary
          }?page_no=${activePage}&page_size=10${filters.join("&")}`
        ).then((response) => {
          console.log(response);
          if (response?.status === true) {
            setIsLoading(false);

            setPages(response?.pageInfo);
            //  const itemsPerPage=10;
            //  const indexOfLastItem = activePage * itemsPerPage;
            //  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            //  const currentItems = response.data.slice(indexOfFirstItem, indexOfLastItem);
            setBeneficiaryList(response?.data);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
            console.log(response);
            toast.error(response.message);
          }
        });
    }
  };

  return (
    <>
      <Layout>
        <div className="content-wrapper-main">
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex justify-content-start">
                    <Heading
                      inputTypeData={inputTypeData}
                      searchOption1Array={searchOption1Array}
                      addLink={{ link: RoutesLink?.add_beneficiary }}
                      // addLink={RoutesLink?.add_beneficiary}
                      btnName={
                        Roles?.admin_value === role
                          ? `${t("add_beneficiary")}`
                          : ""
                      }
                      name={t("beneficiary")}
                      downloadToggle={downloadModalToggle}
                      addArray={addAction1}
                      searchOption2Array={searchOption2Array}
                      inputCategoryData={inputCategoryData}
                      setInputCategoryData={setInputCategoryData}
                      //Filter Props
                      setFilterData={setFilterData}
                      setSearchOptionData={setSearchOptionData}
                      setInputTypeData1={setInputTypeData}
                      setMainCallApi={() => getBeneficiaryList(1)}
                      setData={setBeneficiaryList}
                      filterData={filterData}
                      setActivePage={setActivePage}
                      ResetAccountHandler={ResetAccountHandler}
                    />
                    <PrimaryTable
                      listData={beneficiaryList}
                      listHeading={listHeading}
                      editPage={RoutesLink?.add_beneficiary}
                      // handleDelete={deletHandler}
                      addData={addHeadingAction}
                      beneficiary={true}
                      activePage={activePage}
                      pages={pages}
                      handlePageChange={handlePageChange}
                      isLoading={isLoading}
                      idClickLink={RoutesLink?.beneficiary_profile}
                    />
                    {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
                       {!isLoading && beneficiaryList?.length===0 ? <h4 style={{ textAlign: "center" }}>No Data Found</h4>:"" }
                       */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {pageIsLoading && <PageLoader />}
      </Layout>
    </>
  );
};

export default BeneficiaryList;
