// import BackButton from "../CommonElements/BackButton";
import AppHeading from "../CommonElements/Headings";
import Layout from "./Layout";

const FormLayout = ({ children, name, backButtonHandler }) => {

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card rounded-4">
              <div className="card-body">
                <div>
                  <AppHeading title={name} backButtonHandler={backButtonHandler} />
                  {/* <p class="text-black  font-family-Roboto  fw-semibold   my-2">{name}</p> */}
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FormLayout;