import { useState } from "react";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { useEffect } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { profileImage, setUserLoggedInDetails } from "../../../store/actions/UserActions";
import Layout from "../../../Components/Layout/Layout";
import Heading from "../../../Components/CommonElements/Heading";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { toast } from "react-toastify";

const BeneficiarySessionList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [inputTypeData, setInputTypeData] = useState("text");
    const [inputCategoryData, setInputCategoryData] = useState("");
    const [filterData, setFilterData] = useState();
    const [searchOptionData, setSearchOptionData] = useState("global_search");
    const [beneficiarySessionList, setBeneficiarySessionList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [pages, setPages] = useState();

    const listHeading = [{ data: "Reference Id" },
    { data: "Full Name" },
    { data: "Contact Detail" },
    { data: "Province" },
    { data: "Grant Status" },
    { data: "Status" }];

    const searchOption1Array = [
        { name: "Search By", value: "global_search", type: "text" },
        { name: "Reference ID", value: "reference_id", type: "text" },
        { name: "Name", value: "fullname", type: "text" },
        { name: "Contact Detail", value: "telephone_pref", type: "text" },
        { name: "Province", value: "province", type: "text" },
        { name: "Commune", value: "commune", type: "text" },
        { name: "Quartier", value: "quartier", type: "text" },
        // { name: "Grant Status", value: "grant_eligibility", type: "select" },
        { name: "Status", value: "status", type: "select" },
        { name: "Sector", value: "sector", type: "select" },

    ];

    const searchOptionStatusArray = [
        // { name: "Status", value: "", type: "status" },
        { name: "Active", value: 1, type: "status" },
        { name: "Inactive", value: 0, type: "status" },
        { name: "Allowed", value: 1, type: "grant_eligibility" },
        { name: "Not Allowed", value: 0, type: "grant_eligibility" },
        { name: "Unassigned", value: 2, type: "grant_eligibility" },
        { name: "Unassigned", value: 0, type: "assignment" },
        { name: "Assigned", value: 1, type: "assignment" },
        { name: "Agri Business", value: 'agribusiness', type: "sector" },
        { name: "Manufacturing", value: 'manufacturing', type: "sector" },
        { name: "Services", value: 'services', type: "sector" }
    ];


    const searchOption2Array = searchOptionStatusArray.filter((data) => data?.type === searchOptionData);

    const filters = [];

    const applyFilter = () => {
        const filterValue = filterData ? `&${searchOptionData}=${filterData}` : "";
        filters.push(filterValue);
    };

    const handlePageChange = (pageitem) => {
        setActivePage(pageitem);
    }

    useEffect(() => {
        getBeneficiarySessionList(activePage);
    }, [])

    const getBeneficiarySessionList = (pageItem) => {
        setIsLoading(true);
        applyFilter();
        handleFetchRequest(`${apiUrl?.get_assigned_beneficiary}?coach_id=${location?.state?.id}&page_no=${pageItem}&page_size=10&grant_eligibility=1${filters.join("&")}`).then(response => {
            if (response?.status === true) {
                const benArray = response?.data?.filter((data) => data?.coach_plan !== true)
                setBeneficiarySessionList(benArray);
                // setCallApi(false);
                setIsLoading(false);
                setPages(response?.pageInfo);
            }
            else if (response?.messageCode === 3) {
                Cookies.remove("emp_token");
                Cookies.remove("emp_role");
                Cookies.remove("emp_name");
                Cookies.remove("emp_currency");
                Cookies.remove("emp_profile");
                Cookies.remove("emp_data");
                Cookies.remove("emp_conversion");
                Cookies.remove("emp_conversion");
                dispatch(setUserLoggedInDetails({}));
                dispatch(profileImage(""));
                setIsLoading(false);
                navigate(RoutesLink.login);
            } else {
                setIsLoading(false);
            }
        })

    }

    const ResetuserAccount = () => {
        setIsLoading(true);
        setActivePage(1);
        setSearchOptionData("");
        setFilterData("");
        setBeneficiarySessionList([]);
        setInputCategoryData("###select");
        handleFetchRequest(`${apiUrl?.get_assigned_beneficiary}?coach_id=${location?.state?.id}&page_no=1&page_size=10&grant_eligibility=1`).then(response => {
            if (response?.status === true) {
                const benArray = response?.data?.filter((data) => data?.coach_plan !== true)
                setBeneficiarySessionList(benArray);
                // setBeneficiarySessionList(response?.data);
                // setCallApi(false);
                setIsLoading(false);
                setPages(response?.pageInfo);
            }
            else if (response?.messageCode === 3) {
                Cookies.remove("emp_token");
                Cookies.remove("emp_role");
                Cookies.remove("emp_name");
                Cookies.remove("emp_currency");
                Cookies.remove("emp_profile");
                Cookies.remove("emp_data");
                Cookies.remove("emp_conversion");
                Cookies.remove("emp_conversion");
                dispatch(setUserLoggedInDetails({}));
                dispatch(profileImage(""));
                setIsLoading(false);
                navigate(RoutesLink.login);
            } else {
                setIsLoading(false);
            }
        })
    }

    const backButtonHandler = () => {
        navigate(RoutesLink.coach_profile,
            { state: { id: location.state.id, tabid: "3", data: { alias_id: location.state.alias_id } } }
        )
    }

    const AddCoachPlanHandler = (data) => {
        if (data?.coach_plan === true) {
            navigate(RoutesLink?.add_coach_plan, { state: { ...data, routes: "session_list", beneficiaryCount: beneficiarySessionList } });
        } else {
            handleFetchRequest(apiUrl.add_coach_plan, "POST", {
                coach_id: data?.assigned_beneficiary?.coach_id,
                beneficiary_id: data?.id,
            }).then((response) => {
                if (response?.status === true) {
                    navigate(RoutesLink?.add_coach_plan,
                        { state: { ...data, coach_plan_id: response?.data?.id, routes: "session_list", beneficiaryCount: beneficiarySessionList }, });
                } else {
                    toast.error(response.message)
                }
            })
        }
        // to = { RoutesLink?.add_coach_plan } state = { data }
    }


    return (
        <Layout>
            <div className="content-wrapper-main">
                <div className="row">
                    <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
                        <div className="card">
                            <div className="card-body">
                                {beneficiarySessionList?.length === 0 && !isLoading && !filterData ?
                                    <div>
                                        <Heading
                                            backButtonHandler={backButtonHandler}
                                        />
                                        <h4 className="text-center">No Beneficiary Has Grant Allowed</h4>
                                    </div>
                                    :
                                    <div className="row d-flex justify-content-start">
                                        <Heading
                                            backButtonHandler={backButtonHandler}
                                            inputTypeData={inputTypeData}
                                            searchOption1Array={searchOption1Array}
                                            addLink={RoutesLink?.assign_beneficiary}
                                            name={"Beneficiary"}
                                            // coachId={coachId}
                                            setFilterData={setFilterData}
                                            setSearchOptionData={setSearchOptionData}
                                            searchOption2Array={searchOption2Array}
                                            setInputTypeData1={setInputTypeData}
                                            // setMainCallApi={setCallApi}
                                            setData={setBeneficiarySessionList}
                                            filterData={filterData}
                                            setActivePage={setActivePage}
                                            setMainCallApi={() => getBeneficiarySessionList(1)}
                                            ResetAccountHandler={ResetuserAccount}
                                            inputCategoryData={inputCategoryData}
                                            setInputCategoryData={setInputCategoryData}
                                        />
                                        <PrimaryTable
                                            // assignedList={assignedBeneficiaryList}
                                            listHeading={listHeading}
                                            listData={beneficiarySessionList}
                                            beneficiary={true}
                                            // showDeleteBox={showDeleteBox}
                                            // checkboxFunction={AssignCheckboxBeneficiaries}
                                            // assignAllCheckbox={assignAllBeneficiaryHandler}
                                            activePage={activePage}
                                            pages={pages}
                                            // addData={addCoachPlanData}
                                            handlePageChange={handlePageChange}
                                            isLoading={isLoading}
                                            idClickLink={RoutesLink?.beneficiary_profile}
                                            coachPlanBeneficiary={true}
                                            AddCoachPlanHandler={AddCoachPlanHandler}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default BeneficiarySessionList;