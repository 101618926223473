import { toast } from "react-toastify";
// import Layout from "../../../Components/Layout/Layout";
import { useForm } from "react-hook-form";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import FormLayout from "../../../Components/Layout/FormLayout";
// import AppHeading from "../../../Components/CommonElements/Headings";
import { useEffect } from "react";
import { useState } from "react";
// import { Spinner } from "reactstrap";
import {
  valChar,
  validateName,
  validatePassword,
  validateUsername,
} from "../../../ApiHelper/FormValidation";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useDispatch } from "react-redux";
import { t } from "i18next";

const AddUserAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState("");
  console.log("userRole", userRole);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const backButtonHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (location?.state?.id) {
      handleFetchRequest(
        `${apiUrl?.get_account_detail}?account_id=${location?.state?.id}`
      ).then((response) => {
        if (response.status === true) {
          setUserRole(response?.data?.role);
          reset({
            first_name: response?.data?.first_name,
            last_name: response?.data?.last_name,
            username: response?.data?.username,
            status: response?.data?.status,
            role: response?.data?.role,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      });
    } else {
      reset({
        status: 1,
      });
    }
  }, []);

  const passwordMatch = (value) => {
    const newPassword = watch("password");
    return newPassword === value || "Passwords do not match";
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    const accountData = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      // password:data?.password,
      role: data?.role,
      username: data?.username,
      status: +data?.status,
    };
    const password = { password: data?.password };
    if (location?.state?.id) {
      const accountData2 = {
        ...accountData,
        account_id: location?.state?.id,
      };
      handleFetchRequest(apiUrl?.update_account, "PUT", accountData2).then(
        (response) => {
          if (response?.status === true) {
            setIsLoading(false);
            toast.success("Account Updated Successfully");
            navigate(-1);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
            toast.error(response.message);
          }
        }
      );
    } else {
      handleFetchRequest(
        apiUrl?.create_account,
        "POST",
        accountData,
        password
      ).then((response) => {
        if (response.status === true) {
          setIsLoading(false);
          toast.success("Account Created Successfully");
          if (data?.role === "coach") {
            navigate(RoutesLink.add_user_profile, {
              state: { data: response.data, param: location?.state },
            });
          } else {
            navigate(-1);
          }
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    }
  };

  return (
    <FormLayout
      name={location?.state?.id ? "Edit User Account" : "Add User Account"}
      backButtonHandler={backButtonHandler}
    >
      <form className="form-inline" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="row mt-4 mb-2 row-gap-2">
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">
                {t("first_name")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control mr-sm-2"
                id="inlineFormInputName2"
                placeholder={t("first_name")}
                {...register("first_name", {
                  required: "Please enter First Name*",
                  validate: valChar,
                })}
              />
              <span className="text-danger ">
                {errors?.first_name?.message}
              </span>
            </div>
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">
                {t("last_name")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("last_name")}
                {...register("last_name", {
                  required: "Please enter last name*",
                  validate: valChar,
                })}
              />
              <span className="text-danger">{errors?.last_name?.message}</span>
            </div>
          </div>

          <div className="row mb-2 row-gap-2">
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">
                {t("username")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control mr-sm-2"
                id="inlineFormInputName2"
                placeholder={t("username")}
                {...register("username", {
                  required: "Please enter Username*",
                  validate: valChar,
                })}
              />
              <span className="text-danger ">{errors?.username?.message}</span>
            </div>
            <div className="col-12 col-md-6 px-0 px-md-2">
              <label className="input-label ps-3">
                Select Status <span className="text-danger">*</span>
              </label>
              <div className="form-group">
                <select
                  className="form-control"
                  {...register("status", {
                    required: "Please enter Status*",
                  })}
                >
                  <option value={""}>Select Status</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
                {/* <span className="text-danger ">{errors?.status?.message}</span> */}
              </div>
              <span className="text-danger">{errors?.status?.message}</span>
            </div>
          </div>
          {!location?.state?.id && (
            <div className="row mb-2 row-gap-2">
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label ps-3">
                  Password<span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control mr-sm-2"
                  id="inlineFormInputName2"
                  placeholder="Password"
                  {...register("password", {
                    required: "Please enter password*",
                    validate: validatePassword,
                  })}
                />
                <span className="text-danger">{errors?.password?.message}</span>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label ps-3">
                  Confirm Password <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  {...register("confirm_password", {
                    required: "Please enter confirm password*",
                    validate: passwordMatch,
                  })}
                />
                {/* {errors.con_password && <p className="f-error mb-0" style={{ fontSize: "14px", color: "darkblue" }}>{errors.con_password.message}</p>} */}
                {errors?.confirm_password && (
                  <span className="text-danger ">
                    {errors?.confirm_password?.message}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="row mb-2 row-gap-2">
            <div className="col-12 px-0 px-md-2">
              <label className="input-label ps-3">
                Select Role <span className="text-danger">*</span>
              </label>
              <div className="form-group">
                <select
                  className="form-control"
                  {...register("role", {
                    required: "Please enter role*",
                  })}
                >
                  <option value={""}>Select Role</option>
                  {location.state?.id ? (
                    <>
                      {userRole === "admin" && (
                        <>
                          <option value={"admin"}>Admin</option>
                          <option value={"coach"}>Coach</option>
                          <option value={"world_bank"}>Viewer</option>
                        </>
                      )}
                      {(userRole === "coach" || userRole === "world_bank") && (
                        <>
                          <option value={"coach"}>Coach</option>
                          <option value={"world_bank"}>Viewer</option>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <option value={"admin"}>Admin</option>
                      <option value={"coach"}>Coach</option>
                      <option value={"world_bank"}>Viewer</option>
                    </>
                  )}
                </select>
              </div>
              <span className="text-danger ">{errors?.role?.message}</span>
            </div>
          </div>
          <div>
            <input
              id="upload image"
              type="file"
              //  class="form-control my-3"
              // placeholder="Password"
              //  {...register("password", {
              //   required: "Please enter password*",
              // })}
              style={{ display: "none" }}
            />
            {/* <label className="form-control" for="upload image">Upload</label> */}
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-end pt-2 pt-md-3 pt-lg-5 gap-2">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-light"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {isLoading ? <ButtonLoader /> : "Save"}
          </button>
        </div>
      </form>
    </FormLayout>
  );
};
export default AddUserAccount;
