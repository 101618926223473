import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../store/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { apiUrl } from "../../ApiHelper/ApiUrl";
import { RoutesLink } from "../../ApiHelper/RoutesLink";
// import { useState } from "react";
import RoleType from "../../ApiHelper/Roles.json";
import { TimeStampConverter } from "../../ApiHelper/TimeStampConverter";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

const Header = ({ sidebarToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notificationDetails = useSelector(
    (data) => data?.user?.notificationDetail
  );
  console.log("headerrrrr", notificationDetails);

  let role = Cookies.get("emp_role");

  const logoutHandler = () => {
    // let isToken = true;
    handleFetchRequest(apiUrl.logout).then((response) => {
      if (response?.status === true) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_time_zone");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
        toast.success("logged out successfully");
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_time_zone");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        // setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span className="mdi mdi-menu"></span>
        </button> */}
        <div className="navbar-brand brand-logo">
          <img src="assets/image/groupp.png" alt="logo" />
        </div>
        <div className="navbar-brand brand-logo-mini">
          <img src="assets/image/logo-mini.png" alt="logo" />
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <ul className="navbar-nav navbar-nav-right">
          {role === RoleType?.admin_value || role === RoleType?.coach_value ? (
            <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="mdi mdi-bell-outline"></i>

                <span className="count-symbol d-flex justify-content-center align-items-center">
                  {notificationDetails?.length || 0}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <div className="d-flex justify-content-between">
                  {
                    <h6 className="p-3 mb-0">
                      Today's Notifications {notificationDetails?.length || 0}
                    </h6>
                  }
                  {/* <strong class="font-weight-light text-muted p-3 mb-0"></strong> */}
                </div>
                <ul className="list-inline p-2">
                  {notificationDetails?.map((item, index) => (
                    <li key={index}>
                      <div
                        className="card rounded-3 my-1 px-2 py-1 "
                        style={{ backgroundColor: "#f8f9fa" }}
                      >
                        <p className="m-0">{item?.message}</p>
                        <div className="d-flex justify-content-end text-gray">
                          <p>
                            {moment(TimeStampConverter(item?.createdAt)).format(
                              "HH:mm:ss"
                            )}
                          </p>
                          {/* <p className="mb-0">{item?.createdAt ?  : 'N/A'}
                          </p> */}
                        </div>
                      </div>
                    </li>
                  ))}
                  <li className="text-center cursor-pointer">
                    <Link to={RoutesLink?.notification}>More Notification</Link>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            ""
          )}
          <li className="nav-item nav-logout ">
            <button className="nav-link" onClick={logoutHandler}>
              <span className="d-none d-lg-block">{t("logout")}</span>
              <i className="mdi mdi-power"></i>
            </button>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          onClick={sidebarToggle}
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Header;
