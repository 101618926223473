import { useEffect, useState } from "react";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import RoleType from "../../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { convertToEpoch } from "../../../ApiHelper/TimeStampConverter";
import Swal from "sweetalert2";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

const CoachPlanDetail = () => {
  const { t } = useTranslation();
  let role = Cookies.get("emp_role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [inputTypeData, setInputTypeData] = useState("select");
  const [disableEndTime, setDisableEndTime] = useState([]);

  const searchOption1Array = [
    { name: "Search By", value: "", type: "select" },
    { name: "Status", value: "status", type: "select" },
    // { name: "Assign Beneficiary", value: "assign", type: "select" },
    // { name: "Beneficiary Alias ID", value: "beneficiary_aliasId", type: "text" },
    // { name: "Coach Alias ID", value: "coach_aliasId", type: "text" },
    { name: "Session Date/Time", value: "session_time", type: "date" },
  ];

  const [inputCategoryData, setInputCategoryData] = useState("");
  const [searchOptionData, setSearchOptionData] = useState();
  const [coachList, setCoachList] = useState([]);
  const [pages, setPages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState();
  const [modal2, setModal2] = useState(false);
  const [lodingModalBtn, setLodingModalBtn] = useState(false);
  const [selecCoachId, setSelectCoachId] = useState("");
  const [selecCoachPlanId, setSelectCoachPalnId] = useState("");
  const [allCoachList, setAllCoachList] = useState([]);

  let listHeading;
  if (role === RoleType.admin_value) {
    listHeading = [
      { data: `${t("session_id")}`, classType: "" },
      { data: "Schedule Start Time (DD/MM/YYYY)", classType: "" },
      { data: "Schedule End time (DD/MM/YYYY)", classType: "" },
      { data: "Session Start Time (DD/MM/YYYY)", classType: "" },
      { data: "Session End time (DD/MM/YYYY)", classType: "" },
      { data: `${t("status")}`, classType: "" },
      { data: `${t("action")}`, classType: "" },
    ];
  } else {
    listHeading = [
      { data: `${t("session_id")}`, classType: "" },
      { data: "Schedule Start Time (DD/MM/YYYY)", classType: "" },
      { data: "Schedule End time (DD/MM/YYYY)", classType: "" },
      { data: "Session Start Time (DD/MM/YYYY)", classType: "" },
      { data: "Session End time (DD/MM/YYYY)", classType: "" },
      { data: `${t("status")}`, classType: "" },
      // { data: "Action", classType: "" }
    ];
  }

  const searchOptionStatusArray = [
    { name: "In Progress", value: "in progress", type: "status" },
    { name: "Completed", value: "completed", type: "status" },
    { name: "Pending", value: "pending", type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  const filters = [];

  const deleteSessionHandler = (data) => {
    handleFetchRequest(
      `${apiUrl?.delete_coach_plan_session}?plan_session_id=${data?.id}`,
      "DELETE"
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response?.message);
        setCoachList([]);
        getCoachPlanList(currentPage);
        // const newData = stateVariable.filter((item) => item.id !== rowId);
        // stateVariableFunction(newData);
      } else if (response?.messageCode === 99) {
        setIsLoading(false);
        Swal.fire({
          text: "Some of the data is not compeleted are you sure you want to delete this session ?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            handleFetchRequest(
              `${apiUrl?.delete_coach_plan_session}?plan_session_id=${data?.id}&force_delete=true`,
              "DELETE"
            ).then((response) => {
              if (response?.status === true) {
                setIsLoading(false);
                toast.success(response?.message);
                setCoachList([]);
                getCoachPlanList(currentPage);
                // const newData = stateVariable.filter((item) => item.id !== rowId);
                // stateVariableFunction(newData);
              }
            });
          }
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast.error(response?.message);
      }
    });
  };

  let addHeadingAction;
  if (role === RoleType.admin_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.coach_plan_session_details },
      {
        name: "Delete",
        type: "function",
        path: deleteSessionHandler,
        value: "pending",
      },
    ];
  }

  useEffect(() => {
    getCoachPlanList(currentPage);
    getCoachList();
  }, []);

  const applyFilter = () => {
    if (searchOptionData === "session_time") {
      const filterValue = filterData
        ? `&started_ts=${convertToEpoch(
            filterData.start_date
          )}&end_ts=${convertToEpoch(filterData.end_date)}`
        : "";
      filters.push(filterValue);
    } else {
      const filterValue = filterData
        ? `&${searchOptionData}=${filterData}`
        : "";
      filters.push(filterValue);
    }
  };

  const getCoachList = () => {
    handleFetchRequest(`${apiUrl.get_all_coach}`).then((response) => {
      if (response?.status === true) {
        setAllCoachList(response.data);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
      }
    });
  };

  const handlePageChange = (pageitem) => {
    setCoachList([]);
    getCoachPlanList(pageitem);
    setCurrentPage(pageitem);
  };

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setSearchOptionData("");
    setFilterData("");
    setCoachList([]);
    setInputTypeData("select");
    setInputCategoryData("");
    handleFetchRequest(
      `${apiUrl.get_session_list}?coach_plan_id=${location?.state?.data?.id}&page_no=1&page_size=10`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCoachList(response.data);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const getCoachPlanList = (pageItem) => {
    setIsLoading(true);
    applyFilter();
    handleFetchRequest(
      `${apiUrl.get_session_list}?coach_plan_id=${
        location?.state?.data?.id
      }&page_no=${pageItem}&page_size=10${filters.join("&")}`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCoachList(response.data);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const backButtonHandler = () => {
    if (location?.state?.back === "dashboard") {
      navigate(RoutesLink?.dashboard, {
        state: { tabpane: location?.state?.tabpane },
      });
    } else if (location?.state?.back === "coach_profile") {
      navigate(RoutesLink?.coach_profile, {
        state: {
          tabpane: "3",
          id: location?.state?.data?.coach_id,
          data: { alias_id: location?.state?.data?.coach?.alias_id },
        },
      });
    } else {
      navigate(-1);
    }
  };

  const FilterApiCallFunction = () => {
    if (searchOptionData === "session_time") {
      if (filterData?.start_date && filterData.end_date) {
        getCoachPlanList(1);
      } else {
        toast.error("Please select both dates");
      }
    } else {
      getCoachPlanList(1);
    }
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };
  const handelOpenCoachModel = () => {
    // console.log("dfsdfsd", id)
    setModal2(!modal2);
    setSelectCoachPalnId(location?.state?.id);
    console.log(
      "khk",
      location?.state?.data?.coach_id,
      allCoachList.filter((e) => e.id === location?.state?.data?.coach_id)
    );
    let allCoach = allCoachList.filter(
      (e) => e.id !== location?.state?.data?.coach_id
    );
    setAllCoachList(allCoach);
  };

  const handelChangeCoach = () => {
    if (selecCoachId !== "") {
      let Data = {
        coach_id: selecCoachId,
        plan_id: location?.state?.data?.id,
      };
      setLodingModalBtn(true);
      handleFetchRequest(apiUrl?.change_coach, "PUT", Data).then((response) => {
        if (response?.status === true) {
          setLodingModalBtn(false);
          toast.success("Coach Change Successfully");
          toggle2();
          setSelectCoachId("");
          setSelectCoachPalnId("");
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setLodingModalBtn(false);
          toast.error(response.message);
        }
      });
    } else {
      toast.error("Please select coach");
    }
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <Heading backButtonHandler={backButtonHandler} />
                <div className="pro ">
                  <div className="newcon">
                    <ul className="uln my-1 ">
                      <li>
                        <h5>{t("beneficiary_name")}:</h5>{" "}
                        <span>
                          {location?.state?.data?.beneficiary?.fullname}{" "}
                          {location?.state?.beneficiary_name}
                        </span>
                      </li>
                      <li>
                        <h5>{t("coach_name")}:</h5>{" "}
                        <span>
                          {location?.state?.data?.coach?.first_name}{" "}
                          {location?.state?.data?.coach?.last_name}{" "}
                          {location?.state?.coach_name}
                        </span>
                      </li>
                      <li>
                        <h5>{t("coaching_plan_id")}:</h5>{" "}
                        <span>
                          {location?.state?.data?.plan_id}{" "}
                          {location?.state?.data?.cp_master?.plan_id}{" "}
                        </span>
                      </li>
                      <li>
                        <h5>{t("Beneficiary_Contact")} : </h5>{" "}
                        <span>
                          {location.state?.data?.beneficiary?.telephone_pref}{" "}
                        </span>
                      </li>

                      {/* <li><h5>Registeration Site ID:</h5> <span>23</span></li> */}
                      {/* <li><h5>Loterie:</h5><span>234</span></li> */}
                      <li>
                        <div className="abs-btn">
                          <span className="active-button  badge">
                            {location?.state?.data?.status}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row d-flex justify-content-start">
                  <Heading
                    // backButtonHandler={() => navigate(-1)}
                    inputTypeData={inputTypeData}
                    setInputTypeData1={setInputTypeData}
                    searchOption1Array={searchOption1Array}
                    // addLink={RoutesLink?.add_coach_plan}
                    addLink={{
                      link: RoutesLink?.add_coach_plan,
                      data: {
                        data: {
                          coach: {
                            first_name:
                              location?.state?.data?.coach?.first_name,
                            last_name: location?.state?.data?.coach?.last_name,
                          },
                        },
                        fullname: location?.state?.data?.beneficiary?.fullname,
                        coach_plan_id: location?.state?.data?.id,
                        assigned_beneficiary: {
                          coach_id: location?.state?.data?.coach_id,
                        },
                      },
                    }}
                    deleteBeneficiary={
                      role === RoleType.admin_value ? true : false
                    }
                    btnClickName={"Change Coach"}
                    btnCickFunction={handelOpenCoachModel}
                    btnName={
                      location?.state?.data?.status !== "completed" &&
                      (coachList?.length > 0 ? "Edit Sessions" : "Add Sessions")
                    }
                    name={"Coaching Plan Sessions"}
                    // addArray={addAction1}
                    inputCategoryData={inputCategoryData}
                    setInputCategoryData={setInputCategoryData}
                    setSearchOptionData={setSearchOptionData}
                    searchOption2Array={searchOption2Array}
                    setMainCallApi={FilterApiCallFunction}
                    setFilterData={setFilterData}
                    setData={setCoachList}
                    filterData={filterData}
                    setActivePage={setCurrentPage}
                    ResetAccountHandler={ResetAccountHandler}
                    customClassName={"customDatePicker"}
                  />
                  <PrimaryTable
                    listData={coachList}
                    listHeading={listHeading}
                    editPage={"/add-coach"}
                    addData={addHeadingAction}
                    coachPlan={true}
                    pages={pages}
                    activePage={currentPage}
                    handlePageChange={handlePageChange}
                    isLoading={isLoading}
                    idClickLink={RoutesLink?.coach_plan_session_details}
                    // idClickLink={RoutesLink?.coach_profile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal2} centered toggle={toggle2} className=" modal">
        <ModalHeader className="border-bottom " toggle={toggle2}>
          Change Coach
        </ModalHeader>
        <ModalBody className="p-3 pt-4">
          <form>
            <div className="p-2 rounded box-shadow">
              <div className="row">
                <div className="col-12 p-0">
                  <label className="input-label ps-3">
                    Select Coach<span className="text-danger">*</span>
                  </label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => setSelectCoachId(e.target.value)}
                    >
                      <option value={""}>Select Coach</option>
                      {allCoachList &&
                        allCoachList?.map((item, key) => (
                          <option key={key} value={item?.id}>
                            {item?.first_name} {item?.last_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer pt-4">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => toggle2()}
              >
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handelChangeCoach}
              >
                {lodingModalBtn == true ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  `{t("submit")}`
                )}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default CoachPlanDetail;
