import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import tEn from "../src/utility/locales/en/translation.json";
import tfr from "../src/utility/locales/fr/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: tEn,
      },
      fr: {
        translation: tfr,
      },
    },
    debug: true,
    lng: localStorage.getItem("i18nextLng") || "en",
    fallbackLng: ["en"],
  });

export default i18n;
