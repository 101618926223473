import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Layout from "../../Components/Layout/Layout";
// import { Chart } from "react-google-charts";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Chart } from 'apexcharts'
import Heading from "../../Components/CommonElements/Heading";
// import PrimaryInput from "../../Components/Inputs/PrimaryInput";
import PrimaryTable from "../../Components/Tables/PrimaryTable";
import { Chart } from "react-google-charts";
import { useEffect, useState } from "react";
import { apiUrl } from "../../ApiHelper/ApiUrl";
import {
  DownloadReportHandler,
  DownloadReportsHandler,
  handleFetchRequest,
} from "../../ApiHelper/ApiHelper";

import RoleType from "../../ApiHelper/Roles.json";

import { RoutesLink } from "../../ApiHelper/RoutesLink";
import App from "../../Components/CommonElements/Chart";
// import Chart from "react-apexcharts";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import PageLoader from "../../Components/CommonElements/PageLoader";
import {
  CurrencyConverter,
  CurrencyConverter2,
} from "../../ApiHelper/CurrencyConverter";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../store/actions/UserActions";
import ReactApexChart from "react-apexcharts";
import TableLoader from "../../Components/CommonElements/TableLoader";
import {
  ConvertInputTimeToSetting,
  TimeStampConverter,
  convertToEpoch,
} from "../../ApiHelper/TimeStampConverter";
import { JsonCalendar } from "json-calendar";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";

const Dashboard = () => {
  moment.locale("en-GB");
  let localize = momentLocalizer(moment);
  const userId = useSelector((state) => state.user.userDetails.account_id);
  const allViews = Object.keys(Views).map((k) => Views[k]);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  console.log(userId, "userId");
  let currencyType = Cookies.get("emp_currency");
  const [eventData, setEventData] = useState("");
  const Roles = Cookies.get("emp_role");
  const [coachSearchTerm, setCoachSearchTerm] = useState({ name: "", id: "" });
  const [coachMonitoringSearchTerm, setCoachMonitoringSearchTerm] = useState({
    name: "",
    id: "",
  });

  const [beneficiarySearchTerm, setBeneficiarySearchTerm] = useState({
    name: "",
    id: "",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBeneficiaryDropdownOpen, setIsBeneficiaryDropdownOpen] =
    useState(false);

  const [provinceData, setProvinceData] = useState("");
  const [sectorData, setSectorData] = useState("");
  const [CoachingSectorData, setCoachingSectorData] = useState("");
  const [beneficiarySectorData, setBeneficiarySectorData] = useState("");
  const [coachMonitoringProvinceData, setCoachMonitoringProvinceData] =
    useState("");
  const [
    beneficiaryMonitoringProvinceData,
    setbeneficiaryMonitoringProvinceData,
  ] = useState("");

  const dispatch = useDispatch();

  const [selectBeneficiaryStatus, setSelectBeneficiaryStatus] = useState("");
  const [selectSessionsListStatus, setSelectSessionsListStatus] = useState("");
  const [currentCoachingListPage, setCurrentCoachingListPage] = useState(1);
  const [allCoachingListPage, setAllCoachingListPage] = useState(1);
  const [currentSessionsListPage, setCurrentSessionsListPage] = useState(1);
  const [allSessionsListPage, setAllSessionsListPage] = useState(1);
  const [coachMonitoringSessionList, setCoachMonitoringSessionList] = useState(
    []
  );
  // Get the current date
  let currentDate = moment();
  const navigate = useNavigate();
  const [tabSubOpen, setTabSubOpen] = useState("1");
  const { t } = useTranslation();

  // var myVariable = "28 Aug 2014"
  // var makeDate = new Date(myVariable);

  // console.log('Original date: ', makeDate.toString());

  const prevMonthDate = moment(currentDate).subtract(1, "month");
  const prevMonthConvertedDate = prevMonthDate.startOf("day");
  const newEndDate = currentDate.endOf("day");

  console.log("prevvvvv month dateee", prevMonthDate);
  console.log("After subtracting a month: ", prevMonthDate.toString());

  const [startDate, setDate] = useState({
    from: prevMonthConvertedDate.toDate(),
    to: newEndDate?.toDate(),
  });
  const [startCoachMonitoringDate, setStartCoachMonitoringDate] = useState({
    from: prevMonthConvertedDate.toDate(),
    to: newEndDate?.toDate(),
  });

  const [startBeneficiaryMonitoringDate, setStartBeneficiaryMonitoringDate] =
    useState({
      from: prevMonthConvertedDate.toDate(),
      to: newEndDate?.toDate(),
    });
  const [tabOpen, setTabOpen] = useState("1");
  const [submenuTabOpen, setSubmenuTabOpen] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  // const [pageIsLoading,setPageIsLoading] = useState(false);
  const [subventionList, setSubventionList] = useState([]);
  const [sessionsList, setSessionsList] = useState([]);
  const [coachSubTab, setCoachSubTab] = useState("2");

  const setCoachTabValue = (tab) => {
    setCoachSubTab(tab);
  };

  const [pages, setPages] = useState([]);
  // const [subventionMonitoringData, setSubventionMonitoringData] = useState([]);
  const location = useLocation();
  console.log("fasf", location);
  const startYear = 1950;
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const listHeading = [
    { classType: "", data: `${t("subvention_id")}` },
    { classType: "", data: `${t("coach_id")}` },
    { classType: "", data: `${t("coach_name")}` },
    { classType: "", data: `${t("benreferenceId")}` },
    { classType: "", data: `${t("beneficiary_name")}` },
    { classType: "text-end", data: `${t("amount")}` },
    { classType: "", data: `${t("status")}` },
    { classType: "", data: `${t("order_date")}` },
  ];

  const sessionListHeading = [
    { data: `${t("coaching_plan_id")}`, classType: "" },
    { data: `${t("beneficiary_reference_id")}`, classType: "" },
    { data: `${t("coach_reference_id")}`, classType: "" },
    { data: `${t("schedule_start_time")}`, classType: "" },
    { data: `${t("planning_start_time")}`, classType: "" },
    { data: `${t("planning_end_time")}`, classType: "" },
    { data: `${t("status")}`, classType: "" },
    // { data: "Action", classType: "" }
  ];

  const beneficiaryMonitoringListHeading = [
    { data: `Session id`, classType: "" },
    // { data: "Coach ID", classType: "" },
    { data: `${t("coach_name")}`, classType: "" },
    // { data: "Ben. Reference ID", classType: "" },
    { data: `${t("beneficiary_name")}`, classType: "" },
    { data: `${t("employees")}`, classType: "text-end" },
    { data: `${t("sales")}`, classType: "text-end" },
    { data: `${t("expenses")}`, classType: "text-end" },
    { data: `${t("purchase")}`, classType: "text-end" },
    { data: `${t("profit_loss")}`, classType: "text-end" },
    { data: `${t("session_start(dd/mm/yyyy)")}`, classType: "" },
    { data: "Session End(DD/MM/YYYY)", classType: "" },
    { data: `${t("status")}`, classType: "" },
    // { data: "Order Date", classType: "" },
    // { data: "Action", classType: "" }
  ];

  const [subventionMonitoringData, setSubventionMonitoringData] = useState();

  const [subventionFinanceChartData, setSubventionFinanceChartData] = useState({
    options: {
      chart: {
        height: 380,
        width: "100%",
        type: "area",
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        title: {
          text: `${t("date_time")}`, // Label for the y-axis
        },
      },
      yaxis: {
        title: {
          text: `${t("amount")}`, // Label for the y-axis
        },
        // labels: {
        //   formatter: function (value) {
        //     return value.toFixed(2); // Example of y-axis label formatting
        //   }
        // }
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
    },
    series: [
      {
        name: "Subvention Finance Monitoring",
        data: [],
      },
    ],
  });

  const [subventionChartData, setSubventionChartData] = useState({
    options: {
      chart: {
        height: 380,
        width: "100%",

        type: "area",
        animations: {
          initialAnimation: {
            enabled: false,
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        // label: "time",
        title: {
          text: `${t("date_time")}`, // Label for the y-axis
        },
      },
      yaxis: {
        title: {
          text: "No. of subventions", // Label for the y-axis
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
    },
    series: [
      {
        name: "Subvention  Monitoring",
        data: [],
      },
    ],
  });

  const [sessionSchedulechartData, setSessionSchedulechartData] = useState({
    series: [
      {
        name: "name 1",
        data: [],
      },
      {
        name: "name 2",
        data: [],
      },
      {
        name: "name 3",
        data: [],
      },
      {
        name: "name 4",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,

        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
              inverseOrder: true,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        title: {
          text: `${t("date_time")}`, // Label for the y-axis
        },
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.7,
              width: 1,
            },
          },
        },

        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      yaxis: {
        title: {
          text: "No. of Sessions", // Label for the y-axis
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const [coachMonitoringData, setCoachMonitoringData] = useState({});
  const [beneficiaryMonitoringData, setBeneficiaryMonitoringData] = useState(
    {}
  );
  const [
    beneficiaryMonitoringCoachingListData,
    setBeneficiaryMonitoringCoachingListData,
  ] = useState([]);

  const [sessionBreakupChartData, setSessionBreakupChartData] = useState({
    data: [],
    options: {
      title: "My Daily Activities",
      legend: "none",
      legend: { position: "none" },
      colors: ["#e0440e", "#e6693e", "#ec8f6e"],
      pieHole: 0.1,
      is3D: false,
      // legend: 'bottom',
      chartArea: { width: 400, height: 400 },
    },
  });

  const [
    beneficiarySessionBreakupChartData,
    setBeneficiarySessionBreakupChartData,
  ] = useState({
    data: [],
    options: {
      title: "My Daily Activities",
      pieHole: 0.1,
      is3D: false,
      legend: { position: "none" },
      // legend: "bottom",
      chartArea: { width: 400, height: 400 },
    },
  });

  const [
    beneficiaryMonitoringHumanResourceChart,
    setBeneficiaryMonitoringHumanResourceChart,
  ] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      colors: ["#510DBC"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        title: {
          text: `${t("date_time")}`, // Label for the y-axis
        },
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.7,
              width: 1,
            },
          },
        },

        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      yaxis: {
        title: {
          text: "No. of Sessions", // Label for the y-axis
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const [
    beneficiaryMonitoringFinanceChart,
    setBeneficiaryMonitoringFinanceChart,
  ] = useState({
    series: [
      {
        name: "Sales",
        data: [],
      },
      {
        name: "Profit",
        data: [],
      },
    ],
    options: {
      colors: ["#510DBC", "#098E7E"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        title: {
          text: `${t("date_time")}`, // Label for the y-axis
        },
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.7,
              width: 1,
            },
          },
        },

        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      yaxis: {
        title: {
          text: "No. of Sessions", // Label for the y-axis
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const startOfPreviousMonth = new Date();
  startOfPreviousMonth.setMonth(startOfPreviousMonth.getMonth() - 1);
  startOfPreviousMonth.setDate(1);

  // states/variables related to beneficiary monitoring starts here
  const [benSessionInputTypeData, setBenSessionInputTypeData] =
    useState("text");

  const benSessionsearchOption1Array = [
    { name: "Search By", value: "global_search", type: "text" },
    { name: "Reference ID", value: "reference_id", type: "text" },
    { name: "Name", value: "fullname", type: "text" },
    { name: "Contact Detail", value: "telephone_pref", type: "text" },
    { name: "Province", value: "province", type: "text" },
    { name: "Commune", value: "commune", type: "text" },
    { name: "Quartier", value: "quartier", type: "text" },
    { name: "Status", value: "status", type: "select" },
    { name: "Grant Status", value: "grant_eligibility", type: "select" },
    { name: "Sector", value: "sector", type: "select" },
  ];

  const [benSessionSearchOptionData, setBenSessionSearchOptionData] =
    useState("global_search");

  const benSessionSearchOptionStatusArray = [
    // { name: "Status", value: "", type: "status" },
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
    { name: "Allowed", value: 1, type: "grant_eligibility" },
    { name: "Not Allowed", value: 0, type: "grant_eligibility" },
    { name: "Unassigned", value: 2, type: "grant_eligibility" },
    { name: "Unassigned", value: 0, type: "assignment" },
    { name: "Assigned", value: 1, type: "assignment" },
    { name: "Agri Business", value: "agribusiness", type: "sector" },
    { name: "Manufacturing", value: "manufacturing", type: "sector" },
    { name: "Services", value: "services", type: "sector" },
  ];
  const [benSessionFilterData, setBenSessionFilterData] = useState();

  const benSessionsearchOption2Array = benSessionSearchOptionStatusArray.filter(
    (data) => data?.type === benSessionInputTypeData
  );
  const [benSessionInputCategoryData, setBenSessionInputCategoryData] =
    useState("");
  const [benSessionActivePage, setBenSessionActivePage] = useState("1");
  let benSessionListHeading = [
    { classType: "", data: `${t("beneficiary_name")}` },
    { classType: "", data: "Coaching Plan Status" },
    { classType: "text-end", data: "Total" },
    { classType: "text-end", data: `${t("pending")}` },
    { classType: "text-end", data: `${t("in_progress")}` },
    { classType: "text-end", data: `${t("completed")}` },
  ];
  const [benSessionListData, setBenSessionListData] = useState([]);

  // states/variables related to beneficiary monitoring ends  here

  useEffect(() => {
    if (location?.state?.tabpane) {
      setTabOpen(location?.state?.tabpane);
    }

    getSubventionList(1);
    getSessionList(1);
    const start = moment(startDate?.from).format("YYYY-MM-DD hh:mm:ss");
    const end = moment(startDate?.to).format("YYYY-MM-DD hh:mm:ss");

    getSubventionMonitoring(
      `${apiUrl?.subvention_monitoring}?fromDate=${start}&toDate=${end}`
    );
    getCoachMonitoringGraph(
      `${apiUrl?.get_coach_monitoring_chart}?schedule_start_ts=${convertToEpoch(
        startDate?.from
      )}&schedule_end_ts=${convertToEpoch(startDate?.to)}`
    );
    getCoachMonitorCoachingSession(
      `${
        apiUrl?.get_dashboard_coaching_sessions
      }?schedule_start_ts=${convertToEpoch(
        startDate?.from
      )}&schedule_end_ts=${convertToEpoch(startDate?.to)}&${
        Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
      }`
    );
    getCoachMonitoringDashboard(
      `${
        apiUrl?.get_coach_monitoring_dashboard
      }?schedule_start_ts=${convertToEpoch(
        startDate?.from
      )}&schedule_end_ts=${convertToEpoch(startDate?.to)}`
    );
    getBeneficiaryMonitoringGraph(
      `${
        apiUrl?.get_beneficiary_monitoring_graph
      }?schedule_start_ts=${convertToEpoch(
        startDate?.from
      )}&schedule_end_ts=${convertToEpoch(startDate?.to)}&${
        Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
      }`
    );
    getBeneficiaryMonitoring(
      `${apiUrl?.get_beneficiary_monitoring}?schedule_start_ts=${convertToEpoch(
        startDate?.from
      )}&schedule_end_ts=${convertToEpoch(startDate?.to)}&${
        Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
      }`
    );
    getBeneficiaryMonitoringCoachingList();
    if (RoleType.admin_value === Roles || RoleType.viewer_value === Roles) {
      getcoachList();
    }
    benSessionList(
      `${
        apiUrl?.get_ben_monitoring_session_list
      }?schedule_start_ts=${convertToEpoch(
        startDate?.from
      )}&schedule_end_ts=${convertToEpoch(startDate?.to)}&${
        Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
      }`
    );
  }, []);

  useEffect(() => {
    getBeneficiaryList();
  }, [coachMonitoringSearchTerm?.id]);

  useEffect(() => {
    if (selectSessionsListStatus !== "") {
      getSessionListByStatus(currentSessionsListPage);
    }
  }, [selectSessionsListStatus]);

  const subventionFilterSubmitHandler = () => {
    let newStartDate = moment(startDate?.from)?.startOf("day");
    let newEndDate = moment(startDate?.to)?.endOf("day");
    const start = moment(newStartDate).format("YYYY-MM-DD hh:mm:ss");
    const end = moment(newEndDate).format("YYYY-MM-DD hh:mm:ss");

    const filters = [];
    if (start !== "Invalid date") {
      filters.push(`fromDate=${start}`);
    }
    if (end !== "Invalid date") {
      filters.push(`toDate=${end}`);
    }
    if (provinceData) {
      filters.push(`province=${provinceData}`);
    }
    if (sectorData) {
      filters.push(`sector=${sectorData}`);
    }
    if (coachSearchTerm?.id) {
      filters.push(`coach_id=${coachSearchTerm?.id}`);
    }
    console.log("subbb start dateeee..... ", start, end);
    if (start !== "Invalid date") {
      if (end === "Invalid date") {
        toast.error("Please Select Both Dates");
      } else {
        getSubventionMonitoring(
          `${apiUrl?.subvention_monitoring}?${filters.join("&")}`
        );
      }
    } else {
      // toast.success("doneee");
      getSubventionMonitoring(
        `${apiUrl?.subvention_monitoring}?${filters.join("&")}`
      );
    }
  };

  const coachDateSubmitHandler = () => {
    const start = startCoachMonitoringDate?.from
      ? convertToEpoch(moment(startCoachMonitoringDate?.from)?.startOf("day"))
      : "";
    const end = startCoachMonitoringDate?.to
      ? convertToEpoch(moment(startCoachMonitoringDate?.to).endOf("day"))
      : "";

    console.log("================================", start, end);

    const filters = [];
    if (start) {
      filters.push(`schedule_start_ts=${start}`);
    }
    if (end) {
      filters.push(`schedule_end_ts=${end}`);
    }
    if (CoachingSectorData) {
      filters.push(`sector=${CoachingSectorData}`);
    }
    if (coachMonitoringProvinceData) {
      filters.push(`province=${coachMonitoringProvinceData}`);
    }
    if (coachMonitoringSearchTerm?.id) {
      filters.push(`coach_id=${coachMonitoringSearchTerm?.id}`);
    }

    if (start) {
      if (!end) {
        toast.error("Please Select Both Dates");
      } else {
        getCoachMonitoringGraph(
          `${apiUrl?.get_coach_monitoring_chart}?${filters.join("&")}`
        );
        getCoachMonitorCoachingSession(
          `${apiUrl?.get_dashboard_coaching_sessions}?${filters.join("&")}&${
            Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
          }`
        );

        getCoachMonitoringDashboard(
          `${apiUrl?.get_coach_monitoring_dashboard}?${filters.join("&")}`
        );
      }
    } else {
      // toast.success("doneee");
      getCoachMonitoringGraph(
        `${apiUrl?.get_coach_monitoring_chart}?${filters.join("&")}`
      );
      getCoachMonitorCoachingSession(
        `${apiUrl?.get_dashboard_coaching_sessions}?${filters.join("&")}&${
          Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
        }`
      );

      getCoachMonitoringDashboard(
        `${apiUrl?.get_coach_monitoring_dashboard}?${filters.join("&")}`
      );
    }
  };
  const beneficiaryDateSubmitHandler = () => {
    const start = startBeneficiaryMonitoringDate?.from
      ? convertToEpoch(
          moment(startBeneficiaryMonitoringDate?.from)?.startOf("day")
        )
      : "";
    const end = startBeneficiaryMonitoringDate?.to
      ? convertToEpoch(moment(startBeneficiaryMonitoringDate?.to)?.endOf("day"))
      : "";

    const filters = [];
    if (start) {
      filters.push(`schedule_start_ts=${start}`);
    }
    if (end) {
      filters.push(`schedule_end_ts=${end}`);
    }
    if (beneficiarySectorData) {
      filters.push(`sector=${beneficiarySectorData}`);
    }
    if (beneficiaryMonitoringProvinceData) {
      filters.push(`province=${beneficiaryMonitoringProvinceData}`);
    }

    if (coachMonitoringSearchTerm?.id) {
      filters.push(`coach_id=${coachMonitoringSearchTerm?.id}`);
    }
    if (beneficiarySearchTerm?.id) {
      filters.push(`beneficiary_id=${beneficiarySearchTerm?.id}`);
    }
    if (start) {
      if (!end) {
        toast.error("Please Select Both Dates");
      } else {
        getBeneficiaryMonitoringGraph(
          `${apiUrl?.get_beneficiary_monitoring_graph}?${filters?.join("&")}`
        );

        getBeneficiaryMonitoring(
          `${apiUrl?.get_beneficiary_monitoring}?${filters?.join("&")}`
        );
        benSessionList(
          `${apiUrl?.get_ben_monitoring_session_list}?${filters?.join("&")}`
        );
      }
    } else {
      getBeneficiaryMonitoringGraph(
        `${apiUrl?.get_beneficiary_monitoring_graph}?${filters?.join("&")}`
      );

      getBeneficiaryMonitoring(
        `${apiUrl?.get_beneficiary_monitoring}?${filters?.join("&")}`
      );

      benSessionList(
        `${apiUrl?.get_ben_monitoring_session_list}?${filters?.join("&")}`
      );
    }
  };

  const selectDateHandler = (d, type) => {
    console.log(d);
    if (type === "from") {
      let inputDate = d;
      setDate({ ...startDate, from: d, to: "" });
    } else {
      setDate({ ...startDate, to: d });
    }
  };

  const selectCoachDateHandler = (d, type) => {
    console.log(d);
    if (type === "from") {
      setStartCoachMonitoringDate({
        ...startCoachMonitoringDate,
        from: d,
        to: "",
      });
    } else {
      setStartCoachMonitoringDate({ ...startCoachMonitoringDate, to: d });
    }
  };

  const selectBeneficiaryDateHandler = (d, type) => {
    console.log(d);
    if (type === "from") {
      setStartBeneficiaryMonitoringDate({
        ...startBeneficiaryMonitoringDate,
        from: d,
        to: "",
      });
    } else {
      setStartBeneficiaryMonitoringDate({
        ...startBeneficiaryMonitoringDate,
        to: d,
      });
    }
  };

  const setTabValue = (data) => {
    setTabOpen(data);
  };

  const setSubmenuTabValue = (data) => {
    setSubmenuTabOpen(data);
  };

  const getSubventionList = (pageItem) => {
    setIsLoading(true);
    handleFetchRequest(
      `${apiUrl?.get_subvention_list}?page_no=${pageItem}&page_size=20`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setSubventionList(response?.data);
        setPages(response?.pageInfo);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_time_zone");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getSubventionMonitoring = (link) => {
    setIsPageLoading(true);
    handleFetchRequest(link).then((response) => {
      if (response?.status === true) {
        setSubventionMonitoringData(response.data);
        const dateArray = response?.data?.subvention_graph?.map(
          (item) => item?.date
        );
        // const amountArray = response?.data?.subvention_graph?.map(item => { return ([moment(item?.date).valueOf(), +item?.total_amount]) });
        const amountArray = response?.data?.subvention_graph?.map((item) =>
          CurrencyConverter2(+item?.total_amount)
        );
        console.log("Datatatatatataaa", amountArray);
        const numberArray = response?.data?.subvention_graph?.map(
          (item) => item?.total_subvention
        );
        setSubventionFinanceChartData({
          options: {
            colors: ["#510DBC"],
            chart: {
              height: 380,
              width: "100%",
              type: "area",
              animations: {
                initialAnimation: {
                  enabled: false,
                },
              },
            },
            xaxis: {
              title: {
                text: `${t("date_time")}`, // Label for the y-axis
              },
              type: "datetime",
              categories: dateArray,
            },
            yaxis: {
              title: {
                text: `${t("amount")}`, // Label for the y-axis
              },
            },
            tooltip: {
              x: {
                format: "dd/MM/yy",
              },
            },
          },
          series: [
            {
              name: "Subvention Finance Monitoring",
              data: amountArray,
            },
          ],
        });

        setSubventionChartData({
          options: {
            colors: ["#510DBC"],
            chart: {
              height: 380,
              width: "100%",
              type: "area",
              animations: {
                initialAnimation: {
                  enabled: false,
                },
              },
            },
            xaxis: {
              type: "datetime",
              categories: dateArray,
              title: {
                text: `${t("date_time")}`, // Label for the y-axis
              },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return value; // Example of y-axis label formatting
                },
              },
            },
            yaxis: {
              title: {
                text: "No. of subventions", // Label for the y-axis
              },
            },
            tooltip: {
              x: {
                format: "dd/MM/yy",
              },
            },
          },
          series: [
            {
              name: "Subvention Monitoring",
              data: numberArray,
            },
          ],
        });

        setIsPageLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_time_zone");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const getCoachMonitoringGraph = (link) => {
    setIsPageLoading(true);

    handleFetchRequest(link).then((response) => {
      if (response?.status === true) {
        const dateArray = response?.data?.map(
          (item) =>
            item?.date && `${moment(item.date).format("MM/DD/YYYY")} GMT`
        );
        console.log("dateArray: ", dateArray);
        const completedArray = response?.data?.map(
          (item) => item?.completed_plan
        );

        const pendingArray = response?.data?.map((item) => item?.pending_plan);
        const inProgressArray = response?.data?.map(
          (item) => item?.inprogress_plan
        );
        console.log(
          "session chart data",
          dateArray,
          completedArray,
          inProgressArray,
          pendingArray
        );
        // const amountArray = response?.data?.subvention_graph?.map(item => { return ([moment(item?.date).valueOf(), +item?.total_amount]) });
        // const amountArray = response?.data?.subvention_graph?.map(item => +item?.total_amount);
        // console.log("Datatatatatataaa", amountArray);
        // const numberArray = response?.data?.subvention_graph?.map(item => +item?.total_subvention);
        setSessionSchedulechartData({
          series: [
            {
              name: `${t("Completed_")}`,
              data: completedArray,
            },
            {
              name: `${t("In_Progress")}`,
              data: inProgressArray,
            },
            {
              name: `${t("Pending")}`,
              data: pendingArray,
            },
          ],
          options: {
            colors: ["#098E7E", "#00B8F5", "#AB0D82"],
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
              },

              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -5,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              type: "datetime",
              categories: dateArray,
            },
            legend: {
              position: "right",
              offsetY: 40,
              inverseOrder: true,
            },
            fill: {
              opacity: 1,
            },
          },
        });
        setIsPageLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_time_zone");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const getSessionList = () => {
    setIsLoading(true);
    handleFetchRequest(
      `dashboard/v1/latest-coach-sessions?page_no=1&page_size=20`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setSessionsList(response?.data);
        // setPages(response?.pageInfo);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_time_zone");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleSessionListPageChange = (pageitem) => {
    setSessionsList([]);
    getSessionListByStatus(pageitem);
    setCurrentSessionsListPage(pageitem);
  };

  const handelResetSessionList = () => {
    getSessionList();
    setCurrentSessionsListPage(1);
    setAllSessionsListPage("");
    setSelectSessionsListStatus("");
  };

  const getSessionListByStatus = (pageitem) => {
    setIsLoading(true);
    const start = convertToEpoch(
      moment(startCoachMonitoringDate?.from)?.startOf("day")
    );
    const end = convertToEpoch(
      moment(startCoachMonitoringDate?.to)?.endOf("day")
    );
    console.log("================================", start, end);

    const filters = [];
    if (start) {
      filters.push(`schedule_start_ts=${start}`);
    }
    if (end) {
      filters.push(`schedule_end_ts=${end}`);
    }
    if (CoachingSectorData) {
      filters.push(`sector=${CoachingSectorData}`);
    }
    if (coachMonitoringProvinceData) {
      filters.push(`province=${coachMonitoringProvinceData}`);
    }
    if (coachMonitoringSearchTerm?.id) {
      filters.push(`coach_id=${coachMonitoringSearchTerm?.id}`);
    }
    handleFetchRequest(
      `${
        apiUrl?.get_coach_plan
      }?page_no=${pageitem}&page_size=10&status=${selectSessionsListStatus.toLowerCase()}&${filters?.join(
        "&"
      )}`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setSessionsList(response?.data);
        setAllSessionsListPage(response?.pageInfo);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_time_zone");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getCoachMonitoringDashboard = (apiLink) => {
    setIsPageLoading(true);
    handleFetchRequest(apiLink).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);
        let breakpointData = [
          ["Task", "weeks per month"],
          [
            "Completed",
            response?.data?.total_plan_with_status?.total_completed_plan,
          ],
          [
            "In Progress",
            response?.data?.total_plan_with_status?.total_inprogress_plan,
          ],
          [
            "Pending",
            response?.data?.total_plan_with_status?.total_pending_plan,
          ],
        ];

        let total = 0;
        breakpointData.slice(1).forEach(([_, value]) => {
          total += value || 0;
        });

        // Convert values to percentages
        const dataWithPercentage = breakpointData.map(
          ([task, value], index) => {
            if (index === 0) return [task, value]; // Keep the header row unchanged
            const percentage = total !== 0 ? ((value || 0) / total) * 100 : 0;
            return [task, percentage];
          }
        );

        // Update the breakpointData with percentages
        breakpointData = dataWithPercentage;

        setCoachMonitoringData(response?.data);
        setSessionBreakupChartData({
          data: breakpointData,
          options: {
            legend: "none",
            // title: "Sessions",
            legend: { position: "none" },
            colors: ["#098E7E", "#00B8F5", "#AB0D82"],
            pieHole: 0.3,
            is3D: false,
            chartArea: { width: 250, height: 250 },
          },
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const getCoachMonitorCoachingSession = (apiLink) => {
    setIsPageLoading(true);
    handleFetchRequest(apiLink).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);
        let sessionData = response?.data?.map((data) => {
          return {
            id: data?.session_id,
            beneficiary_id: data?.cp_master?.beneficiary?.id,
            title: data?.cp_master?.beneficiary?.fullname,
            start: new Date(TimeStampConverter(data?.schedule_start_ts)),
            end: new Date(TimeStampConverter(data?.schedule_end_ts)),
            beneficiary_name: data?.cp_master?.beneficiary?.fullname,
            color:
              data?.status === "completed"
                ? "#098E7E"
                : data?.status === "pending"
                ? "#AB0D82"
                : data?.status === "in progress"
                ? "#00B8F5"
                : "",
          };
        });
        setCoachMonitoringSessionList(sessionData);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const getBeneficiaryMonitoringGraph = (link) => {
    setIsPageLoading(true);
    handleFetchRequest(link).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);
        // var dataFormat = [
        //   { name: 'Completed', data: response?.data?.plan?.total_completed_plan },
        //   { name: 'Pending', data: response?.data?.plan?.total_inprogress_plan },
        //   { name: 'In progress', data: response?.data?.plan?.total_pending_plan }
        // ];

        const hrData = response?.data?.employees?.map((data) => data?.total_hr);
        const hrDateData = response?.data?.employees?.map((data) => data?.date);
        const financeSalesData = response?.data?.financial?.map((data) =>
          CurrencyConverter2(data?.total_selas)
        );
        const financeProfitData = response?.data?.financial?.map((data) =>
          CurrencyConverter2(data?.net_profit_loss)
        );
        const financeDate = response?.data?.financial?.map(
          (data) => data?.date
        );

        let breakpointData = [
          ["Task", "weeks per month"],
          ["Completed", response?.data?.plan_session?.total_completed_session],
          [
            "In Progress",
            response?.data?.plan_session?.total_inprogress_session,
          ],
          ["Pending", response?.data?.plan_session?.total_pending_session],
        ];

        let total = 0;
        breakpointData.slice(1).forEach(([_, value]) => {
          total += value || 0;
        });

        setBeneficiaryMonitoringHumanResourceChart({
          series: [
            {
              name: "Employee",
              data: hrData,
            },
          ],
          options: {
            colors: ["#510DBC"],
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              type: "datetime",
              title: {
                text: `${t("date_time")}`, // Label for the y-axis
              },
              zoom: {
                enabled: true,
                autoScaleYaxis: true,
                zoomedArea: {
                  fill: {
                    color: "#90CAF9",
                    opacity: 0.4,
                  },
                  stroke: {
                    color: "#0D47A1",
                    opacity: 0.7,
                    width: 1,
                  },
                },
              },

              categories: hrDateData,
            },
            yaxis: {
              title: {
                text: "No. of Employees", // Label for the y-axis
              },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        });

        setBeneficiaryMonitoringFinanceChart({
          series: [
            {
              name: "Sales",
              data: financeSalesData,
            },
            {
              name: "Profit",
              data: financeProfitData,
            },
          ],
          options: {
            colors: ["#510DBC", "#098E7E"],
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              type: "datetime",
              title: {
                text: `${t("date_time")}`, // Label for the y-axis
              },
              zoom: {
                enabled: true,
                autoScaleYaxis: true,
                zoomedArea: {
                  fill: {
                    color: "#90CAF9",
                    opacity: 0.4,
                  },
                  stroke: {
                    color: "#0D47A1",
                    opacity: 0.7,
                    width: 1,
                  },
                },
              },

              categories: financeDate,
            },
            yaxis: {
              title: {
                text: `${t("amount")}`, // Label for the y-axis
              },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        });

        // Convert values to percentages
        const dataWithPercentage = breakpointData.map(
          ([task, value], index) => {
            if (index === 0) return [task, value]; // Keep the header row unchanged
            const percentage = total !== 0 ? ((value || 0) / total) * 100 : 0;
            return [task, percentage];
          }
        );

        // Update the breakpointData with percentages
        breakpointData = dataWithPercentage;
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const getBeneficiaryMonitoring = (apiUrl) => {
    setIsPageLoading(true);
    handleFetchRequest(apiUrl).then((response) => {
      if (response?.status === true) {
        setBeneficiaryMonitoringData(response.data);
        setIsPageLoading(false);
        let breakpointData = [
          ["Task", "weeks per month"],
          [
            "Completed",
            response?.data?.session_breakups?.total_completed_session,
          ],
          [
            "In Progress",
            response?.data?.session_breakups?.total_inprogress_session,
          ],
          ["Pending", response?.data?.session_breakups?.total_pending_session],
        ];

        let total = 0;
        breakpointData.slice(1).forEach(([_, value]) => {
          total += value || 0;
        });

        // Convert values to percentages
        const dataWithPercentage = breakpointData.map(
          ([task, value], index) => {
            if (index === 0) return [task, value]; // Keep the header row unchanged
            const percentage = total !== 0 ? ((value || 0) / total) * 100 : 0;
            return [task, percentage];
          }
        );

        // Update the breakpointData with percentages
        breakpointData = dataWithPercentage;

        setBeneficiarySessionBreakupChartData({
          data: breakpointData,
          options: {
            pieHole: 0.3,
            is3D: false,
            // legend: false,
            legend: { position: "none" },
            colors: ["#098E7E", "#00B8F5", "#AB0D82"],
            chartArea: { width: 250, height: 250 },
          },
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const getBeneficiaryMonitoringCoachingList = () => {
    setIsLoading(true);
    handleFetchRequest(
      `${apiUrl?.get_beneficiary_monitoring_coaching_list}`
    ).then((response) => {
      if (response?.status === true) {
        setBeneficiaryMonitoringCoachingListData(response?.data);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  function capitalizeFirstLetter(str) {
    if (str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    }
  }

  // function related to Subvention monitoring starts here
  const [originalCoachListData, setOriginalCoachListData] = useState([]);
  const [originalBeneficiaryListData, setOriginalBeneficiaryListData] =
    useState([]);
  const [coachListData, setCoachListData] = useState([]);
  const [beneficiaryListData, setBeneficiaryListData] = useState([]);
  const [subventionInputTypeData, setSubventionInputTypeData] =
    useState("select");
  const [subventionSearchOptionData, setSubventionSearchOptionData] =
    useState();
  const subventionSearchOption1Array = [
    { name: "Search By", value: "", type: "select" },
    { name: "Coach", value: "coach_name", type: "select" },
    { name: "Province", value: "province", type: "text" },
  ];
  const [subventionInputCategoryData, setSubventionInputCategoryData] =
    useState("");

  // const subventionSearchOptionStatusArray = [...coachListData];
  // const subventionSearchOption2Array = coachListData?.filter((data) => data?.type === subventionSearchOptionData);

  const [subventionFilterData, setSubventionFilterData] = useState();

  useEffect(() => {
    const subventionSearchOption2Array = originalCoachListData?.filter(
      (data) => data?.type === subventionSearchOptionData
    );
    setCoachListData(subventionSearchOption2Array);
  }, [subventionSearchOptionData]);

  useEffect(() => {
    if (selectBeneficiaryStatus !== "") {
      getBeneficiaryMonitoringCoachingListByStatus(currentCoachingListPage);
    }
  }, [selectBeneficiaryStatus]);

  const ResetSubventionAccountHandler = () => {
    setSubventionInputTypeData("select");
    setSubventionSearchOptionData("");
    setSubventionInputCategoryData("");
    setSubventionFilterData("");
    getSubventionMonitoring(
      `${apiUrl?.subvention_monitoring}?fromDate=${moment(
        startDate?.from
      ).format("YYYY-MM-DD")}&toDate=${moment(startDate?.to).format(
        "YYYY-MM-DD"
      )}`
    );
  };
  // function related to Subvention monitoring ends here

  // functions related to coach monitoring starts here

  // const [coachFilterData, setCoachFilterData] = useState();

  // functions related to coach monitoring ends here
  const getcoachList = () => {
    handleFetchRequest(
      // "all-coach-as-assignment?username=vik"
      `${apiUrl?.get_all_coach}`
    ).then((response) => {
      if (response?.status === true) {
        response?.data?.map((data) => {
          setOriginalCoachListData((prevState) => [
            ...prevState,
            {
              name: `${capitalizeFirstLetter(
                data?.first_name
              )} ${capitalizeFirstLetter(data?.last_name)}`,
              username: data?.username,
              value: data?.alias_id,
              type: "coach_name",
              id: data?.id,
            },
          ]);
          setCoachListData((prevState) => [
            ...prevState,
            {
              name: `${capitalizeFirstLetter(
                data?.first_name
              )} ${capitalizeFirstLetter(data?.last_name)}`,
              username: data?.username,
              value: data?.alias_id,
              type: "coach_name",
              id: data?.id,
            },
          ]);
        });
        // setCoachListData();
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };
  console.log(userId, "userId");
  const getBeneficiaryList = () => {
    let apiUrlWithParams = apiUrl?.beneficiary_list;
    if (coachMonitoringSearchTerm?.id) {
      apiUrlWithParams += `?coach_id=${coachMonitoringSearchTerm.id || userId}`;
    }
    if (Roles == RoleType?.coach_value) {
      apiUrlWithParams += `?coach_id=${userId}`;
    }

    handleFetchRequest(apiUrlWithParams).then((response) => {
      if (response?.status === true) {
        const beneficiaryData = response.data.map((data) => ({
          name: `${capitalizeFirstLetter(data.fullname)} `,
          username: data.username,
          value: data.alias_id,
          type: "coach_name",
          id: data.id,
        }));
        setBeneficiaryListData(beneficiaryData);
        setOriginalBeneficiaryListData(beneficiaryData);
      } else if (response?.messageCode === 3) {
        // Handle session expiry
      } else {
        setIsPageLoading(false);
      }
    });
  };
  const handleInputCoachChange = (e, setState) => {
    const term = e.target.value;
    let arr = originalCoachListData.filter((ele) =>
      ele.username.toLowerCase().includes(term.toLowerCase())
    );

    setCoachListData(arr);
    setState({ name: term });
  };
  const handleInputBeneficiaryChange = (e, setState) => {
    const term = e.target.value;
    const filteredBeneficiaries = originalBeneficiaryListData.filter((ele) =>
      ele.name.toLowerCase().includes(term?.toLowerCase())
    );
    setState({ name: term });
    setBeneficiaryListData(filteredBeneficiaries);
  };

  const handleInputCoachFocus = () => {
    setIsDropdownOpen(true);
  };
  const handleInputBeneficiaryFocus = () => {
    setIsBeneficiaryDropdownOpen(true);
  };

  const handleInputBeneficiaryBlur = () => {
    setTimeout(() => {
      setIsBeneficiaryDropdownOpen(false);
    }, 300);
  };

  const handleCoachSelect = (coach, setState) => {
    setState({ name: `${coach?.username}`, id: coach?.id });
    // setCoachSearchTermId();
    setIsDropdownOpen(false);
    setBeneficiarySearchTerm({
      name: "",
      id: "",
    });
    // setFilteredCountries2([]);
  };
  const handleBeneficarySelect = (coach, setState) => {
    console.log(coach, "coach");
    setState({ name: `${coach?.name}`, id: coach?.id });
    setIsDropdownOpen(false);
  };

  const handleInputCoachBlur = () => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 300);
  };

  const handleChartSelect = (Chart) => {
    const chartWrapper = Chart.chartWrapper;
    if (chartWrapper) {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();

      if (selection.length === 1) {
        const selectedItem = selection[0];
        console.log("Selected Item:", selectedItem);

        // Check if selectedItem has a valid row
        if (selectedItem.row !== null && selectedItem.row >= 0) {
          const dataTable = chartWrapper.getDataTable();
          console.log("Data Table:", dataTable);

          // Assuming a simple chart structure, get the value from the first column
          const value = dataTable.getValue(selectedItem.row, 0);
          console.log("Selected Value:", value);
          setSelectBeneficiaryStatus(value);
          setCurrentCoachingListPage(1);
        } else {
          console.log(null);
        }
      } else {
        console.log(null);
      }
    }
  };

  const handleChartSelect2 = (Chart) => {
    const chartWrapper = Chart.chartWrapper;
    if (chartWrapper) {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();

      if (selection.length === 1) {
        const selectedItem = selection[0];
        console.log("Selected Item:", selectedItem);

        // Check if selectedItem has a valid row
        if (selectedItem.row !== null && selectedItem.row >= 0) {
          const dataTable = chartWrapper.getDataTable();
          console.log("Data Table:", dataTable);

          // Assuming a simple chart structure, get the value from the first column
          const value = dataTable.getValue(selectedItem.row, 0);
          console.log("Selected Value:", value);
          setSelectSessionsListStatus(value);
          setCurrentSessionsListPage(1);
        } else {
          console.log(null);
        }
      } else {
        console.log(null);
      }
    }
  };

  const chartEvents = [
    {
      eventName: "select",
      callback: handleChartSelect,
    },
  ];

  const chartEvents2 = [
    {
      eventName: "select",
      callback: handleChartSelect2,
    },
  ];

  const handleCoachingListPageChange = (pageitem) => {
    console.log("consle pageeeee", pageitem);
    setCurrentCoachingListPage(pageitem);
    setBeneficiaryMonitoringCoachingListData([]);
    getBeneficiaryMonitoringCoachingListByStatus(pageitem);
  };

  const handelResetCoachingList = () => {
    getBeneficiaryMonitoringCoachingList();
    setCurrentCoachingListPage(1);
    setAllCoachingListPage(1);
    setSelectBeneficiaryStatus("");
  };

  const getBeneficiaryMonitoringCoachingListByStatus = (pageitem) => {
    setIsLoading(true);

    let filters = [];
    const start = convertToEpoch(
      moment(startBeneficiaryMonitoringDate?.from).startOf("day")
    );
    const end = convertToEpoch(
      ConvertInputTimeToSetting(
        moment(startBeneficiaryMonitoringDate?.to).endOf("day")
      )
    );

    if (start) {
      filters.push(`schedule_start_ts=${start}`);
    }
    if (end) {
      filters.push(`schedule_end_ts=${end}`);
    }
    if (beneficiarySectorData) {
      filters.push(`sector=${beneficiarySectorData}`);
    }
    if (beneficiaryMonitoringProvinceData) {
      filters.push(`province=${beneficiaryMonitoringProvinceData}`);
    }

    handleFetchRequest(
      `${
        apiUrl?.get_session_list
      }?page_no=${pageitem}&page_size=10&status=${selectBeneficiaryStatus.toLowerCase()}&${filters?.join(
        "&"
      )}`
    ).then((response) => {
      if (response.status === true) {
        setBeneficiaryMonitoringCoachingListData(response.data);
        setAllCoachingListPage(response?.pageInfo);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  function formatAmount(value) {
    let sign;

    if (currencyType == "usd") {
      sign = "$";
    } else {
      sign = "CDF";
    }
    let amount = CurrencyConverter2(+value);

    if (amount < 1000) {
      return `${sign} ${parseFloat(amount).toFixed(2)}`; // Keep two decimal places for amounts less than 1000
    } else if (amount < 1000000) {
      return `${sign} ${(amount / 1000).toFixed(2)}K`; // Convert to thousands
    } else if (amount < 1000000000) {
      return `${sign} ${(amount / 1000000).toFixed(2)}M`; // Convert to millions
    } else {
      return `${sign} ${(amount / 1000000000).toFixed(2)}B`; // Convert to billions
    }
  }

  const handleEventClick = (event) => {
    console.log("event", event);
    navigate(RoutesLink.beneficiary_profile, {
      state: {
        id: event?.event?.beneficiary_id,
        tabpane: "7",
      },
    });
  };

  const renderEvent = (event) => {
    if (Views.AGENDA === eventData || Views.DAY === eventData) {
      return (
        <div onClick={() => handleEventClick(event)}>
          <div>{`${event?.event?.id} (${event?.event?.beneficiary_name})`}</div>
        </div>
      );
    } else {
      return <div onClick={() => handleEventClick(event)}>{event.title}</div>;
    }
  };

  const filters = [];
  const applyFilter = () => {
    const filterValue = benSessionFilterData
      ? `&${benSessionSearchOptionData}=${benSessionFilterData}`
      : "";
    filters.push(filterValue);
  };

  const benSessionList = (link) => {
    // applyFilter();
    handleFetchRequest(link).then((response) => {
      if (response.status === true) {
        setBenSessionListData(response.data);
        // setBeneficiaryMonitoringCoachingListData(response.data);
        // setAllCoachingListPage(response?.pageInfo);
        // setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  const coachingDownloadReport = () => {
    const start = startCoachMonitoringDate?.from
      ? convertToEpoch(moment(startCoachMonitoringDate?.from)?.startOf("day"))
      : "";
    const end = startCoachMonitoringDate?.to
      ? convertToEpoch(moment(startCoachMonitoringDate?.to).endOf("day"))
      : "";

    console.log("================================", start, end);

    const filters = [];
    if (start) {
      filters.push(`schedule_start_ts=${start}`);
    }
    if (end) {
      filters.push(`schedule_end_ts=${end}`);
    }
    if (CoachingSectorData) {
      filters.push(`sector=${CoachingSectorData}`);
    }
    if (coachMonitoringProvinceData) {
      filters.push(`province=${coachMonitoringProvinceData}`);
    }
    if (coachMonitoringSearchTerm?.id) {
      filters.push(`coach_id=${coachMonitoringSearchTerm?.id}`);
    }
    if (!end) {
      toast.error("Please Select Both Dates");
    } else {
      setPageIsLoading(true);
      DownloadReportsHandler(
        "Coaching Monitoring",
        `${apiUrl?.get_report}?${filters.join("&")}&${
          Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
        }`,
        setPageIsLoading
      ).then((response) => {
        if (response?.status === true) {
          toast.success(response?.message);
          // setPageIsLoading(false);
        } else {
          // setPageIsLoading(false);
          toast.error(response?.message);
        }
      });
    }
  };
  const beneficiaryDownloadReport = () => {
    const start = startBeneficiaryMonitoringDate?.from
      ? convertToEpoch(
          moment(startBeneficiaryMonitoringDate?.from)?.startOf("day")
        )
      : "";
    const end = startBeneficiaryMonitoringDate?.to
      ? convertToEpoch(moment(startBeneficiaryMonitoringDate?.to)?.endOf("day"))
      : "";

    const filters = [];
    if (start) {
      filters.push(`schedule_start_ts=${start}`);
    }
    if (end) {
      filters.push(`schedule_end_ts=${end}`);
    }
    if (beneficiarySectorData) {
      filters.push(`sector=${beneficiarySectorData}`);
    }
    if (beneficiaryMonitoringProvinceData) {
      filters.push(`province=${beneficiaryMonitoringProvinceData}`);
    }

    if (coachMonitoringSearchTerm?.id) {
      filters.push(`coach_id=${coachMonitoringSearchTerm?.id}`);
    }
    if (beneficiarySearchTerm?.id) {
      filters.push(`beneficiary_id=${beneficiarySearchTerm?.id}`);
    }
    if (!end) {
      toast.error("Please Select Both Dates");
    } else {
      setPageIsLoading(true);
      DownloadReportsHandler(
        "Beneficiary Monitoring",
        `${apiUrl?.get_report}?${filters.join("&")}&${
          Roles == RoleType?.coach_value ? `coach_id=${userId}` : ""
        }`,
        setPageIsLoading
      ).then((response) => {
        if (response?.status === true) {
          toast.success(response?.message);
          // setPageIsLoading(false);
        } else {
          // setPageIsLoading(false);
          toast.error(response?.message);
        }
      });
    }
  };
  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-12 px-0 px-md-3 py-0 mb-4">
            <div className="dashboard-top-card">
              <Nav className="nav nav-tabs tabHolder mb-2 mb-md-0">
                {/* <NavItem className="nav-item">
              <NavLink

                aria-current="page"
                className={tabOpen === "1" ? "nav-link d-flex flex-column justify-content-center align-items-center active" : "nav-link d-flex flex-column justify-content-center align-items-center"}
                onClick={() => setTabValue('1')}

              >
                <div className="py-2"><img src="assets/image/Group-1.png" alt="img" /></div>
                <div className="text-center font-weight-bold">Beneficiary Monitoring</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                className={tabOpen === "2" ? " d-flex flex-column justify-content-center align-items-center active" : "nav-link d-flex flex-column justify-content-center align-items-center"}
                onClick={() => setTabValue('2')}

              >
                <div className="py-2"><img src="assets/image/Group-3.png" alt="img" /></div>
                <div>Coach Monitoring</div>
              </NavLink>

            </NavItem>
            <NavItem className="nav-item">
              <NavLink

                aria-current="page"

                className={tabOpen === "3" ? "nav-link d-flex flex-column justify-content-center align-items-center active" : "nav-link d-flex flex-column justify-content-center align-items-center"}
                onClick={() => setTabValue('3')}
              >
                <div className="py-2"><img src="assets/image/Group-2.png" alt="img" /></div>
                <div>Coaching Monitoring</div>
              </NavLink>
            </NavItem> */}
                <NavItem className="nav-item">
                  <NavLink
                    aria-current="page"
                    className={
                      tabOpen === "1"
                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                    }
                    onClick={() => setTabValue("1")}
                  >
                    {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                    <div>{t("subvention_monitoring")}</div>
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink
                    aria-current="page"
                    className={
                      tabOpen === "2"
                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                    }
                    onClick={() => setTabValue("2")}
                  >
                    {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                    <div>{t("coaching_monitoring")}</div>
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                  <NavLink
                    aria-current="page"
                    className={
                      tabOpen === "3"
                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                    }
                    onClick={() => setTabValue("3")}
                  >
                    {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                    <div>{t("beneficiary_monitoring")}</div>
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="absolute-border"></div>
            </div>

            <div className="row">
              <TabContent activeTab={tabOpen}>
                <TabPane tabId={"1"}>
                  <div className="col-lg-12 grid-margin">
                    <div className="row mt-3 mt-sm-0">
                      <div className="desktfcol">
                        <div className="revdea">
                          {RoleType?.admin_value == Roles ||
                          RoleType?.viewer_value == Roles ? (
                            <div className="ndcol">
                              {/* <label className="input-label ps-3">
                          Coach
                        </label> */}
                              <div style={{ position: "relative" }}>
                                <input
                                  className="dashboard-input form-control tabel-input px-2 bg-white"
                                  placeholder={t("coach_name")}
                                  value={coachSearchTerm?.name}
                                  onChange={(e) =>
                                    handleInputCoachChange(
                                      e,
                                      setCoachSearchTerm
                                    )
                                  }
                                  // onClick={handleClickHandler2}
                                  onFocus={handleInputCoachFocus}
                                  onBlur={handleInputCoachBlur}
                                  id="sub_coach"
                                />
                                {isDropdownOpen && (
                                  <ul
                                    className={
                                      isDropdownOpen
                                        ? "dropdown-menu countrydrop d-block"
                                        : "dropdown-menu"
                                    }
                                  >
                                    {coachListData.map((coach, i) => (
                                      <li
                                        key={i}
                                        onClick={() =>
                                          handleCoachSelect(
                                            coach,
                                            setCoachSearchTerm
                                          )
                                        }
                                      >
                                        {/* {console.log("coachhh  dropdownnn.... ", coach)} */}
                                        {coach.username}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                                <label
                                  for="sub_coach"
                                  className="dashboard-chevron"
                                >
                                  <i className="mdi mdi-chevron-down"></i>
                                </label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="ndcol">
                            {/* <label className="input-label ps-3">
                          Province
                        </label> */}
                            <input
                              className="dashboard-input form-control tabel-input px-2 bg-white"
                              placeholder={t("province")}
                              onChange={(e) =>
                                setProvinceData(e?.target?.value)
                              }
                            />
                          </div>
                          <div className="ndcol position-relative">
                            <select
                              className="custom-select-box  px-1 bg-white"
                              // className="dashboard-input form-control tabel-input"
                              onChange={(e) => setSectorData(e.target.value)}
                              // id="subsector11"
                            >
                              <option value="">{t("sector")}</option>
                              <option value="agribusiness">
                                Agri Business
                              </option>
                              <option value="manufacturing">
                                manufacturing
                              </option>
                              <option value="services">services</option>
                            </select>
                            {/* <label for="subsector11" class="dashboard-chevron">
                          <i class="mdi mdi-chevron-down"></i>
                        </label> */}
                            {/* <div style={{ position: "relative" }}>
                          <label className="input-label ps-3">
                            Sector
                          </label>

                         
                          <input
                            className="dashboard-input form-control tabel-input" placeholder="Sector" onChange={(e) => setSectorData(e?.target?.value)} />
                        </div> */}
                          </div>
                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">
                            From Date:
                          </label> */}
                              <ReactDatePicker
                                dateFormat="dd/MM/yyyy"
                                onChange={(d) => selectDateHandler(d, "from")}
                                selected={startDate?.from}
                                id="sub_from_date"
                                // style={{
                                //   borderBottom: " 2px solid  #726b7f",
                                //   borderRadius: " 0px",
                                //   backgroundColor: "white"
                                // }}
                                className="dashboard-input form-control tabel-input  px-2 bg-white"
                                placeholderText={"From Date"}
                                // filterDate={isDateDisabled}
                                // defaultValue={profileData?.coach_profile?.dob}
                                // startDate={startOfPreviousMonth}
                                // endDate={new Date()}

                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log(
                                      "dateeeeeeee..... from ",
                                      date
                                    )}
                                    <button
                                      onClick={decreaseMonth}
                                      type="button"
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      {"<"}
                                    </button>
                                    <select
                                      value={date.getFullYear()}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      value={months[date?.getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button
                                      onClick={increaseMonth}
                                      type="button"
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      {">"}
                                    </button>
                                  </div>
                                )}
                                // startDate={startDate}
                                // endDate={endDate}
                              />
                              <label
                                for="sub_from_date"
                                className="dashboard-chevron"
                              >
                                <i className="mdi mdi-calendar"></i>
                              </label>
                            </div>
                          </div>

                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">
                            To Date:
                          </label> */}
                              <ReactDatePicker
                                dateFormat="dd/MM/yyyy"
                                onChange={(d) => selectDateHandler(d, "to")}
                                selected={startDate?.to}
                                className="dashboard-input form-control tabel-input px-2"
                                placeholderText={"To Date"}
                                id="sub_to_date"
                                minDate={startDate?.from}
                                // filterDate={isDateDisabled}
                                // startDate={startOfPreviousMonth}
                                // endDate={new Date()}
                                // defaultValue={profileData?.coach_profile?.dob}

                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log(
                                      "dateeeeeeee..... from ",
                                      date
                                    )}
                                    <button
                                      onClick={decreaseMonth}
                                      type="button"
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      {"<"}
                                    </button>
                                    <select
                                      className=" px-2 bg-white"
                                      value={date.getFullYear()}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      value={months[date?.getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button
                                      onClick={increaseMonth}
                                      type="button"
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      {">"}
                                    </button>
                                  </div>
                                )}
                              />
                              <label
                                for="sub_to_date"
                                className="dashboard-chevron"
                              >
                                <i className="mdi mdi-calendar"></i>
                              </label>
                            </div>
                          </div>
                          <div className="ndcolbtn">
                            <button
                              className="btn btn-primary w-100"
                              onClick={subventionFilterSubmitHandler}
                            >
                              {t("submit")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12  px-0">
                        <div
                          style={{
                            borderRadius: "20px",
                          }}
                        >
                          <div className="dash-maintp">
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "rgb(123, 97, 196)",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {
                                        subventionMonitoringData?.ben_with_subvention
                                      }{" "}
                                      /
                                      {
                                        subventionMonitoringData?.total_beneficiary
                                      }
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("Beneficiaries")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>
                                  {t("with_subvention")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "rgb(123, 97, 196)",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {
                                        subventionMonitoringData?.beneficiary
                                          ?.totalBeneficiariesWithGrant
                                      }{" "}
                                      /
                                      {
                                        subventionMonitoringData?.total_beneficiary
                                      }
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("Beneficiaries")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>
                                  {t("with_grant")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "rgb(123, 97, 196)",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {subventionMonitoringData?.beneficiary
                                        ?.totalGrantAmount
                                        ? formatAmount(
                                            subventionMonitoringData
                                              ?.beneficiary?.totalGrantAmount
                                          )
                                        : `${
                                            currencyType == "usd" ? "$" : "CDF"
                                          }0`}
                                    </h3>
                                  </div>
                                </div>
                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("amount_granted")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>
                                  {t("total")}
                                </strong>
                              </div>
                            </div>

                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "rgb(123, 97, 196)",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {subventionMonitoringData?.subvention
                                        ?.subvention_total_amount
                                        ? formatAmount(
                                            subventionMonitoringData?.subvention
                                              ?.subvention_total_amount
                                          )
                                        : `${
                                            currencyType === "usd" ? "$" : "CDF"
                                          }0`}
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("subvention")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>
                                  {t("amount")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "rgb(123, 97, 196)",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {subventionMonitoringData?.subvention
                                        ?.total_subventions
                                        ? subventionMonitoringData?.subvention
                                            ?.total_subventions
                                        : "0"}
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("subventions")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>#</strong>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{ background: "white" }}
                            className="mt-3 card"
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-xl-6 px-0 stretch-card mb-3 mb-xl-0">
                                  <div className="card bg-white  shadow-sm">
                                    <h1 className="card-title p-3 font-weight-bold dash-title">
                                      {t("financial_monitoring")}
                                    </h1>
                                    <div id="chart">
                                      {/* <ApexCharts
                                      options={options}
                                      series={series}
                                      type="line"
                                      height={350}
                                    /> */}

                                      {/* <Chart options={chartData.options} series={chartData.series} type={"line"} width="500" height={350} /> */}
                                    </div>
                                    <App
                                      chartData={subventionFinanceChartData}
                                    />
                                    {/* <Chart options={chartData.options} series={chartData.series} type="line" width="500" /> */}
                                    {/* <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}
                                  /> */}
                                    {/* <canvas id="lineChart" style={{height:"250px", width:"400px"}}></canvas> */}
                                  </div>
                                </div>
                                <div className="col-xl-6 px-0 stretch-card">
                                  <div className="card bg-white  shadow-sm ">
                                    <h1 className="card-title p-3 font-weight-bold dash-title">
                                      # {t("monitoring")}{" "}
                                    </h1>
                                    <div id="chart">
                                      {/* <ApexCharts
                                      options={options}
                                      series={series}
                                      type="line"
                                      height={350}
                                    /> */}

                                      {/* <Chart options={chartData.options} series={chartData.series} type={"line"} width="500" height={350} /> */}
                                    </div>
                                    <App chartData={subventionChartData} />
                                    {/* <Chart options={chartData.options} series={chartData.series} type="line" width="500" /> */}
                                    {/* <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}
                                  /> */}
                                    {/* <canvas id="lineChart" style={{height:"250px", width:"400px"}}></canvas> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ background: "white" }}
                            className="mt-3 card"
                          >
                            <div className="card-body">
                              <div className="d-flex justify-content-between align-items-center">
                                <h3
                                  style={{ fontSize: "20px", fontWeight: 600 }}
                                >
                                  {t("recent_subventions")}
                                </h3>
                                {/* <Heading name={"Subventions (Recent 20)"} /> */}
                                {/* <Heading name={""} /> */}
                                <Link
                                  to={RoutesLink?.subventions}
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    color: "#7b61c4",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {t("see_all")}
                                </Link>
                              </div>
                              <PrimaryTable
                                listData={subventionList}
                                listHeading={listHeading}
                                subvention={true}
                                // addData={addHeadingAction}
                                // activePage={activePage}
                                // pages={pages}
                                // handlePageChange={handlePageChange}
                                isLoading={isLoading}
                                idClickLink={RoutesLink?.subvention_detail}
                                subventionMonitoring={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={"2"}>
                  <div className="col-lg-12 grid-margin">
                    <div className="row mt-3 mt-sm-0">
                      <div className="desktfcol">
                        <div
                          className={`revdea ${
                            Roles === RoleType?.coach_value
                              ? "coach_custom"
                              : "custom"
                          }`}
                        >
                          {RoleType?.admin_value == Roles ||
                          RoleType?.viewer_value == Roles ? (
                            <div className="ndcol">
                              {/* <label className="input-label ps-3">
                          Coach
                        </label> */}
                              <div style={{ position: "relative" }}>
                                <input
                                  className="dashboard-input form-control tabel-input px-2 bg-white"
                                  placeholder={t("coach_name")}
                                  value={coachMonitoringSearchTerm?.name}
                                  onChange={(e) =>
                                    handleInputCoachChange(
                                      e,
                                      setCoachMonitoringSearchTerm
                                    )
                                  }
                                  // onClick={handleClickHandler2}
                                  id="coach_coach_name"
                                  onFocus={handleInputCoachFocus}
                                  onBlur={handleInputCoachBlur}
                                />
                                {isDropdownOpen && (
                                  <ul
                                    className={
                                      isDropdownOpen
                                        ? "dropdown-menu countrydrop d-block"
                                        : "dropdown-menu"
                                    }
                                  >
                                    {coachListData.map((coach, i) => (
                                      <li
                                        key={i}
                                        onClick={() =>
                                          handleCoachSelect(
                                            coach,
                                            setCoachMonitoringSearchTerm
                                          )
                                        }
                                      >
                                        {/* {console.log("coachhh  dropdownnn.... ", coach)} */}
                                        {coach.username}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                                <label
                                  for="coach_coach_name"
                                  className="dashboard-chevron"
                                >
                                  <i className="mdi mdi-chevron-down"></i>
                                </label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="ndcol">
                            {/* 
                        <label className="input-label ps-3">
                          Province
                        </label> */}
                            <input
                              className="dashboard-input form-control tabel-input px-2 bg-white"
                              placeholder={t("province")}
                              onChange={(e) =>
                                setCoachMonitoringProvinceData(e?.target?.value)
                              }
                            />
                          </div>
                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">
                            Sector
                          </label> */}
                              <select
                                className="custom-select-box px-1 bg-white"
                                // className="dashboard-input form-control tabel-input"
                                onChange={(e) =>
                                  setCoachingSectorData(e.target.value)
                                }
                                id="coach_sector_name"
                              >
                                <option value="">{t("sector")}</option>
                                <option value="agribusiness">
                                  Agri Business
                                </option>
                                <option value="manufacturing">
                                  manufacturing
                                </option>
                                <option value="services">services</option>
                              </select>
                              {/* <label
                            for="coach_sector_name"
                            className="dashboard-chevron"
                          >
                            <i className="mdi mdi-chevron-down"></i>
                          </label> */}
                            </div>
                          </div>
                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">
                            Schedule Start Date:
                          </label> */}
                              <ReactDatePicker
                                dateFormat="dd/MM/yyyy"
                                id="coach_from_date"
                                onChange={(d) =>
                                  selectCoachDateHandler(d, "from")
                                }
                                selected={
                                  startCoachMonitoringDate?.from &&
                                  new Date(startCoachMonitoringDate?.from)
                                }
                                className="dashboard-input form-control tabel-input px-2 bg-white"
                                placeholderText={"From Date"}
                                // filterDate={isDateDisabled}
                                // defaultValue={profileData?.coach_profile?.dob}
                                // startDate={startOfPreviousMonth}
                                // endDate={new Date()}

                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log(
                                      "dateeeeeeee..... from ",
                                      date
                                    )}
                                    <button
                                      onClick={decreaseMonth}
                                      type="button"
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      {"<"}
                                    </button>
                                    <select
                                      value={date.getFullYear()}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      value={months[date?.getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button
                                      onClick={increaseMonth}
                                      type="button"
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      {">"}
                                    </button>
                                  </div>
                                )}
                              />
                              <label
                                for="coach_from_date"
                                className="dashboard-chevron"
                              >
                                <i className="mdi mdi-calendar"></i>
                              </label>
                            </div>
                          </div>
                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">
                            Schedule End Date:
                          </label> */}
                              <ReactDatePicker
                                id="coach_to_date"
                                dateFormat="dd/MM/yyyy"
                                minDate={startCoachMonitoringDate?.from}
                                onChange={(d) =>
                                  selectCoachDateHandler(d, "to")
                                }
                                selected={
                                  startCoachMonitoringDate?.to &&
                                  new Date(startCoachMonitoringDate?.to)
                                }
                                className="dashboard-input form-control tabel-input px-2 bg-white"
                                placeholderText={"To Date"}
                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log(
                                      "dateeeeeeee..... from ",
                                      date
                                    )}
                                    <button
                                      onClick={decreaseMonth}
                                      type="button"
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      {"<"}
                                    </button>
                                    <select
                                      value={date.getFullYear()}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      value={months[date?.getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button
                                      onClick={increaseMonth}
                                      type="button"
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      {">"}
                                    </button>
                                  </div>
                                )}
                              />
                              <label
                                for="coach_to_date"
                                className="dashboard-chevron"
                              >
                                <i className="mdi mdi-calendar"></i>
                              </label>
                            </div>
                          </div>
                          <div className="ndcol">
                            {" "}
                            <Tooltip title="Download Report">
                              <IconButton onClick={coachingDownloadReport}>
                                <span class="mdi mdi-download"></span>
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="ndcol">
                            <button
                              className="btn btn-primary w-100"
                              onClick={coachDateSubmitHandler}
                            >
                              {t("submit")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 px-0">
                        <div
                          style={{
                            borderRadius: "20px",
                          }}
                        >
                          <div className="dash-maintp">
                            {RoleType?.admin_value == Roles ||
                            RoleType?.viewer_value == Roles ? (
                              <div className="dashmtpa">
                                <div className="dash-card shadow-sm">
                                  <div className="d-flex justify-content-between align-items-start ">
                                    <div>
                                      <h3
                                        style={{
                                          color: "#7b61c4",
                                          fontSize: "25px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {coachMonitoringData?.total_coach_with_plan
                                          ? `${coachMonitoringData?.total_coach_with_plan} / ${coachMonitoringData?.total_coach}`
                                          : "N/A"}
                                      </h3>
                                    </div>
                                  </div>

                                  <h3
                                    className="text-black"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {t("coaches")}
                                  </h3>
                                  <strong style={{ color: "#3c3838" }}>
                                    {t("coaching")}
                                  </strong>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "#7b61c4",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {coachMonitoringData?.total_plan}
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_plans")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>
                                  {t("total")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        // color: "rgb(224 186 87)",
                                        color: "#AB0D82",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {
                                        coachMonitoringData
                                          ?.total_plan_with_status
                                          ?.total_pending_plan
                                      }
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_plans")}
                                </h3>
                                <strong style={{ color: "#AB0D82" }}>
                                  {t("pending")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "#00B8F5",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {
                                        coachMonitoringData
                                          ?.total_plan_with_status
                                          ?.total_inprogress_plan
                                      }
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_plans")}
                                </h3>
                                <strong
                                  style={{
                                    color: "#00B8F5",
                                  }}
                                >
                                  {t("in_progress")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "#098E7E",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {
                                        coachMonitoringData
                                          ?.total_plan_with_status
                                          ?.total_completed_plan
                                      }
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_plans")}
                                </h3>
                                <strong style={{ color: "#098E7E" }}>
                                  {t("completed")}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card px-0 mt-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xl-8 px-0 mb-3 mb-lg-0">
                              <div
                                style={{
                                  borderRadius: "20px",
                                }}
                              ></div>
                              {/* sub tab starts here */}
                              <h1 className="card-title dash-title">
                                {t("session_schedule")}
                              </h1>
                              <Nav className="nav nav-tabs tabHolder dash-tab px-2">
                                <NavItem className="nav-item">
                                  <NavLink
                                    aria-current="page"
                                    className={
                                      coachSubTab === "1"
                                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                                    }
                                    onClick={() => setCoachTabValue("1")}
                                  >
                                    <div>{t("chart_view")}</div>
                                  </NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                  <NavLink
                                    aria-current="page"
                                    className={
                                      coachSubTab === "2"
                                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                                    }
                                    onClick={() => setCoachTabValue("2")}
                                  >
                                    {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                                    <div>{t("calendar_view")}</div>
                                  </NavLink>
                                </NavItem>
                              </Nav>

                              {/* <div className="absolute-border"></div> */}

                              <TabContent activeTab={coachSubTab}>
                                <TabPane tabId={"1"}>
                                  {/* sub tab ends here */}
                                  <div className="row">
                                    <div className="col-12 px-0">
                                      <div className="card  shadow-sm bg-white">
                                        <div id="chart"></div>
                                        {/* <ReactApexChart/> */}
                                        <ReactApexChart
                                          options={
                                            sessionSchedulechartData.options
                                          }
                                          series={
                                            sessionSchedulechartData.series
                                          }
                                          type="bar"
                                          height={350}
                                        />
                                      </div>

                                      {/* <div className="col-lg-6 grid-margin stretch-card align-items-center">
                                  <div className="card bg-white rounded-3  mt-3">
                                    <div id="chart"></div>

                                    <ReactApexChart
                                  options={sessionBreakupChartData.options}
                                  series={sessionBreakupChartData.series}
                                  type="donut"
                                /> 
                                  </div>
                                </div> */}
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="2">
                                  <div className="row">
                                    {
                                      <div
                                        style={{ height: "600px" }}
                                        // className="custom-cal-container"
                                      >
                                        <Calendar
                                          tooltipAccessor={(event) =>
                                            `${event?.id} (${event.beneficiary_name})`
                                          }
                                          // popup={true}
                                          events={coachMonitoringSessionList}
                                          localizer={localize}
                                          // step={60}
                                          // onSelectEvent={event => alert(event)}
                                          views={allViews}
                                          defaultDate={new Date()}
                                          popup={false}
                                          // onSelectEvent={(event) => console.log("event Selectedddd", event)}
                                          // onNavigate={(event) => console.log("on navigateeee....", event)}
                                          onView={(event) =>
                                            setEventData(event)
                                          }
                                          eventPropGetter={(
                                            event,
                                            start,
                                            end,
                                            isSelected
                                          ) => {
                                            const isPastDate =
                                              event?.start < new Date();
                                            const style = {
                                              backgroundColor: isPastDate
                                                ? "yellow"
                                                : event.color,
                                              color: isPastDate && "black",
                                            };
                                            return { style };
                                          }}
                                          onSelectSlot={(slotInfo) =>
                                            console.log(
                                              "dfasdfslotttteee",
                                              slotInfo
                                            )
                                          }
                                          components={{
                                            event: renderEvent,
                                          }}
                                          startAccessor="start"
                                          endAccessor="end"
                                          // tooltipAccessor={() => <div><p>Static Text</p></div>}
                                          // tooltipAccessor={(event) => renderTooltip(event)}
                                          // onShowMore={(events, date) => this.setState({ showModal: true, events })}
                                        />
                                      </div>
                                    }

                                    {/* <div className="col-12">
                              <div className="card bg-white  dash-card ">
                                <h1 className="card-title dash-title">
                                  Sessions Schedule{" "}
                                </h1>

                                <div className="row row-gap-4 session-table-wrapper">
                                  <div className="col-6">
                                    <div className="card dash-card">
                                      <div className="d-flex justify-content-between align-items-start ">
                                        <div>
                                          <p className=" text-dark dash-title">
                                            Sessions <sapn>#1647</sapn>{" "}
                                          </p>
                                        </div>
                                        <div>
                                          <p className=" text-danger fw-bold">
                                            Pending
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-start align-items-center mt-3 gap-1 session-time">
                                        <sapn className=" fw-bold">
                                          24 Mar-3:00pm
                                        </sapn>
                                        -
                                        <sapn className="fw-bold ">
                                          24 Mar-4:00pm
                                        </sapn>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="card dash-card">
                                      <div className="d-flex justify-content-between align-items-start ">
                                        <div>
                                          <p className=" text-dark dash-title">
                                            Sessions <sapn>#1647</sapn>{" "}
                                          </p>
                                        </div>
                                        <div>
                                          <p className=" text-success fw-bold">
                                            Completed
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-start align-items-center mt-3 gap-1 session-time">
                                        <sapn className=" fw-bold">
                                          24 Mar-3:00pm
                                        </sapn>
                                        -
                                        <sapn className="fw-bold ">
                                          24 Mar-4:00pm
                                        </sapn>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="card dash-card">
                                      <div className="d-flex justify-content-between align-items-start ">
                                        <div>
                                          <p className=" text-dark dash-title">
                                            Sessions <sapn>#1647</sapn>{" "}
                                          </p>
                                        </div>
                                        <div>
                                          <p className=" text-warning fw-bold">
                                            In Progress
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-start align-items-center mt-3 gap-1 session-time">
                                        <sapn className=" fw-bold">
                                          24 Mar-3:00pm
                                        </sapn>
                                        -
                                        <sapn className="fw-bold ">
                                          24 Mar-4:00pm
                                        </sapn>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                                  </div>
                                </TabPane>
                              </TabContent>
                            </div>
                            <div className="col-xl-4 px-0">
                              <div
                                className="card  shadow-sm"
                                style={{
                                  height: "100%",
                                  borderRadius: "20px",
                                }}
                              >
                                <div className="d-flex flex-column gap-3">
                                  <h1 className="card-title p-3 dash-title">
                                    {t("coaching_plans")}
                                  </h1>
                                  <div>
                                    <div
                                      className="d-flex flex-column align-items-center justify-content-center  pb-3 pb-lg-0"
                                      style={{ marginTop: "10px" }}
                                    >
                                      {
                                        <Chart
                                          data={sessionBreakupChartData?.data}
                                          options={
                                            sessionBreakupChartData?.options
                                          }
                                          chartType="PieChart"
                                          chartEvents={chartEvents2}
                                          width={"280px"}
                                          height={"280px"}
                                        />
                                      }
                                      <div className="d-flex gap-2 px-2 chrtd my-3 justify-content-center">
                                        <p className="m-0">{t("pending")}</p>
                                        <p className="m-0">
                                          {t("in_progress")}
                                        </p>
                                        <p className="m-0">{t("completed")}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 style={{ fontSize: "20px", fontWeight: 600 }}>
                          {selectSessionsListStatus
                            ? `${selectSessionsListStatus} Coaching Plan`
                            : `${t("recent_coaching_plan")}`}
                        </h3>
                        {/* <Heading name={"Coaching Plans (Recent 20)"} /> */}

                        {selectSessionsListStatus !== "" ? (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: 500,
                              color: "#7b61c4",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => handelResetSessionList()}
                          >
                            {t("reset")}
                          </span>
                        ) : (
                          <Link
                            to={RoutesLink?.coach_plan_list}
                            style={{
                              fontSize: "18px",
                              fontWeight: 500,
                              color: "#7b61c4",
                              textDecoration: "underline",
                            }}
                          >
                            {t("see_all")}
                          </Link>
                        )}
                        {/* <Heading name={""} /> */}
                      </div>
                      <div
                        className="table-responsive"
                        style={{
                          border: "1px solid #EFEFEF",
                          borderRadius: "20px",
                        }}
                      >
                        {/* {<div className="d-flex justify-content-center"> No Data Found</div>} */}
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              {sessionListHeading?.map((item, index) => (
                                <th
                                  key={index}
                                  className={item?.classType}
                                  style={{
                                    maxWidth: "150px",
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {item?.data}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {sessionsList?.map((data) => (
                              <tr>
                                {/* checkbox Data start here */}
                                {
                                  <td>
                                    <Link
                                      to={RoutesLink?.coach_plan_detail}
                                      state={{
                                        data: data,
                                        tabpane: "2",
                                        back: "dashboard",
                                      }}
                                    >
                                      {data?.plan_id}
                                    </Link>
                                  </td>
                                }

                                {
                                  <td>
                                    {capitalizeFirstLetter(
                                      data?.beneficiary?.reference_id
                                    )}
                                  </td>
                                }
                                {<td>{data?.coach?.alias_id}</td>}
                                {
                                  <td>
                                    {data?.schedule_start_date
                                      ? moment(
                                          TimeStampConverter(
                                            data?.schedule_start_date
                                          )
                                        ).format("DD/MM/YYYY hh:mm a")
                                      : "N/A"}
                                  </td>
                                }
                                {
                                  <td>
                                    {data?.planing_start_ts
                                      ? moment(
                                          TimeStampConverter(
                                            data?.planing_start_ts
                                          )
                                        ).format("DD/MM/YYYY hh:mm a")
                                      : "N/A"}
                                  </td>
                                }
                                {
                                  <td>
                                    {data?.planing_end_ts
                                      ? moment(
                                          TimeStampConverter(
                                            data?.planing_end_ts
                                          )
                                        ).format("DD/MM/YYYY hh:mm a")
                                      : "N/A"}
                                  </td>
                                }
                                {
                                  <td>
                                    {data?.status === "completed" ? (
                                      <p
                                        style={{
                                          color: "#098E7E",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        {capitalizeFirstLetter(data?.status)}
                                      </p>
                                    ) : data?.status === "in progress" ? (
                                      <p
                                        style={{
                                          color: "#00B8F5",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        {capitalizeFirstLetter(data?.status)}
                                      </p>
                                    ) : data?.status === "pending" ? (
                                      <p
                                        style={{
                                          color: "#AB0D82",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        {capitalizeFirstLetter(data?.status)}
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          color: "Gray",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        {capitalizeFirstLetter(data?.status)}
                                      </p>
                                    )}
                                  </td>
                                }
                              </tr>
                            ))}

                            {isLoading && (
                              <TableLoader listHeading={listHeading} />
                            )}
                          </tbody>
                        </table>

                        {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>} */}
                        {!isLoading && sessionsList?.length === 0 ? (
                          <h4 style={{ textAlign: "center" }}>No Data Found</h4>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12 my-2 px-3 d-flex justify-content-between align-items-center">
                        <div>
                          {allSessionsListPage?.total ? (
                            <p>
                              <strong>{t("total_records")} :</strong>
                              {allSessionsListPage?.total}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                          <p>
                            <strong>{t("per_page_account")} : </strong>
                            {sessionsList?.length}
                          </p>
                        </div>

                        {allSessionsListPage?.totalPage > 1 && (
                          <Pagination
                            activePage={currentSessionsListPage}
                            itemsCountPerPage={10}
                            totalItemsCount={allSessionsListPage?.total}
                            disabledClass="paginationDisableCustom"
                            pageRangeDisplayed={5}
                            onChange={handleSessionListPageChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={"3"}>
                  <div className="col-lg-12 grid-margin">
                    <div className="row mt-3 mt-sm-0">
                      <div className="desktfcol cutom-grid">
                        <div
                          className={`revdea ${
                            Roles === RoleType?.coach_value
                              ? "coach-custom-beneficiary"
                              : "custom-beneficiary"
                          }`}
                        >
                          {RoleType?.admin_value == Roles ||
                          RoleType?.viewer_value == Roles ? (
                            <div className="ndcol">
                              <div style={{ position: "relative" }}>
                                <input
                                  className="dashboard-input form-control tabel-input px-2 bg-white"
                                  placeholder={t("coach_name")}
                                  value={coachMonitoringSearchTerm?.name}
                                  onChange={(e) =>
                                    handleInputCoachChange(
                                      e,
                                      setCoachMonitoringSearchTerm
                                    )
                                  }
                                  // onClick={handleClickHandler2}
                                  id="coach_coach_name"
                                  onFocus={handleInputCoachFocus}
                                  onBlur={handleInputCoachBlur}
                                />
                                {isDropdownOpen && (
                                  <ul
                                    className={
                                      isDropdownOpen
                                        ? "dropdown-menu countrydrop d-block"
                                        : "dropdown-menu"
                                    }
                                  >
                                    {coachListData.map((coach, i) => (
                                      <li
                                        key={i}
                                        onClick={() =>
                                          handleCoachSelect(
                                            coach,
                                            setCoachMonitoringSearchTerm
                                          )
                                        }
                                      >
                                        {/* {console.log("coachhh  dropdownnn.... ", coach)} */}
                                        {coach.username}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                                <label
                                  for="coach_coach_name"
                                  className="dashboard-chevron"
                                >
                                  <i className="mdi mdi-chevron-down"></i>
                                </label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* beneficary filter starts here  */}
                          <div style={{ position: "relative" }}>
                            <input
                              id="ben_sectorr"
                              className="dashboard-input form-control tabel-input  px-2 bg-white"
                              placeholder={t("beneficiary")}
                              value={beneficiarySearchTerm?.name}
                              onChange={(e) =>
                                handleInputBeneficiaryChange(
                                  e,
                                  setBeneficiarySearchTerm
                                )
                              }
                              onFocus={handleInputBeneficiaryFocus}
                              onBlur={handleInputBeneficiaryBlur}
                            />
                            {isBeneficiaryDropdownOpen && (
                              <ul
                                className={
                                  isBeneficiaryDropdownOpen
                                    ? "dropdown-menu countrydrop d-block"
                                    : "dropdown-menu"
                                }
                              >
                                {beneficiaryListData.map((coach, i) => (
                                  <li
                                    key={i}
                                    onClick={() =>
                                      handleBeneficarySelect(
                                        coach,
                                        setBeneficiarySearchTerm
                                      )
                                    }
                                  >
                                    {/* {console.log("coachhh  dropdownnn.... ", coach)} */}
                                    {coach.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                            <label
                              for="ben_sectorr"
                              className="dashboard-chevron"
                            >
                              <i className="mdi mdi-chevron-down"></i>
                            </label>
                          </div>
                          <div className="ndcol">
                            {/* <label className="input-label ps-3">Province</label> */}
                            <input
                              className="dashboard-input form-control tabel-input  px-2 bg-white"
                              placeholder={t("province")}
                              onChange={(e) =>
                                setbeneficiaryMonitoringProvinceData(
                                  e?.target?.value
                                )
                              }
                            />
                          </div>

                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">Sector</label> */}
                              <select
                                id="ben_sector_id"
                                className="custom-select-box  px-1 bg-white"
                                // className="dashboard-input form-control tabel-input"
                                onChange={(e) =>
                                  setBeneficiarySectorData(e.target.value)
                                }
                              >
                                <option value="">{t("sector")}</option>
                                <option value="agribusiness">
                                  Agri Business
                                </option>
                                <option value="manufacturing">
                                  manufacturing
                                </option>
                                <option value="services">services</option>
                              </select>
                              {/* <label
                            for="ben_sector_id"
                            className="dashboard-chevron"
                          >
                            <i className="mdi mdi-chevron-down"></i>
                          </label> */}
                            </div>
                          </div>

                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">
                            Schedule Start Date:
                          </label> */}
                              <ReactDatePicker
                                dateFormat="dd/MM/yyyy"
                                onChange={(d) =>
                                  selectBeneficiaryDateHandler(d, "from")
                                }
                                selected={startBeneficiaryMonitoringDate?.from}
                                className="dashboard-input form-control tabel-input  px-2 bg-white"
                                placeholderText={"From Date"}
                                // filterDate={isDateDisabled}
                                // defaultValue={profileData?.coach_profile?.dob}
                                // startBeneficiaryMonitoringDate={startOfPreviousMonth}
                                // endDate={new Date()}

                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log(
                                      "dateeeeeeee..... from ",
                                      date
                                    )}
                                    <button
                                      onClick={decreaseMonth}
                                      type="button"
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      {"<"}
                                    </button>
                                    <select
                                      value={date.getFullYear()}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      value={months[date?.getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button
                                      onClick={increaseMonth}
                                      type="button"
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      {">"}
                                    </button>
                                  </div>
                                )}
                                id="bendatepicker1"
                              />
                              <label
                                for="bendatepicker1"
                                className="dashboard-chevron"
                              >
                                <i className="mdi mdi-calendar"></i>
                              </label>
                            </div>
                          </div>
                          <div className="ndcol">
                            <div style={{ position: "relative" }}>
                              {/* <label className="input-label ps-3">
                            Schedule End Date:
                          </label> */}
                              <ReactDatePicker
                                dateFormat="dd/MM/yyyy"
                                id="bendatepicker2"
                                minDate={startBeneficiaryMonitoringDate?.from}
                                onChange={(d) =>
                                  selectBeneficiaryDateHandler(d, "to")
                                }
                                selected={
                                  startBeneficiaryMonitoringDate?.to &&
                                  new Date(startBeneficiaryMonitoringDate?.to)
                                }
                                className="dashboard-input form-control tabel-input px-2 bg-white"
                                placeholderText={"To Date"}
                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log(
                                      "dateeeeeeee..... from ",
                                      date
                                    )}
                                    <button
                                      onClick={decreaseMonth}
                                      type="button"
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      {"<"}
                                    </button>
                                    <select
                                      value={date.getFullYear()}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      value={months[date?.getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button
                                      onClick={increaseMonth}
                                      type="button"
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      {">"}
                                    </button>
                                  </div>
                                )}
                              />
                              <label
                                for="bendatepicker2"
                                className="dashboard-chevron"
                              >
                                <i className="mdi mdi-calendar"></i>
                              </label>
                            </div>
                          </div>
                          <div className="ndcol">
                            {" "}
                            <Tooltip title="Download Report">
                              <IconButton onClick={beneficiaryDownloadReport}>
                                <span class="mdi mdi-download"></span>
                              </IconButton>
                            </Tooltip>
                          </div>

                          <div className="ndcol">
                            <button
                              className="btn btn-primary w-100"
                              onClick={beneficiaryDateSubmitHandler}
                            >
                              {t("submit")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 px-0">
                        <div
                          style={{
                            borderRadius: "20px",
                          }}
                        >
                          <div className="dash-maintp">
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div className="custom-flex">
                                    <h3
                                      style={{
                                        color: "#7b61c4",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {
                                        beneficiaryMonitoringData?.beneficiary
                                          ?.under_plan
                                      }{" "}
                                      /{" "}
                                      {
                                        beneficiaryMonitoringData?.beneficiary
                                          ?.total_assigned_beneficiary
                                      }
                                    </h3>
                                    <h4>
                                      {" "}
                                      {
                                        beneficiaryMonitoringData?.beneficiary
                                          ?.ratio
                                      }
                                    </h4>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("Beneficiaries")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>
                                  {t("coaching")}
                                </strong>
                              </div>
                            </div>

                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "#7b61c4",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {beneficiaryMonitoringData
                                        ?.session_breakups?.total_session
                                        ? beneficiaryMonitoringData
                                            ?.session_breakups?.total_session
                                        : "0"}
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_sessions")}
                                </h3>
                                <strong style={{ color: "#3c3838" }}>
                                  {t("total")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "#AB0D82",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {beneficiaryMonitoringData
                                        ?.session_breakups
                                        ?.total_pending_session
                                        ? beneficiaryMonitoringData
                                            ?.session_breakups
                                            ?.total_pending_session
                                        : "0"}
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_sessions")}
                                </h3>
                                <strong style={{ color: "#AB0D82" }}>
                                  {t("pending")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "#00B8F5",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {beneficiaryMonitoringData
                                        ?.session_breakups
                                        ?.total_inprogress_session
                                        ? beneficiaryMonitoringData
                                            ?.session_breakups
                                            ?.total_inprogress_session
                                        : "0"}
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_sessions")}
                                </h3>
                                <strong style={{ color: "#00B8F5" }}>
                                  {t("in_progress")}
                                </strong>
                              </div>
                            </div>
                            <div className="dashmtpa">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <h3
                                      style={{
                                        color: "#098E7E",
                                        fontSize: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {beneficiaryMonitoringData
                                        ?.session_breakups
                                        ?.total_completed_session
                                        ? beneficiaryMonitoringData
                                            ?.session_breakups
                                            ?.total_completed_session
                                        : "0"}
                                    </h3>
                                  </div>
                                </div>

                                <h3
                                  className="text-black "
                                  style={{ fontWeight: "600" }}
                                >
                                  {t("coaching_sessions")}
                                </h3>
                                <strong style={{ color: "#098E7E" }}>
                                  {t("completed")}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 px-0 mt-3">
                        <div
                          style={{
                            borderRadius: "20px",
                          }}
                        >
                          <div className="dashnps">
                            <div className="dashbtm">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <div>
                                      <h3
                                        style={{
                                          color: "rgb(123, 97, 196)",
                                          fontSize: "25px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {beneficiaryMonitoringData
                                          ?.human_resources?.total_hr
                                          ? beneficiaryMonitoringData
                                              ?.human_resources?.total_hr
                                          : "0"}
                                      </h3>
                                    </div>
                                    <div>
                                      <h3 className="text-gray pur dash-title">
                                        {t("human_resources")}
                                      </h3>
                                    </div>
                                  </div>
                                  <h3
                                    className="font-weight-bold"
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      color: "green",
                                    }}
                                  >
                                    {beneficiaryMonitoringData?.human_resources
                                      ?.hr_percentage
                                      ? `${beneficiaryMonitoringData?.human_resources?.hr_percentage}%`
                                      : ""}
                                  </h3>
                                </div>
                                <div className="d-flex justify-content-between align-items-center ">
                                  <strong
                                    className="text-black"
                                    style={{ fontWeight: "600" }}
                                  >
                                    #{" "}
                                  </strong>
                                </div>
                              </div>
                            </div>
                            <div className="dashbtm">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <div>
                                      <h3
                                        style={{
                                          color: "rgb(123, 97, 196)",
                                          fontSize: "25px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {beneficiaryMonitoringData?.sales
                                          ?.total_sales
                                          ? formatAmount(
                                              beneficiaryMonitoringData?.sales
                                                ?.total_sales
                                            )
                                          : `${
                                              currencyType === "usd"
                                                ? "$"
                                                : "CDF"
                                            }0`}
                                      </h3>
                                    </div>
                                    <div>
                                      <h3 className="text-gray pur dash-title">
                                        {t("sales")}{" "}
                                      </h3>
                                    </div>
                                  </div>
                                  <h3
                                    className="font-weight-bold"
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      color: "green",
                                    }}
                                  >
                                    {beneficiaryMonitoringData?.sales
                                      ?.sales_percentage
                                      ? `${beneficiaryMonitoringData?.sales?.sales_percentage}%`
                                      : ""}
                                    {/* {subventionMonitoringData?.subvention?.ben_with_subvention} */}
                                  </h3>
                                </div>
                                <div className="d-flex justify-content-between align-items-center ">
                                  <strong
                                    className="text-black"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {t("amount")}
                                    {/* {subventionMonitoringData?.subvention?.ben_with_subvention} */}
                                  </strong>
                                </div>
                              </div>
                            </div>
                            <div className="dashbtm">
                              <div className="dash-card shadow-sm">
                                <div className="d-flex justify-content-between align-items-start ">
                                  <div>
                                    <div>
                                      <h3
                                        style={{
                                          color: "rgb(123, 97, 196)",
                                          fontSize: "25px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {beneficiaryMonitoringData?.profit_loss
                                          ?.total_profit_loss
                                          ? formatAmount(
                                              beneficiaryMonitoringData
                                                ?.profit_loss?.total_profit_loss
                                            )
                                          : `${
                                              currencyType === "usd"
                                                ? "$"
                                                : "CDF"
                                            }0`}
                                      </h3>
                                    </div>
                                    <div>
                                      <h3 className="text-gray pur dash-title">
                                        {t("profit_loss")}
                                      </h3>
                                    </div>
                                  </div>
                                  <h3
                                    className="font-weight-bold"
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      color: "green",
                                    }}
                                  >
                                    {beneficiaryMonitoringData?.profit_loss
                                      ?.profit_loss_percentage
                                      ? `${beneficiaryMonitoringData?.profit_loss?.profit_loss_percentage}%`
                                      : ""}
                                    {/* {subventionMonitoringData?.subvention?.ben_with_subvention} */}
                                  </h3>
                                </div>
                                <div className="d-flex justify-content-between align-items-center ">
                                  <strong
                                    className="text-black"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {t("amount")}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body px-0">
                      <div className="row">
                        <div className="col-xl-8 px-0 mb-3 mb-lg-0">
                          <div
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <div className="card bg-white shadow-sm row">
                              <Nav className="nav nav-tabs tabHolder dash-tab px-2">
                                <NavItem className="nav-item">
                                  <NavLink
                                    aria-current="page"
                                    className={
                                      submenuTabOpen === "1"
                                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                                    }
                                    onClick={() => setSubmenuTabValue("1")}
                                  >
                                    <div>{t("human_resource")}</div>
                                  </NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                  <NavLink
                                    aria-current="page"
                                    className={
                                      submenuTabOpen === "2"
                                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                                    }
                                    onClick={() => setSubmenuTabValue("2")}
                                  >
                                    {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                                    <div>{t("financial")}</div>
                                  </NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                  <NavLink
                                    aria-current="page"
                                    className={
                                      submenuTabOpen === "3"
                                        ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                        : "nav-link d-flex flex-column justify-content-center align-items-center"
                                    }
                                    onClick={() => setSubmenuTabValue("3")}
                                  >
                                    {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                                    <div>{t("session_table")}</div>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                              <TabContent activeTab={submenuTabOpen}>
                                <TabPane tabId="1">
                                  <div className="col-12 grid-margin stretch-card p-2 pb-0 mb-0">
                                    <div className="card bg-white">
                                      <h1 className="card-title p-3 dash-title d-none">
                                        {t("human_resource")}{" "}
                                      </h1>
                                      <div id="chart"></div>
                                      {/* <ReactApexChart/> */}
                                      <ReactApexChart
                                        options={
                                          beneficiaryMonitoringHumanResourceChart.options
                                        }
                                        series={
                                          beneficiaryMonitoringHumanResourceChart.series
                                        }
                                        type="bar"
                                        height={350}
                                      />
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="2">
                                  <div className="col-lg-12 grid-margin  p-2 pb-0 mb-0">
                                    <div className="card bg-white">
                                      <h1 className="card-title dash-title p-3 d-none">
                                        {t("financial")}{" "}
                                      </h1>
                                      <ReactApexChart
                                        options={
                                          beneficiaryMonitoringFinanceChart.options
                                        }
                                        series={
                                          beneficiaryMonitoringFinanceChart.series
                                        }
                                        type="bar"
                                        height={350}
                                      />
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tabId="3">
                                  <div className="row d-flex justify-content-start m-2">
                                    {/* <Heading
                                  inputTypeData={benSessionInputTypeData}
                                  searchOption1Array={benSessionsearchOption1Array}
                                  // addLink={{ link: RoutesLink?.add_beneficiary, }}
                                  // addLink={RoutesLink?.add_beneficiary}
                                  // btnName={Roles?.admin_value === role ? "Add Beneficiary" : ""}
                                  // name={"Beneficiary"}
                                  // downloadToggle={downloadModalToggle}
                                  // addArray={addAction1}
                                  searchOption2Array={benSessionsearchOption2Array}
                                  inputCategoryData={benSessionInputCategoryData}
                                  setInputCategoryData={setBenSessionInputCategoryData}
                                  //Filter Props
                                  setFilterData={setBenSessionFilterData}
                                  setSearchOptionData={setBenSessionSearchOptionData}
                                  setInputTypeData1={setBenSessionInputTypeData}
                                  setMainCallApi={() => benSessionList(`${apiUrl?.get_ben_monitoring_session_list}?schedule_start_ts=${convertToEpoch(startDate?.from)}&schedule_end_ts=${convertToEpoch(startDate?.to)}`)}
                                  setData={setBenSessionListData}
                                  filterData={benSessionFilterData}
                                  setActivePage={setBenSessionActivePage}
                                // ResetAccountHandler={ResetAccountHandler}
                                /> */}
                                    <div
                                      className="table-responsive p-0 "
                                      style={{
                                        border: "1px solid #EFEFEF",
                                        borderRadius: "20px",
                                        maxHeight: "400px",
                                      }}
                                    >
                                      {/* {<div className="d-flex justify-content-center"> No Data Found</div>} */}
                                      <table className="table table-hover">
                                        <thead>
                                          <tr>
                                            {benSessionListHeading?.map(
                                              (data) => (
                                                <th>{data?.data}</th>
                                              )
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {benSessionListData?.map((data) => (
                                            <tr>
                                              <td>
                                                {data?.beneficiary?.fullname}
                                              </td>
                                              <td>
                                                {data?.status ===
                                                "completed" ? (
                                                  <p
                                                    style={{
                                                      color: "#098E7E",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                ) : data?.status ===
                                                  "in progress" ? (
                                                  <p
                                                    style={{
                                                      color: "#00B8F5",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                ) : data?.status ===
                                                  "pending" ? (
                                                  <p
                                                    style={{
                                                      color: "#AB0D82",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                ) : (
                                                  <p
                                                    style={{
                                                      color: "#AB0D82",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                )}
                                              </td>
                                              <td className="text-end">
                                                {data?.total_sessions}
                                              </td>
                                              <td className="text-end">
                                                <p
                                                  style={{
                                                    color: "#AB0D82",
                                                    fontSize: "0.875rem",
                                                  }}
                                                >
                                                  {data?.total_pending_sessions}
                                                </p>
                                              </td>
                                              <td className="text-end">
                                                <p
                                                  style={{
                                                    color: "#00B8F5",
                                                    fontSize: "0.875rem",
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    data?.total_inprogress_sessions
                                                  }
                                                </p>
                                              </td>
                                              <td className="text-end">
                                                <p
                                                  style={{
                                                    color: "#098E7E",
                                                    fontSize: "0.875rem",
                                                  }}
                                                >
                                                  {
                                                    data?.total_completed_sessions
                                                  }
                                                </p>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
                       {!isLoading && beneficiaryList?.length===0 ? <h4 style={{ textAlign: "center" }}>No Data Found</h4>:"" }
                       */}
                                  </div>
                                </TabPane>
                              </TabContent>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 px-0">
                          <div
                            className="card shadow-sm h-100"
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <div className="d-flex flex-column gap-3">
                              <div>
                                <div
                                  style={{ marginTop: "10px", padding: "2px" }}
                                >
                                  {/* <h1 className="card-title mt-4 font-weight-bold fs-5">Session Breakups </h1> */}
                                  <h1 class="card-title p-3 dash-title">
                                    {t("coaching_sessions")}
                                  </h1>
                                  <div className="d-flex flex-column align-items-center justify-content-center pb-3 pb-lg-0">
                                    {
                                      <Chart
                                        chartEvents={chartEvents}
                                        data={
                                          beneficiarySessionBreakupChartData?.data
                                        }
                                        options={
                                          beneficiarySessionBreakupChartData?.options
                                        }
                                        chartType="PieChart"
                                        width={"280px"}
                                        height={"280px"}
                                      />
                                    }
                                    <div className="d-flex gap-3 chrtd my-3 justify-content-center">
                                      <p className="m-0">{t("pending")}</p>
                                      <p className="m-0">{t("in_progress")}</p>
                                      <p className="m-0">{t("completed")}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mx-3 d-flex align-items-center justify-content-between">
                        <Heading
                          name={
                            selectBeneficiaryStatus
                              ? `${selectBeneficiaryStatus} ${t(
                                  "coaching_sessions"
                                )}`
                              : `${t("recent_coaching_sessions")}`
                          }
                        />
                        {selectBeneficiaryStatus !== "" ? (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: 500,
                              color: "#7b61c4",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => handelResetCoachingList()}
                          >
                            {t("reset")}
                          </span>
                        ) : (
                          <Link
                            to={RoutesLink?.coach_plan_list}
                            style={{
                              fontSize: "18px",
                              fontWeight: 500,
                              color: "#7b61c4",
                              textDecoration: "underline",
                            }}
                          >
                            {t("see_all")}
                          </Link>
                        )}
                      </div>
                      <div
                        className="table-responsive p-0 mx-3"
                        style={{
                          border: "1px solid #EFEFEF",
                          borderRadius: "20px",
                        }}
                      >
                        {/* {<div className="d-flex justify-content-center"> No Data Found</div>} */}
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              {beneficiaryMonitoringListHeading?.map(
                                (item, index) => (
                                  <th
                                    key={index}
                                    className={item?.classType}
                                    style={{
                                      maxWidth: "150px",
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {item?.data}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {beneficiaryMonitoringCoachingListData?.map(
                              (data) => (
                                <tr>
                                  {
                                    <td>
                                      <Link
                                        to={
                                          RoutesLink?.coach_plan_session_details
                                        }
                                        state={{
                                          data: data,
                                          tabpane: "3",
                                          back: "dashboard",
                                          beneficiary_name:
                                            data?.cp_master?.beneficiary
                                              ?.fullname,
                                          coach_name: `${data?.cp_master?.coach?.first_name} ${data?.cp_master?.coach?.last_name}`,
                                        }}
                                      >
                                        {data?.session_id}
                                      </Link>
                                    </td>
                                  }

                                  {
                                    <td>
                                      {data?.cp_master?.coach?.first_name}{" "}
                                      {data?.cp_master?.coach?.last_name}
                                    </td>
                                  }

                                  {
                                    <td>
                                      <Link
                                        to={RoutesLink?.beneficiary_profile}
                                        state={{
                                          data: data,
                                          tabpane: "7",
                                          back: "dashboard",
                                          beneficiary_name:
                                            data?.cp_master?.beneficiary
                                              ?.fullname,
                                          coach_name: `${data?.cp_master?.coach?.first_name} ${data?.cp_master?.coach?.last_name}`,
                                        }}
                                      >
                                        {data?.cp_master?.beneficiary?.fullname}
                                      </Link>
                                    </td>
                                  }
                                  <td className="text-end">
                                    {data?.employees ? data?.employees : "N/A"}
                                  </td>
                                  <td className="text-end">
                                    {data?.sales
                                      ? CurrencyConverter(data?.sales)
                                      : "N/A"}
                                  </td>
                                  <td className="text-end">
                                    {data?.expenses
                                      ? CurrencyConverter(data?.expenses)
                                      : "N/A"}
                                  </td>
                                  <td className="text-end">
                                    {data?.purchase
                                      ? CurrencyConverter(data?.purchase)
                                      : "N/A"}
                                  </td>
                                  <td className="text-end">
                                    {data?.profit_loss
                                      ? CurrencyConverter(data?.profit_loss)
                                      : "N/A"}
                                  </td>
                                  {
                                    <td>
                                      {data?.session_start_ts
                                        ? moment(
                                            TimeStampConverter(
                                              data?.session_start_ts
                                            )
                                          ).format("DD/MM/YYYY hh:mm a")
                                        : "N/A"}
                                    </td>
                                  }
                                  {
                                    <td>
                                      {data?.session_end_ts
                                        ? moment(
                                            TimeStampConverter(
                                              data?.session_end_ts
                                            )
                                          ).format("DD/MM/YYYY hh:mm a")
                                        : "N/A"}
                                    </td>
                                  }
                                  {
                                    <td>
                                      {data?.status === "completed" ? (
                                        <p
                                          style={{
                                            color: "#098E7E",
                                            fontSize: "0.875rem",
                                          }}
                                        >
                                          {capitalizeFirstLetter(data?.status)}
                                        </p>
                                      ) : data?.status === "in progress" ? (
                                        <p
                                          style={{
                                            color: "#00B8F5",
                                            fontSize: "0.875rem",
                                          }}
                                        >
                                          {capitalizeFirstLetter(data?.status)}
                                        </p>
                                      ) : data?.status === "pending" ? (
                                        <p
                                          style={{
                                            color: "#AB0D82",
                                            fontSize: "0.875rem",
                                          }}
                                        >
                                          {capitalizeFirstLetter(data?.status)}
                                        </p>
                                      ) : (
                                        <p
                                          style={{
                                            color: "#AB0D82",
                                            fontSize: "0.875rem",
                                          }}
                                        >
                                          {capitalizeFirstLetter(data?.status)}
                                        </p>
                                      )}
                                    </td>
                                  }
                                </tr>
                              )
                            )}

                            {isLoading && (
                              <TableLoader listHeading={listHeading} />
                            )}
                          </tbody>
                        </table>

                        {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>} */}
                        {!isLoading && sessionsList?.length === 0 ? (
                          <h4 style={{ textAlign: "center" }}>No Data Found</h4>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12 px-3 my-2 d-flex justify-content-between align-items-center">
                        <div>
                          {allCoachingListPage?.total ? (
                            <p>
                              <strong>{t("total_records")} :</strong>
                              {allCoachingListPage?.total}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                          <p>
                            <strong>{t("per_page_account")} : </strong>
                            {beneficiaryMonitoringCoachingListData?.length}
                          </p>
                        </div>
                        {allCoachingListPage?.totalPage > 1 && (
                          <Pagination
                            activePage={currentCoachingListPage}
                            itemsCountPerPage={10}
                            totalItemsCount={allCoachingListPage?.total}
                            disabledClass="paginationDisableCustom"
                            pageRangeDisplayed={5}
                            onChange={handleCoachingListPageChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
      {isPageLoading && <PageLoader />}
      {pageIsLoading && <PageLoader />}
    </Layout>
  );
};
export default Dashboard;
