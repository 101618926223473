import { Link } from "react-router-dom";

const ActionDropDown = ({ addArray, id, detailsData }) => {
  for (let i = 0; i < addArray?.length; i++) {
    addArray[i]["id"] = id;
  }
  let funalData = addArray;
  if (detailsData.members_count == 0) {
    funalData = addArray?.filter((e) => e.name != "Unassign Beneficiary");
  }
  if (detailsData?.equipment_count == 0) {
    funalData = addArray?.filter((e) => e.name != "Unassign Equipments");
  }
  if (detailsData.status == 0) {
    funalData = addArray?.filter((e) => e.name != "Assign Equipments");
    funalData = funalData?.filter((e) => e.name != "Unassign Equipments");
  }
  return (
    <>
      {/* <div class="d-flex justify-content-between align-items-center px-1 py-1">
                                <h6 class=" mb-0 text-muted font-weight-bold">Actions</h6>
                              <span class="closebtn rounded bg-light"><i class="mdi mdi-close text-end"></i></span>
                              </div> */}
      {funalData?.map((data) => {
        console.log(data);
        return (
          <>
            {data?.type !== "function" && (
              <div className="dropdown-item preview-item">
                <Link
                  className="preview-item-content w-100 rounded-3"
                  to={data?.path}
                  state={{
                    id: id,
                    data: detailsData,
                    type: data?.type,
                    page: data?.page,
                    pageurl: data.pageurl,
                  }}
                >
                  <p className="text-muted px-2 my-1">{data?.name}</p>
                </Link>
              </div>
            )}
            {/* {
          data?.type === "function" &&
          < div className="dropdown-item preview-item" >
            <p className="preview-item-content w-100 rounded-3 m-0" onClick={() => data?.path(detailsData)}>
              <p className="text-muted px-2 my-1">{data?.name}</p></p>
          </div>
        } */}
            {data?.type === "function" &&
              (detailsData?.status === data?.value ||
              data?.value == "always" ? (
                <div className="dropdown-item preview-item">
                  <p
                    className="preview-item-content w-100 rounded-3 m-0"
                    onClick={() => data?.path(detailsData)}
                  >
                    <p className="text-muted px-2 my-1">{data?.name}</p>
                  </p>
                </div>
              ) : (
                ""
              ))}
          </>
        );
      })}
    </>
  );
};
export default ActionDropDown;
