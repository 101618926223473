import ButtonLoader from "./ButtonLoader";

const AssignBeneficiaryCard = ({ assignedBeneficiaryList, deleteBeneficiaryHandler, cancelAssignedListHandler, submitAssignmentHandler, type, isLoading }) => {
  return (
    <div className="addbenepop">
      <div className="assben">
        <div className="behd">
          <h4>{type === "delete" ? "Unassign Beneficiary" : "Assign Beneficiary "} 
            <h6>Count: <span className="wqty">{assignedBeneficiaryList?.length}</span></h6>
          </h4>
          <ul>
            {assignedBeneficiaryList?.map((data) => <li>
              <div className="bens">
                <div className="bnid ben">{data?.reference_id}</div>
                <div className="bname  ben">{data?.fullname}</div>
                {/* <div className="bnemail ben">{data?.telephone_alt1}</div> */}
                <div className="chkbtns ben">
                  <div className="buttons">
                    {/* <button className="correct-btn">
                            <span class="mdi mdi-check"></span>
                          </button> */}
                    <button className="mx-1 close-btn" onClick={() => deleteBeneficiaryHandler(data?.id)}>
                      <span className="mdi mdi-close "></span>
                    </button>
                  </div>
                </div>
              </div>
            </li>)}
          </ul>
        </div>
        <div className="btnboxs">
          <button className="btn btn-light" onClick={cancelAssignedListHandler}>Cancel</button>
          <button className="btn btn-primary" onClick={submitAssignmentHandler}>{isLoading ? <ButtonLoader /> : (type === "delete" ? "Unassign " : "Assign")}</button>
        </div>
      </div>
    </div>
  )
}
export default AssignBeneficiaryCard;