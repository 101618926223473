import React from "react";
import { NavItem, TabContent, TabPane } from "reactstrap";
import Layout from "../../../Components/Layout/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import Cookies from "js-cookie";
// import { Roles } from "../../../ApiHelper/Nomanclatures";
import Roles from "../../../ApiHelper/Roles.json";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import RoleType from "../../../ApiHelper/Roles.json";
import {
  TimeStampConverter,
  convertToEpoch,
} from "../../../ApiHelper/TimeStampConverter";
import Heading from "../../../Components/CommonElements/Heading";
import ProfileHeader from "../../../Components/CommonElements/ProfileHeader";
import moment from "moment";
import { useTranslation } from "react-i18next";
const SupplierDetail = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const roleType = Cookies.get("emp_role");

  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [searchOptionData, setSearchOptionData] = useState("");
  const [inputTypeData, setInputTypeData] = useState("text");
  const [isLoading, setIsLoading] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [tabOpen, setTabOpen] = useState("1");
  const [filterData, setFilterData] = useState();
  const [inputCategoryData, setInputCategoryData] = useState("");
  let role = Cookies.get("emp_role");

  const location = useLocation();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();
  const [pages, setPages] = useState();
  const [callApi, setCallApi] = useState(true);
  const [equipmentList, setEquipmentList] = useState([]);
  const { t } = useTranslation();

  const handlePageChange = (pageitem) => {
    setEquipmentList([]);
    setActivePage(pageitem);
    getEquipment(pageitem);
  };

  let listHeading = [
    { classType: "", data: `${t("name")}` },
    { classType: "text-truncate", data: `${t("detail")}` },
    { classType: "text-end", data: `${t("price")}` },
    { classType: "text-end", data: `${t("quantity")}` },
    { classType: "", data: `${t("status")}` },
  ];

  const searchOption1Array = [
    { name: `${t("search_by")}`, value: "", type: "select" },
    { name: `${t("name")}`, value: "name", type: "text" },
    { name: `${t("status")}`, value: "status", type: "select" },
  ];

  const searchOptionStatusArray = [
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  const setTabValue = (data) => {
    setTabOpen(data);
    console.log(tabOpen);
  };

  const filters = [];

  const applyFilter = () => {
    const filterValue = filterData ? `&${searchOptionData}=${filterData}` : "";
    filters.push(filterValue);
  };

  useEffect(() => {
    console.log("locationnnn.....", location.state);
    // if (location?.state?.tabid) {
    //   setTabOpen(location?.state?.tabid);
    // }
    setPageIsLoading(true);
    handleFetchRequest(
      `${apiUrl?.supplier_detail}?supplier_id=${location?.state?.id}`
    ).then((response) => {
      if (response.status === true) {
        setProfileData(response?.data);
        setPageIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
    if (
      roleType === Roles?.admin_value ||
      roleType === Roles?.coach_value ||
      roleType === Roles?.viewer_value
    ) {
      // getBeneficiaryList(activePage);
      getEquipment(activePage);
    }
  }, []);

  const getEquipment = (pageItem) => {
    applyFilter();
    setIsLoading(true);

    handleFetchRequest(
      `${apiUrl?.get_equipment_list}?supplier_id=${
        location?.state?.id
      }&assign_supplier=0&page_no=${pageItem}&page_size=10${filters.join("&")}`
    ).then((response) => {
      if (response.status === true) {
        setEquipmentList(response?.data);
        setCallApi(false);
        setIsLoading(false);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const backButtonHandler = () => {
    navigate(-1);
  };

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setActivePage(1);
    setSearchOptionData("");
    setFilterData("");
    setInputCategoryData("###select");
    setEquipmentList([]);

    handleFetchRequest(
      `${apiUrl?.get_equipment_list}?supplier_id=${location?.state?.id}&assign_supplier=0&page_no=1&page_size=10`
    ).then((response) => {
      if (response.status === true) {
        setEquipmentList(response?.data);
        setCallApi(false);
        setIsLoading(false);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-12 px-0 px-lg-3">
            <div className="card mb-3">
              <ProfileHeader
                fullName={profileData?.supplier_name}
                backButtonHandler={backButtonHandler}
              />
              <div className="">
                <div className="page-header mx-3 mb-0 position-relative supplier-tab">
                  <ul className="nav nav-tabs tabHolder d-flex flex-nowrap overflow-x-auto ">
                    <NavItem className="nav-item">
                      <div
                        // className={tabOpen == '1' ? "nav-link d-flex flex-column justify-content-center align-items-center active" : "nav-link"}
                        className={
                          tabOpen === "1"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("1")}
                      >
                        <div className="text-center font-weight-bold">
                          {t("Supplier")}
                        </div>
                      </div>
                    </NavItem>
                    {
                      <NavItem class="nav-item">
                        <div
                          className={
                            tabOpen === "2"
                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                          }
                          onClick={() => setTabValue("2")}
                        >
                          <div class="text-center font-weight-bold">
                            {t("Equipments")}
                          </div>
                        </div>
                      </NavItem>
                    }
                  </ul>
                  <div className="absolute-border"></div>
                </div>
                <div className="row">
                  <TabContent activeTab={tabOpen}>
                    <TabPane tabId="1">
                      <div className="col-lg-12 stretch-card px-0 px-md-3">
                        <div className="card-body">
                          <div className="pro">
                            {profileData && (
                              <div className="newcon">
                                <div className="d-flex justify-content-between mb-3">
                                  <h4> {t("Supplier_Information")}</h4>
                                  {roleType === Roles?.admin_value
                                    ? tabOpen === "1" && (
                                        <div className="position-relative  d-flex justify-content-end">
                                          <div className="grant">
                                            <Link
                                              className="btn btn-primary"
                                              to={RoutesLink?.add_suppliers}
                                              state={{
                                                id: profileData?.id,
                                                data: profileData,
                                              }}
                                            >
                                              Edit Supplier
                                            </Link>
                                          </div>
                                        </div>
                                      )
                                    : ""}
                                </div>
                                <ul className="uln">
                                  <li>
                                    <h5>{t("supplier_name")}</h5>{" "}
                                    <span>{profileData?.supplier_name}</span>
                                  </li>
                                  <li>
                                    <h5>{t("Alias_id")}</h5>{" "}
                                    <span>{profileData?.alias_id}</span>
                                  </li>
                                  <li>
                                    <h5>{t("Email")}</h5>{" "}
                                    <span>{profileData?.email}</span>
                                  </li>
                                  <li>
                                    <h5>{t("Country_Code")}</h5>{" "}
                                    <span>{profileData?.country_code}</span>
                                  </li>

                                  <li>
                                    <h5>{t("Mobile_No")}</h5>{" "}
                                    <span>{profileData?.contact}</span>
                                  </li>
                                  <li>
                                    <h5>{t("City")}</h5>{" "}
                                    <span>{profileData?.city}</span>
                                  </li>
                                  <li>
                                    <h5>{t("State")}</h5>{" "}
                                    <span>{profileData?.state}</span>
                                  </li>
                                  <li>
                                    <h5>{t("Country")}</h5>{" "}
                                    <span>{profileData?.country}</span>
                                  </li>
                                  <li>
                                    <h5>{t("Zip_Code")}</h5>{" "}
                                    <span>{profileData?.zip_code}</span>
                                  </li>
                                  <li>
                                    <h5>{t("Address_line1")}</h5>{" "}
                                    <span>{profileData?.address_line1}</span>
                                  </li>
                                  <li>
                                    <h5>{t("address_line2")}</h5>{" "}
                                    <span>{profileData?.address_line2}</span>
                                  </li>
                                  <li>
                                    <h5>{t("Created_At")}</h5>{" "}
                                    <span>
                                      {profileData?.createdAt
                                        ? moment(
                                            TimeStampConverter(
                                              profileData?.createdAt
                                            )
                                          ).format("DD/MM/YYYY")
                                        : "N/A"}
                                    </span>
                                  </li>
                                  {/* <li><h5>Sales</h5> <span>{profileData?.sales}</span></li> */}
                                  <li className="mb-0">
                                    <div className="abs-btn">
                                      {/* {/ { <button className="absbtn">Assigned</button>} /} */}
                                      {profileData?.status === 1 ? (
                                        <button className="acbtn badge">
                                          Active
                                        </button>
                                      ) : (
                                        <button className="acbtn">
                                          Inactive
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="col-lg-12  px-0 px-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="row d-flex justify-content-start">
                              <Heading
                                searchOption1Array={searchOption1Array}
                                name={t("Equipments")}
                                useraccount={true}
                                setInputTypeData1={setInputTypeData}
                                setMainCallApi={() => getEquipment(1)}
                                inputTypeData={inputTypeData}
                                searchOption2Array={searchOption2Array}
                                setData={setEquipmentList}
                                setFilterData={setFilterData}
                                setSearchOptionData={setSearchOptionData}
                                filterData={filterData}
                                setActivePage={setActivePage}
                                ResetAccountHandler={ResetAccountHandler}
                                inputCategoryData={inputCategoryData}
                                setInputCategoryData={setInputCategoryData}
                              />
                              {roleType == Roles?.admin_value &&
                                profileData?.status == 1 && (
                                  <div className="d-flex align-items-center justify-content-end gap-2 mb-3 px-0">
                                    <Link
                                      to={RoutesLink?.assign_equipments}
                                      state={{
                                        id: location?.state?.id,
                                        type: "assign",
                                        page: "/supplier-detail",
                                      }}
                                      className="btn btn-primary"
                                    >
                                      {/* <span className="mdi mdi-plus" /> */}
                                      Assign Equipments
                                    </Link>
                                    {profileData?.supplier_equipments?.length >
                                      0 && (
                                      <Link
                                        to={RoutesLink?.assign_equipments}
                                        state={{
                                          id: location?.state?.id,
                                          type: "unassign",
                                          page: "/supplier-detail",
                                        }}
                                        className="btn btn-primary"
                                      >
                                        {/* <span className="mdi mdi-plus" /> */}
                                        Unassign Equipments
                                      </Link>
                                    )}
                                  </div>
                                )}
                              <div className="ptable px-0">
                                <div className={"tablcont"}>
                                  <PrimaryTable
                                    listHeading={listHeading}
                                    listData={equipmentList}
                                    equipment_list={true}
                                    activePage={activePage}
                                    pages={pages}
                                    handlePageChange={handlePageChange}
                                    isLoading={isLoading}
                                    idClickLink={
                                      RoutesLink?.beneficiary_profile
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
            {pageIsLoading && <PageLoader />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SupplierDetail;
