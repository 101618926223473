import Cookies from "js-cookie";
import moment from "moment-timezone";

export function TimeStampConverter(epochTimestamp) {
    let timezone = Cookies.get("emp_time_zone");
    const inputString = timezone;
    const regex = /\((UTC[-+]\d{2}:\d{2})\)/; // Regular expression pattern to match the timezone offset

    const match = inputString?.match(regex); // Match the pattern in the input string
    let timeZoneOffset;
    if (match) {
        timeZoneOffset = match[1]; // Extract the matched substring
        console.log(timeZoneOffset); // Output: -06:00
    }
    else {
        return;
        // console.log("Timezone offset not found in input string.");
    }
    let appliedDate = +epochTimestamp;
    const formattedDate = moment(appliedDate).utcOffset(timeZoneOffset).format('YYYY-MM-DD HH:mm:ss');;
    return formattedDate;
}

// convert date to epoch

export const convertToEpoch = (normalTime) => {
    const normalTimeObject = new Date(normalTime);
    const epochTime = Math.floor(normalTimeObject.getTime());
    return epochTime;
}

export const ConvertInputTimeToSetting = (time) => {

    let timezone = Cookies.get("emp_time_zone");
    const inputString = timezone;
    const regex = /\((UTC[-+]\d{2}:\d{2})\)/; // Regular expression pattern to match the timezone offset

    const match = inputString?.match(regex); // Match the pattern in the input string
    let timeZoneOffset;
    if (match) {
        timeZoneOffset = match[1]; // Extract the matched substring
        console.log(timeZoneOffset); // Output: -06:00
    }
    else {
        return;
        // console.log("Timezone offset not found in input string.");
    }
    // const appliedDate = "Fri Feb 23 2024 14: 43: 56 GMT +0530(India Standard Time)";
    const formattedDate = moment(time).utcOffset(timeZoneOffset).format('YYYY-MM-DD HH:mm:ss');;
    return formattedDate;
}
