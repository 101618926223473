import { useEffect } from "react";
import Layout from "../../../Components/Layout/Layout";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import Heading from "../../../Components/CommonElements/Heading";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { useState } from "react";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import Roles from "../../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const SubventionDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [pageIsLoading, setPageIsLoading] = useState(false);
  let role = Cookies.get("emp_role");
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentData, setEquipmentData] = useState();
  // const [callApi, setCallApi] = useState(true);
  const listHeading = [
    { data: `${t("euipment_name")}` },
    { classType: "text-end", data: `${t("Equipment_Amount")}` },
    { classType: "text-end", data: `${t("Equipment_Quantity")}` },
    { classType: "text-end", data: `${t("Total_Price")}` },
  ];
  // const [subventionStatusText, setSubventionStatusText] = useState(" Mark As Dispatched");
  const updateStatusHandler = () => {
    let updatedStatus;
    if (equipmentData?.status === "created") {
      updatedStatus = "dispatched";
    }
    if (equipmentData?.status === "dispatched") {
      updatedStatus = "delivered";
    }
    const updateData = {
      subvention_id: equipmentData?.id,
      status: updatedStatus,
    };

    Swal.fire({
      text: `Do you want to Update the status to ${updatedStatus}?`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setPageIsLoading(true);
        handleFetchRequest(
          apiUrl?.update_subvention_status,
          "PUT",
          updateData
        ).then((response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            GetSubventionData();
            toast.success(response?.message);
            // setCallApi(true);
            // setSubventionStatusText("Mark As Delivered");
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        });
      }
    });
  };

  const deleteSubventionHandler = () => {
    Swal.fire({
      text: `Do you want to delete this Subvention?`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setPageIsLoading(true);
        handleFetchRequest(
          `${apiUrl?.delete_subvention}?subvention_id=${equipmentData?.id}`,
          "DELETE"
        ).then((response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            toast.success(response?.message);
            navigate(-1);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        });
      }
    });
  };

  const backButtonHandler = () => {
    if (location?.state?.type === "profile") {
      navigate(RoutesLink?.beneficiary_profile, {
        state: { id: location?.state?.data?.beneficiary_id, tabpane: "4" },
      });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    GetSubventionData();
  }, []);

  const GetSubventionData = () => {
    setIsLoading(true);
    handleFetchRequest(
      `${apiUrl?.get_subvention_detail}?subvention_id=${location?.state?.id}`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setEquipmentData(response?.data);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        // setCallApi(false);
      }
    });
  };

  return (
    <Layout>
      {/* <ProfileHeader 
      /> */}
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <div className="d-md-flex justify-content-between">
                    <Heading
                      backButtonHandler={backButtonHandler}
                      //  inputTypeData={inputTypeData}
                      //  setInputTypeData1={setInputTypeData}
                      //  searchOption1Array={searchOption1Array}
                      // addLink={"/add-subventions"}
                      name={t("Subvention_Detail")}
                      // searchOption2Array={searchOption2Array}
                      // setSearchOptionData={setSearchOptionData}
                      // setMainCallApi={setCallApi}
                      // setFilterData={setFilterData}
                      // setData={setSubventionList}
                    />
                    <div className="d-flex gap-2 align-items-center w-sm-100">
                      {role !== Roles.viewer_value &&
                        equipmentData?.status != "delivered" && (
                          <button
                            className="btn btn-primary w-sm-100"
                            onClick={updateStatusHandler}
                          >
                            {equipmentData?.status === "created"
                              ? `${t("Mark_as_dispatched_")}`
                              : equipmentData?.status === "dispatched"
                              ? "Mark as Delivered"
                              : null}
                          </button>
                        )}
                      {role === Roles?.admin_value &&
                        equipmentData?.status === "created" && (
                          <button
                            className="btn btn-primary"
                            onClick={deleteSubventionHandler}
                          >
                            {t("delete_subvention")}
                          </button>
                        )}
                    </div>
                  </div>
                  <div
                    className="d-flex col-lg-12 p-3 row p-md-4 my-3 fw-300 text-gray"
                    style={{
                      border: "1px solid rgb(239, 239, 239)",
                      borderRadius: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    <div className="col-lg-6 px-0 px-lg-3">
                      <div className="subvenrowdetails">
                        <span className="" style={{ fontWeight: "bold" }}>
                          {t("subvention_id")}:{" "}
                        </span>
                        <span className=""> {equipmentData?.alias_id}</span>
                      </div>

                      <div className="subvenrowdetails">
                        <span className="" style={{ fontWeight: "bold" }}>
                          {t("beneficiary_id")}:{" "}
                        </span>
                        <span className="">
                          {" "}
                          {location?.state?.data?.beneficiary?.reference_id}
                        </span>
                      </div>
                      <div className="subvenrowdetails">
                        <span className="" style={{ fontWeight: "bold" }}>
                          {t("beneficiary_name")}:{" "}
                        </span>{" "}
                        {location?.state?.data?.beneficiary?.fullname}
                      </div>
                      {/* <div><span style={{ fontWeight: "bold" }}>Grant Amount:</span> {CurrencyConverter(location?.state?.data?.beneficiary?.grant_amount)}</div> */}
                      {/* <div><span style={{ fontWeight: "bold" }}> Grant Balance:</span> {CurrencyConverter(location?.state?.data?.beneficiary?.grant_balance)}</div> */}
                      <div className="subvenrowdetails">
                        <span className="" style={{ fontWeight: "bold" }}>
                          {t("status")}:
                        </span>
                        {equipmentData?.status}
                      </div>
                    </div>
                    <div className="col-lg-6 px-0 px-lg-3">
                      <div className="row">
                        <div className="subvenrowdetails p-0">
                          <span className=" p-0" style={{ fontWeight: "bold" }}>
                            {t("coach_id")}:
                          </span>
                          <span className=" p-0">
                            {location?.state?.data?.account?.alias_id}
                          </span>
                        </div>

                        <div className="subvenrowdetails p-0">
                          <span className="p-0" style={{ fontWeight: "bold" }}>
                            {t("coach_name")}:
                          </span>
                          <span className="p-0">
                            {location?.state?.data?.account?.first_name}{" "}
                            {location?.state?.data?.account?.last_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PrimaryTable
                    listData={equipmentData?.subvention_equipments}
                    listHeading={listHeading}
                    subvention_detail={true}
                    // equipment={true}
                    // subvention={true}
                    // addData={addHeadingAction}
                    // activePage={activePage}
                    // pages={pages}
                    // handlePageChange={handlePageChange}
                    isLoading={isLoading}
                    totalSubventionAmount={equipmentData?.total_amount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageIsLoading && <PageLoader />}
    </Layout>
  );
};

export default SubventionDetail;
