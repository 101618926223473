import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CheckoutCard = ({
  equipmentCheckoutList,
  checkoutDeleteHandler,
  quantityUpdateHandler,
  CreateSubventionHandler,
  handleQuantityToggle,
  items,
  isLoading,
}) => {
  const [checkOutValue, setCheckOutValue] = useState("");
  const [isCheckoutSaved, setIsCheckoutSaved] = useState(true);
  const { t } = useTranslation();

  const handleCheckoutValueChange = (e, i) => {
    const newValue = e.target.value;
    const updatedList = [...equipmentCheckoutList.data];
    updatedList[i].quantity = newValue;
    setCheckOutValue(newValue);
    setIsCheckoutSaved(false);
  };

  const saveCheckoutValue = (newValue, itemId) => {
    quantityUpdateHandler(newValue, itemId);
    setIsCheckoutSaved(true);
  };

  const handleCreateSubvention = () => {
    if (!isCheckoutSaved) {
      toast.error(
        "Please save the checkout value before creating the subvention"
      );
    } else {
      CreateSubventionHandler();
    }
  };

  return (
    <div className="equmnt">
      <div className="thd"></div>
      <div className="tcon">
        <div className="eqcon">
          <h4>{t("Equipments")}</h4>
          <span className="wqty">
            {equipmentCheckoutList?.data?.length || 0}
          </span>
        </div>
        <div className="chqbtn">
          <ul>
            {equipmentCheckoutList?.data?.map((item, i) => (
              <li key={i}>
                <div className="pritem">
                  <h5 className="prname">{item?.equipment_name}</h5>
                  <h5 className="prqty">$ {item?.total_price}</h5>
                  <div className="customInputBx">
                    <div className="qtysbtn">
                      <input
                        value={item.quantity}
                        onChange={(e) => handleCheckoutValueChange(e, i)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="dltbtn saveBtn"
                    onClick={() => saveCheckoutValue(checkOutValue, item.id)}
                  >
                    <span className="mdi mdi-content-save"></span>
                  </button>
                  <button
                    className="dltbtn"
                    onClick={() => checkoutDeleteHandler(item?.equipment_id)}
                  >
                    <span className="mdi mdi-trash-can-outline"></span>
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="chly">
            <div className="totalch">
              <h4 className="text-end">
                {t("Total_Amount")}: ${" "}
                {equipmentCheckoutList?.total_equipment_amount}
              </h4>
            </div>
            <button
              className="btn btn-primary w-100"
              onClick={handleCreateSubvention}
            >
              {"Create Subvention"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckoutCard;
