import React from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import FormLayout from "../../../Components/Layout/FormLayout";
import { useEffect } from "react";
import { useState } from "react";
import {
  valChar,
  valOptionalChar,
  validateEmail,
  validateMobileNumber,
  validateName,
  validateZip,
} from "../../../ApiHelper/FormValidation";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useDispatch } from "react-redux";
import PrimaryInput from "../../../Components/Inputs/PrimaryInput";
import { CountryCode } from "../../../ApiHelper/CountryCode";
import AppHeading from "../../../Components/CommonElements/Headings";
import { t } from "i18next";

const AddSupplier = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState(CountryCode);
  const [filteredCountries2, setFilteredCountries2] = useState(CountryCode);

  const [searchTerm, setSearchTerm] = useState(
    "Congo, The Democratic Republic of the Congo +243"
  );
  const [searchTerm2, setSearchTerm2] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const backButtonHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log("hjb", location?.state);
    if (location?.state?.id) {
      reset({
        supplier_name: location?.state.data.supplier_name,
        status: location?.state.data.status,
        email: location?.state?.data?.email,
        country_code: location?.state?.data?.country_code,
        contact: location?.state?.data?.contact,
        address_line_1: location?.state?.data?.address_line1,
        address_line_2: location?.state?.data?.address_line2,
        city: location?.state?.data?.city,
        state: location?.state?.data?.state,
        country: location?.state?.data?.country,
        zip_code: location?.state?.data?.zip_code,
      });
      setSearchTerm(location?.state?.data?.country_code);
      setSearchTerm2(location?.state?.data?.country);
    } else {
      reset({
        status: 1,
      });
    }
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);

    const cooperativeData = {
      supplier_name: data.supplier_name,
      status: data.status,
      email: data.email,
      contact: data.contact,
      country_code: searchTerm,
      address_line1: data?.address_line_1,
      address_line2: data?.address_line_2,
      city: data?.city?.charAt(0)?.toUpperCase() + data?.city?.slice(1),
      state: data?.state?.charAt(0)?.toUpperCase() + data?.state?.slice(1),
      country: searchTerm2,
      zip_code: data?.zip_code,
    };
    if (location?.state?.id) {
      const cooperativeData2 = {
        ...cooperativeData,
        supplier_id: location?.state?.id,
      };
      handleFetchRequest(apiUrl?.add_suppliers, "Post", cooperativeData2).then(
        (response) => {
          if (response?.status === true) {
            setIsLoading(false);
            toast.success("Supplier Updated Successfully");
            navigate(-1);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
            toast.error(response.message);
          }
        }
      );
    } else {
      handleFetchRequest(apiUrl?.add_suppliers, "POST", cooperativeData).then(
        (response) => {
          if (response.status === true) {
            setIsLoading(false);
            toast.success("Supplier Created Successfully");
            navigate(RoutesLink.assign_equipments, {
              state: {
                id: response.data.id,
                type: "assign",
                page: "/suppliers",
              },
            });
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
            toast.error(response.message);
          }
        }
      );
    }
  };

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
  };
  const handleInputBlur = () => {
    // Delay closing the dropdown to check if the click event occurs on the dropdown itself
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 400);
  };

  const handleInputChange = (e) => {
    const term = e.target.value;
    let arr = CountryCode.filter(
      (ele) =>
        ele.name.toLowerCase().includes(term.toLowerCase()) ||
        ele.dial_code.includes(term)
    );
    setFilteredCountries(arr);
    setSearchTerm(term);
  };

  const handleInputFocus2 = () => {
    setIsDropdownOpen2(true);
  };

  const handleInputBlur2 = () => {
    // Delay closing the dropdown to check if the click event occurs on the dropdown itself
    setTimeout(() => {
      setIsDropdownOpen2(false);
    }, 400);
  };

  const handleInputChange2 = (e) => {
    const term = e.target.value;
    let arr = CountryCode.filter((ele) =>
      ele.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCountries2(arr);
    setSearchTerm2(term);
  };

  const handleCountrySelect = (country) => {
    setSearchTerm(`${country.name} ${country.dial_code}`);
    setIsDropdownOpen(false);
    // Perform any other actions when a country is selected, e.g., update state or trigger an event
  };

  const handleCountrySelect2 = (country) => {
    setSearchTerm2(`${country.name}`);

    setIsDropdownOpen2(false);

    // Perform any other actions when a country is selected, e.g., update state or trigger an event
  };

  return (
    <FormLayout
      name={location?.state?.id ? "Edit Supplier" : `${t("add_supplier")}`}
      backButtonHandler={backButtonHandler}
    >
      <form className="form-inline" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="row mt-4">
            <div className="col-lg-6 px-0 px-lg-3 mb-3">
              <label className="input-label ps-3">
                {t("supplier_name")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control mr-sm-2"
                id="inlineFormInputName2"
                placeholder={t("supplier_name")}
                {...register("supplier_name", {
                  required: "Please enter Supplier Name*",
                  validate: valChar,
                })}
              />
              <span className="text-danger ">
                {errors?.supplier_name?.message}
              </span>
            </div>

            <div className="col-lg-6 px-0 px-lg-3 mb-3">
              <label className="input-label ps-3"> {t("mobileNo")}</label>
              <div className="d-flex">
                <div className="col-lg-4 me-1">
                  <div className="position-relative d-flex">
                    <input
                      {...register("country_code")}
                      type="text"
                      className="form-control"
                      placeholder="Country Code"
                      value={searchTerm}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      // onClick={handleClickHandler}
                      // onBlur={handleMoveHandler}
                    />

                    {isDropdownOpen && (
                      <ul
                        className={
                          isDropdownOpen
                            ? "dropdown-menu countrydrop d-block"
                            : "dropdown-menu"
                        }
                      >
                        {filteredCountries.map((country, i) => (
                          <li
                            key={i}
                            onClick={() => handleCountrySelect(country)}
                          >
                            {country.name} {country.dial_code}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <PrimaryInput
                  type={"number"}
                  className={"form-control"}
                  placeholder={"Phone Number"}
                  register={register("contact", {
                    validate: validateMobileNumber,
                  })}
                />
              </div>
              <span className="text-danger ">{errors?.contact?.message}</span>
            </div>

            <div className="col-lg-6 px-0 px-lg-3 mb-3">
              <label className="input-label ps-3"> {t("email")}</label>
              <PrimaryInput
                type="email"
                className="form-control"
                placeholder="Email"
                register={register("email", {
                  validate: validateEmail,
                })}
              />
              <span className="text-danger ">{errors?.email?.message}</span>
            </div>

            <div className="col-lg-6 px-0 px-lg-3">
              <label className="input-label ps-3">
                Select Status <span className="text-danger">*</span>
              </label>
              <div className="form-group">
                <select
                  className="form-control"
                  {...register("status", {
                    required: "Please enter Status*",
                  })}
                >
                  <option value={""}>Select Status</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
                {/* <span className="text-danger ">{errors?.status?.message}</span> */}
              </div>
              <span className="text-danger">{errors?.status?.message}</span>
            </div>
          </div>

          <div>
            {/* physical address */}
            <div>
              <div className="d-flex justify-content-between my-4">
                <AppHeading title="Address" />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 px-0 px-lg-3 mb-3">
                <label className="input-label px-3">{t("country")}</label>
                <div className="position-relative">
                  <input
                    {...register("country")}
                    type="text"
                    className="form-control"
                    placeholder="Country Name"
                    value={searchTerm2}
                    onChange={handleInputChange2}
                    onFocus={handleInputFocus2}
                    onBlur={handleInputBlur2}
                  />

                  {isDropdownOpen2 && (
                    <ul
                      className={
                        isDropdownOpen2
                          ? "dropdown-menu countrydrop d-block"
                          : "dropdown-menu"
                      }
                    >
                      {filteredCountries2.map((country, i) => (
                        <li
                          key={i}
                          onClick={() => handleCountrySelect2(country)}
                        >
                          {country.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <span className="text-danger ">{errors?.country?.message}</span>
              </div>
              <div className="col-lg-6 px-0 px-lg-3 mb-3">
                <label className="input-label px-3">{t("state")}</label>
                <PrimaryInput
                  type="text"
                  className="form-control"
                  register={register("state", {
                    validate: validateName,
                  })}
                  placeholder="State"
                />
                <span className="text-danger ">{errors?.state?.message}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 px-0 px-lg-3 mb-3">
                <label className="input-label ps-3">{t("City")}</label>
                <PrimaryInput
                  type="text"
                  className="form-control"
                  placeholder="City"
                  register={register("city", {
                    validate: validateName,
                  })}
                />

                <span className="text-danger ">{errors?.city?.message}</span>
              </div>
              <div className="col-lg-6 px-0 px-lg-3 mb-3">
                <label className="input-label px-3">{t("Zip_Code")}</label>
                <PrimaryInput
                  type="text"
                  className="form-control "
                  register={register("zip_code", {
                    validate: validateZip,
                  })}
                  placeholder="Zip Code"
                />

                <span className="text-danger ">
                  {errors?.zip_code?.message}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 px-0 px-lg-3 mb-3">
                <label className="input-label ps-3">
                  {" "}
                  {t("address_line1")}
                </label>
                <PrimaryInput
                  type="text"
                  className="form-control "
                  placeholder="Address Line 1"
                  register={register("address_line_1", {
                    validate: valOptionalChar,
                  })}
                />

                <span className="text-danger ">
                  {errors?.address_line_1?.message}
                </span>
              </div>
              <div className="col-lg-6 px-0 px-lg-3">
                <label className="input-label px-3">
                  {" "}
                  {t("address_line2")}
                </label>
                <PrimaryInput
                  type="text"
                  className="form-control"
                  placeholder="Address Line 2"
                  register={register("address_line_2", {
                    validate: valOptionalChar,
                  })}
                />

                <span className="text-danger ">
                  {errors?.address_line_2?.message}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end pt-5 ">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-light  rounded-3"
          >
            {t("cancel")}
          </button>
          <button
            type="submit"
            className="btn btn-primary me-2  ms-4 rounded-3"
          >
            {isLoading ? <ButtonLoader /> : "Save"}
          </button>
        </div>
      </form>
    </FormLayout>
  );
};

export default AddSupplier;
