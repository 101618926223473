import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import moment from "moment";
import {
  TimeStampConverter,
  convertToEpoch,
} from "../../../ApiHelper/TimeStampConverter";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import { toast } from "react-toastify";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import RoleType from "../../../ApiHelper/Roles.json";
// import RoleType from "../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import { CurrencyConverter } from "../../../ApiHelper/CurrencyConverter";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { validateNegative } from "../../../ApiHelper/FormValidation";
import { useTranslation } from "react-i18next";
import DateTimeModal from "../../../Components/modal/DateTimeModal";

const SessionDetails = () => {
  let role = Cookies.get("emp_role");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sessionData, setSessionData] = useState();
  console.log("dfasfasflococococ", location?.state);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isFormFilled2, setIsFormFilled2] = useState(false);
  const [startDateTimeModal, setStartDateTimeModal] = useState(false);
  const startDateTimeToggle = () => setStartDateTimeModal(!startDateTimeModal);
  const [endDateTimeModal, setEndDateTimeModal] = useState(false);
  const endDateTimeToggle = () => setEndDateTimeModal(!endDateTimeModal);
  const [startDateData, setStartDateData] = useState("");
  const [endDateData, setEndDateData] = useState("");
  console.log(startDateData, "startDateData");
  const [completeSubmitFormData, setCompleteSubmitFormData] = useState({
    employees: "",
    purchase: "",
    sales: "",
    profit_loss: "",
    expenses: "",
    meeting_notes: "",
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty: isDirty },
  } = useForm({ mode: "onBlur" });

  const watchAllFields = watch();

  useEffect(() => {
    console.log("watchAllFields", watchAllFields);
    if (
      watchAllFields?.employees &&
      watchAllFields?.expenses &&
      watchAllFields?.meeting_notes &&
      watchAllFields?.profit_loss &&
      watchAllFields?.purchase &&
      watchAllFields?.sales
    ) {
      setCompleteSubmitFormData({
        expenses: watchAllFields?.expenses,
        meeting_notes: watchAllFields?.meeting_notes,
        employees: watchAllFields?.employees,
        purchase: watchAllFields?.purchase,
        sales: watchAllFields?.sales,
        profit_loss: watchAllFields?.profit_loss,
      });
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
    // console.log(",forrmm filleddd....", isFormFilled);
  }, [
    watchAllFields?.employees,
    watchAllFields?.expenses,
    watchAllFields?.meeting_notes,
    watchAllFields?.profit_loss,
    watchAllFields?.purchase,
    watchAllFields?.sales,
  ]);

  useEffect(() => {
    // location?.state?.data?.status === "pending" && setEditMode(true);
    // setSessionData(location?.state?.data);
    getSessionDetail();
  }, []);

  const sessionStartHandler = (status, data) => {
    setIsLoading(true);
    handleFetchRequest(apiUrl.session_update_status, "PUT", data).then(
      (response) => {
        if (response?.status === true) {
          setIsLoading(false);
          setSessionData({ ...sessionData, status: status });
          toast.success(response?.message);
          getSessionDetail();
          setStartDateTimeModal(false);
          setEndDateTimeModal(false);
          if (response?.data?.status === "in progress") {
            setEditMode(true);
          }
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      }
    );
  };

  const onSubmit = (data) => {
    console.log("daddadadataaaa", data);

    const sessionData = {
      ...data,
      employees: +data?.employees,
      purchase: +data?.purchase,
      sales: +data?.sales,
      profit_loss: sales - expenses - purchases,
      expenses: +data?.expenses,
      plan_session_id: location?.state?.data?.id,
    };

    setIsPageLoading(true);
    handleFetchRequest(
      apiUrl.edit_coach_plan_session_detail,
      "PUT",
      sessionData
    ).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);
        toast.success(response.message);
        setEditMode(false);
        getSessionDetail();
        // if (sessionData?.status === "pending") {
        //     sessionStartHandler("in progress", {
        //         plan_session_id: location?.state?.data?.id,
        //         coach_plan_id: location?.state?.data?.coach_plan_id,
        //         status: "in progress",
        //         session_start_ts: convertToEpoch(new Date()),
        //     });
        // }
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const getSessionDetail = () => {
    setIsPageLoading(true);
    handleFetchRequest(
      `${apiUrl.get_session_list}?plan_session_id=${location?.state?.data?.id}`
    ).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);
        // setEditMode(false);
        if (
          response?.data?.meeting_notes &&
          response?.data?.sales &&
          response?.data?.purchase &&
          response?.data?.expenses &&
          response?.data?.profit_loss &&
          response?.data?.employees
        ) {
          setIsFormFilled2(true);
        }
        setCompleteSubmitFormData({
          employees: response?.data?.employees,
          purchase: response?.data?.purchase,
          sales: response?.data?.sales,
          profit_loss: response?.data?.profit_loss,
          expenses: response?.data?.expenses,
          meeting_notes: response?.data?.meeting_notes,
        });
        reset({
          meeting_notes: response?.data?.meeting_notes,
          // country_code: response?.data?.coach_profile?.country_code ? response?.data?.coach_profile?.country_code : "congo +242",
          // hr_evolution: response?.data?.hr_evolution,
          sales: response?.data?.sales,
          view_sales:
            response?.data?.sales &&
            `${CurrencyConverter(response?.data?.sales)}`,
          // country_code: response?.data?.coach_profile?.country_code ? response?.data?.coach_profile?.country_code : "congo +242",
          purchase: response?.data?.purchase,
          view_purchase:
            response?.data?.purchase &&
            `${CurrencyConverter(response?.data?.purchase)}`,
          expenses: response?.data?.expenses,
          view_expenses:
            response?.data?.expenses &&
            `${CurrencyConverter(response?.data?.expenses)}`,
          // country_code: response?.data?.coach_profile?.country_code ? response?.data?.coach_profile?.country_code : "congo +242",
          profit_loss: response?.data?.profit_loss,
          view_profit_loss:
            response?.data?.profit_loss &&
            `${CurrencyConverter(response?.data?.profit_loss)}`,
          employees: response?.data?.employees,
        });
        setSessionData(response?.data);
      }
    });
  };

  const completeHandler = () => {
    const sessionData = {
      employees: +completeSubmitFormData?.employees,
      purchase: +completeSubmitFormData?.purchase,
      sales: +completeSubmitFormData?.sales,
      profit_loss: +completeSubmitFormData?.profit_loss,
      expenses: +completeSubmitFormData?.expenses,
      meeting_notes: completeSubmitFormData?.meeting_notes,
      plan_session_id: location?.state?.data?.id,
    };

    setIsPageLoading(true);
    handleFetchRequest(
      apiUrl.edit_coach_plan_session_detail,
      "PUT",
      sessionData
    ).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);
        toast.success(response.message);
        setEditMode(false);
        getSessionDetail();
      } else {
        setIsPageLoading(false);
      }
    });
    sessionStartHandler("completed", {
      plan_session_id: location?.state?.data?.id,
      coach_plan_id: location?.state?.data?.coach_plan_id,
      status: "completed",
      session_end_ts: convertToEpoch(new Date()),
    });
  };

  const backButtonHandler = () => {
    if (location?.state?.back === "dashboard") {
      navigate(RoutesLink?.dashboard, {
        state: { tabpane: location.state.tabpane },
      });
    } else if (location.state?.linkname?.linkname === "ben_profile") {
      navigate(RoutesLink?.beneficiary_profile, {
        state: {
          ...location?.state?.data,
          id: location?.state?.linkname?.beneficiary_id,
          tabpane: "5",
        },
      });
    } else {
      navigate(-1);
    }
  };

  const sales = watch("sales", 0);
  const expenses = watch("expenses", 0);
  const purchases = watch("purchase", 0);

  // Calculate profit_loss
  const profitLoss = sales - expenses - purchases;

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <Heading backButtonHandler={backButtonHandler} />
                  <div className="d-flex">
                    {role === RoleType.coach_value && (
                      <>
                        {sessionData?.status === "pending" &&
                          editMode === false && (
                            <div>
                              <button
                                className="btn btn-primary"
                                // onClick={() => {
                                //   sessionStartHandler("in progress", {
                                //     plan_session_id: location?.state?.data?.id,
                                //     coach_plan_id:
                                //       location?.state?.data?.coach_plan_id,
                                //     status: "in progress",
                                //     session_start_ts: convertToEpoch(
                                //       new Date()
                                //     ),
                                //   });
                                // }}
                                onClick={startDateTimeToggle}
                              >
                                {isLoading ? <ButtonLoader /> : "Start Session"}
                              </button>
                            </div>
                          )}
                        {sessionData?.status === "in progress" &&
                          editMode === false && (
                            <div>
                              <button
                                className={`btn btn-primary ${
                                  isFormFilled2 ? "" : "disabled"
                                }`}
                                disabled={!isFormFilled2}
                                // onClick={() =>
                                //   sessionStartHandler("completed", {
                                //     plan_session_id: location?.state?.data?.id,
                                //     coach_plan_id:
                                //       location?.state?.data?.coach_plan_id,
                                //     status: "completed",
                                //     session_end_ts: convertToEpoch(new Date()),
                                //   })
                                // }
                                onClick={endDateTimeToggle}
                              >
                                {isLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  "Complete Session"
                                )}
                              </button>
                            </div>
                          )}
                        <div>
                          {editMode === false &&
                            sessionData?.status !== "pending" && (
                              <button
                                className="btn btn-primary ms-1"
                                onClick={() => setEditMode(true)}
                              >
                                {t("Edit_session")}
                              </button>
                            )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {editMode === true && (
                  <div>
                    <div className="pro ">
                      <div className="newcon">
                        <ul className="uln my-1 ">
                          <li>
                            <h5>Coach Plan ID:</h5>{" "}
                            <span>{sessionData?.cp_master?.plan_id}</span>
                          </li>
                          <li>
                            <h5>{t("session_id")}:</h5>{" "}
                            <span>{sessionData?.session_id} </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <form
                      className="form-inline"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div>
                        <div className="row mt-4">
                          <label className="input-label ps-3">
                            {t("employees")}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={`${t("employees")}`}
                            {...register("employees", {
                              // required: "Please enter last name*",
                              validate: validateNegative,
                            })}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^0-9]/g, "")
                                .slice(0, 10);
                            }}
                            step="any"
                          />
                          <span className="text-danger">
                            {errors?.employees?.message}
                          </span>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-6 p-0">
                            <label className="input-label ps-3">
                              {t("sales")}($)
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="number"
                              className="form-control mr-sm-2"
                              id="inlineFormInputName2"
                              placeholder={`${t("sales")}($)`}
                              {...register("sales", {
                                // required: "Please enter Username*",
                                // validate: valChar,
                                validate: validateNegative,
                              })}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 10);
                              }}
                              step="any"
                            />
                            <span className="text-danger ">
                              {errors?.sales?.message}
                            </span>
                          </div>
                          <div className="col-lg-6 p-0 ps-1">
                            <label className="input-label ps-3">
                              {t("purchase")}($)
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="number"
                              className="form-control mr-sm-2"
                              id="inlineFormInputName2"
                              placeholder={`${t("purchase")}($)`}
                              {...register("purchase", {
                                // required: "Please enter Username*",
                                // validate: valChar,
                                validate: validateNegative,
                              })}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 10);
                              }}
                              step="any"
                            />
                            <span className="text-danger ">
                              {errors?.purchase?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-6 p-0">
                            <label className="input-label ps-3">
                              {t("Expenses")}($)
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="number"
                              className="form-control mr-sm-2"
                              id="inlineFormInputName2"
                              placeholder="Expenses($)"
                              {...register("expenses", {
                                // required: "Please enter Username*",
                                // validate: valChar,
                                validate: validateNegative,
                              })}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9]/g, "")
                                  .slice(0, 10);
                              }}
                              step="any"
                            />
                            <span className="text-danger ">
                              {errors?.expenses?.message}
                            </span>
                          </div>
                          <div className="col-lg-6 p-0 ps-1">
                            <label className="input-label ps-3">
                              {t("profit_loss")}($)
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="number"
                              className="form-control mr-sm-2"
                              id="inlineFormInputName2"
                              placeholder={`${t("profit_loss")}($)`}
                              {...register("profit_loss", {
                                // required: "Please enter Username*",
                                // validate: valChar,
                                // validate: validateNegative,
                              })}
                              step="any"
                              value={profitLoss}
                              disabled
                            />
                            <span className="text-danger ">
                              {errors?.profit_loss?.message}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <label className="input-label ps-3">
                            {t("Meeting_Notes")}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <textarea
                            type="text"
                            className="form-control mr-sm-2"
                            id="inlineFormInputName2"
                            placeholder="Meeting Notes"
                            {...register("meeting_notes", {
                              // required: "Please enter First Name*",
                              // validate: valChar
                            })}
                          />
                          {/* <span className="text-danger ">{errors?.first_name?.message}</span> */}
                        </div>
                      </div>

                      <div className="d-flex justify-content-end pt-5 ">
                        <button
                          onClick={() => setEditMode(false)}
                          type="button"
                          className="btn btn-light  rounded-3"
                        >
                          {t("cancel")}
                        </button>
                        <button
                          type="submit"
                          className={`btn btn-primary mx-2 ${
                            isDirty ? "" : "disabled"
                          }`}
                          disabled={!isDirty}
                        >
                          {"Save"}
                        </button>

                        {sessionData?.status !== "completed" && (
                          <button
                            type="button"
                            onClick={completeHandler}
                            className={`btn btn-primary ${
                              isFormFilled ? "" : "disabled"
                            }`}
                            disabled={!isFormFilled}
                          >
                            {"Complete Session"}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                )}
                {editMode === false && (
                  <>
                    <div className="pro ">
                      <div className="newcon">
                        <ul class="uln my-1 ">
                          <div className="d-flex col-lg-12">
                            <div className="col-lg-5">
                              <li>
                                <h5>{t("beneficiary_name")}:</h5>{" "}
                                <span>
                                  {" "}
                                  {
                                    sessionData?.cp_master?.beneficiary
                                      ?.fullname
                                  }{" "}
                                </span>
                              </li>
                              <li>
                                <h5>Coach Plan ID:</h5>{" "}
                                <span>{sessionData?.cp_master?.plan_id}</span>
                              </li>
                              <li>
                                <h5>{t("session_id")}:</h5>{" "}
                                <span>{sessionData?.session_id} </span>
                              </li>
                              <li>
                                <h5>Schedule Start (DD/MM/YYYY) : </h5>{" "}
                                <span>
                                  {sessionData?.schedule_start_ts &&
                                    moment(
                                      TimeStampConverter(
                                        sessionData?.schedule_start_ts
                                      )
                                    ).format("DD/MM/YYYY hh:mm a")}{" "}
                                </span>
                              </li>
                            </div>
                            <div className="col-lg-5">
                              <li>
                                <h5>{t("ben_contact")}:</h5>{" "}
                                <span>
                                  {
                                    sessionData?.cp_master?.beneficiary
                                      ?.telephone_pref
                                  }
                                </span>
                              </li>
                              <li>
                                <h5>Schedule End (DD/MM/YYYY) : </h5>{" "}
                                <span>
                                  {sessionData?.schedule_end_ts &&
                                    moment(
                                      TimeStampConverter(
                                        sessionData?.schedule_end_ts
                                      )
                                    ).format("DD/MM/YYYY hh:mm a")}{" "}
                                </span>
                              </li>
                              <li>
                                <h5>Session Start (DD/MM/YYYY) : </h5>{" "}
                                <span>
                                  {sessionData?.session_start_ts &&
                                    moment(
                                      TimeStampConverter(
                                        sessionData?.session_start_ts
                                      )
                                    ).format("DD/MM/YYYY hh:mm a")}{" "}
                                </span>
                              </li>
                              <li>
                                <h5>Session End (DD/MM/YYYY) : </h5>{" "}
                                <span>
                                  {sessionData?.session_end_ts &&
                                    moment(
                                      TimeStampConverter(
                                        sessionData?.session_end_ts
                                      )
                                    ).format("DD/MM/YYYY hh:mm a")}{" "}
                                </span>
                              </li>
                            </div>
                          </div>

                          {/*  <li><h5>Registeration Site ID:</h5> <span>23</span></li> */}
                          {/* <li><h5>Loterie:</h5><span>234</span></li> */}
                          <li>
                            <div class="abs-btn">
                              <span class="active-button  badge">
                                {sessionData?.status}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <form
                      className="form-inline"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div>
                        <div className="row mt-4">
                          <label className="input-label ps-3">
                            {t("employees")}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Employees"
                            {...register("employees", {
                              // required: "Please enter last name*",
                              // validate: valChar,
                            })}
                            step="any"
                            disabled
                          />
                          {/* <span className="text-danger">
                                                    {errors?.last_name?.message}
                                                </span> */}
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-6 p-0">
                            <label className="input-label ps-3">
                              {t("sales")}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <div className="d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control mr-sm-2"
                                id="inlineFormInputName2"
                                placeholder="Sales"
                                {...register("view_sales", {})}
                                step="any"
                                disabled
                              />
                            </div>
                            {/* <span className="text-danger ">{errors?.username?.message}</span> */}
                          </div>
                          <div className="col-lg-6 p-0  ps-1">
                            <label className="input-label ps-3">
                              {t("purchase")}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              className="form-control mr-sm-2"
                              id="inlineFormInputName2"
                              placeholder="Purchase"
                              {...register("view_purchase", {
                                // required: "Please enter Username*",
                                // validate: valChar,
                              })}
                              step="any"
                              disabled
                            />
                            {/* <span className="text-danger ">{errors?.username?.message}</span> */}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-6 p-0">
                            <label className="input-label ps-3">
                              {t("Expenses")}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              className="form-control mr-sm-2"
                              id="inlineFormInputName2"
                              placeholder="Expenses"
                              {...register("view_expenses", {
                                // required: "Please enter Username*",
                                // validate: valChar,
                              })}
                              step="any"
                              disabled
                            />
                            {/* <span className="text-danger ">{errors?.username?.message}</span> */}
                          </div>
                          <div className="col-lg-6 p-0 ps-1">
                            <label className="input-label ps-3">
                              {t("Profit_Loss")}
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              className="form-control mr-sm-2"
                              id="inlineFormInputName2"
                              placeholder="Profit Loss"
                              {...register("view_profit_loss", {
                                // required: "Please enter Username*",
                                // validate: valChar,
                              })}
                              step="any"
                              disabled
                            />
                            {/* <span className="text-danger ">{errors?.username?.message}</span> */}
                          </div>
                        </div>
                        <div className="row mt-4">
                          <label className="input-label ps-3">
                            {t("Meeting_Notes")}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <textarea
                            type="text"
                            className="form-control mr-sm-2"
                            id="inlineFormInputName2"
                            placeholder="Meeting Notes"
                            disabled
                            {...register("meeting_notes", {
                              // required: "Please enter First Name*",
                              // validate: valChar
                            })}
                          />
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPageLoading && <PageLoader />}
      <DateTimeModal
        modal={startDateTimeModal}
        toggle={startDateTimeToggle}
        isLoading={isLoading}
        setDateTimeData={setStartDateData}
        selectedDate={startDateData}
        SubmitHandler={() =>
          sessionStartHandler("in progress", {
            plan_session_id: location?.state?.data?.id,
            coach_plan_id: location?.state?.data?.coach_plan_id,
            status: "in progress",
            session_start_ts: convertToEpoch(startDateData),
          })
        }
        btn="Start Session"
        title="Start Session"
      />
      <DateTimeModal
        modal={endDateTimeModal}
        toggle={endDateTimeToggle}
        isLoading={isLoading}
        setDateTimeData={setEndDateData}
        selectedDate={endDateData}
        SubmitHandler={() =>
          sessionStartHandler("completed", {
            plan_session_id: location?.state?.data?.id,
            coach_plan_id: location?.state?.data?.coach_plan_id,
            status: "completed",
            session_end_ts: convertToEpoch(endDateData),
          })
        }
        btn="Complete Session"
        title="Complete Session"
      />
    </Layout>
  );
};

export default SessionDetails;
