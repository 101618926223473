import { Link } from "react-router-dom";
// import PrimaryInput from "../Inputs/PrimaryInput";
// import ActionDropDown from "./ActionDropDown";
// import { RoutesLink } from "../../ApiHelper/RoutesLink";
import ActionDropDown2 from "./ActionDropdown2";
import AppHeading from "./Headings";
import texts from "../../ApiHelper/Nomanclatures.json";
import RoleType from "../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import ReactDatePicker from "react-datepicker";
import { convertToEpoch } from "../../ApiHelper/TimeStampConverter";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Heading = ({
  searchOption1Array,
  searchOption2Array,
  name,
  downloadToggle,
  useraccount,
  setMainCallApi,
  addArray,
  btnName,
  setSearchOptionData,
  setFilterData,
  inputTypeData,
  setInputTypeData1,
  setData,
  addLink,
  editBtn,
  btnCickFunction,
  btnClickName,
  cartItem,
  deleteBeneficiary,
  setActivePage,
  itemCount,
  backButtonHandler,
  showCheckoutBox,
  filterData,
  ResetAccountHandler,
  inputCategoryData,
  setInputCategoryData,
  subventionList,
  customClassName,
}) => {
  const { t } = useTranslation();
  let role = Cookies.get("emp_role");

  const startYear = 1950;
  const currentYear = new Date().getFullYear();

  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const searchHandler = () => {
    setActivePage(1);
    // if (inputTypeData === "date") {
    //   let dateValue = filterData;
    //   setFilterData(
    //     `${convertToEpoch(dateValue.start_date)}&end_ts=${convertToEpoch(dateValue.end_date)}`);
    // }
    setMainCallApi(true);
    if (typeof filterData === "object" && filterData.end_date) {
      setData([]);
    } else if (typeof filterData === "string") {
      setData([]);
    }
  };
  const [disabledTimeSlot, setDesabledTimeSlot] = useState([]);
  const DateValueHandler = (type, date) => {
    if (type === "start_date") {
      const startDate = new Date(date); // Assuming value is the selected date
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0); // Set the time to 12:00 AM
      const timeSlots = [];
      for (let hour = 0; hour <= startDate.getHours(); hour++) {
        for (let minute = 0; minute < 60; minute++) {
          const slot = new Date(startOfDay);
          slot.setHours(hour, minute, 0, 0);
          timeSlots.push(slot);
        }
      }
      setDesabledTimeSlot(timeSlots);

      setFilterData(
        // `started_ts=${1707507000}&end_ts=${1707510605}`
        { ...filterData, start_date: date, end_date: "" }
      );
    } else if (type === "end_date") {
      setFilterData({ ...filterData, end_date: date });
    }
  };

  // const statusChangeHandler = (e) => {
  //   console.log("statussss...", e?.target?.value);
  //   setSearchOptionData(e?.target?.value);
  //   // setFilterStatus(e.target.value);
  // };
  // const roleChangeHandler = (e) => {
  //   console.log("role...", e?.target?.value);
  //   setFilterRole(e.target.value);
  // };

  const filterValueHandler = (e) => {
    setFilterData(e.target.value);
  };

  const statusChangeHandler = (e) => {
    console.log(e.target.value);
    setInputCategoryData(e.target.value);
    setFilterData("");
    let inputType = e.target.value.split("###")?.[1];
    setInputTypeData1(inputType);
    let data = e.target.value.split("###")?.[0];
    setSearchOptionData(data);
  };

  return (
    <div className="flex-column gap-3 p-0">
      <div className="d-flex flex-column flex-md-row gap-2 justify-content-md-between align-items-md-center mb-3 mb-md-0">
        {/* <h4 className="mb-0">{name}</h4> */}
        <AppHeading title={name} backButtonHandler={backButtonHandler} />

        {role !== RoleType.viewer_value && (
          <div className="d-flex flex-column flex-md-row gap-2">
            {btnName && (
              <Link
                to={addLink.link}
                state={addLink.data}
                className="btn btn-primary w-sm-100"
              >
                <span className="mdi mdi-plus" />
                {btnName}
              </Link>
            )}

            {deleteBeneficiary ? (
              <button className="btn btn-primary" onClick={btnCickFunction}>
                {btnClickName}
              </button>
            ) : (
              ""
            )}
            {/* {showCheckoutBox==0?<button  className="btn btn-primary mx-1" onClick={btnCickFunction}>
          {btnClickName}
        </button>:""} */}
            {showCheckoutBox === 0 && subventionList?.length === 0 && (
              <button
                className="btn btn-primary ms-1"
                onClick={btnCickFunction}
              >
                <span className="mdi mdi-cart-outline"></span>
                {itemCount?.data?.length === 0 && "Create Subvention"}

                {itemCount?.data?.length > 0 && (
                  <>
                    {" "}
                    Update Subvention{" "}
                    <span className="qty"> {itemCount?.data?.length} </span>
                    <span>Items</span>{" "}
                  </>
                )}
              </button>
            )}
            {editBtn && (
              <Link
                to={editBtn?.route}
                state={editBtn?.state}
                className="btn btn-primary"
              >
                <span className="mdi mdi-plus" />
                {editBtn?.btnName}
              </Link>
            )}
          </div>
        )}
      </div>
      <div className=" mt-2">
        <div className="form-group">
          <div>
            {searchOption1Array?.length > 0 ? (
              <div className={`searchbox-headingpg ${customClassName}`}>
                <select
                  className="form-control tabel-input"
                  value={inputCategoryData}
                  onChange={statusChangeHandler}
                  // selected={inputCategoryData}
                >
                  {searchOption1Array?.map((data, index) => (
                    <option
                      key={index}
                      value={`${data?.value}###${data?.type}`}
                    >
                      {data?.name}
                    </option>
                  ))}
                </select>
                {inputTypeData === "select" ? (
                  <select
                    className="form-control tabel-input"
                    // style={{ width: "200px" }}
                    onChange={filterValueHandler}
                  >
                    <option value="">{t("select_value")}</option>
                    {searchOption2Array?.map((data) => (
                      <>
                        <option value={data?.value}>{data?.name}</option>
                      </>
                    ))}
                    {/* <option value={"coach"}>Coach</option>
                  <option value={"world_bank"}>World Bank</option>
                  <option value={"admin"}>Admin</option> */}
                  </select>
                ) : inputTypeData === "text" ? (
                  <input
                    className="form-control tabel-input"
                    placeholder={t("search_text")}
                    value={filterData}
                    onChange={filterValueHandler}
                  />
                ) : inputTypeData === "date" ? (
                  <>
                    <ReactDatePicker
                      className="form-control tabel-input"
                      placeholderText={"From Date"}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {console.log("dateeeeeeee..... from ", date)}
                          <button
                            onClick={decreaseMonth}
                            type="button"
                            disabled={prevMonthButtonDisabled}
                          >
                            {"<"}
                          </button>
                          <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) =>
                              changeYear(value)
                            }
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[date?.getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button
                            onClick={increaseMonth}
                            type="button"
                            disabled={nextMonthButtonDisabled}
                          >
                            {">"}
                          </button>
                        </div>
                      )}
                      showTimeSelect
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy h:mm aa"
                      onChange={(d) => DateValueHandler("start_date", d)}
                      selected={filterData?.start_date}
                    />
                    <ReactDatePicker
                      excludeTimes={disabledTimeSlot}
                      minDate={filterData?.start_date}
                      disabled={!filterData?.start_date}
                      className="form-control  tabel-input"
                      placeholderText={"To Date"}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {console.log("dateeeeeeee..... from ", date)}
                          <button
                            onClick={decreaseMonth}
                            type="button"
                            disabled={prevMonthButtonDisabled}
                          >
                            {"<"}
                          </button>
                          <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) =>
                              changeYear(value)
                            }
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[date?.getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button
                            onClick={increaseMonth}
                            type="button"
                            disabled={nextMonthButtonDisabled}
                          >
                            {">"}
                          </button>
                        </div>
                      )}
                      showTimeSelect
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy h:mm aa"
                      onChange={(d) => DateValueHandler("end_date", d)}
                      selected={filterData.end_date}
                    />
                  </>
                ) : (
                  ""
                )}
                <button className="btn btn-primary" onClick={searchHandler}>
                  {t("search")}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={ResetAccountHandler}
                >
                  {t("reset")}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end mb-2">
          {addArray && (
            <div className="dropdown">
              <a
                className="nav-link"
                id="messageDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="rounded-3 bg-light mdi mdi-filter-variant  px-2 py-2 text-muted">
                  {" "}
                  Actions
                </span>
              </a>
              <div
                className="dropdown-menu-table dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="messageDropdown"
              >
                <ActionDropDown2
                  downloadToggle={downloadToggle}
                  addArray={addArray}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Heading;
