import Cookies from "js-cookie";
import BackButton from "./BackButton";
import ProfileCard from "./ProfileCard";
import Roles from "../../ApiHelper/Roles.json";

const ProfileHeader = ({ fullName, backButtonHandler, profileImage }) => {
  let role = Cookies.get("emp_role");
  const initials = fullName?.split(' ')?.map(word => word[0])?.join('').toUpperCase();

  return (<div className="gradiantbox position-relative">
    <BackButton clickFunction={backButtonHandler} />
    <div className="bg-white position-absolute p-0 border d-inline-block user-contianer">

      <ProfileCard initials={initials} fullName={fullName} profileImage={profileImage} />
    </div>
  </div>)
}

export default ProfileHeader;