import React from "react";
import { NavItem, TabContent, TabPane } from "reactstrap";
import Layout from "../../../Components/Layout/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import Cookies from "js-cookie";
// import { Roles } from "../../../ApiHelper/Nomanclatures";
import Roles from "../../../ApiHelper/Roles.json";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import RoleType from "../../../ApiHelper/Roles.json";
import { TimeStampConverter } from "../../../ApiHelper/TimeStampConverter";
import ProfileHeader from "../../../Components/CommonElements/ProfileHeader";
import moment from "moment";
import { CurrencyConverter } from "../../../ApiHelper/CurrencyConverter";
import { useTranslation } from "react-i18next";

const CooperativeDetail = () => {
  const roleType = Cookies.get("emp_role");
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [searchOptionData, setSearchOptionData] = useState("global_search");
  const [isLoading, setIsLoading] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [tabOpen, setTabOpen] = useState("1");
  const [filterData, setFilterData] = useState();
  let role = Cookies.get("emp_role");

  const location = useLocation();
  console.log("loccc", location?.state);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();
  const [pages, setPages] = useState();

  const handlePageChange = (pageitem) => {
    setActivePage(pageitem);
  };

  const listHeading = [
    { data: `${t("Reference_ID")}` },
    { data: `${t("full_name")}` },
    { data: `${t("contact_detail")}` },
    { data: `${t("province")}` },
    { data: `${t("grant_status")}` },
    { data: `${t("status")}` },
  ];

  const setTabValue = (data) => {
    setTabOpen(data);
    console.log(tabOpen);
  };

  useEffect(() => {
    console.log("locationnnn.....", location.state);
    // if (location?.state?.tabid) {
    //   setTabOpen(location?.state?.tabid);
    // }
    setPageIsLoading(true);
    handleFetchRequest(
      `${apiUrl?.get_cooperative_detail}?cooperative_id=${location?.state?.id}`
    ).then((response) => {
      if (response?.status === true) {
        setProfileData(response?.data);
        setPageIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
    if (
      roleType === Roles?.admin_value ||
      roleType === Roles?.coach_value ||
      roleType === Roles?.viewer_value
    ) {
      // getBeneficiaryList(activePage);
    }
  }, []);

  const filters = [];

  const applyFilter = () => {
    const filterValue = filterData ? `&${searchOptionData}=${filterData}` : "";
    filters.push(filterValue);
  };

  const backButtonHandler = () => {
    navigate(-1);
  };

  const deleteHandler = () => {
    // setShowDeleteBox(true);
    navigate(RoutesLink.assign_beneficiary, {
      state: { id: location.state.id, type: "unassign", param: "profile" },
    });
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-12 px-0 px-lg-3">
            <div className="card">
              <ProfileHeader
                fullName={profileData?.cooperative_name}
                backButtonHandler={backButtonHandler}
              />
              <div className="dummypj">
                <div className="page-header mx-3 mb-0 position-relative supplier-tab">
                  <ul className=" nav nav-tabs tabHolder d-flex flex-nowrap overflow-x-auto ">
                    <NavItem className="nav-item mb-0">
                      <div
                        // className={tabOpen == '1' ? "nav-link d-flex flex-column justify-content-center align-items-center active" : "nav-link"}
                        className={
                          tabOpen === "1"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("1")}
                      >
                        <div className="text-center font-weight-bold">
                          {t("cooperative")}
                        </div>
                      </div>
                    </NavItem>
                    {
                      <NavItem class="nav-item mb-0">
                        <div
                          className={
                            tabOpen === "2"
                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                          }
                          onClick={() => setTabValue("2")}
                        >
                          <div class="text-center font-weight-bold">
                            {t("Beneficiaries")}
                          </div>
                        </div>
                      </NavItem>
                    }
                  </ul>
                  <div className="absolute-border"></div>
                </div>
                <div className="row">
                  <TabContent activeTab={tabOpen}>
                    <TabPane tabId="1">
                      <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
                        <div className="card-body">
                          <div className="pro">
                            {profileData && (
                              <div className="newcon">
                                <div className="d-flex flex-column flex-md-row justify-content-between mb-2">
                                  <h4>Cooperative information</h4>
                                  {roleType === Roles?.coach_value ||
                                  roleType === Roles?.admin_value
                                    ? tabOpen === "1" && (
                                        <div className="position-relative d-flex justify-content-end">
                                          <div className="grant w-100">
                                            <Link
                                              className="btn w-100 btn-primary"
                                              to={RoutesLink?.add_cooperatives}
                                              state={{
                                                id: profileData?.id,
                                                data: profileData,
                                              }}
                                            >
                                              Edit Cooperative
                                            </Link>
                                          </div>
                                        </div>
                                      )
                                    : ""}
                                </div>
                                <ul className="uln">
                                  <li>
                                    <h5>{t("cooperative_name")}</h5>{" "}
                                    <span>{profileData?.cooperative_name}</span>
                                  </li>
                                  {/* <li><h5>Business Type</h5> <span>{profileData?.business_type}</span></li> */}
                                  <li>
                                    <h5>Sector Activity</h5>{" "}
                                    <span>
                                      {profileData?.sector_activity &&
                                        profileData?.sector_activity
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          profileData?.sector_activity?.slice(
                                            1
                                          )}
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("sales")}</h5>{" "}
                                    <span>
                                      {profileData?.sales
                                        ? CurrencyConverter(profileData?.sales)
                                        : "N/A"}
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("Created_At")}</h5>{" "}
                                    <span>
                                      {profileData?.createdAt
                                        ? moment(
                                            TimeStampConverter(
                                              profileData?.createdAt
                                            )
                                          ).format("DD/MM/YYYY")
                                        : "N/A"}
                                    </span>
                                  </li>
                                  {roleType === Roles?.admin_value && (
                                    <>
                                      <li>
                                        <h5>{t("coach_username")}</h5>{" "}
                                        <span>
                                          {profileData?.account?.username}
                                        </span>
                                      </li>
                                      <li>
                                        <h5>Coach Alias Id </h5>{" "}
                                        <span>
                                          {profileData?.account?.alias_id}
                                        </span>
                                      </li>
                                    </>
                                  )}
                                  <li>
                                    <div className="abs-btn">
                                      {/* {/ { <button className="absbtn">Assigned</button>} /} */}
                                      {profileData?.status === 1 ? (
                                        <button className="acbtn badge">
                                          Active
                                        </button>
                                      ) : (
                                        <button className="acbtn">
                                          Inactive
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="row d-flex justify-content-start">
                              {roleType === Roles?.admin_value && (
                                <div className="my-2 text-end px-0">
                                  <Link
                                    to={RoutesLink?.assign_beneficiary}
                                    state={{
                                      id: location?.state?.id,
                                      type: "assign",
                                      page: "cooperative",
                                      pageurl: RoutesLink.cooperative_detail,
                                    }}
                                    className="btn btn-primary"
                                  >
                                    {/* <span className="mdi mdi-plus" /> */}
                                    {t("assign_beneficiairy")}
                                  </Link>

                                  {profileData?.cooperative_participants
                                    ?.length > 0 && (
                                    <Link
                                      to={RoutesLink?.assign_beneficiary}
                                      state={{
                                        id: location?.state?.id,
                                        type: "unassign",
                                        page: "cooperative",
                                        pageurl: RoutesLink.cooperative_detail,
                                      }}
                                      className="btn btn-primary ms-2"
                                    >
                                      {/* <span className="mdi mdi-plus" /> */}
                                      {t("assign_beneficiairy")}
                                    </Link>
                                  )}
                                </div>
                              )}
                              <div className="ptable px-0">
                                <div className={"tablcont"}>
                                  <PrimaryTable
                                    listHeading={listHeading}
                                    listData={
                                      profileData?.cooperative_participants
                                    }
                                    cooperatives_beneficiary={true}
                                    // showDeleteBox={showDeleteBox}
                                    // checkboxFunction={AssignCheckboxBeneficiaries}
                                    // assignAllCheckbox={assignAllBeneficiaryHandler}
                                    activePage={activePage}
                                    pages={pages}
                                    handlePageChange={handlePageChange}
                                    isLoading={isLoading}
                                    idClickLink={
                                      RoutesLink?.beneficiary_profile
                                    }
                                  />
                                </div>
                                {/* {showDeleteBox &&
                          <AssignBeneficiaryCard
                            type="delete"
                            assignedBeneficiaryList={assignedBeneficiaryList}
                            deleteBeneficiaryHandler={deleteBeneficiaryHandler}
                            cancelAssignedListHandler={cancelAssignedListHandler}
                            submitAssignmentHandler={submitAssignmentHandler}
                          />
                        } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
            {pageIsLoading && <PageLoader />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CooperativeDetail;
