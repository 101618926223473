const PrimaryInput = ({
  type,
  placeholder,
  register,
  defaultValue,
  className,
  onChange,
  style,
  id,
  step,
}) => {
  return (
    <input
      type={type}
      onChange={onChange}
      id={id}
      className={className}
      step={step}
      defaultValue={defaultValue}
      style={style}
      placeholder={placeholder}
      {...register}
    />
  );
};
export default PrimaryInput;
