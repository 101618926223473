import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import PageLoader from "../CommonElements/PageLoader";

const DateTimeModal = ({
  modal,
  toggle,
  SubmitHandler,
  setDateTimeData,
  isLoading,
  selectedDate,
  btn,
  title,
}) => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const currentYear = new Date().getFullYear();
  const startYear = 1950;

  const onSubmit = (data) => {
    if (selectedDate) {
      const submitData = {
        ...data,
      };
      SubmitHandler(submitData, reset);
    } else {
      toast.error("Please select a date");
    }
  };

  const handleClose = () => {
    reset();
    toggle();
  };

  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateChange = (date) => {
    setDateTimeData(date); // Example: Assuming setDateRangeData updates the state with the selected date
    console.log(date, "date");
  };

  return (
    <>
      <div
        className="modal fade grid-inputs"
        id="addgroupcndata"
        tabIndex="-1"
        aria-labelledby="addgroupcndata"
        aria-hidden="true"
      >
        <Modal
          style={{ maxWidth: "500px" }}
          isOpen={modal}
          centered
          toggle={handleClose}
          //   className="modal-dialog modal-dialog-centered modal-lg"
        >
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="addbenepop" style={{ width: "100%" }}>
              <div className="assben">
                <div className="behd">
                  <h4>{`Select ${title} Date Time`}</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                  ></button>
                  <ul>
                    <li>
                      <div className="bens">
                        <ReactDatePicker
                          dateFormat="dd/MM/yyyy h:mm aa"
                          onChange={handleDateChange} // Pass handleDateChange function here
                          selected={selectedDate ?? new Date()}
                          style={{ zIndex: 9999 }}
                          className="form-control"
                          placeholderText={"Start Date/Time"}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                onClick={decreaseMonth}
                                type="button"
                                disabled={prevMonthButtonDisabled}
                              >
                                {"<"}
                              </button>
                              <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={months[date?.getMonth()]} // Corrected from months[date?.getMonth(date)]
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                onClick={increaseMonth}
                                type="button"
                                disabled={nextMonthButtonDisabled}
                              >
                                {">"}
                              </button>
                            </div>
                          )}
                          showTimeSelect
                          timeIntervals={60}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="btnboxs">
                  <button className="btn btn-primary rounded-0" type="submit">
                    {btn}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </div>
      {isLoading && <PageLoader />}
    </>
  );
};

export default DateTimeModal;
