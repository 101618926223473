import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [sidebarShow, setSidebarShow] = useState(false);
  const sidebarToggle = () => setSidebarShow(!sidebarShow);
  return (
    <div className="container-scroller">
      <Header sidebarToggle={sidebarToggle} />
      <div className="container-fluid page-body-wrapper  ">
        <Sidebar sidebarShow={sidebarShow} />
        <div className="main-panel">{children}</div>
      </div>
    </div>
  );
};
export default Layout;
