import Cookies from "js-cookie";

export const CurrencyConverter = (priceInDollar) => {
  // let userData = JSON.parse(Cookies?.get("emp_data"));
  let currencyType = Cookies.get("emp_currency");
  let conversionRate = Cookies.get("emp_conversion");
  // Convert the price to rupees
  const priceInCDF = priceInDollar * conversionRate;
  // Round the result to two decimal places
  let dollarRate = `$ ${parseFloat(priceInDollar).toFixed(2)}`;
  let cdfRate = `CDF ${parseFloat(priceInCDF).toFixed(2)}`;
  if (currencyType === "usd") {
    return dollarRate;
  } else {
    return cdfRate;
  }
};

export const CurrencyConverter2 = (priceInDollar) => {
  // let userData = JSON.parse(Cookies?.get("emp_data"));
  let currencyType = Cookies.get("emp_currency");
  let conversionRate = Cookies.get("emp_conversion");
  // Convert the price to rupees
  const priceInCDF = priceInDollar * conversionRate;
  // Round the result to two decimal places
  let dollarRate = parseFloat(priceInDollar).toFixed(2);
  let cdfRate = parseFloat(priceInCDF).toFixed(2);
  if (currencyType === "usd") {
    return dollarRate;
  } else {
    return cdfRate;
  }
};