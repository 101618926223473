import moment from "moment";
import React, { useState } from "react";
import Chart from "react-apexcharts";

const App = ({ chartData }) => {
    // Assuming you want to use data from the props
    // let dateValue = props.charData.subvention_graph;
    console.log("props.............", chartData);
    // const dateArray = props?.chartData?.subvention_graph?.map(item => moment(item?.date).format("DD/MM/YYYY"));
    // const numberArray = props?.chartData?.subvention_graph?.map(item => +item?.total_amount);
    // console.log("dateArrayyyyyy", dateArray, numberArray);

    // const [chartData, setChartData] = useState({
    //     options: {
    //         chart: {
    //             id: "basic-bar"
    //         },
    //         xaxis: {
    //             categories: dateArray,
    //         }
    //     },
    //     series: [
    //         {
    //             name: "series-1",
    //             data: numberArray
    //         }
    //     ]
    // });

    return (
        <div className="row">
            <Chart
                options={chartData?.options}
                series={chartData?.series}
            // type="line"
            // width=""
            />
        </div>
    );
};

export default App;
