const InputLayout1 = ({ children }) => {
  return (
    <div className="col-md-6">
      <div className="form-group row">
        <div className="col-sm-9">
          {children}
        </div>
      </div>
    </div>
  )
}
export default InputLayout1;