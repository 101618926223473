// import logo from './logo.svg';
import './App.css';
import CommonRoutes from './Routes/CommonRoutes';
import { useEffect } from 'react';
import { handleFetchRequest } from './ApiHelper/ApiHelper';
// import { apiUrl } from './ApiHelper/ApiUrl';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { noticationDetail, profileImage, setUserLoggedInDetails } from './store/actions/UserActions';
import RoleType from "../src/ApiHelper/Roles.json";
import { useNavigate } from 'react-router-dom';
import { RoutesLink } from './ApiHelper/RoutesLink';
import { apiUrl } from './ApiHelper/ApiUrl';
// import { ScrollRestoration } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App({ children }) {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  let role = Cookies.get("emp_role");
  // const navigate = useNavigate();
  useEffect(() => {

    if (role === RoleType.coach_value) {
      const imageFromCookies = Cookies.get("emp_profile");
      const newProfileImage = imageFromCookies !== "null" ? imageFromCookies : null;
      dispatch(profileImage(newProfileImage));
    }

    if (role === RoleType.coach_value || role === RoleType.admin_value) {
      handleFetchRequest(`notification/v1/notification-list`).then((response) => {
        // console.log("responseee ..e ee.e .e e.", response);
        if (response?.status === true) {
          dispatch(noticationDetail(response.data));
        }
        else if (response?.messageCode === 3) {
          // alert("hello world");
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_time_zone");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          // navigate(RoutesLink.login);
        }
      })
    }
    if (role === RoleType.coach_value) {

      handleFetchRequest(`${apiUrl?.get_coach_profile}?coach_id=${""}`).then(response => {
        if (response?.status === true) {
          // setProfileData(response?.data);
          // setPageIsLoading(false);

          {
            role === RoleType.coach_value
              &&
              dispatch(profileImage(response?.data?.coach_profile?.profile_image));
            Cookies.set("emp_profile", response?.data?.coach_profile?.profile_image);
          }
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_time_zone");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          // setIsLoading(false);
          // navigate(RoutesLink.login);
        }
      })
    }

  }, [])

  if (Cookies?.get("emp_data")) {
    let data = Cookies?.get("emp_data");
    dispatch(setUserLoggedInDetails(JSON.parse(data)));
  }
  return (
    <>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {children}
        <CommonRoutes />
      </LocalizationProvider>

    </>
  );
}

export default App;

