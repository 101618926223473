import { useEffect, useState } from "react";
import { CountryCode } from "../../../ApiHelper/CountryCode";
// import Layout from "../../../Components/Layout/Layout";
import { useForm } from "react-hook-form";
import PrimaryInput from "../../../Components/Inputs/PrimaryInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { toast } from "react-toastify";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import FormLayout from "../../../Components/Layout/FormLayout";
// import InputLayout1 from "../../../Components/Layout/InputLayout1";
import ReactDatePicker from "react-datepicker";
import AppHeading from "../../../Components/CommonElements/Headings";
import {
  valOptionalChar,
  validateEmail,
  validateMobileNumber,
  validateName,
  validateZip,
} from "../../../ApiHelper/FormValidation";
import { useRef } from "react";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import Roles from "../../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import { useAutocomplete } from '@mui/base/useAutocomplete';
// import { Autocomplete, TextField } from "@mui/material";

const AddUserProfile = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(
    "Congo, The Democratic Republic of the Congo +243"
  );
  const { t } = useTranslation();
  const [searchTerm2, setSearchTerm2] = useState("");
  const [searchTerm3, setSearchTerm3] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(CountryCode);
  const [filteredCountries2, setFilteredCountries2] = useState(CountryCode);
  const [filteredCountries3, setFilteredCountries3] = useState(CountryCode);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);

  const inputRef = useRef(null);

  // const [startDate, setStartDate] = useState(new Date());
  const startYear = 1950;
  let minDob = moment(new Date()).subtract(18, "years").toDate();
  const currentYear = new Date(minDob).getFullYear();
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fileInputRef = useRef(null);
  const role = useSelector((data) => data?.user?.userDetails?.role);
  console.log(role);
  const navigate = useNavigate();

  const [startDate, setDate] = useState();
  const location = useLocation();
  const [imagePreview, setImagePreview] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [profileData, setProfileData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  console.log("locococococo...", location.state);

  const selectDateHandler = (d) => {
    if (new Date(d).getFullYear() <= currentYear) {
      console.log(d);
      setDate(d);
    } else {
      let newDate = new Date(d); // Get today's date
      newDate.setFullYear(1950); // Set the year to 1950
      // console.log(newDate);
      setDate(newDate);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch, //This method will watch specified inputs and return their values. It is useful to render input value and for determining what to render by condition.
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    //API which fetches the coach profile
    if (location?.state?.id || location?.state?.data?.id) {
      handleFetchRequest(
        `${apiUrl?.get_coach_profile}?coach_id=${
          location?.state?.id || location.state.data?.id
        }`
      ).then((response) => {
        if (response?.status === true) {
          setProfileData(response?.data);
          setImagePreview(response?.data?.coach_profile?.profile_image);
          setImageUrl(response?.data?.coach_profile?.profile_image);
          const date = response?.data?.coach_profile?.dob
            ? new Date(response?.data?.coach_profile?.dob)
            : null;
          setDate(date);
          const countryCodeData = response?.data?.country_code
            ? response?.data?.country_code
            : "Congo, The Democratic Republic of the Congo +243";
          setSearchTerm(countryCodeData);
          setSearchTerm3(
            response?.data?.coach_profile?.business_address?.country
          );
          setSearchTerm2(
            response?.data?.coach_profile?.physical_address?.country
          );
          //resets the the form data with the response of the coach profile api
          reset({
            phone: response?.data?.mobile,
            // country_code: response?.data?.coach_profile?.country_code ? response?.data?.coach_profile?.country_code : "congo +242",
            email: response?.data?.coach_profile?.email,
            date: response?.data?.coach_profile?.dob,

            //physical address
            physical_address_line_1:
              response?.data?.coach_profile?.physical_address?.address_line1,
            physical_address_line_2:
              response?.data?.coach_profile?.physical_address?.address_line2,
            physical_address_city:
              response?.data?.coach_profile?.physical_address?.city,
            physical_address_state:
              response?.data?.coach_profile?.physical_address?.state,
            physical_address_country:
              response?.data?.coach_profile?.physical_address?.country,
            physical_address_zip_code:
              response?.data?.coach_profile?.physical_address?.zip_code,

            // business address
            business_address_line_1:
              response?.data?.coach_profile?.business_address?.address_line1,
            business_address_line_2:
              response?.data?.coach_profile?.business_address?.address_line2,
            business_address_city:
              response?.data?.coach_profile?.business_address?.city,
            business_address_state:
              response?.data?.coach_profile?.business_address?.state,
            business_address_country:
              response?.data?.coach_profile?.business_address?.country,
            business_address_zip_code:
              response?.data?.coach_profile?.business_address?.zip_code,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          toast.success(response.message, {
            position: "top-right",
          });
        }
      });
    }
  }, []);

  // checkbox function used to copy address

  const handleCheckboxChange = () => {
    const isSameAsAbove = watch("sameAsAbove");
    console.log(isSameAsAbove);
    setValue(
      "business_address_line_1",
      !isSameAsAbove ? watch("physical_address_line_1") : ""
    );
    setValue(
      "business_address_line_2",
      !isSameAsAbove ? watch("physical_address_line_2") : ""
    );
    setValue(
      "business_address_city",
      !isSameAsAbove ? watch("physical_address_city") : ""
    );
    setValue(
      "business_address_state",
      !isSameAsAbove ? watch("physical_address_state") : ""
    );
    setValue(
      "business_address_city",
      !isSameAsAbove ? watch("physical_address_city") : ""
    );
    setValue(
      "business_address_country",
      !isSameAsAbove ? setSearchTerm3(searchTerm2) : setSearchTerm3()
    );
    setValue(
      "business_address_zip_code",
      !isSameAsAbove ? watch("physical_address_zip_code") : ""
    );
  };

  const imageDeleteHandler = () => {
    setImagePreview("");
    setImageUrl("");
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const imageUploadHandler = (e) => {
    // console.log(e.target.value);
    const file = e.target.files[0];
    if (file) {
      // Check file size (500KB)
      const maxSizeInBytes = 500 * 1024;
      if (file.size > maxSizeInBytes) {
        toast.error(
          "File size exceeds the limit of 500KB. Please choose a smaller file."
        );
        // alert('File size exceeds the limit of 500KB. Please choose a smaller file.');
        // You may want to reset the input value to clear the selected file
        e.target.value = null;
        return;
      }
    }
    let preview = URL.createObjectURL(e.target.files?.[0]);
    setImagePreview(preview);
    setImageUrl(e.target.files[0]);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    let physical_address = JSON.stringify({
      address_line1: data?.physical_address_line_1,
      address_line2: data?.physical_address_line_2,
      city:
        data?.physical_address_city?.charAt(0)?.toUpperCase() +
        data?.physical_address_city?.slice(1),
      state:
        data?.physical_address_state?.charAt(0)?.toUpperCase() +
        data?.physical_address_state?.slice(1),
      country: searchTerm2,
      zip_code: data?.physical_address_zip_code,
    });
    let business_address = JSON.stringify({
      address_line1: data?.business_address_line_1,
      address_line2: data?.business_address_line_2,
      city:
        data?.business_address_city?.charAt(0)?.toUpperCase() +
        data?.business_address_city?.slice(1),
      state:
        data?.business_address_state?.charAt(0)?.toUpperCase() +
        data?.business_address_state?.slice(1),
      country: searchTerm3,
      zip_code: data?.business_address_zip_code,
    });
    const formData = new FormData();
    if (data?.email) {
      formData.append("email", data?.email);
    }
    formData.append(
      "account_id",
      location?.state?.id || location?.state?.data?.id
    );
    if (searchTerm) {
      formData.append("country_code", searchTerm);
    }
    if (data?.phone) {
      formData.append("mobile", data?.phone);
    }
    formData.append("physical_address", physical_address);
    formData.append("business_address", business_address);
    if (startDate) {
      formData.append("dob", startDate);
    }
    formData.append("image", imageUrl);

    handleFetchRequest(
      apiUrl.create_account_profile,
      "PUT",
      formData,
      null,
      true
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response.message, {
          position: "top-right",
        });
        reset();
        if (role === Roles?.admin_value) {
          if (location.state.param === "user_account") {
            navigate(RoutesLink?.user_account);
          } else {
            navigate(RoutesLink?.coach_list);
          }
        } else if (role === Roles?.coach_value) {
          navigate(RoutesLink?.coach_profile);
        }
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast.error(response.message, {
          position: "top-right",
        });
      }
    });
  };

  const backButtonHandler = () => {
    if (role === Roles?.admin_value) {
      if (location.state.param === "user_account") {
        navigate(RoutesLink?.user_account);
      } else {
        navigate(RoutesLink?.coach_list);
      }
    } else if (role === Roles?.coach_value) {
      navigate(RoutesLink?.coach_profile);
    }
  };

  // const handleClickHandler2 = (event) => {
  //   setFilteredCountries2(CountryCode);
  // }

  // const handleClickHandler3 = (event) => {
  //   console.log(event);
  //   console.log("mouse click event", event.target);
  //   setFilteredCountries3(CountryCode);
  // }

  const handleInputChange = (e) => {
    const term = e.target.value;
    console.log("this is the required termmmmm", term);
    let arr = CountryCode.filter(
      (ele) =>
        ele.name.toLowerCase().includes(term.toLowerCase()) ||
        ele.dial_code.includes(term)
    );
    console.log("arr----->>   ", arr);
    setFilteredCountries(arr);
    // if (term.length == 0) {
    //   setFilteredCountries(CountryCode);
    // }
    setSearchTerm(term);
    // Use the functional form of setState to ensure proper state updates
    //  setFilteredCountries((prevFilteredCountries) => ({
    //   ...prevFilteredCountries,
    //   filteredCountry: prevFilteredCountries?.countryList.filter((country) =>
    //     country.name.toLowerCase().includes(term.toLowerCase())
    //   ),
    // }));
  };

  const handleInputChange2 = (e) => {
    const term = e.target.value;
    let arr = CountryCode.filter((ele) =>
      ele.name.toLowerCase().includes(term.toLowerCase())
    );
    console.log("arr----->>   ", arr);
    setFilteredCountries2(arr);
    setSearchTerm2(term);
  };

  const handleInputChange3 = (e) => {
    const term = e.target.value;
    let arr = CountryCode.filter((ele) =>
      ele.name.toLowerCase().includes(term.toLowerCase())
    );
    console.log("arr----->>   ", arr);
    setFilteredCountries3(arr);
    // if (term.length == 0) {
    //   setFilteredCountries3([])
    // }
    setSearchTerm3(term);
  };

  const handleCountrySelect = (country) => {
    console.log("countryyyyyy....", country);
    setSearchTerm(`${country.dial_code}`);
    setIsDropdownOpen(false);
    // Perform any other actions when a country is selected, e.g., update state or trigger an event
  };

  const handleCountrySelect2 = (country) => {
    console.log("dasdfasdf", country);
    setSearchTerm2(`${country.name}`);

    setIsDropdownOpen2(false);

    // Perform any other actions when a country is selected, e.g., update state or trigger an event
  };

  const handleCountrySelect3 = (country) => {
    console.log("dasdfasdf", country);
    setSearchTerm3(`${country.name}`);
    setIsDropdownOpen3(false);
    // Perform any other actions when a country is selected, e.g., update state or trigger an event
  };

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
  };
  const handleInputFocus2 = () => {
    setIsDropdownOpen2(true);
  };
  const handleInputFocus3 = () => {
    setIsDropdownOpen3(true);
  };

  const handleInputBlur = () => {
    // Delay closing the dropdown to check if the click event occurs on the dropdown itself
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 400);
  };

  const handleInputBlur2 = () => {
    // Delay closing the dropdown to check if the click event occurs on the dropdown itself
    setTimeout(() => {
      setIsDropdownOpen2(false);
    }, 400);
  };

  const handleInputBlur3 = () => {
    // Delay closing the dropdown to check if the click event occurs on the dropdown itself
    setTimeout(() => {
      setIsDropdownOpen3(false);
    }, 400);
  };

  // console.log("datesss....... .. ", minDob);

  return (
    <FormLayout
      name={"Add Coach Profile"}
      backButtonHandler={backButtonHandler}
    >
      <form className="form-inline" onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-4 mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              Date Of Birth (DD/MM/YYYY)
            </label>
            <div className="w-100">
              <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                onChange={selectDateHandler}
                selected={startDate}
                onClickOutside={(date) => {
                  // Handle clicking outside of the calendar
                  if (!date) {
                    selectDateHandler(null); // Clear selected date if no date is chosen
                  }
                }}
                // maxDate={new Date()}  // Set maxDate to prevent selecting dates from the future
                // showYearDropdown={true} // Optionally allow users to select the year from a dropdown
                className="form-control"
                placeholderText={"Date Of Birth"}
                // defaultValue={profileData?.coach_profile?.dob}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      type="button"
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>
                    <select
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[date?.getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={increaseMonth}
                      type="button"
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
                // minDate={new Date(minDob)}
                // minDate={moment(new Date()).subtract(18, 'years').toDate()}
              />
              <span className="text-danger ps-3 ">{errors?.dob?.message}</span>
            </div>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("mobileNo")}</label>
            <div className="d-flex">
              <div className="col-4 me-1">
                <div className="position-relative d-flex">
                  <input
                    {...register("country_code")}
                    ref={inputRef}
                    type="text"
                    className="form-control"
                    placeholder="Country Code"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    // onClick={handleClickHandler}
                    // onBlur={handleMoveHandler}
                  />

                  {isDropdownOpen && (
                    <ul
                      className={
                        isDropdownOpen
                          ? "dropdown-menu countrydrop d-block"
                          : "dropdown-menu"
                      }
                    >
                      {filteredCountries.map((country, i) => (
                        <li
                          key={i}
                          onClick={() => handleCountrySelect(country)}
                        >
                          {country.name} {country.dial_code}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {/* <div className="col-lg-4 me-1">
                        <select
                       {...register("country_code", {
                      
                      })}
                      className="form-control "
                     >
                      {CountryCode?.map((data)=><option 
                      selected={data?.dial_code===profileData?.country_code}
                      >{data?.dial_code} {data?.name}</option>)}
                      </select> 
                      </div> */}
              <PrimaryInput
                type={"number"}
                className={"form-control"}
                placeholder={"Phone Number"}
                defaultValue={+profileData?.mobile}
                register={register("phone", {
                  validate: validateMobileNumber,
                })}
              />

              {/* <input class="form-control" placeholder="Age" /> */}
            </div>
            <span className="text-danger ps-3 ">{errors?.phone?.message}</span>
          </div>
        </div>
        <div className="row mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">{t("email")}</label>
            <PrimaryInput
              defaultValue={profileData?.coach_profile?.email}
              type="email"
              className="form-control"
              placeholder="Email"
              register={register("email", {
                validate: validateEmail,
              })}
            />
            <span className="text-danger ps-3 ">{errors?.email?.message}</span>
          </div>
        </div>

        <div>
          <div>
            {/* physical address */}
            <div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <AppHeading title="Physical Address" />
              </div>
            </div>

            <div className="row mt-4 mb-2 row-gap-2">
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("Country")}</label>
                <div className="position-relative">
                  <input
                    {...register("physical_address_country")}
                    type="text"
                    className="form-control"
                    placeholder="Country Name"
                    value={searchTerm2}
                    onChange={handleInputChange2}
                    onFocus={handleInputFocus2}
                    onBlur={handleInputBlur2}
                  />

                  {isDropdownOpen2 && (
                    <ul
                      className={
                        isDropdownOpen2
                          ? "dropdown-menu countrydrop d-block"
                          : "dropdown-menu"
                      }
                    >
                      {filteredCountries2.map((country, i) => (
                        <li
                          key={i}
                          onClick={() => handleCountrySelect2(country)}
                        >
                          {country.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <span className="text-danger ps-3 ">
                  {errors?.physical_address_country?.message}
                </span>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("State")}</label>
                <PrimaryInput
                  defaultValue={
                    profileData?.coach_profile?.physical_address?.state
                  }
                  type="text"
                  className="form-control"
                  register={register("physical_address_state", {
                    validate: validateName,
                  })}
                  placeholder="State"
                />
                <span className="text-danger ps-3 ">
                  {errors?.physical_address_state?.message}
                </span>
              </div>
            </div>
            <div className="row mb-2 row-gap-2">
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label ps-3">{t("City")}</label>
                <PrimaryInput
                  defaultValue={
                    profileData?.coach_profile?.physical_address?.city
                  }
                  type="text"
                  className="form-control"
                  placeholder="City"
                  register={register("physical_address_city", {
                    validate: validateName,
                  })}
                />

                <span className="text-danger ps-3 ">
                  {errors?.physical_address_city?.message}
                </span>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("zip_code")}</label>
                <PrimaryInput
                  defaultValue={profileData?.physical_address?.zip_code}
                  type="text"
                  className="form-control "
                  register={register("physical_address_zip_code", {
                    validate: validateZip,
                  })}
                  placeholder="Zip Code"
                />

                <span className="text-danger ps-3 ">
                  {errors?.physical_address_zip_code?.message}
                </span>
              </div>
            </div>
            <div className="row mb-2 row-gap-2">
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label ps-3">{t("address_line1")}</label>
                <PrimaryInput
                  defaultValue={profileData?.physical_address?.address_line_1}
                  type="text"
                  className="form-control "
                  placeholder="Address Line 1"
                  register={register("physical_address_line_1", {
                    validate: valOptionalChar,
                  })}
                />

                <span className="text-danger ps-3 ">
                  {errors?.physical_address_line_1?.message}
                </span>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("address_line2")}</label>
                <PrimaryInput
                  defaultValue={profileData?.physical_address?.address_line_2}
                  type="text"
                  className="form-control"
                  placeholder="Address Line 2"
                  register={register("physical_address_line_2", {
                    validate: valOptionalChar,
                  })}
                />

                <span className="text-danger ps-3 ">
                  {errors?.physical_address_line_2?.message}
                </span>
              </div>
            </div>
          </div>
          {/* Business Address */}

          <div>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <AppHeading title={"Business Address"} />
              {/* <p className="mb-0 color-gray fw-semibold">Business Address</p> */}
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  onClick={handleCheckboxChange}
                  {...register("sameAsAbove")}
                />
                <label className="fw-semibold ms-2">Same As Above </label>
              </div>
            </div>

            <div className="row mt-4 mb-2 row-gap-2">
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3"> {t("Country")}</label>
                <div className="position-relative">
                  <input
                    {...register("business_address_country")}
                    type="text"
                    className="form-control"
                    placeholder="Country Name"
                    value={searchTerm3}
                    onChange={handleInputChange3}
                    onFocus={handleInputFocus3}
                    onBlur={handleInputBlur3}
                  />
                  {isDropdownOpen3 && (
                    <ul
                      className={
                        isDropdownOpen3
                          ? "dropdown-menu countrydrop d-block"
                          : "dropdown-menu"
                      }
                    >
                      {filteredCountries3.map((country, i) => (
                        <li
                          key={i}
                          onClick={() => handleCountrySelect3(country)}
                        >
                          {country.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <span className="text-danger ps-3 ">
                  {errors?.business_address_country?.message}
                </span>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("State")}</label>
                <PrimaryInput
                  type="text"
                  defaultValue={profileData?.business_address?.state}
                  className="form-control"
                  register={register("business_address_state", {
                    // required: "Please enter mobile",
                    validate: validateName,
                  })}
                  placeholder="State"
                />

                <span className="text-danger ps-3 ">
                  {errors?.business_address_state?.message}
                </span>
              </div>
            </div>
            <div className="row mb-2 row-gap-2">
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("City")}</label>
                <PrimaryInput
                  defaultValue={profileData?.business_address?.city}
                  type="text"
                  className="form-control"
                  placeholder="City"
                  register={register("business_address_city", {
                    // required: "Please enter mobile",
                    validate: validateName,
                  })}
                />

                <span className="text-danger ps-3 ">
                  {errors?.business_address_city?.message}
                </span>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("zip_code")}</label>
                <PrimaryInput
                  defaultValue={profileData?.business_address?.zip_code}
                  type="text"
                  className="form-control"
                  register={register("business_address_zip_code", {
                    // required: "Please enter mobile",
                    validate: validateZip,
                  })}
                  placeholder="Zip Code"
                />

                <span className="text-danger ps-3 ">
                  {errors?.business_address_zip_code?.message}
                </span>
              </div>
            </div>
            <div className="row mb-2 row-gap-2">
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("address_line1")}</label>
                <PrimaryInput
                  type="text"
                  defaultValue={profileData?.business_address?.address_line_1}
                  className="form-control"
                  placeholder="Address Line 1"
                  register={register("business_address_line_1", {
                    // required: "Please enter mobile",
                    validate: valOptionalChar,
                  })}
                />

                <span className="text-danger ps-3 ">
                  {errors?.business_address_line_1?.message}
                </span>
              </div>
              <div className="col-12 col-md-6 px-0 px-md-2">
                <label className="input-label px-3">{t("address_line2")}</label>
                <PrimaryInput
                  type="text"
                  defaultValue={profileData?.business_address?.address_line_2}
                  className="form-control"
                  register={register("business_address_line_2", {
                    // required: "Please enter mobile",
                    validate: valOptionalChar,
                  })}
                  placeholder="Address Line 2"
                />

                <span className="text-danger ps-3 ">
                  {errors?.business_address_line_2?.message}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="col-12 col-md-6 px-0 px-md-2 d-flex">
              <div className="d-flex flex-column align-items-center gap-2">
                <div className="image-preview">
                  {imagePreview ? (
                    <img
                      height={"100%"}
                      width={"100%"}
                      alt="img"
                      //  className="image-preview"
                      src={imagePreview}
                    />
                  ) : (
                    <>
                      <div>
                        <span className="mdi mdi-upload"></span>
                      </div>
                      <div>Upload Photo</div>
                      <p className="m-0 fs-0">(jpeg/png/svg max-size:500kb)</p>
                    </>
                  )}
                </div>
                {imagePreview && (
                  <div>
                    {/* <button className="correct-btn"><span class="mdi mdi-check"></span></button> */}
                    <button
                      className="mx-1 close-btn"
                      onClick={imageDeleteHandler}
                    >
                      <span className="mdi mdi-close "></span>
                    </button>
                  </div>
                )}
                <input
                  ref={fileInputRef}
                  id="img1"
                  type="file"
                  accept="image/*"
                  onChange={imageUploadHandler}
                  style={{ display: "none" }}
                />
                <label for="img1" className="btn btn-light p-2">
                  <p className="m-0">Choose Photo</p>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-end pt-2 pt-md-3 pt-lg-5 gap-2">
          <button type="submit" className="btn btn-primary rounded-3">
            {isLoading ? <ButtonLoader /> : "Save"}
          </button>
          <button
            onClick={backButtonHandler}
            // to={role === Roles?.admin_value ? RoutesLink.coach_list : RoutesLink?.coach_profile}
            className="btn btn-light rounded-3"
          >
            {t("cancel")}
          </button>
          <button
            onClick={backButtonHandler}
            // to={role === Roles?.admin_value ? RoutesLink.coach_list : RoutesLink?.coach_profile}
            className="btn btn-primary rounded-3"
          >
            Skip
          </button>
        </div>
      </form>
    </FormLayout>
  );
};

export default AddUserProfile;
