import Cookies from "js-cookie";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const serverUrl = process.env.REACT_APP_SERVER_URL;

export async function handleFetchRequest(
  url,
  method = "GET",
  data = null,
  password,
  formData
) {
  let tokens = null;
  let headers = {};
  let requestOptions = {};

  if (Cookies.get("emp_token")) {
    tokens = Cookies.get("emp_token");
  }

  if (tokens && !password && !formData) {
    // tokens = Cookies.get("emp_token");
    headers = {
      Accept: "application/json",
      Authorization: "Bearer " + tokens,
      "Accept-Language": "en",
      "allow-access-origin": "*",
      "X-localization": "en",
      // 'ngrok-skip-browser-warning': 'true',
      "Content-Type": "application/json",
    };

    requestOptions = {
      method: method,
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    };
  } else if (password) {
    headers = {
      Authorization: "Bearer " + tokens,
      "Content-Type": "application/json",
      "X-localization": "en",
      ...password,
      Accept: "application/json",
      "allow-access-origin": "*",
      // 'ngrok-skip-browser-warning': 'true',
    };

    requestOptions = {
      method: method,
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    };
  } else if (formData) {
    headers = {
      Accept: "application/json",
      Authorization: "Bearer " + tokens,
      "Accept-Language": "en",
      // 'Content-Type': `multipart/form-data`,
      // 'ngrok-skip-browser-warning': 'true',
      "allow-access-origin": "*",
      "X-localization": "en",
    };
    requestOptions = {
      method: method,
      headers: headers,
      body: data ? data : null,
    };
  }

  try {
    const response = await fetch(serverUrl + url, requestOptions);
    //   .catch(error => {
    //   if (error instanceof TypeError && error.message.includes('API key')) {
    //     console.error('Invalid API key:', error);
    //     return error.json();
    //   } else {
    //     console.error('There was a problem with the Fetch operation:', error);
    //     return error.json();
    //   }
    // });;
    const result = await response.json();
    return result;
    // const response = await fetch(serverUrl + url, requestOptions)
    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }
    // const result = await response.json();
    // return result;
    //   .then((response) => {
    //   return response.json();
    // })
    //   .catch(error => {
    //   if (error instanceof TypeError && error.message.includes('API key')) {
    //     console.error('Invalid API key:', error);
    //     return error.json();
    //   } else {
    //     console.error('There was a problem with the Fetch operation:', error);
    //     return error.json();
    //   }
    // });

    // if (!response.ok) {
    //   return response.data;
    // }

    // const result = await response.json();
    // return result;
  } catch (error) {
    // console.error('Error:', error.message);
    return error.message;
  }
}

export async function DownloadReportHandler(
  type,
  url,
  setIsLoading,
  method = "GET",
  data = null
) {
  Swal.fire({
    text: "Do you want to Download this file?",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Yes",
  }).then((result) => {
    let tokens = null;
    if (result.isConfirmed) {
      setIsLoading(true);
      if (Cookies.get("emp_token")) {
        tokens = Cookies.get("emp_token");
      }
      let headers = {
        Accept: "application/json",
        Authorization: "Bearer " + tokens,
        "Accept-Language": "en",
        "allow-access-origin": "*",
        "X-localization": "en",
        // 'ngrok-skip-browser-warning': 'true',
        "Content-Type": "application/json",
      };

      let requestOptions = {
        method: method,
        headers: headers,
        body: data ? data : null,
      };
      fetch(serverUrl + url, requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
          // console.log(blob);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          // Beneficiary -
          //   <DDMMYYYY>-
          //     HHMISS
          let date = moment(new Date()).format("DD-MM-YYYY-hh:mm:ss");
          let fileName = `${type}-${date}`;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          setIsLoading(false);
          // setLoadingData(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // setLoadingData(false);
          setIsLoading(false);
        });
    }
  });
}

export async function DownloadReportsHandler(
  type,
  url,
  setLoadingState,
  method = "GET",
  data = null
) {
  console.log(type, "type");
  let tokens = null;
  setLoadingState(true);
  if (Cookies.get("emp_token")) {
    tokens = Cookies.get("emp_token");
  }
  let headers = {
    Accept: "application/json",
    Authorization: "Bearer " + tokens,
    "Accept-Language": "en",
    "allow-access-origin": "*",
    "X-localization": "en",
    "ngrok-skip-browser-warning": "true",
    "Content-Type": "application/json",
  };

  let requestOptions = {
    method: method,
    headers: headers,
    body: data ? data : null,
  };
  fetch(serverUrl + url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        toast.error("Data not exist");
      } else {
        return response.blob();
      }
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      let date = moment(new Date()).format("DDMMYYYY-hhmmss");
      let fileName = `${type}-${date}`;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      setLoadingState(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      setLoadingState(false);
    });
}
