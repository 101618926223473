import { useEffect, useState, useTransition } from "react";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Roles from "../../../ApiHelper/Roles.json";
import { toast } from "react-toastify";
import { convertToEpoch } from "../../../ApiHelper/TimeStampConverter";
import Swal from "sweetalert2";
import { t } from "i18next";
import PageLoader from "../../../Components/CommonElements/PageLoader";

const CoachPlanList = () => {
  let RoleType = Cookies.get("emp_role");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [inputTypeData, setInputTypeData] = useState("select");
  const [modal2, setModal2] = useState(false);
  const [lodingModalBtn, setLodingModalBtn] = useState(false);
  const [selecCoachId, setSelectCoachId] = useState("");
  const [selecCoachPlanId, setSelectCoachPalnId] = useState("");
  const [allCoachList, setAllCoachList] = useState([]);
  const searchOption1Array = [
    { name: `${t("search_by")}`, value: "", type: "select" },
    { name: `${t("status")}`, value: "status", type: "select" },
    // { name: "Assign Beneficiary", value: "assign", type: "select" },
    // { name: "Beneficiary Alias ID", value: "beneficiary_aliasId", type: "text" },
    // { name: "Coach Alias ID", value: "coach_aliasId", type: "text" },
    { name: `${t("coaching_plan_id")}`, value: "plan_aliasId", type: "text" },
    {
      name: `${t("beneficiary_name")}`,
      value: "beneficiary_name",
      type: "text",
    },
    { name: `${t("coach_username")}`, value: "coach_username", type: "text" },
    // { name: "Session Time", value: "session_time", type: "date" }
  ];

  const [pageIsLoading, setPageIsLoading] = useState(false);

  const [inputCategoryData, setInputCategoryData] = useState("");
  const [searchOptionData, setSearchOptionData] = useState();
  const [coachList, setCoachList] = useState([]);
  const [pages, setPages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState();
  let listHeading;
  if (Roles.admin_value === RoleType || Roles.coach_value === RoleType) {
    listHeading = [
      { data: `${t("coaching_plan_id")}`, classType: "" },
      { data: `${t("beneficiary_name")}`, classType: "" },
      { data: `${t("coach_username")}`, classType: "" },
      { data: `${t("schedule_start_date_time_(j/m/a)")}`, classType: "" },
      { data: `${t("coach_plan_start_time_(j/m/a)")}`, classType: "" },
      { data: `${t("coach_plan_end_time_(j/m/a)")}`, classType: "" },
      { data: `${t("status")}`, classType: "" },
      { data: `${t("action")}`, classType: "" },
    ];
  } else if (Roles.viewer_value === RoleType) {
    listHeading = [
      { data: `${t("coaching_plan_id")}`, classType: "" },
      { data: `${t("beneficiary_name")}`, classType: "" },
      { data: `${t("coach_username")}`, classType: "" },
      { data: `${t("schedule_start_date_time_(j/m/a)")}`, classType: "" },
      { data: `${t("coach_plan_start_time_(j/m/a)")}`, classType: "" },
      { data: `${t("coach_plan_end_time_(j/m/a)")}`, classType: "" },
      { data: `${t("status")}`, classType: "" },
      // { data: "Action", classType: "" }
    ];
  }

  const searchOptionStatusArray = [
    { name: "In Progress", value: "in progress", type: "status" },
    { name: "Completed", value: "completed", type: "status" },
    { name: "Pending", value: "pending", type: "status" },
    { name: "No Session", value: "no session", type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  const filters = [];

  const toggle2 = () => {
    setModal2(!modal2);
  };
  const handelOpenCoachModel = (id) => {
    console.log("dfsdfsd", id);
    setModal2(!modal2);
    setSelectCoachPalnId(id?.id);
    let allCoach = allCoachList.filter((e) => e.id !== id?.coach_id);
    setAllCoachList(allCoach);
  };

  const DeleteCoachPlan = (data) => {
    setIsLoading(true);
    console.log("dataaaaaa...", data);
    handleFetchRequest(
      `${apiUrl?.delete_coach_plan}?coach_planId=${data?.id}`,
      "DELETE"
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response.message);
        getCoachPlanList(1);
      } else if (response?.messageCode === 99) {
        setIsLoading(false);
        Swal.fire({
          text: "One or more session exists in this plan are you sure you want to delete this coaching plan?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            handleFetchRequest(
              `${apiUrl?.delete_coach_plan}?coach_planId=${data?.id}&force_delete=true`,
              "DELETE"
            ).then((response) => {
              if (response?.status === true) {
                getCoachPlanList(1);
                setIsLoading(false);
                toast.success(response?.message);
                // const newData = stateVariable.filter((item) => item.id !== rowId);
                // stateVariableFunction(newData);
              }
            });
          }
        });
      }
    });
  };

  const handleStatusChange = (data) => {
    if (data?.status === "completed") {
      let statusBody = {
        coach_plan_id: data?.id,
        status: "in progress",
      };
      setPageIsLoading(true);
      handleFetchRequest(
        apiUrl?.coach_plan_status_change,
        "PUT",
        statusBody
      ).then((response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          navigate(RoutesLink?.add_coach_plan, { state: data });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      });
    } else {
      navigate(RoutesLink?.add_coach_plan, { state: data });
    }
  };

  let addHeadingAction;

  if (Roles.admin_value === RoleType) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.coach_plan_detail },
      {
        name: `${t("edit")}`,
        path: handleStatusChange,
        type: "function",
        value: "always",
      },
      {
        name: "Delete",
        type: "function",
        path: DeleteCoachPlan,
        value: "always",
      },
      {
        name: "Change Coach",
        type: "function",
        path: handelOpenCoachModel,
        value: "always",
      },
    ];
  } else if (Roles.coach_value === RoleType) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.coach_plan_detail },
      {
        name: `${t("edit")}`,
        path: handleStatusChange,
        type: "function",
        value: "always",
      },
      {
        name: "Delete",
        type: "function",
        path: DeleteCoachPlan,
        value: "always",
      },
    ];
  }

  useEffect(() => {
    getCoachPlanList(currentPage);
    getCoachList();
  }, []);

  const getCoachList = () => {
    handleFetchRequest(`${apiUrl.get_all_coach}`).then((response) => {
      if (response?.status === true) {
        setAllCoachList(response.data);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
      }
    });
  };

  const applyFilter = () => {
    if (searchOptionData === "session_time") {
      const filterValue = filterData
        ? `&started_ts=${convertToEpoch(
            filterData.start_date
          )}&end_ts=${convertToEpoch(filterData.end_date)}`
        : "";
      filters.push(filterValue);
    } else {
      const filterValue = filterData
        ? `&${searchOptionData}=${filterData}`
        : "";
      filters.push(filterValue);
    }
  };

  const handlePageChange = (pageitem) => {
    setCoachList([]);
    getCoachPlanList(pageitem);
    setCurrentPage(pageitem);
  };

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setSearchOptionData("");
    setFilterData("");
    setCoachList([]);
    setInputCategoryData("###select");
    handleFetchRequest(`${apiUrl.get_coach_plan}?page_no=1&page_size=10`).then(
      (response) => {
        if (response?.status === true) {
          // setCallApi(false);
          setIsLoading(false);
          setCoachList(response.data);
          setPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          // setCallApi(false);
          console.log(response);
          setIsLoading(false);
          toast.error(response.message);
        }
      }
    );
  };

  const getCoachPlanList = (pageItem) => {
    setIsLoading(true);
    applyFilter();
    handleFetchRequest(
      `${apiUrl.get_coach_plan}?page_no=${pageItem}&page_size=10${filters.join(
        "&"
      )}`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCoachList(response.data);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const handelChangeCoach = () => {
    if (selecCoachId != "") {
      let Data = {
        coach_id: selecCoachId,
        plan_id: selecCoachPlanId,
      };
      setLodingModalBtn(true);
      handleFetchRequest(apiUrl?.change_coach, "PUT", Data).then((response) => {
        if (response?.status === true) {
          setLodingModalBtn(false);
          toast.success("Coach Change Successfully");
          toggle2();
          setSelectCoachId("");
          setSelectCoachPalnId("");
          getCoachPlanList(currentPage);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setLodingModalBtn(false);
          toast.error(response.message);
        }
      });
    } else {
      toast.error("Please select coach");
    }
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading
                    inputTypeData={inputTypeData}
                    setInputTypeData1={setInputTypeData}
                    searchOption1Array={searchOption1Array}
                    // addLink={RoutesLink?.add_user_account}
                    // addLink={{ link: RoutesLink?.add_user_account, data: "coach" }}
                    // btnName={role === Roles.admin_value ? "Add Coach" : ""}
                    name={t("coaching_plan")}
                    // addArray={addAction1}
                    inputCategoryData={inputCategoryData}
                    setInputCategoryData={setInputCategoryData}
                    setSearchOptionData={setSearchOptionData}
                    searchOption2Array={searchOption2Array}
                    setMainCallApi={() => getCoachPlanList(1)}
                    setFilterData={setFilterData}
                    setData={setCoachList}
                    filterData={filterData}
                    setActivePage={setCurrentPage}
                    ResetAccountHandler={ResetAccountHandler}
                  />
                  <PrimaryTable
                    listData={coachList}
                    listHeading={listHeading}
                    editPage={"/add-coach"}
                    addData={addHeadingAction}
                    mainCoachPlan={true}
                    pages={pages}
                    activePage={currentPage}
                    handlePageChange={handlePageChange}
                    isLoading={isLoading}
                    idClickLink={RoutesLink?.coach_plan_detail}
                    // idClickLink={RoutesLink?.coach_profile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal2}
        centered
        toggle={toggle2}
        className=" modal modal-coach"
      >
        <ModalHeader className="border-bottom " toggle={toggle2}>
          Change Coach
        </ModalHeader>
        <ModalBody className="p-3">
          <form>
            <div className="p-2 rounded box-shadow">
              <div className="row">
                <div className="col-12 p-0">
                  <label className="input-label ps-3">
                    Select Coach<span className="text-danger">*</span>
                  </label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => setSelectCoachId(e.target.value)}
                    >
                      <option value={""}>Select Coach</option>
                      {allCoachList &&
                        allCoachList?.map((item, key) => (
                          <option key={key} value={item?.id}>
                            {item?.first_name} {item?.last_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="modal-footer pt-4">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => toggle2()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handelChangeCoach}
          >
            {lodingModalBtn == true ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              `{t("submit")}`
            )}
          </button>
        </ModalFooter>
      </Modal>
      {pageIsLoading && <PageLoader />}
    </Layout>
  );
};
export default CoachPlanList;
