export const validateName = (value) => {
  // Check if the name contains special characters
  const specialChars = /[!@#$%^&*(),.?":{}|<>]/;

  if (specialChars.test(value)) {
    return "Input value should not contain special characters";
  }

  const hasNumber = /\d/;
  if (hasNumber.test(value)) {
    return "Input value should not contain numbers";
  }

  // Check if the length of the name is not greater than 24 characters
  if (value.length > 24) {
    return "Input value should not be greater than 24 characters";
  }

  return true;
};

export const validatePassword = (value) => {
  // Check if the password has a minimum length of 6 characters
  if (value.length < 6) {
    return "Password should be at least 6 characters long";
  }

  if (/\s/.test(value)) {
    return "Password should not contain spaces";
  }

  if (value.length > 24) {
    return "Password should not be greater than 24 characters";
  }
  return true;
};

export const validateUsername = (value) => {
  // Use a regular expression to check if the username contains any spaces
  const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
  if (specialChars.test(value)) {
    return "Input value should not contain special characters";
  }
  if (/\s/.test(value)) {
    return "should not contain spaces";
  }
  if (value.length > 24) {
    return "should not be greater than 24 characters";
  }

  return true;
};

export const validateAge = (value) => {
  // Use a regular expression to check if the username contains any spaces
  if (/\s/.test(value)) {
    return "should not contain spaces";
  }
  if (value.length > 3) {
    return "Age cannot be greater than 3 digits";
  }

  return true;
};

export const validateMobileNumber = (value) => {
  if (value) {
    // Check if the value is numeric
    const isNumeric = /^\d+$/;
    if (!isNumeric.test(value)) {
      return "Mobile No. should only contain digits";
    }

    // Check if the length of the mobile number is between 8 and 15 characters
    if (value.length < 8 || value.length > 15) {
      return "Mobile No. should be between 8 and 15 digits";
    }
  }
  return true;
};

export const validateEmail = (value) => {
  // Regular expression for a basic email format
  if (value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the value matches the email format
    if (!emailRegex.test(value)) {
      return "Please enter a valid email address";
    }
    if (value.length > 40) {
      return "email not to be greater than 24 characters";
    }
  }

  return true;
};

export const valChar = (value) => {
  // Use a regular expression to check if the username contains any spaces

  if (value.length > 40) {
    return "Values not be greater than 40 characters";
  } else if (value.length < 3) {
    return "Values not be less than 3 characters";
  }

  return true;
};
export const valOptionalChar = (value) => {
  if (value) {
    if (value.length > 40) {
      return "Values not be greater than 40 characters";
    }
  }

  return true;
};

export const vallongitude = (longitude) => {
  if (longitude) {
    if (
      /^-?((1[0-7]|[1-9])?\d(?:\.\d{1,})?|180(?:\.0{1,})?)$/.test(longitude) &&
      longitude.length >= 1 &&
      longitude.length <= 16
    ) {
      return true;
    } else {
      return "Please enter valide longitude";
    }
  }
};

export const vallatitude = (latitude) => {
  if (latitude) {
    if (
      /^-?([1-8]?\d(?:\.\d{1,})?|90(?:\.0{1,})?)$/.test(latitude) &&
      latitude.length >= 1 &&
      latitude.length <= 15
    ) {
      return true;
    } else {
      return "Please enter valide latitude";
    }
  }
};

export const validateLottery = (value) => {
  // Use a regular expression to check if the username contains any spaces
  if (/\s/.test(value)) {
    return "should not contain spaces";
  }

  if (value.length > 25) {
    return "should not be greater than 25 characters";
  }

  return true;
};

export const validateEquipmentName = (value) => {
  // Use a regular expression to check if the username contains any spaces

  if (value.length > 25) {
    return "Equipment Name not be greater than 25 characters";
  }

  return true;
};

export const validateEquipmentQuantity = (value) => {
  // Use a regular expression to check if the username contains any spaces

  if (value > 9999) {
    return "Quantity must not be greater than 10000";
  }

  return true;
};

export const validateZip = (value) => {
  if (value) {
    // Check if the value is numeric
    const isNumeric = /^\d+$/;
    if (!isNumeric.test(value)) {
      return "ZIP Code should only contain digits";
    }

    // Check if the length of the mobile number is between 8 and 15 characters
    if (value.length > 8) {
      return "ZIP Code should be less than 8 digits";
    }
  }

  return true;
};

export const validateEquipmentPrice = (value) => {
  // Regular expression for allowing only numbers and up to 3 digits after the decimal point
  const priceRegex = /^\d+(\.\d{1,3})?$/;

  // Check if the value matches the pattern
  if (!priceRegex.test(value)) {
    return "Please enter a valid equipment price with only numbers and up to 3 digits after the decimal point.";
  }

  return true;
};

export const validateRegistrationSiteId = (value) => {
  if (value.length > 10) {
    return "must be less than  11 digit";
  }
};

export const validateNegative = (value) => {
  if (value < 0) {
    return "Value must be non-negative";
  }
  return true;
};
