import { toast } from "react-toastify";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { apiUrl } from "../../ApiHelper/ApiUrl";
import FormLayout from "../Layout/FormLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ButtonLoader from "./ButtonLoader";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../store/actions/UserActions";
import { RoutesLink } from "../../ApiHelper/RoutesLink";
import { useTranslation } from "react-i18next";
// import PageLoader from "./PageLoader"

const UploadData = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const dataUploader = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];
      setExcelData(file);
    }
  };

  const documentUploadHandler = (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", excelData);
    if (location?.state?.type === "beneficiary") {
      handleFetchRequest(
        `${apiUrl?.upload_beneficiaries}`,
        "POST",
        formData,
        null,
        true
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast?.success(response?.message);
          navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_time_zone");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast?.error(response?.message);
        }
      });
    } else if (location?.state?.type === "equipments") {
      handleFetchRequest(
        `${apiUrl?.upload_equipments}`,
        "POST",
        formData,
        null,
        true
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast?.success(response?.message);
          navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_time_zone");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast?.error(response?.message);
        }
      });
    }
  };

  return (
    <FormLayout name={"Upload Data"}>
      <div style={{ cursor: "pointer" }}>
        <label
          className="form-control py-5 text-center"
          style={{ border: "2px dashed #858599", cursor: "pointer" }}
          for="uploadfile"
        >
          {excelData
            ? `${excelData?.name} (${excelData?.size} kb)`
            : "Upload File Here (xls/xlss/csv)"}
        </label>
        <input
          id="uploadfile"
          type="file"
          onChange={dataUploader}
          className="form-control"
          style={{ display: "none" }}
        />
      </div>
      <div className="d-flex justify-content-end mt-3">
        <button
          onClick={documentUploadHandler}
          className="btn btn-primary me-2"
        >
          {isLoading ? <ButtonLoader /> : `${t("submit")}`}
        </button>
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="btn btn-light"
        >
          {t("cancel")}
        </button>
      </div>
    </FormLayout>
  );
};
export default UploadData;
