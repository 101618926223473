import React from "react";
import BackButton from "./BackButton";

function AppHeading({ title, style, backButtonHandler }) {
  return (
    <div className="d-flex align-items-center align-items-md-start flex-row flex-md-column gap-2">
      {" "}
      {backButtonHandler && <BackButton clickFunction={backButtonHandler} />}
      <h3 className="" style={{ fontSize: "20px", fontWeight: 600 }}>
        {title}
      </h3>
    </div>
  );
}

export default AppHeading;
