import { useForm } from "react-hook-form";
import FormLayout from "../../../Components/Layout/FormLayout";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import { useState } from "react";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ResetUserAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    watch,
  } = useForm({ mode: "onBlur" });

  const validatePassword = (value) => {
    // Check if the password has a minimum length of 6 characters
    if (value.length < 6) {
      return "Password should be at least 6 characters long";
    }
    return true;
  };

  const passwordMatch = (value) => {
    const newPassword = watch("password");
    return newPassword === value || "Passwords do not match";
  };

  const backButtonHandler = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    const password = { password: data?.password };
    const accountData = {
      account_id: location?.state?.id,
    };

    handleFetchRequest(
      apiUrl?.reset_user_account,
      "PUT",
      accountData,
      password
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response?.message);
        navigate(-1);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  return (
    <FormLayout name={"Reset Password"} backButtonHandler={backButtonHandler}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-4 mb-2 row-gap-2">
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              {t("new_password")}
              <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="password"
              placeholder="New Password"
              {...register("password", {
                required: "Please enter new password*",
                validate: validatePassword,
              })}
            />
            <span className="text-danger ps-3">
              {errors?.password?.message}
            </span>
          </div>
          <div className="col-12 col-md-6 px-0 px-md-2">
            <label className="input-label ps-3">
              Confirm New Password <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="password"
              placeholder="Confirm New Password"
              {...register("confirm_password", {
                required: "Please enter password confirmation*",
                validate: passwordMatch,
              })}
            />
            <span className="text-danger ps-3">
              {errors?.confirm_password?.message}
            </span>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-end pt-2 pt-md-3 pt-lg-5 gap-2">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-light  "
          >
            {t("cancel")}
          </button>
          <button type="submit" className="btn btn-primary">
            {isLoading ? <ButtonLoader /> : `${t("save")}`}
          </button>
        </div>
      </form>
    </FormLayout>
  );
};
export default ResetUserAccount;
