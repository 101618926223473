import React from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import FormLayout from "../../../Components/Layout/FormLayout";
import { useEffect } from "react";
import { useState } from "react";
import { valChar, validateNegative } from "../../../ApiHelper/FormValidation";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import Roles from "../../../ApiHelper/Roles.json";
import { useTranslation } from "react-i18next";

const AddCooperatives = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let role = Cookies.get("emp_role");
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const backButtonHandler = () => {
    navigate(-1);
  };

  console.log("hhjf", location.state);

  useEffect(() => {
    if (location?.state?.id) {
      reset({
        cooperative_name: location?.state.data.cooperative_name,
        business_type: location?.state.data.business_type,
        sector_activity: location?.state.data.sector_activity,
        sales: location?.state.data.sales,
        status: location?.state.data.status,
      });
      // handleFetchRequest(`${apiUrl?.get_account_detail}?account_id=${location?.state?.id}`).then((response) => {
      //   if (response?.status === true) {
      //   } else if (response?.messageCode === 3) {
      //     Cookies.remove("emp_token");
      //     Cookies.remove("emp_role");
      //     Cookies.remove("emp_name");
      //     Cookies.remove("emp_currency");
      //     Cookies.remove("emp_profile");
      //     Cookies.remove("emp_data");
      //     Cookies.remove("emp_conversion");
      //     Cookies.remove("emp_conversion");
      //     dispatch(setUserLoggedInDetails({}));
      //     dispatch(profileImage(""));
      //     setIsLoading(false);
      //     navigate(RoutesLink.login);
      //   }
      // })
    } else {
      reset({
        status: 1,
      });
    }
  }, []);

  const passwordMatch = (value) => {
    const newPassword = watch("password");
    return newPassword === value || "Passwords do not match";
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    const cooperativeData = {
      coach_id: location?.state?.profileData?.id,
      cooperative_name: data.cooperative_name,
      business_type: data.business_type,
      sector_activity: data.sector_activity,
      sales: data.sales ? data.sales : null,
      status: data.status,
    };
    if (location?.state?.id) {
      const cooperativeData2 = {
        ...cooperativeData,
        cooperative_id: location?.state?.id,
      };
      handleFetchRequest(
        apiUrl?.update_cooperatives,
        "PUT",
        cooperativeData2
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success("Account Updated Successfully");
          navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    } else {
      handleFetchRequest(
        apiUrl?.add_cooperatives,
        "POST",
        cooperativeData
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success("Cooperative Created Successfully");
          navigate(RoutesLink.assign_beneficiary, {
            state: {
              id: response?.data?.id,
              data: response.data,
              param: location?.state,
              type: "assign",
              page: "cooperative",
              pageurl: RoutesLink.cooperatives,
            },
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    }
  };
  return (
    <FormLayout
      name={location?.state?.id ? "Edit Cooperatives" : "Add Cooperatives"}
      backButtonHandler={backButtonHandler}
    >
      <form className="form-inline" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="row mt-4">
            {role == Roles.admin_value && (
              <div className="col-lg-6 px-0 px-lg-3">
                <label className="input-label ps-3">
                  Cooperative Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control mr-sm-2"
                  id="inlineFormInputName2"
                  placeholder="Cooperative Name"
                  {...register("cooperative_name", {
                    required: "Please enter Cooperative Name*",
                    validate: valChar,
                  })}
                />
                <span className="text-danger ">
                  {errors?.cooperative_name?.message}
                </span>
              </div>
            )}

            <div className="col-lg-6 px-0 px-lg-3">
              <label className="input-label ps-3">{t("sales")} </label>
              <input
                type="number"
                className="form-control mr-sm-2"
                id="inlineFormInputName2"
                step={"any"}
                placeholder="Sales"
                {...register("sales", {
                  // required: "Please enter sales*",
                  validate: validateNegative,
                })}
              />
              <span className="text-danger ">{errors?.sales?.message}</span>
            </div>

            {role == Roles.admin_value && (
              <div className="col-lg-6 px-0 px-lg-3">
                <label className="input-label ps-3">
                  Select Sector Activity <span className="text-danger">*</span>
                </label>
                <div className="form-group">
                  <select
                    className="form-control"
                    {...register("sector_activity", {
                      required: "Please Select Sector Activity*",
                    })}
                  >
                    <option value={""}>Select Sector Activity</option>
                    <option value={"agribusiness"}>Agri Business</option>
                    <option value={"manufacturing"}>Manufacturing</option>
                    <option value={"services"}>Services</option>
                  </select>
                </div>
                <span className="text-danger ">
                  {errors?.sector_activity?.message}
                </span>
              </div>
            )}

            {/* {role == Roles.admin_value && <div className="col-lg-6">
              <label className="input-label ps-3">Select Business Type <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  className="form-control"
                  {...register("business_type", {
                    required: "Please Select Business Type*",
                  })}
                >
                  <option value={""}>Select Business Type</option>
                  <option value={"Business1"}>Business1</option>
                  <option value={"Business2"}>Business2</option>
                  <option value={"Business3"}>Business3</option>
                </select>
              </div>
              <span className="text-danger ">
                {errors?.business_type?.message}
              </span>
            </div>
            } */}
            {role == Roles.admin_value && (
              <div className="col-lg-6 px-0 px-lg-3">
                <label className="input-label ps-3">
                  Select Status <span className="text-danger">*</span>
                </label>
                <div className="form-group">
                  <select
                    className="form-control"
                    {...register("status", {
                      required: "Please enter Status*",
                    })}
                  >
                    <option value={""}>Select Status</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                  {/* <span className="text-danger ">{errors?.status?.message}</span> */}
                </div>
                <span className="text-danger">{errors?.status?.message}</span>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end pt-5 ">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-light  rounded-3"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary me-2  ms-4 rounded-3"
          >
            {isLoading ? <ButtonLoader /> : "Save"}
          </button>
        </div>
      </form>
    </FormLayout>
  );
};

export default AddCooperatives;
