import {
  CHECK_USER_LOGGED_IN,
  USER_LOGGED_IN_DETAILS,
  FETCH_USER_REQUEST,
  USER_TYPE,
  CART_DATA,
  CONVERSION_RATE,
  NOTIFICATION_DETAIL,
  PROFILE_IMAGE,
} from "../types";

const initialState = {
  userType: null,
  loading: false,
  isLoggedIn: false,
  userDetails: {},
  conversionRate:{},
  notificationDetail:[],
  profile_image:""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CART_DATA: {
      return {
        ...state,
        cartData: action.payload,
      };
    }
    case USER_TYPE: {
      return {
        ...state,
        userType: action.payload,
      };
    }
    case USER_LOGGED_IN_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case CONVERSION_RATE: {
      return {
        ...state,
        conversionRate: action.payload,
      };
    }
    case CHECK_USER_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case NOTIFICATION_DETAIL:{
      return {
        ...state,
        notificationDetail: action.payload,
      };
    }
    case PROFILE_IMAGE:{
      return {
        ...state,
        profile_image: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
