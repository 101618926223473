import { useNavigate } from "react-router-dom";
import PrimaryInput from "../../../Components/Inputs/PrimaryInput";
import FormLayout from "../../../Components/Layout/FormLayout";
import InputLayout1 from "../../../Components/Layout/InputLayout1";
import { useTranslation } from "react-i18next";

const AddSubventions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <FormLayout name={"Add Subvention"}>
      <form>
        <div className="row">
          <InputLayout1>
            <select className="form-control">
              <option>Select Beneficiary</option>
              <option>option 1</option>
              <option>option 2</option>
              <option>option 3</option>
              <option>option 4</option>
              <option>option 5</option>
            </select>
          </InputLayout1>
          <InputLayout1>
            <select className="form-control">
              <option>Select Grant Amount</option>
              <option>option 1</option>
              <option>option 2</option>
              <option>option 3</option>
              <option>option 4</option>
              <option>option 5</option>
            </select>
          </InputLayout1>
        </div>
        <p className="text-black  font-family-Roboto  fw-semibold   my-2">
          Add Products
        </p>

        <div className="row align-items-center">
          <div className="col-lg-3">
            <PrimaryInput placeholder={"Name"} className={"form-control"} />
          </div>
          <div className="col-lg-3">
            <PrimaryInput placeholder={"Quantity"} className={"form-control"} />
          </div>
          <div className="col-lg-3">
            <PrimaryInput
              placeholder={"Total Amount"}
              className={"form-control"}
            />
          </div>

          <div className="col-lg-3">
            <button type="submit" className="custom-delete-button mx-2">
              <span className="mdi mdi-trash-can-outline"></span>
            </button>
            <button type="submit" className="custom-add-button">
              <span className="mdi mdi-plus"></span>
            </button>
          </div>
        </div>
        <div className="row my-2 p-2">
          <PrimaryInput
            className={"form-control"}
            placeholder={"Grand Total Amount"}
          />
        </div>
        <div className="row my-2 p-2">
          <textarea placeholder="Comment" className="form-control py-4" />
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary me-2">
            {t("submit")}
          </button>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-light"
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </FormLayout>
  );
};
export default AddSubventions;
