import { Link, NavLink, useLocation } from "react-router-dom";
import { RoutesLink } from "../../ApiHelper/RoutesLink";
import texts from "../../ApiHelper/Nomanclatures.json";
import Roles from "../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import ProfileCard from "../CommonElements/ProfileCard";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Sidebar = ({ sidebarShow }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [callApi, setCallApi] = useState(true);
  const [profileImage, setProfileImage] = useState();
  const fullName = Cookies.get("emp_name");
  const profile_redux_image = useSelector((data) => data?.user?.profile_image);
  const initials = fullName
    ?.split(" ")
    ?.map((word) => word[0])
    .join("")
    .toUpperCase();
  let role = Cookies.get("emp_role");
  //  let profileImage =useSelector((data)=>data?.user?.userDetails?.image);
  console.log(profileImage);

  useEffect(() => {}, []);

  useEffect(() => {
    const imageFromCookies = Cookies.get("emp_profile");
    const newProfileImage =
      imageFromCookies !== "null" ? imageFromCookies : null;
    setProfileImage(newProfileImage);
    setCallApi(false);
  }, [Cookies.get("emp_profile"), callApi]);
  return (
    <nav
      className={
        sidebarShow
          ? "sidebar nav-scroll sidebar-offcanvas active"
          : "sidebar nav-scroll sidebar-offcanvas"
      }
      id="sidebar"
    >
      <ul className="nav ">
        <li>
          {/* <a className="" href="index.html">
            <img className="w-100 " src="assets/image/Framee.png" />
          </a> */}
          <div>
            <ProfileCard
              initials={initials}
              fullName={fullName}
              profileImage={profile_redux_image}
            />
          </div>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
            // className="nav-link active"
            to={RoutesLink.dashboard}
          >
            <i className="mdi mdi-view-dashboard   menu-icon"></i>
            <span className="menu-title">{t("dashboard")}</span>
          </NavLink>
        </li>
        {role === Roles?.admin_value ? (
          <li className="nav-item">
            <NavLink
              className={({ isActive, isPending }) => {
                return isPending
                  ? "nav-link"
                  : isActive ||
                    location.pathname == RoutesLink?.add_user_account
                  ? "nav-link active"
                  : "nav-link";
              }}
              // className="nav-link active"
              to={RoutesLink.user_account}
            >
              <i className="mdi  mdi-account menu-icon"></i>
              <span className="menu-title"> {t("user_account")}</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {role === Roles?.coach_value ? (
          <li className="nav-item">
            <NavLink
              className={({ isActive, isPending }) => {
                return isPending
                  ? "nav-link"
                  : isActive ||
                    location.pathname == RoutesLink?.add_user_account ||
                    location?.pathname === RoutesLink?.add_user_profile
                  ? "nav-link active"
                  : "nav-link";
              }}
              // className="nav-link active"
              to={RoutesLink.coach_profile}
            >
              <i className="mdi mdi-view-dashboard menu-icon"></i>
              <span className="menu-title"> Profile</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive ||
                  location.pathname == RoutesLink?.add_beneficiary ||
                  location.pathname == RoutesLink?.beneficiary_profile ||
                  location.pathname == RoutesLink?.business_plan_form
                ? "nav-link active"
                : "nav-link"
            }
            // className="nav-link"
            to={RoutesLink.beneficiary_list}
          >
            <i className="mdi mdi-human-female-girl menu-icon"></i>
            <span className="menu-title"> {t("beneficiary")}</span>
          </NavLink>
        </li>
        {role === Roles?.admin_value || role === Roles?.viewer_value ? (
          <li className="nav-item">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-link"
                  : isActive ||
                    location.pathname == RoutesLink?.coach_profile ||
                    location?.pathname === RoutesLink?.add_user_profile
                  ? "nav-link active"
                  : "nav-link"
              }
              // className="nav-link"
              to={RoutesLink.coach_list}
            >
              <i className="mdi mdi-human-male menu-icon"></i>
              <span className="menu-title">{t("coach")}</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
        <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive ||
                  location.pathname == RoutesLink?.coach_plan_detail ||
                  location.pathname == RoutesLink?.add_coach_plan ||
                  location.pathname == RoutesLink?.coach_plan_session_details
                ? "nav-link active"
                : "nav-link"
            }
            // className="nav-link"
            to={RoutesLink.coach_plan_list}
          >
            <i className="mdi mdi-account-group menu-icon"></i>
            <span className="menu-title">{t("coaching_plan")}</span>
          </NavLink>
        </li>

        {/* <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
            // className="nav-link"
            to={RoutesLink.coach_plan}
          >
            <i className=" mdi mdi-notebook-multiple menu-icon"></i>
            <span className="menu-title">Coach Plan</span>
          </NavLink>
        </li> */}
        {/* <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
            // className="nav-link"
            to={RoutesLink.coaching}
          >
            <i className="mdi mdi-notebook-outline menu-icon"></i>
            <span className="menu-title">Coaching</span>
          </NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive || location.pathname == RoutesLink?.subvention_detail
                ? "nav-link active"
                : "nav-link"
            }
            to={RoutesLink.subventions}
          >
            <i className="mdi mdi-cash-multiple menu-icon"></i>
            <span className="menu-title">{t("subvention")}</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "nav-link"
                : isActive || location.pathname == RoutesLink?.add_equipment
                ? "nav-link active"
                : "nav-link"
            }
            //  className="nav-link active"
            to={RoutesLink.equipments}
          >
            <i className="mdi mdi-recycle  menu-icon"></i>
            <span className="menu-title">{t("equipment")}</span>
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
           
            to={RoutesLink.suppliers}
          >
            <i className="mdi mdi-chemical-weapon   menu-icon"></i>
            <span className="menu-title">Suppliers</span>
          </NavLink>
        </li> */}
        {
          <li className="nav-item">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-link"
                  : isActive ||
                    location.pathname == RoutesLink?.cooperative_detail ||
                    location?.pathname === RoutesLink?.add_cooperatives
                  ? "nav-link active"
                  : "nav-link"
              }
              to={RoutesLink.cooperatives}
            >
              <i className="mdi mdi-google-physical-web menu-icon"></i>
              <span className="menu-title">{t("cooperative")}</span>
            </NavLink>
          </li>
        }

        {
          <li className="nav-item">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-link"
                  : isActive ||
                    location.pathname == RoutesLink?.suppliers_detail ||
                    location?.pathname === RoutesLink?.add_suppliers
                  ? "nav-link active"
                  : "nav-link"
              }
              to={RoutesLink.suppliers}
            >
              <i className="mdi mdi-google-physical-web menu-icon"></i>
              <span className="menu-title">{t("supplier")}</span>
            </NavLink>
          </li>
        }
        {/* <li className="nav-item">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"
            }
            data-bs-toggle="collapse"
            to={RoutesLink.reports}
            aria-expanded="false"
            aria-controls="general-pages"
          >
            <i className="mdi mdi-book-open  menu-icon"></i>
            <span className="menu-title">Report</span>
          </NavLink>
        </li> */}
        {
          <li className="nav-item">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-link"
                  : isActive
                  ? "nav-link active"
                  : "nav-link"
              }
              //  className="nav-link active"
              to={RoutesLink.settings}
            >
              <i className="mdi mdi-settings  menu-icon"></i>
              <span className="menu-title">{t("setting")}</span>
            </NavLink>
          </li>
        }

        {role === Roles?.admin_value || role === Roles?.coach_value ? (
          <li className="nav-item">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending
                  ? "nav-link"
                  : isActive
                  ? "nav-link active"
                  : "nav-link"
              }
              //  className="nav-link active"
              to={RoutesLink.notification}
            >
              <i className="mdi mdi-bell  menu-icon"></i>
              <span className="menu-title">{t("notifications")}</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;
