import { useEffect, useState } from "react";
import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import RoleType from "../../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { profileImage, setUserLoggedInDetails } from "../../../store/actions/UserActions";
import AssignEquipmentCard from "./AssignEquipmentCard";


const AssignEquipments = () => {
  const dispatch = useDispatch();
  let role = Cookies.get("emp_role");
  const navigate = useNavigate();
  const location = useLocation();
  const [inputCategoryData, setInputCategoryData] = useState("");
  console.log("location", location);
  const [searchOptionData, setSearchOptionData] = useState();
  const [filterData, setFilterData] = useState();
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [inputTypeData, setInputTypeData] = useState("text");
  const [callApi, setCallApi] = useState(true);
  const [equipmentList, setEquipmentList] = useState([]);
  const [pages, setPages] = useState();
  const listHeading = [{ classType: "", data: "Name" }, { classType: "", data: "Detail" }, , { classType: "text-end", data: "Price" }, { classType: "text-end", data: "Quantity" }, { classType: "", data: "Status" }];

  const searchOption1Array = [{ name: "Search By", value: "", type: "select" }, { name: "Name", value: "name", type: "text" }, { name: "Status", value: "status", type: "select" }];
  const searchOptionStatusArray = [{ name: "Active", value: 1, type: "status" }, { name: "Inactive", value: 0, type: "status" },];

  const searchOption2Array = searchOptionStatusArray.filter((data) => data?.type === searchOptionData);


  const [assignedEquipmentsList, setAssignedEquipmentsList] = useState([]);

  const handlePageChange = (pageitem) => {
    setEquipmentList([]);
    setActivePage(pageitem);
    getEquipment(pageitem);
  };

  const AssignCheckboxBeneficiaries = (e, data) => {
    if (e.target.checked === true) {
      setAssignedEquipmentsList((prevData) => [...prevData, data]);
    } else {
      let new_beneficiarierList = assignedEquipmentsList.filter(
        (item) => item?.id !== data?.id
      );

      setAssignedEquipmentsList(new_beneficiarierList);
    }
  };


  const submitAssignmentCooperativeHandler = () => {
    setPageIsLoading(true);
    let bene = {
      supplier_id: location?.state?.id,
      equipment_ids: assignedEquipmentsList.map((item) => item.id),
    };
    let unassignbene = {
      supp_equipment_ids: assignedEquipmentsList.map((item) => item?.supplier_equipments?.[0]?.id),
    }
    {
      location?.state?.type === "assign" && handleFetchRequest(`${apiUrl?.assign_equipments}`, "POST", bene).then(
        (response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            toast.success(response?.message);
            setAssignedEquipmentsList([]);
            setCallApi(true);
            // backButtonHandler();
            if (location.state.page) {
              navigate(`${location.state.page}`, { state: { id: location.state.id, tabid: "2" } })
            } else {
              navigate(-1);
            }
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          }
          else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        }
      );
    }

    {
      location?.state?.type === "unassign" &&
        handleFetchRequest(`${apiUrl?.unassign_equipments}`, "Delete", unassignbene).then((response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            toast.success(response?.message);
            setAssignedEquipmentsList([]);
            setCallApi(true);
            if (location.state.page) {
              navigate(`${location.state.page}`, { state: { id: location.state.id, tabid: "2" } })
            } else {
              navigate(-1);
            }
          }
          else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          }
          else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        })
    }
  };



  const assignAllBeneficiaryHandler = (e) => {
    if (e.target.checked === true) {
      const all_assigned_list = [...equipmentList];
      setAssignedEquipmentsList(all_assigned_list);
    } else {
      setAssignedEquipmentsList([]);
    }
  };

  const filters = [];

  const applyFilter = () => {
    const filterValue = filterData ? `&${searchOptionData}=${filterData}` : "";
    filters.push(filterValue);
  };


  const deleteBeneficiaryHandler = (id) => {
    let new_beneficiarierList = assignedEquipmentsList.filter(
      (item) => item?.id !== id
    );
    setAssignedEquipmentsList(new_beneficiarierList);
  };

  const cancelAssignedListHandler = () => {
    setAssignedEquipmentsList([]);
  };

  const backButtonHandler = () => {

    if (location.state.page) {
      navigate(`${location.state.page}`, { state: { id: location.state.id, tabid: "2" } })
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    getEquipment(activePage);
  }, []);

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setActivePage(1);
    setSearchOptionData("");
    setFilterData("");
    setInputCategoryData("###select");
    setEquipmentList([]);
    {
      location?.state?.type === "assign" &&
        handleFetchRequest(
          `${apiUrl.get_equipment_list
          }?page_no=1&page_size=10&supplier_id=${location?.state?.id}&assign_supplier=1`
        ).then((response) => {
          console.log(response);
          if (response?.status === true) {
            setIsLoading(false);
            setEquipmentList(response.data);
            setCallApi(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setCallApi(false);
            setIsLoading(false);
            console.log(response);
            toast.error(response.message);
          }
        });
    }
    {
      location?.state?.type === "unassign" &&
        handleFetchRequest(`${apiUrl?.get_equipment_list}?supplier_id=${location?.state?.id}&assign_supplier=0&page_no=1&page_size=10`).then(response => {
          if (response?.status === true) {
            setEquipmentList(response?.data);
            setCallApi(false);
            setIsLoading(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          }
        })
    }

  }

  const getEquipment = (pageItem) => {
    applyFilter();
    setIsLoading(true);
    let apiUrl1
    apiUrl1 = `${apiUrl.get_equipment_list
      }?page_no=${pageItem}&page_size=10&supplier_id=${location?.state?.id}&assign_supplier=1${filters.join("&")}`
    {
      location?.state?.type === "assign" &&
        handleFetchRequest(apiUrl1
        ).then((response) => {
          console.log(response);
          if (response.status === true) {
            setIsLoading(false);
            setEquipmentList(response.data);
            setCallApi(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setCallApi(false);
            setIsLoading(false);
            console.log(response);
            toast.error(response.message);
          }
        });
    }
    {
      location?.state?.type === "unassign" &&
        handleFetchRequest(`${apiUrl?.get_equipment_list}?supplier_id=${location?.state?.id}&assign_supplier=0&page_no=${pageItem}&page_size=10${filters.join("&")}`).then(response => {
          if (response.status === true) {
            setEquipmentList(response?.data);
            setCallApi(false);
            setIsLoading(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          }
        })
    }
  }

  return (
    <Layout>

      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 mb-3 mb-lg-5 px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading
                    backButtonHandler={backButtonHandler}
                    searchOption1Array={searchOption1Array}
                    //  addLink={RoutesLink?.add_beneficiary}
                    //  btnName={"Add Beneficiary"}
                    name={location?.state.type === "assign" ? "Assign Equipments" : "Unassign Equipments"}
                    useraccount={true}
                    setInputTypeData1={setInputTypeData}
                    setMainCallApi={() => getEquipment(1)}
                    inputTypeData={inputTypeData}
                    //  downloadToggle={downloadModalToggle}
                    //  addArray={addAction1}
                    searchOption2Array={searchOption2Array}
                    setData={setEquipmentList}
                    setFilterData={setFilterData}
                    setSearchOptionData={setSearchOptionData}
                    filterData={filterData}
                    setActivePage={setActivePage}
                    ResetAccountHandler={ResetAccountHandler}
                    inputCategoryData={inputCategoryData}
                    setInputCategoryData={setInputCategoryData}
                  />
                  <div className=" px-0">
                    <div
                      className={
                        assignedEquipmentsList?.length > 0
                          ? "tablcont active"
                          : "tablcont"
                      }
                    >
                      <PrimaryTable
                        assignedList={assignedEquipmentsList}
                        listData={equipmentList}
                        listHeading={listHeading}
                        editPage={RoutesLink?.add_beneficiary}
                        checkboxFunction={AssignCheckboxBeneficiaries}
                        assignAllCheckbox={assignAllBeneficiaryHandler}
                        // handleDelete={deletHandler}
                        // addData={addHeadingAction}
                        assignEquipments={true}
                        // beneficiary={true}
                        activePage={activePage}
                        pages={pages}
                        handlePageChange={handlePageChange}
                        isLoading={isLoading}
                        idClickLink={RoutesLink?.beneficiary_profile}
                      />
                    </div>
                    {assignedEquipmentsList?.length > 0 && (
                      <AssignEquipmentCard
                        assignedEquipmentsList={assignedEquipmentsList}
                        deleteBeneficiaryHandler={deleteBeneficiaryHandler}
                        cancelAssignedListHandler={cancelAssignedListHandler}
                        submitAssignmentHandler={() => {
                          submitAssignmentCooperativeHandler()
                        }
                        }
                        isLoading={pageIsLoading}
                        type={location?.state?.type === "unassign" && "delete"}
                      />
                    )}
                  </div>

                  {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
                     {!isLoading && beneficiaryList?.length===0 ? <h4 style={{ textAlign: "center" }}>No Data Found</h4>:"" } */}
                  {/* {beneficiaryList?.length>=10||currentPage>1?<Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} setListData={setBeneficiaryList}/>:""}     */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageIsLoading && <PageLoader />}
    </Layout>
  )
}

export default AssignEquipments