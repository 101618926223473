import Heading from "../../../Components/CommonElements/Heading";
import Layout from "../../../Components/Layout/Layout";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { toast } from "react-toastify";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
// import Pagination from "react-js-pagination";
import Roles from "../../../ApiHelper/Roles.json";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const Cooperatives = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [inputCategoryData, setInputCategoryData] = useState("");
  const [pages, setPages] = useState();
  // const [callApi, setCallApi] = useState(true);
  const [coachList, setCoachList] = useState([]);
  const [searchOptionData, setSearchOptionData] = useState();
  const [inputTypeData, setInputTypeData] = useState("select");
  const [filterData, setFilterData] = useState();
  let role = Cookies.get("emp_role");
  const { t } = useTranslation();

  const listHeading = [
    { data: `${t("alias_id")}`, classType: "" },
    { data: `${t("cooperative_name")}`, classType: "" },
    { data: `${t("business_type")}`, classType: "" },
    { data: `${t("sector_activity")}`, classType: "" },
    { data: `${t("sales")}`, classType: "" },
    { data: `${t("createdat")}`, classType: "" },
    { data: `${t("assigned_beneficiary")}`, classType: "text-end" },
    { data: `${t("status")}`, classType: "" },
    { data: `${t("action")}`, classType: "" },
  ];

  const searchOption1Array = [
    { name: `${t("search_by")}`, value: "", type: "select" },
    { name: `${t("alias_id")}`, value: "aliasid", type: "text" },
    {
      name: `${t("cooperative_name")}`,
      value: "cooperative_name",
      type: "text",
    },
  ];

  const searchOptionStatusArray = [
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  let addHeadingAction;

  const filters = [];

  // if(searchOptionData=="assign"){
  //   filters.push(`&role=${filterData}`)
  // }
  if (searchOptionData === "aliasid") {
    if (filterData) {
      filters.push(`&alias_id=${filterData}`);
    }
  }

  if (searchOptionData === "cooperative_name") {
    if (filterData) {
      filters.push(`&cooperative_name=${filterData}`);
    }
  }

  const handlePageChange = (pageitem) => {
    setCoachList([]);
    getCooperativesList(pageitem);
    setCurrentPage(pageitem);
  };

  useEffect(() => {
    getCooperativesList(currentPage);
  }, []);

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setSearchOptionData("");
    setFilterData("");
    setCoachList([]);
    setInputCategoryData("###select");
    handleFetchRequest(
      `${apiUrl.get_cooperatives}?page_no=1&page_size=10`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCoachList(response.data);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const getCooperativesList = (pageItem) => {
    setIsLoading(true);
    if (searchOptionData === "assign") {
      handleFetchRequest(
        `${
          apiUrl.get_cooperatives
        }?page_no=${pageItem}&page_size=10&assignment=${+filterData}`
      ).then((response) => {
        if (response?.status === true) {
          // setCallApi(false);
          setIsLoading(false);
          setCoachList(response.data);
          setPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          // setCallApi(false);
          console.log(response);
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    } else {
      handleFetchRequest(
        `${
          apiUrl.get_cooperatives
        }?page_no=${pageItem}&page_size=10${filters.join("&")}`
      ).then((response) => {
        if (response?.status === true) {
          // setCallApi(false);
          setIsLoading(false);
          setCoachList(response.data);
          setPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          // setCallApi(false);
          console.log(response);
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    }
  };

  const deleteHandlerWithoutPopup = (data) => {
    handleFetchRequest(
      `${apiUrl.delete_cooperatives}?cooperative_id=${data?.id}`,
      "DELETE"
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response?.message);
        // setCallApi(true);
        getCooperativesList(currentPage);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        if (response?.messageCode === 109) {
          deleteHandler(data);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      }
    });
  };

  const deleteHandler = (data) => {
    Swal.fire({
      text: "Are you sure you want to delete this cooperative?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        handleFetchRequest(
          `${apiUrl.delete_cooperatives}?cooperative_id=${
            data?.id
          }&force_delete=${true}`,
          "DELETE"
        ).then((response) => {
          if (response?.status === true) {
            setIsLoading(false);
            toast.success(response?.message);
            // setCallApi(true);
            getCooperativesList(currentPage);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
            toast.error(response?.message);
          }
        });
      }
    });
  };

  if (role === Roles.admin_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.cooperative_detail },
      { name: "Edit", path: RoutesLink?.add_cooperatives },
      {
        name: "Assign Beneficiary",
        path: RoutesLink?.assign_beneficiary,
        type: "assign",
        page: "cooperative",
        pageurl: "/cooperatives",
      },
      {
        name: "Unassign Beneficiary",
        path: RoutesLink?.assign_beneficiary,
        type: "unassign",
        page: "cooperative",
        pageurl: "/cooperatives",
      },
      {
        name: "Delete",
        type: "function",
        path: deleteHandlerWithoutPopup,
        value: "always",
      },
    ];
  } else if (role === Roles.viewer_value || role === Roles.admin_value) {
    addHeadingAction = [{ name: "View", path: RoutesLink?.cooperative_detail }];
  } else if (role === Roles.coach_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.cooperative_detail },
      { name: "Edit", path: RoutesLink?.add_cooperatives },
    ];
  }

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading
                    inputTypeData={inputTypeData}
                    setInputTypeData1={setInputTypeData}
                    searchOption1Array={searchOption1Array}
                    // addLink={RoutesLink?.add_user_account}
                    addLink={{
                      link: RoutesLink?.add_cooperatives,
                      data: "coach",
                    }}
                    // btnName={role === Roles.admin_value ? "Add Cooperative" : ""}
                    name={"Cooperatives"}
                    // addArray={addAction1}
                    inputCategoryData={inputCategoryData}
                    setInputCategoryData={setInputCategoryData}
                    setSearchOptionData={setSearchOptionData}
                    searchOption2Array={searchOption2Array}
                    setMainCallApi={() => getCooperativesList(1)}
                    setFilterData={setFilterData}
                    setData={setCoachList}
                    filterData={filterData}
                    setActivePage={setCurrentPage}
                    ResetAccountHandler={ResetAccountHandler}
                  />
                  <PrimaryTable
                    listData={coachList}
                    listHeading={listHeading}
                    editPage={"/add-coach"}
                    addData={addHeadingAction}
                    cooperatives={true}
                    pages={pages}
                    activePage={currentPage}
                    handlePageChange={handlePageChange}
                    isLoading={isLoading}
                    idClickLink={RoutesLink?.coach_profile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Cooperatives;
