import { Link } from "react-router-dom";
import ActionDropDown from "../CommonElements/ActionDropDown";
import { RoutesLink } from "../../ApiHelper/RoutesLink";
// import { Roles } from "../../ApiHelper/Nomanclatures";
import Roles from "../../ApiHelper/Roles.json";
import Pagination from "react-js-pagination";
import TableLoader from "../CommonElements/TableLoader";
import texts from "../../ApiHelper/Nomanclatures.json";
import { CurrencyConverter } from "../../ApiHelper/CurrencyConverter";
import moment from "moment";
import { TimeStampConverter } from "../../ApiHelper/TimeStampConverter";
import { useTranslation } from "react-i18next";

const PrimaryTable = ({
  listData,
  listHeading,
  editPage,
  handleDelete,
  addData,
  cooperatives_beneficiary,
  userAccount,
  coach,
  isLoading,
  beneficiary,
  assignBeneficiary,
  checkboxFunction,
  cooperatives,
  assignedList,
  assignAllCheckbox,
  equipment,
  handlePageChange,
  pages,
  suppliers,
  assignEquipments,
  equipment_list,
  activePage,
  subvention,
  showDeleteBox,
  cooperatives_list,
  subvention_detail,
  totalSubventionAmount,
  subventionprofile,
  idClickLink,
  linkType,
  showCheckoutBox,
  subventionMonitoring,
  coachPlan,
  coachPlanBeneficiary,
  AddCoachPlanHandler,
  mainCoachPlan,
  mainCoachPlan2,
  beneficiaryName,
}) => {
  const { t } = useTranslation();

  function capitalizeFirstLetter(str) {
    if (str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    }
  }

  // let str = "in progress";
  // let capitalized = ;

  return (
    <>
      <div
        className="table-responsive p-0"
        style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
      >
        {/* {<div className="d-flex justify-content-center"> No Data Found</div>} */}
        <table className="table table-hover">
          <thead>
            <tr>
              {assignEquipments || assignBeneficiary || showDeleteBox ? (
                <th>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={assignAllCheckbox}
                  />
                </th>
              ) : (
                ""
              )}
              {listHeading?.map((item, index) => (
                <th
                  key={index}
                  className={item?.classType ? item?.classType : "text-start"}
                  style={{
                    maxWidth: "150px",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {item?.data}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {listData?.map((data) => (
              <tr>
                {cooperatives_beneficiary && (
                  <td>
                    <Link to={idClickLink} state={{ id: data?.beneficiary_id }}>
                      {data?.beneficiary?.reference_id}
                    </Link>
                  </td>
                )}
                {cooperatives_beneficiary && (
                  <td>{capitalizeFirstLetter(data?.beneficiary?.fullname)}</td>
                )}
                {cooperatives_beneficiary && (
                  <td>{data?.beneficiary?.telephone_pref}</td>
                )}
                {cooperatives_beneficiary && (
                  <td>{capitalizeFirstLetter(data?.beneficiary?.province)}</td>
                )}
                {cooperatives_beneficiary && (
                  <td>
                    {data?.beneficiary?.grant_eligibility === 1 ? (
                      <span>Allowed</span>
                    ) : data?.beneficiary?.grant_eligibility === 0 ? (
                      <span>Not Allowed</span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                )}
                {cooperatives_beneficiary ? (
                  <td>
                    {data?.beneficiary?.status === 1 ? (
                      <span className="active-button  badge">Active </span>
                    ) : (
                      <span className="pending-button  badge">Inactive </span>
                    )}
                  </td>
                ) : (
                  ""
                )}
                {(cooperatives || cooperatives_list) && (
                  <td>
                    <Link
                      to={"/cooperative-detail"}
                      state={{
                        id: data?.cooperative_master?.id
                          ? data?.cooperative_master?.id
                          : data?.id,
                      }}
                    >
                      {data?.alias_id
                        ? data?.alias_id
                        : data?.cooperative_master?.alias_id}
                    </Link>
                  </td>
                )}
                {(cooperatives || cooperatives_list) && (
                  <td>
                    {data?.cooperative_name
                      ? capitalizeFirstLetter(data?.cooperative_name)
                      : capitalizeFirstLetter(
                          data?.cooperative_master?.cooperative_name
                        )}
                  </td>
                )}
                {(cooperatives || cooperatives_list) && (
                  <td>
                    {data?.business_type
                      ? data?.business_type
                      : data?.cooperative_master?.business_type}
                  </td>
                )}
                {(cooperatives || cooperatives_list) && (
                  <td>
                    {data?.sector_activity
                      ? data?.sector_activity
                      : data?.cooperative_master?.sector_activity}
                  </td>
                )}
                {(cooperatives || cooperatives_list) && (
                  <td>
                    {data?.sales
                      ? CurrencyConverter(data?.sales)
                      : data?.cooperative_master?.sales
                      ? CurrencyConverter(data?.cooperative_master?.sales)
                      : "N/A"}
                  </td>
                )}
                {(cooperatives || cooperatives_list) && (
                  <td>
                    {data?.createdAt
                      ? moment(TimeStampConverter(data?.createdAt)).format(
                          "DD/MM/YYYY"
                        )
                      : moment(
                          TimeStampConverter(
                            data?.cooperative_master?.createdAt
                          )
                        ).format("DD/MM/YYYY")}
                  </td>
                )}
                {cooperatives_list ? (
                  <td>
                    {data?.status ? (
                      <>
                        {data?.status === 1 ? (
                          <span className="active-button  badge">Active </span>
                        ) : (
                          <span className="pending-button  badge">
                            Inactive{" "}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {data?.cooperative_master?.status === 1 ? (
                          <span className="active-button  badge">Active </span>
                        ) : (
                          <span className="pending-button  badge">
                            Inactive{" "}
                          </span>
                        )}
                      </>
                    )}
                  </td>
                ) : (
                  ""
                )}
                {suppliers && (
                  <td>
                    <Link to={"/supplier-detail"} state={{ id: data?.id }}>
                      {data?.alias_id ? data?.alias_id : ""}
                    </Link>
                  </td>
                )}
                {suppliers && (
                  <td className="">
                    {capitalizeFirstLetter(data?.supplier_name)}
                  </td>
                )}
                {suppliers && (
                  <td className="">{capitalizeFirstLetter(data?.city)}</td>
                )}
                {suppliers && <td className="">{data?.equipment_count}</td>}
                {suppliers && (
                  <td className="">
                    {moment(TimeStampConverter(data?.createdAt)).format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                )}

                {cooperatives && (
                  <td className="text-end">{data?.members_count}</td>
                )}
                {/* checkbox Data start here */}
                {/* { && <td><Link to={idClickLink} state={{ data: data }}>{data?.plan_id}</Link></td>} */}
                {mainCoachPlan2 && (
                  <td>
                    <Link
                      to={idClickLink}
                      state={{ data: data, back: "coach_profile" }}
                    >
                      {data?.plan_id}
                    </Link>
                  </td>
                )}
                {coachPlan ||
                  (mainCoachPlan && (
                    <td>
                      <Link
                        to={idClickLink}
                        state={{ data: data, linkname: linkType }}
                      >
                        {data?.plan_id}
                      </Link>
                    </td>
                  ))}
                {coachPlan ||
                  (mainCoachPlan && (
                    <td>
                      {capitalizeFirstLetter(data?.beneficiary?.fullname)}
                    </td>
                  ))}
                {mainCoachPlan2 && (
                  <td>{capitalizeFirstLetter(data?.beneficiary?.fullname)}</td>
                )}
                {/* {mainCoachPlan2 && <td><Link to={idClickLink} state={{ data: data, }}>{data?.plan_id}</Link></td>} */}
                {coachPlan ||
                  (mainCoachPlan && <td>{data?.coach?.username}</td>)}
                {mainCoachPlan2 && <td>{data?.coach?.username}</td>}

                {/* {mainCoachPlan && <td>{data?.coach?.username}</td>} */}
                {coachPlan && (
                  <td>
                    <Link
                      to={idClickLink}
                      state={{ data: data, linkname: linkType, tabpane: "3" }}
                    >
                      {data?.session_id}
                    </Link>
                  </td>
                )}
                {mainCoachPlan || mainCoachPlan2 ? (
                  <td>
                    {data?.schedule_start_date
                      ? moment(
                          TimeStampConverter(data?.schedule_start_date)
                        ).format("DD/MM/YYYY h:mm A ")
                      : ""}
                  </td>
                ) : (
                  ""
                )}

                {mainCoachPlan || mainCoachPlan2 ? (
                  <td>
                    {data?.planing_start_ts
                      ? moment(
                          TimeStampConverter(data?.planing_start_ts)
                        ).format("DD/MM/YYYY h:mm A ")
                      : ""}
                  </td>
                ) : (
                  ""
                )}
                {mainCoachPlan || mainCoachPlan2 ? (
                  <td>
                    {data?.planing_end_ts
                      ? moment(TimeStampConverter(data?.planing_end_ts)).format(
                          "DD/MM/YYYY h:mm A "
                        )
                      : ""}
                  </td>
                ) : (
                  ""
                )}

                {mainCoachPlan && (
                  <td className="text-start">
                    {capitalizeFirstLetter(data?.status)}
                  </td>
                )}
                {mainCoachPlan2 && (
                  <td className="text-start">
                    {capitalizeFirstLetter(data?.status)}
                  </td>
                )}

                {coachPlan && (
                  <td>
                    {data?.schedule_start_ts &&
                      moment(
                        TimeStampConverter(data?.schedule_start_ts)
                      ).format("DD/MM/YYYY h:mm A ")}
                  </td>
                )}
                {coachPlan && (
                  <td>
                    {data?.schedule_end_ts &&
                      moment(TimeStampConverter(data?.schedule_end_ts)).format(
                        "DD/MM/YYYY  h:mm A"
                      )}
                  </td>
                )}
                {coachPlan && (
                  <td>
                    {data?.session_start_ts &&
                      moment(TimeStampConverter(data?.session_start_ts)).format(
                        "DD/MM/YYYY  h:mm A"
                      )}
                  </td>
                )}
                {coachPlan && (
                  <td>
                    {data?.session_end_ts &&
                      moment(TimeStampConverter(data?.session_end_ts)).format(
                        "DD/MM/YYYY  h:mm A"
                      )}
                  </td>
                )}
                {coachPlan && <td>{capitalizeFirstLetter(data?.status)}</td>}

                {/* {coachPlan && <td>{data?.remark}</td>} */}
                {assignEquipments || assignBeneficiary || showDeleteBox ? (
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={assignedList?.some((item) => item.id == data.id)}
                      onChange={(event) => checkboxFunction(event, data)}
                    />
                  </td>
                ) : (
                  ""
                )}
                {/* checkbox Data Ends here */}

                {equipment_list && <td>{capitalizeFirstLetter(data?.name)}</td>}
                {equipment_list && (
                  <td className="text-truncate" style={{ maxWidth: "100px" }}>
                    <span title={data?.detail}>
                      {data?.detail ? data?.detail : "N/A"}
                    </span>
                  </td>
                )}
                {equipment_list && <td>{`$ ${data?.price}`}</td>}
                {equipment_list && <td>{data?.quantity}</td>}
                {/* {equipment_list && <td>{data?.total_price}</td>} */}
                {equipment_list && (
                  <td>
                    {" "}
                    {data?.status === 1 ? (
                      <span className="active-button  badge">Active </span>
                    ) : (
                      <span className="pending-button  badge">Inactive </span>
                    )}
                  </td>
                )}

                {/* equipment table starts here */}

                {assignEquipments || equipment || subvention_detail ? (
                  <td>
                    {capitalizeFirstLetter(data?.name)}{" "}
                    {capitalizeFirstLetter(data?.equipment_name)}
                  </td>
                ) : (
                  ""
                )}
                {assignEquipments || equipment ? (
                  <td className=" text-truncate" style={{ maxWidth: "100px" }}>
                    <span title={data?.detail}>
                      {data?.detail ? data?.detail : "N/A"}
                    </span>
                  </td>
                ) : (
                  ""
                )}

                {assignEquipments || equipment || subvention_detail ? (
                  <td className="text-end">
                    {showCheckoutBox === 1
                      ? `$ ${data?.price}`
                      : CurrencyConverter(data?.price)}
                  </td>
                ) : (
                  ""
                )}
                {assignEquipments || equipment || subvention_detail ? (
                  <td className="text-end">{data?.quantity}</td>
                ) : (
                  ""
                )}
                {subvention_detail && (
                  <td className="text-end">
                    {data?.total_price && CurrencyConverter(data?.total_price)}
                  </td>
                )}

                {/* equipment table ends here */}

                {/*Beneficiary Data starts here  */}
                {beneficiary ? (
                  <td className="text-start">
                    <Link to={idClickLink} state={data}>
                      {data?.reference_id} {data?.beneficiary?.reference_id}
                    </Link>
                  </td>
                ) : (
                  ""
                )}
                {beneficiaryName ? (
                  <td className="text-start">
                    <Link to={idClickLink} state={data}>
                      {data?.fullname} {data?.beneficiary?.fullname}
                    </Link>
                  </td>
                ) : (
                  ""
                )}
                {beneficiary ? (
                  <td className="text-start">
                    {capitalizeFirstLetter(data?.fullname)}
                    {capitalizeFirstLetter(data?.beneficiary?.fullname)}
                  </td>
                ) : (
                  ""
                )}
                {beneficiary && (
                  <td className="text-start">{data?.telephone_pref}</td>
                )}
                {beneficiary && (
                  <td className="text-start">
                    {capitalizeFirstLetter(data?.province)}
                  </td>
                )}
                {beneficiary && (
                  <td className="text-start">
                    {data?.grant_eligibility === 1 ? (
                      <span>Allowed</span>
                    ) : data?.grant_eligibility === 0 ? (
                      <span>Not Allowed</span>
                    ) : (
                      "N/A"
                    )}
                    {}
                  </td>
                )}
                {/*Beneficiary Data Ends here  */}
                {userAccount ? (
                  <td className="text-start">{data?.alias_id}</td>
                ) : (
                  ""
                )}

                {coach || subvention || subventionprofile ? (
                  <td className="text-start">
                    <Link
                      to={idClickLink}
                      state={{ id: data?.id, data: data, type: linkType }}
                    >
                      {data?.alias_id}
                    </Link>
                  </td>
                ) : (
                  ""
                )}
                {userAccount && (
                  <td className="text-start">{data?.username}</td>
                )}
                {coach || userAccount ? (
                  <td className="text-start">
                    {capitalizeFirstLetter(data?.first_name)}
                  </td>
                ) : (
                  ""
                )}
                {coach || userAccount ? (
                  <td className="text-start">
                    {capitalizeFirstLetter(data?.last_name)}
                  </td>
                ) : (
                  ""
                )}

                {/* <td>city</td> */}

                {coach && (
                  <td>
                    {data?.createdAt
                      ? moment(TimeStampConverter(data?.createdAt)).format(
                          "DD/MM/YYYY"
                        )
                      : "N/A"}
                    {/* {data?.createdAt ? new Date(data.createdAt * (data.createdAt > 1e12 ? 1 : 1000)).toLocaleDateString('en-US') : 'N/A'} */}
                  </td>
                )}
                {assignEquipments ||
                cooperatives ||
                beneficiary ||
                coach ||
                userAccount ||
                equipment ||
                suppliers ? (
                  <td>
                    {data?.status === 1 ? (
                      <span className="active-button  badge">Active </span>
                    ) : (
                      <span className="pending-button  badge">Inactive </span>
                    )}
                  </td>
                ) : (
                  ""
                )}
                {subvention || subventionprofile ? (
                  <td>{data?.account?.alias_id}</td>
                ) : (
                  ""
                )}
                {subvention || subventionprofile ? (
                  <td>
                    {capitalizeFirstLetter(data?.account?.first_name)}{" "}
                    {capitalizeFirstLetter(data?.account?.last_name)}
                  </td>
                ) : (
                  ""
                )}
                {subvention && <td>{data?.beneficiary?.reference_id}</td>}
                {subvention && (
                  <td>{capitalizeFirstLetter(data?.beneficiary?.fullname)}</td>
                )}
                {subvention || subventionprofile ? (
                  <td className="text-end">
                    {data?.total_amount &&
                      CurrencyConverter(data?.total_amount)}
                  </td>
                ) : (
                  ""
                )}
                {subvention || subventionprofile ? (
                  <td>{capitalizeFirstLetter(data?.status)}</td>
                ) : (
                  ""
                )}
                {subvention || subventionprofile ? (
                  <td>
                    {data?.createdAt
                      ? moment(TimeStampConverter(data?.createdAt)).format(
                          "DD/MM/YYYY"
                        )
                      : "N/A"}
                  </td>
                ) : (
                  ""
                )}
                {userAccount && (
                  <td>
                    {data?.role === Roles?.coach_value && (
                      <span className="coach-button">
                        {capitalizeFirstLetter(texts?.heading_text?.coach_name)}
                      </span>
                    )}
                    {data?.role === Roles?.viewer_value && (
                      <span className="beneficiary-button">
                        {capitalizeFirstLetter(texts.heading_text?.viewer_name)}
                      </span>
                    )}
                    {data?.role === Roles?.admin_value && (
                      <span className="coach-button">
                        {capitalizeFirstLetter(texts.heading_text?.admin_name)}
                      </span>
                    )}
                  </td>
                )}
                {coach && (
                  <td className="text-end">{data?.beneficiaryCount}</td>
                )}

                {/* <td>
                            <Link to={RoutesLink?.add_user_profile} state={{id:data?.id}}  > <span class="mdi mdi-pencil fs-5 "></span></Link>
                            <span class="mdi mdi-lock fs-5"></span>
                            </td> */}
                {coachPlanBeneficiary && (
                  <td className="text-start">
                    <button
                      className="btn btn-primary"
                      onClick={() => AddCoachPlanHandler(data)}
                    >
                      {data.coach_plan === true
                        ? "Update Coaching Plan"
                        : "Add Plan"}
                    </button>
                  </td>
                )}
                {addData && (
                  <td className="dropdown">
                    <a
                      className="nav-link"
                      id="messageDropdown"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-dots-vertical rounded-3 bg-light py-2 px-2"></i>
                    </a>
                    <div
                      className="dropdown-menu-table overflow-hidden dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                      aria-labelledby="messageDropdown"
                    >
                      <ActionDropDown
                        addArray={addData}
                        id={data?.id}
                        detailsData={data}
                      />
                      <div></div>
                    </div>
                  </td>
                )}
              </tr>
            ))}
            {totalSubventionAmount ? (
              <tr>
                <td>
                  <strong>Total Amount</strong>
                </td>
                <td></td>
                <td></td>
                <td className="text-end">
                  <strong>{CurrencyConverter(totalSubventionAmount)}</strong>
                </td>
              </tr>
            ) : (
              ""
            )}
            {isLoading && <TableLoader listHeading={listHeading} />}
          </tbody>
        </table>
        {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>} */}
        {!isLoading && listData?.length === 0 ? (
          <h4 style={{ textAlign: "center" }}>No Data Found</h4>
        ) : (
          ""
        )}
      </div>

      <div className="py-3 row align-items-center justify-content-between px-0">
        <div className="col-12 col-md-6 col-lg-5 px-0">
          <div className="d-flex flex-column">
            {pages?.total ? (
              <>
                <p className="my-0">
                  <strong>{t("total_records")} : </strong>
                  {pages?.total}{" "}
                </p>
              </>
            ) : (
              ""
            )}
            {subventionMonitoring ? (
              <>
                <p className="my-0">
                  <strong> {t("total_records")} :</strong> {listData?.length}
                </p>
              </>
            ) : (
              <>
                <p className="my-0">
                  <strong> {t("per_page_account")} :</strong> {listData?.length}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-7 px-0">
          {pages?.totalPage > 1 && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={pages?.total}
              disabledClass="paginationDisableCustom"
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default PrimaryTable;
