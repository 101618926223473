import { toast } from "react-toastify";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  conversionRate,
  noticationDetail,
  profileImage,
  setUserLoggedInDetails,
} from "../../store/actions/UserActions";

// import { useId } from "react";
import { apiUrl } from "../../ApiHelper/ApiUrl";
import { RoutesLink } from "../../ApiHelper/RoutesLink";
// import { InputTexts, buttonTexts } from "../../ApiHelper/Nomanclatures.json";
import texts from "../../ApiHelper/Nomanclatures.json";
// import { Spinner } from "reactstrap";
import { useState } from "react";
import ButtonLoader from "../../Components/CommonElements/ButtonLoader";
import RoleType from "../../ApiHelper/Roles.json";
import Cookies from "js-cookie";
import moment from "moment";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Generate a random 6-digit number
  var randomString = Math.random().toString(36).slice(2, 8);
  let randomNum = Math.floor(Math.random() * 900000) + 100000;
  var client_id = randomNum + randomString;
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    // console.log(data);
    // console.log(window.sessionStorage);
    // console.log(window.navigator);
    const loginData = {
      username: data?.email,
      client_id: client_id,
      client_type: "web",
    };

    let password = { password: data?.password };

    handleFetchRequest(apiUrl.login, "POST", loginData, password).then(
      (response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response.message, {
            position: "top-right",
          });
          localStorage.setItem("i18nextLng", response.data.access.language);
          let tokenRealDate = new Date(response.data.access.expires);
          let todayRealDate = new Date();
          const diffTime = Math.abs(tokenRealDate - todayRealDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          let expiryDate = diffDays;

          dispatch(setUserLoggedInDetails(response?.data?.access));
          dispatch(conversionRate(response?.data?.access?.conversion_rate));

          if (response?.data?.access?.time_zone) {
            Cookies.set("emp_time_zone", response?.data?.access?.time_zone, {
              expires: expiryDate,
              path: "",
            });
          } else {
            Cookies.set("emp_time_zone", "(UTC+01:00) West Central Africa", {
              expires: expiryDate,
              path: "",
            });
          }

          Cookies.set("emp_data", JSON.stringify(response.data.access), {
            expires: expiryDate,
            path: "",
          });
          console.log("tokennn.....", response?.data?.access.token);
          Cookies.set("emp_token", response?.data?.access?.token, {
            expires: expiryDate,
            path: "",
          });

          Cookies.set("emp_role", response?.data?.access?.role, {
            expires: expiryDate,
            path: "",
          });

          Cookies.set("emp_currency", response?.data?.access?.currency_type, {
            expires: expiryDate,
            path: "",
          });

          const name = `${response?.data?.access?.first_name} ${response?.data?.access?.last_name}`;

          Cookies.set("emp_name", name, {
            expires: expiryDate,
            path: "",
          });
          handleFetchRequest(apiUrl?.get_conversion_rate).then((response) => {
            Cookies.set("emp_conversion", response?.data?.amount, {
              expires: expiryDate,
              path: "",
            });
          });
          if (
            response?.data?.access?.role === RoleType?.coach_value ||
            response?.data?.access?.role === RoleType?.admin_value
          ) {
            handleFetchRequest(apiUrl?.notification_list).then((response) => {
              if (response?.status === true) {
                dispatch(noticationDetail(response.data));
              } else if (response?.messageCode === 3) {
                Cookies.remove("emp_token");
                Cookies.remove("emp_role");
                Cookies.remove("emp_name");
                Cookies.remove("emp_currency");
                Cookies.remove("emp_profile");
                Cookies.remove("emp_data");
                Cookies.remove("emp_conversion");
                Cookies.remove("emp_conversion");
                dispatch(setUserLoggedInDetails({}));
                dispatch(profileImage(""));
                setIsLoading(false);
                navigate(RoutesLink.login);
              }
            });
          }

          if (response?.data?.access?.role === RoleType?.coach_value) {
            handleFetchRequest(apiUrl?.get_coach_profile).then((response) => {
              if (response.status === true) {
                dispatch(
                  profileImage(response?.data?.coach_profile?.profile_image)
                );
                Cookies.set(
                  "emp_profile",
                  response?.data?.coach_profile?.profile_image,
                  {
                    expires: expiryDate,
                  }
                );
              } else if (response?.messageCode === 3) {
                Cookies.remove("emp_token");
                Cookies.remove("emp_role");
                Cookies.remove("emp_name");
                Cookies.remove("emp_currency");
                Cookies.remove("emp_profile");
                Cookies.remove("emp_data");
                Cookies.remove("emp_conversion");
                Cookies.remove("emp_conversion");
                dispatch(setUserLoggedInDetails({}));
                dispatch(profileImage(""));
                setIsLoading(false);
                navigate(RoutesLink.login);
              }
            });
          }

          if (response.data.access.first_login === 1) {
            navigate(RoutesLink.dashboard);
          } else {
            navigate(RoutesLink.change_password, {
              state: {
                id: response?.data?.access?.account_id,
                role: response?.data?.access?.role,
              },
            });
          }
        } else {
          setIsLoading(false);
          toast.error(response.message, {
            position: "top-right",
          });
        }
      }
    );
  };

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper auth">
          <div className="row d-flex align-item-center justify-content-around py-md-5 gap-3 ">
            <div className="col-lg-5 text-align-center d-flex flex-column p-0 gap-3 gap-md-0">
              <p className="text-black fs-1 fw-bold font-family-Roboto line-height-normal m-0 px-md-3">
                {texts?.heading_text?.login_page_heading}
              </p>
              <img
                className="py-3 w-80 z-3"
                alt="login"
                src="assets/image/image_2024_03_08T10_37_25_883Z.png"
              />
            </div>
            <div className="col-lg-5 p-0">
              <div className="auth-form-light text-left p-md-5">
                <div className="brand-logo d-flex align-items-center justify-content-center gap-3">
                  <img src="assets/image/groupp.png" alt="log0" />
                </div>
                {/* <h4>Hello! let's get started</h4> */}
                {/* <h6 className="font-weight-light">Sign in to continue.</h6> */}
                <form className="" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="input-label px-3">
                      {texts?.input_texts?.username?.name}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg bg-light"
                      id="exampleInputEmail1"
                      placeholder={texts?.input_texts?.username?.name}
                      {...register("email", {
                        required: "Please enter name*",
                      })}
                    />
                    <span className="text-danger ">
                      {errors?.email?.message}
                    </span>
                  </div>
                  <div className="form-group position-relative">
                    <label className="input-label px-3">
                      {texts?.input_texts?.password?.name}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg bg-light"
                      id="exampleInputPassword1"
                      placeholder={texts?.input_texts?.password?.name}
                      {...register("password", {
                        required: "Please enter password*",
                      })}
                    />
                    <span className="text-danger">
                      {errors?.password?.message}
                    </span>
                  </div>
                  {/* <div className="my-2 d-flex justify-content-end align-items-center">
                <p className="text-secondary fs-6 fw-medium font-family-Roboto  m-0 px-3 py-2">Forgot password?</p>
              </div>   */}
                  <div className="form-check">
                    <label className="form-check-label text-muted" />
                    <div className="log-btn ">
                      <button className="btn text-center text-white w-100 fs-6 fw-semibold font-family-Roboto  m-0 ">
                        {isLoading ? (
                          <ButtonLoader />
                        ) : (
                          `${texts?.button_texts?.signin?.name}`
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
