import { useForm } from "react-hook-form";
import PrimaryInput from "../../../Components/Inputs/PrimaryInput";
import FormLayout from "../../../Components/Layout/FormLayout";
import { useLocation, useNavigate } from "react-router-dom";

import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  valChar,
  validateEquipmentPrice,
} from "../../../ApiHelper/FormValidation";
import { useState } from "react";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { useTranslation } from "react-i18next";

const AddEquipment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const backButtonHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (location?.state?.id) {
      handleFetchRequest(
        `${apiUrl?.get_equipment_detail}?equipment_id=${location?.state?.id}`
      ).then((response) => {
        if (response?.status === true) {
          reset({
            equipment_name: response?.data?.name,
            equipment_quantity: response?.data?.quantity,
            equipment_price: response?.data?.price,
            detail: response?.data?.detail,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      });
    }
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    const equipmentData = {
      name: data?.equipment_name,
      quantity: data?.equipment_quantity,
      price: data?.equipment_price,
      detail: data?.detail,
    };
    if (location?.state?.id) {
      let updatedEquipmentData = {
        ...equipmentData,
        equipment_id: location?.state?.id,
      };
      handleFetchRequest(
        `${apiUrl?.edit_equipment}`,
        "PUT",
        updatedEquipmentData
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response?.message);
          navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      });
    } else {
      handleFetchRequest(
        `${apiUrl?.add_equipment}`,
        "POST",
        equipmentData
      ).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response?.message);
          navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    }
  };
  return (
    <FormLayout name={"Add Equipment"} backButtonHandler={backButtonHandler}>
      <form className="form-sample" onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-4">
          <div className="col-12 px-0 px-lg-3">
            <label className="input-label ps-3">
              {t("euipment_name")}
              <span className="text-danger">*</span>{" "}
            </label>
            <input
              type={"text"}
              placeholder="Equipment Name"
              className={"form-control"}
              {...register("equipment_name", {
                required: "Please enter Equipment name",
                validate: valChar,
              })}
              defaultValue={location?.state?.first_name}
            />
            <span className="text-danger ">
              {errors?.equipment_name?.message}
            </span>
          </div>
        </div>
        <div class="row mt-4">
          <div className="col-lg-6 px-0 px-lg-3">
            <label className="input-label ps-3">
              {t("eqp_quanitity")}
              <span className="text-danger">*</span>{" "}
            </label>
            <PrimaryInput
              defaultValue={location?.state?.last_name}
              type={"number"}
              placeholder="Equipment Quantity"
              className={"form-control"}
              register={register("equipment_quantity", {
                required: "Please enter Equipment Quantity",
              })}
            />
            <span className="text-danger ">
              {errors?.equipment_quantity?.message}
            </span>
            {/* <input type="text" class="form-control" placeholder="Last Name" /> */}
          </div>
          <div className="col-lg-6 px-0 px-lg-3">
            <label className="input-label ps-3">
              {t("eqp_price")} <span className="text-danger">*</span>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Equipment Price"
              defaultValue={
                location?.state?.address &&
                JSON?.parse(location?.state?.address)?.city
              }
              {...register("equipment_price", {
                required: "Please enter equipment price",
                validate: validateEquipmentPrice,
              })}
            />
            <span className="text-danger ">
              {errors?.equipment_price?.message}
            </span>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 px-0 px-lg-3">
            <label className="input-label ps-3">{t("eqp_detail")}</label>
            <input
              type={"text"}
              placeholder="Equipment Detail"
              className={"form-control"}
              {...register("detail", {
                // required: "Please enter Equipment name",
                // validate: valChar
              })}
              defaultValue={location?.state?.detail}
            />
            <span className="text-danger ">{errors?.detail?.message}</span>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4 px-0 px-lg-3">
          <button type="submit" className="btn btn-primary me-2">
            {isLoading ? <ButtonLoader /> : "Submit"}
          </button>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-light"
          >
            {t("cancel")}
          </button>
        </div>
      </form>
    </FormLayout>
  );
};
export default AddEquipment;
