export const RoutesLink = {
  login: "/",
  assign_beneficiary: "/assign-beneficiary",
  add_beneficiary: "/add-beneficiary",
  add_equipment: "/add-equipments",
  // add_coach: "/add-coach",
  add_coach_plan: "/add-coach-plan",
  add_user_profile: "/add-user-profile",
  add_user_account: "/add-user-account",
  add_subvention: "/add-subvention",
  beneficiary_list: "/beneficiary-list",
  beneficiary_profile: "/beneficiary-profile",
  assign_beneficiart: "/assign-beneficiary",
  coach_plan_list: "/coach-plan-list",
  coach_list: "/coach-list",
  coach_plan: "/coach-plan",
  coach_plan_detail: "/coach-plan-detail",
  coach_plan_session_details: "/coach-plan-session-details",
  coach_plan_beneficiaries: "/coach-plan-beneficiaries",
  coach_profile: "/coach-profile",
  coaching: "/coaching",
  cooperatives: "/cooperatives",
  add_cooperatives: "/add-cooperatives",
  cooperative_detail: "/cooperative-detail",
  suppliers:'/suppliers',
  add_suppliers:'/add-suppliers',
  suppliers_detail:'/supplier-detail',
  assign_equipments:'/assign-equipments',
  dashboard: "/dashboard",
  equipments: "/equipments",
  reset_user_account: "/reset-user-account",
  change_password: "/change-password",
  reports: "/reports",
  settings: "/settings",
  subventions: "/subventions",
  upload_data: "/upload-data",
  user_account: "/user-account",
  subvention_detail: "/subvention-detail",
  notification: "/notifications",
  business_plan_form: "/business-plan-form",

};
