import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import {
  CurrencyConverter,
  CurrencyConverter2,
} from "../../../ApiHelper/CurrencyConverter";
// import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfileHeader from "../../../Components/CommonElements/ProfileHeader";
import CheckoutCard from "../../../Components/CommonElements/CheckoutCard";
import Heading from "../../../Components/CommonElements/Heading";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { toast } from "react-toastify";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import Cookies from "js-cookie";
import Roles from "../../../ApiHelper/Roles.json";
import AppHeading from "../../../Components/CommonElements/Headings";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import {
  convertToEpoch,
  TimeStampConverter,
  ConvertInputTimeToSetting,
} from "../../../ApiHelper/TimeStampConverter";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import ReactApexChart from "react-apexcharts";
import { Chart } from "react-google-charts";
import moment from "moment";
import TableLoader from "../../../Components/CommonElements/TableLoader";

const BeneficiaryProfile = () => {
  let currencyType = Cookies.get("emp_currency");
  const dispatch = useDispatch();
  const [inputCategoryData, setInputCategoryData] = useState("");
  const [inputCategorySessionData, setInputCategorySessionData] = useState("");
  const location = useLocation();
  const { t } = useTranslation();
  const [coachList, setCoachList] = useState([]);
  let RoleType = Cookies.get("emp_role");
  const [
    beneficiaryMonitoringCoachingListData,
    setBeneficiaryMonitoringCoachingListData,
  ] = useState([]);
  const navigate = useNavigate();
  //subvention List variables start here
  const [searchOptionSessionData, setSearchOptionSessionData] = useState();
  const [subventionList, setSubventionList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [pages, setPages] = useState();
  const [subventionPages, setSubventionPages] = useState();
  const [coachplanPages, setCoachplanPages] = useState();
  const [CoopPages, setCoopPages] = useState();
  const [searchStateOption1Array, setSearchStateOption1Array] = useState([]);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [benSessionListData, setBenSessionListData] = useState([]);
  const [originalCoachListData, setOriginalCoachListData] = useState([]);
  const [originalBeneficiaryListData, setOriginalBeneficiaryListData] =
    useState([]);
  let currentDate = moment();
  const [coachListData, setCoachListData] = useState([]);
  const [beneficiaryListData, setBeneficiaryListData] = useState([]);
  const prevMonthDate = moment(currentDate).subtract(1, "month");
  const prevMonthConvertedDate = prevMonthDate.startOf("day");
  const newEndDate = currentDate.endOf("day");
  const [beneficiarySectorData, setBeneficiarySectorData] = useState("");
  const [submenuTabOpen, setSubmenuTabOpen] = useState("1");
  const [startDate, setDate] = useState({
    from: prevMonthConvertedDate.toDate(),
    to: newEndDate?.toDate(),
  });
  const [startCoachMonitoringDate, setStartCoachMonitoringDate] = useState({
    from: prevMonthConvertedDate.toDate(),
    to: newEndDate?.toDate(),
  });
  const [sessionsList, setSessionsList] = useState([]);
  const [allCoachingListPage, setAllCoachingListPage] = useState(1);
  console.log(allCoachingListPage, "allCoachingListPage");
  const [startBeneficiaryMonitoringDate, setStartBeneficiaryMonitoringDate] =
    useState({
      from: prevMonthConvertedDate.toDate(),
      to: newEndDate?.toDate(),
    });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBeneficiaryDropdownOpen, setIsBeneficiaryDropdownOpen] =
    useState(false);

  const [businessPlanData, setBusinessPlanData] = useState({
    main_form: "",
    business_modal: "",
    production_process: "",
    market_analysis: [],
    variable_cost: [],
    fixed_cost: [],
    material_resources: [],
    human_resources: [],
    production_unit: [],
    breakeven_point: "",
  });
  let role = Cookies.get("emp_role");

  const [
    beneficiarySessionBreakupChartData,
    setBeneficiarySessionBreakupChartData,
  ] = useState({
    data: [],
    options: {
      title: "My Daily Activities",
      pieHole: 0.1,
      is3D: false,
      legend: { position: "none" },
      // legend: "bottom",
      chartArea: { width: 400, height: 400 },
    },
  });
  console.log(location.state, "xxxxxxxxxxxxxxxxx");
  const searchSessionOptionStatusArray = [
    { name: "In Progress", value: "in progress", type: "status" },
    { name: "Completed", value: "completed", type: "status" },
    { name: "Pending", value: "pending", type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
  ];

  const searchSessionOption2Array = searchSessionOptionStatusArray.filter(
    (data) => data?.type === searchOptionSessionData
  );

  // const [searchStateOption1Array,setSearchStateOption1Array]=useState([{name:"Search By",value:""},{name:"Status",value:"status",type:"select"},{name:"Alias Id",value:"alias_id",type:"text"}]);
  const equipmentListHeading = [
    { classType: "", data: `${t("name")}` },
    { classType: "", data: `${t("eqp_detail")}` },
    { classType: "text-end", data: `${t("price")}` },
    { classType: "text-end", data: `${t("quantity")}` },
    { data: `${t("status")}` },
  ];
  let listHeading;
  if (Roles.admin_value === RoleType || Roles.coach_value === RoleType) {
    listHeading = [
      { data: `${t("coaching_plan_id")}`, classType: "" },
      { data: `${t("beneficiary_name")}`, classType: "" },
      { data: `${t("coach_username")}`, classType: "" },
      { data: "Schedule Start Date Time (DD/MM/YYYY)", classType: "" },
      { data: "Coach Plan Start Time (DD/MM/YYYY)", classType: "" },
      { data: "Coach Plan End Time (DD/MM/YYYY)", classType: "" },
      { data: `${t("status")}`, classType: "" },
      { data: `${t("action")}`, classType: "" },
    ];
  } else if (Roles.viewer_value === RoleType) {
    listHeading = [
      { data: `${t("coaching_plan_id")}`, classType: "" },
      { data: `${t("beneficiary_name")}`, classType: "" },
      { data: `${t("coach_username")}`, classType: "" },
      { data: "Schedule Start Date Time (DD/MM/YYYY)", classType: "" },
      { data: "Coach Plan Start Time (DD/MM/YYYY)", classType: "" },
      { data: "Coach Plan End Time (DD/MM/YYYY)", classType: "" },
      { data: `${t("status")}`, classType: "" },
      // { data: "Action", classType: "" }
    ];
  }

  console.log(location, "bbbbbbbbbbbbbb");
  const setSubmenuTabValue = (data) => {
    setSubmenuTabOpen(data);
  };

  const [activePage, setActivePage] = useState(1);

  const [sessionCurrentPage, setSessionCurrentPage] = useState(1);
  const [selectBeneficiaryStatus, setSelectBeneficiaryStatus] = useState("");
  const [currentCoachingListPage, setCurrentCoachingListPage] = useState(1);
  const [activeSubventionPage, setActiveSubventionPage] = useState(1);
  const [businessActivePage, setBusinessActivePage] = useState("1");
  const [searchOptionData, setSearchOptionData] = useState();
  const [beneficiaryMonitoringData, setBeneficiaryMonitoringData] = useState(
    {}
  );
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [inputTypeData, setInputTypeData] = useState("select");
  const [inputSessionTypeData, setInputSessionTypeData] = useState("select");
  const [
    beneficiaryMonitoringProvinceData,
    setbeneficiaryMonitoringProvinceData,
  ] = useState("");
  const [filterData, setFilterData] = useState();
  const [filterSessionData, setFilterSessionData] = useState();
  // const [callApi, setCallApi] = useState(true);
  const [callBenApi, setCallBenApi] = useState(true);
  const [showCheckoutBox, setShowCheckoutBox] = useState(0);
  const [equipmentCheckoutList, setEquipmentCheckoutList] = useState([]);

  const [beneficiaryMonitoringSearchTerm, setBeneficiaryMonitoringSearchTerm] =
    useState({ name: "", id: "" });

  console.log(location, "xxxxxxxxxxxxx");
  const [
    beneficiaryMonitoringFinanceChart,
    setBeneficiaryMonitoringFinanceChart,
  ] = useState({
    series: [
      {
        name: "Sales",
        data: [],
      },
      {
        name: "Profit",
        data: [],
      },
    ],
    options: {
      colors: ["#510DBC", "#098E7E"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        title: {
          text: `${t("date_time")}`, // Label for the y-axis
        },
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.7,
              width: 1,
            },
          },
        },

        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      yaxis: {
        title: {
          text: "No. of Sessions", // Label for the y-axis
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const searchSessionOption1Array = [
    { name: `${t("Search_by")}`, value: "", type: "select" },
    { name: `${t("status")}`, value: "status", type: "select" },
    // { name: "Assign Beneficiary", value: "assign", type: "select" },
    // { name: "Beneficiary Alias ID", value: "beneficiary_aliasId", type: "text" },
    // { name: "Coach Alias ID", value: "coach_aliasId", type: "text" },
    { name: "Session Time", value: "session_time", type: "date" },
  ];

  let benSessionListHeading = [
    { classType: "", data: `${t("beneficiary_name")}` },
    { classType: "", data: `${t("coaching_plan_status")}` },
    { classType: "text-end", data: `${t("total")}` },
    { classType: "text-end", data: `${t("pending")}` },
    { classType: "text-end", data: `${t("in_progress")}` },
    { classType: "text-end", data: `${t("completed")}` },
  ];

  const deleteSubventionHandler = (equipmentId) => {
    Swal.fire({
      text: `Do you want to delete this Subvention?`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setPageIsLoading(true);
        handleFetchRequest(
          `${apiUrl?.delete_subvention}?subvention_id=${equipmentId.id}`,
          "DELETE"
        ).then((response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            toast.success(response?.message);
            getSubvention(1);
            checkoutListHandler();
            // navigate(-1);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setPageIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setPageIsLoading(false);
            toast.error(response?.message);
            getSubvention(1);
          }
        });
      }
    });
  };

  const editSubventionHandler = (data) => {
    setPageIsLoading(true);
    console.log("Datatatataaaa...", data);
    let submitData = {
      subvention_id: data?.id,
      beneficiary_id:
        location?.state?.id ||
        location?.state?.data?.cp_master?.beneficiary?.id,
    };
    handleFetchRequest(apiUrl?.edit_subvention, "PUT", submitData).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          // toast.success(response?.message);
          // getSubvention(1);
          getEquipmentList(1);
          setShowCheckoutBox(1);
          checkoutListHandler();
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setPageIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
          toast.error(response?.message);
          getSubvention(1);
        }
      }
    );
  };

  useEffect(() => {
    getBeneficiaryMonitoringGraph(
      `${apiUrl?.get_beneficiary_data}?beneficiary_id=${
        location?.state?.id || location?.state?.data?.cp_master?.beneficiary?.id
      }`
    );
    getBeneficiaryMonitoring(
      `${apiUrl?.get_beneficiary_data}?beneficiary_id=${
        location?.state?.id || location?.state?.data?.cp_master?.beneficiary?.id
      }`
    );
  }, []);

  const getBeneficiaryMonitoring = (apiUrl) => {
    setIsPageLoading(true);
    handleFetchRequest(apiUrl).then((response) => {
      if (response?.status === true) {
        setBeneficiaryMonitoringData(response.data);
        setIsPageLoading(false);
        let breakpointData = [
          ["Task", "weeks per month"],
          [
            "Completed",
            response?.data?.session_breakups?.total_completed_session,
          ],
          [
            "In Progress",
            response?.data?.session_breakups?.total_inprogress_session,
          ],
          ["Pending", response?.data?.session_breakups?.total_pending_session],
        ];

        let total = 0;
        breakpointData.slice(1).forEach(([_, value]) => {
          total += value || 0;
        });

        // Convert values to percentages
        const dataWithPercentage = breakpointData.map(
          ([task, value], index) => {
            if (index === 0) return [task, value]; // Keep the header row unchanged
            const percentage = total !== 0 ? ((value || 0) / total) * 100 : 0;
            return [task, percentage];
          }
        );

        // Update the breakpointData with percentages
        breakpointData = dataWithPercentage;

        setBeneficiarySessionBreakupChartData({
          data: breakpointData,
          options: {
            pieHole: 0.3,
            is3D: false,
            // legend: false,
            legend: { position: "none" },
            colors: ["#098E7E", "#00B8F5", "#AB0D82"],
            chartArea: { width: 250, height: 250 },
          },
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const [
    beneficiaryMonitoringHumanResourceChart,
    setBeneficiaryMonitoringHumanResourceChart,
  ] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      colors: ["#510DBC"],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        title: {
          text: `${t("date_time")}`, // Label for the y-axis
        },
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.7,
              width: 1,
            },
          },
        },

        categories: [
          "01/01/2011 GMT",
          "01/02/2011 GMT",
          "01/03/2011 GMT",
          "01/04/2011 GMT",
          "01/05/2011 GMT",
          "01/06/2011 GMT",
        ],
      },
      yaxis: {
        title: {
          text: "No. of Sessions", // Label for the y-axis
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const getBeneficiaryMonitoringGraph = (link) => {
    setIsPageLoading(true);
    handleFetchRequest(link).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);

        setBeneficiaryMonitoringCoachingListData(
          response?.data?.recent_coaching_session
        );

        setBenSessionListData(response.data?.session_table);

        const hrData = response?.data?.employees?.map((data) => data?.total_hr);
        const hrDateData = response?.data?.employees?.map((data) => data?.date);
        const financeSalesData = response?.data?.financial?.map((data) =>
          CurrencyConverter2(data?.total_selas)
        );
        const financeProfitData = response?.data?.financial?.map((data) =>
          CurrencyConverter2(data?.net_profit_loss)
        );
        const financeDate = response?.data?.financial?.map(
          (data) => data?.date
        );

        let breakpointData = [
          ["Task", "weeks per month"],
          ["Completed", response?.data?.plan_session?.total_completed_session],
          [
            "In Progress",
            response?.data?.plan_session?.total_inprogress_session,
          ],
          ["Pending", response?.data?.plan_session?.total_pending_session],
        ];

        let total = 0;
        breakpointData.slice(1).forEach(([_, value]) => {
          total += value || 0;
        });

        setBeneficiaryMonitoringHumanResourceChart({
          series: [
            {
              name: "Employee",
              data: hrData,
            },
          ],
          options: {
            colors: ["#510DBC"],
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              type: "datetime",
              title: {
                text: `${t("date_time")}`, // Label for the y-axis
              },
              zoom: {
                enabled: true,
                autoScaleYaxis: true,
                zoomedArea: {
                  fill: {
                    color: "#90CAF9",
                    opacity: 0.4,
                  },
                  stroke: {
                    color: "#0D47A1",
                    opacity: 0.7,
                    width: 1,
                  },
                },
              },

              categories: hrDateData,
            },
            yaxis: {
              title: {
                text: "No. of Employees", // Label for the y-axis
              },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        });

        setBeneficiaryMonitoringFinanceChart({
          series: [
            {
              name: "Sales",
              data: financeSalesData,
            },
            {
              name: "Profit",
              data: financeProfitData,
            },
          ],
          options: {
            colors: ["#510DBC", "#098E7E"],
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                  total: {
                    enabled: true,
                    style: {
                      fontSize: "13px",
                      fontWeight: 900,
                    },
                  },
                },
              },
            },
            xaxis: {
              type: "datetime",
              title: {
                text: `${t("date_time")}`, // Label for the y-axis
              },
              zoom: {
                enabled: true,
                autoScaleYaxis: true,
                zoomedArea: {
                  fill: {
                    color: "#90CAF9",
                    opacity: 0.4,
                  },
                  stroke: {
                    color: "#0D47A1",
                    opacity: 0.7,
                    width: 1,
                  },
                },
              },

              categories: financeDate,
            },
            yaxis: {
              title: {
                text: `${t("amount")}`, // Label for the y-axis
              },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        });

        // Convert values to percentages
        const dataWithPercentage = breakpointData.map(
          ([task, value], index) => {
            if (index === 0) return [task, value]; // Keep the header row unchanged
            const percentage = total !== 0 ? ((value || 0) / total) * 100 : 0;
            return [task, percentage];
          }
        );

        // Update the breakpointData with percentages
        breakpointData = dataWithPercentage;
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  const DeleteCoachPlan = (data) => {
    setIsLoading(true);
    console.log("dataaaaaa...", data);
    handleFetchRequest(
      `${apiUrl?.delete_coach_plan}?coach_planId=${data?.id}`,
      "DELETE"
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response.message);
        getCoachPlanList(1);
      } else if (response?.messageCode === 99) {
        setIsLoading(false);
        Swal.fire({
          text: "One or more session exists in this plan are you sure you want to delete this coaching plan?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            handleFetchRequest(
              `${apiUrl?.delete_coach_plan}?coach_planId=${data?.id}&force_delete=true`,
              "DELETE"
            ).then((response) => {
              if (response?.status === true) {
                getCoachPlanList(1);
                setIsLoading(false);
                toast.success(response?.message);
                // const newData = stateVariable.filter((item) => item.id !== rowId);
                // stateVariableFunction(newData);
              }
            });
          }
        });
      }
    });
  };

  function capitalizeFirstLetter(str) {
    if (str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    }
  }

  const beneficiaryMonitoringListHeading = [
    { data: `${t("session_id")}`, classType: "" },
    // { data: "Coach ID", classType: "" },
    { data: `${t("coach_name")}`, classType: "" },
    // { data: "Ben. Reference ID", classType: "" },
    { data: `${t("beneficiary_name")}`, classType: "" },
    { data: `${t("employees")}`, classType: "text-end" },
    { data: `${t("sales")}`, classType: "text-end" },
    { data: `${t("expenses")}`, classType: "text-end" },
    { data: `${t("purchase")}`, classType: "text-end" },
    { data: `${t("profit_loss")}`, classType: "text-end" },
    { data: `${t("session_start(dd/mm/yyyy)")}`, classType: "" },
    { data: "Session End(DD/MM/YYYY)", classType: "" },
    { data: `${t("status")}`, classType: "" },
    // { data: "Order Date", classType: "" },
    // { data: "Action", classType: "" }
  ];
  const startYear = 1950;
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getBeneficiaryMonitoringCoachingListByStatus = (pageitem) => {
    setIsLoading(true);

    let filters = [];
    const start = convertToEpoch(
      moment(startBeneficiaryMonitoringDate?.from).startOf("day")
    );
    const end = convertToEpoch(
      ConvertInputTimeToSetting(
        moment(startBeneficiaryMonitoringDate?.to).endOf("day")
      )
    );

    if (start) {
      filters.push(`schedule_start_ts=${start}`);
    }
    if (end) {
      filters.push(`schedule_end_ts=${end}`);
    }
    if (beneficiarySectorData) {
      filters.push(`sector=${beneficiarySectorData}`);
    }
    if (beneficiaryMonitoringProvinceData) {
      filters.push(`province=${beneficiaryMonitoringProvinceData}`);
    }
    if (beneficiaryMonitoringSearchTerm?.id) {
      filters.push(`coach_id=${beneficiaryMonitoringSearchTerm?.id}`);
    }

    handleFetchRequest(
      `${
        apiUrl?.get_session_list
      }?page_no=${pageitem}&page_size=10&status=${selectBeneficiaryStatus.toLowerCase()}&${filters?.join(
        "&"
      )}`
    ).then((response) => {
      if (response.status === true) {
        setBeneficiaryMonitoringCoachingListData(response.data);
        setAllCoachingListPage(response?.pageInfo);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        // Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handelResetCoachingList = () => {
    // getBeneficiaryMonitoringCoachingList();
    setCurrentCoachingListPage(1);
    setAllCoachingListPage(1);
    setSelectBeneficiaryStatus("");
  };

  const handleCoachingListPageChange = (pageitem) => {
    console.log("consle pageeeee", pageitem);
    setCurrentCoachingListPage(pageitem);
    setBeneficiaryMonitoringCoachingListData([]);
    getBeneficiaryMonitoringCoachingListByStatus(pageitem);
  };

  const handleStatusChange = (data) => {
    if (data?.status === "completed") {
      let statusBody = {
        coach_plan_id: data?.id,
        status: "in progress",
      };
      setPageIsLoading(true);
      handleFetchRequest(
        apiUrl?.coach_plan_status_change,
        "PUT",
        statusBody
      ).then((response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          navigate(RoutesLink?.add_coach_plan, { state: data });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      });
    } else {
      navigate(RoutesLink?.add_coach_plan, { state: data });
    }
  };

  let addCoachHeadingAction;
  addCoachHeadingAction = [
    { name: "View", path: RoutesLink?.coach_plan_detail },
    {
      name: "Edit",
      path: handleStatusChange,
      type: "function",
      value: "always",
    },
    {
      name: "Delete",
      type: "function",
      path: DeleteCoachPlan,
      value: "always",
    },
  ];

  let addHeadingAction;
  if (role === Roles.admin_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.subvention_detail, type: "profile" },

      {
        name: "Delete Subvention",
        type: "function",
        value: "created",
        path: deleteSubventionHandler,
      },
      {
        name: "Edit Subvention",
        type: "function",
        value: "created",
        path: editSubventionHandler,
      },
    ];
  } else if (role === Roles.coach_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.subvention_detail, type: "profile" },
      {
        name: "Edit Subvention",
        type: "function",
        value: "created",
        path: editSubventionHandler,
      },
      // { name: "Delete Subvention", type: "function", path: deleteSubventionHandler }
    ];
  } else if (role === Roles.viewer_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.subvention_detail, type: "profile" },
      // { name: "Edit Subvention", type: "function", value: "created", path: editSubventionHandler },
      // { name: "Delete Subvention", type: "function", path: deleteSubventionHandler }
    ];
  }

  let searchOptionStatusArray = [
    { name: "Created", value: "created", type: "status" },
    { name: "Dispatched", value: "dispatched", type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
    { name: "Active", value: 1, type: "status_2" },
    { name: "Inactive", value: 0, type: "status_2" },
  ];
  let searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );
  const selectBeneficiaryDateHandler = (d, type) => {
    console.log(d);
    if (type === "from") {
      setStartBeneficiaryMonitoringDate({
        ...startBeneficiaryMonitoringDate,
        from: d,
        to: "",
      });
    } else {
      setStartBeneficiaryMonitoringDate({
        ...startBeneficiaryMonitoringDate,
        to: d,
      });
    }
  };

  const cooperativelistHeading = [
    { data: `${t("Alias_id")}`, classType: "" },
    { data: `${t("cooperative_name")}`, classType: "" },
    { data: `${t("business_type")}`, classType: "" },
    { data: `${t("sector_activity")}`, classType: "" },
    { data: `${t("sales")}`, classType: "" },
    { data: `${t("createdat")}`, classType: "" },
    { data: `${t("status")}`, classType: "" },
  ];

  // subvention list variable ends here

  const handlePageChange = (pageitem) => {
    setEquipmentList([]);
    getEquipmentList(pageitem);
    setActivePage(pageitem);
  };
  const handleSubventionPageChange = (pageitem) => {
    setSubventionList([]);
    getSubvention(pageitem);
    setActiveSubventionPage(pageitem);
  };
  const [tabOpen, setTabOpen] = useState("1");
  // const tableHeadingArray = ["Id", "Name", "Status", "Price", "Total Price", "Action"];
  const [beneficiaryProfile, setBeneficiaryProfile] = useState();
  // const [subventionList,setSubventionList]=useState();

  const setTabValue = (data) => {
    setTabOpen(data);
    setShowCheckoutBox(0);
  };

  function formatAmount(value) {
    let sign;

    if (currencyType == "usd") {
      sign = "$";
    } else {
      sign = "CDF";
    }
    let amount = CurrencyConverter2(+value);

    if (amount < 1000) {
      return `${sign} ${parseFloat(amount).toFixed(2)}`; // Keep two decimal places for amounts less than 1000
    } else if (amount < 1000000) {
      return `${sign} ${(amount / 1000).toFixed(2)}K`; // Convert to thousands
    } else if (amount < 1000000000) {
      return `${sign} ${(amount / 1000000).toFixed(2)}M`; // Convert to millions
    } else {
      return `${sign} ${(amount / 1000000000).toFixed(2)}B`; // Convert to billions
    }
  }

  const businessModalApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl.get_business_canvas}${data}`).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          setBusinessPlanData({
            ...businessPlanData,
            business_modal: response?.data,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setPageIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      }
    );
  };

  const productionProcessApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl.get_production}${data}`).then((response) => {
      if (response?.status === true) {
        setPageIsLoading(false);
        setBusinessPlanData({
          ...businessPlanData,
          production_process: response?.data,
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setPageIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setPageIsLoading(false);
      }
    });
  };

  const calculationApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl.get_calculation}${data}`).then((response) => {
      if (response?.status === true) {
        setPageIsLoading(false);
        setBusinessPlanData({
          ...businessPlanData,
          breakeven_point: response?.data,
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setPageIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setPageIsLoading(false);
      }
    });
  };

  const marketAnalysisApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl?.get_market_analysis}${data}`).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          setBusinessPlanData({
            ...businessPlanData,
            market_analysis: response,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setPageIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      }
    );
  };

  const materialResourcesApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl?.get_material_resources}${data}`).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          setBusinessPlanData({
            ...businessPlanData,
            material_resources: response,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setPageIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      }
    );
  };

  const humanResourcesApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl?.get_human_resources}${data}`).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          setBusinessPlanData({
            ...businessPlanData,
            human_resources: response,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      }
    );
  };

  const productionUnitApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl?.get_production_unit}${data}`).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          setBusinessPlanData({
            ...businessPlanData,
            production_unit: response,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      }
    );
  };

  const fixedCostApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl?.get_fixed_cost}${data}`).then((response) => {
      if (response?.status === true) {
        setPageIsLoading(false);
        setBusinessPlanData({ ...businessPlanData, fixed_cost: response });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setPageIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setPageIsLoading(false);
      }
    });
  };

  const variableCostApi = (data) => {
    setPageIsLoading(true);
    handleFetchRequest(`${apiUrl?.get_variable_cost}${data}`).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          setBusinessPlanData({ ...businessPlanData, variable_cost: response });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      }
    );
  };

  const setBusinessTabValue = (data) => {
    setBusinessActivePage(data);
    if (data === "2" && businessPlanData?.business_modal === "") {
      businessModalApi(businessPlanData?.main_form?.id);
    } else if (data === "3" && businessPlanData?.market_analysis.length === 0) {
      marketAnalysisApi(businessPlanData?.main_form?.id);
    } else if (data === "4" && businessPlanData?.production_process === "") {
      productionProcessApi(businessPlanData?.main_form?.id);
    } else if (
      data === "5" &&
      businessPlanData?.material_resources?.length === 0
    ) {
      materialResourcesApi(businessPlanData?.main_form?.id);
    } else if (
      data === "6" &&
      businessPlanData?.human_resources?.length === 0
    ) {
      humanResourcesApi(businessPlanData?.main_form?.id);
    } else if (data === "7" && businessPlanData?.production_unit.length === 0) {
      productionUnitApi(businessPlanData?.main_form?.id);
    } else if (data === "8" && businessPlanData?.fixed_cost.length === 0) {
      fixedCostApi(businessPlanData?.main_form?.id);
    } else if (data === "9" && businessPlanData?.variable_cost?.length === 0) {
      variableCostApi(businessPlanData?.main_form?.id);
    } else if (data === "10" && businessPlanData?.breakeven_point === "") {
      calculationApi(businessPlanData?.main_form?.id);
    }
  };

  const handleChartSelect = (Chart) => {
    const chartWrapper = Chart.chartWrapper;
    if (chartWrapper) {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();

      if (selection.length === 1) {
        const selectedItem = selection[0];
        console.log("Selected Item:", selectedItem);

        // Check if selectedItem has a valid row
        if (selectedItem.row !== null && selectedItem.row >= 0) {
          const dataTable = chartWrapper.getDataTable();
          console.log("Data Table:", dataTable);

          // Assuming a simple chart structure, get the value from the first column
          const value = dataTable.getValue(selectedItem.row, 0);
          console.log("Selected Value:", value);
          setSelectBeneficiaryStatus(value);
          setCurrentCoachingListPage(1);
        } else {
          console.log(null);
        }
      } else {
        console.log(null);
      }
    }
  };

  const chartEvents = [
    {
      eventName: "select",
      callback: handleChartSelect,
    },
  ];

  const handleCreateSubvention = () => {
    setShowCheckoutBox(1);
    let searchOption1Array = [
      { name: `${t("Search_by")}`, value: "" },
      { name: `${t("name")}`, value: "name", type: "text" },
    ];
    setSearchStateOption1Array(searchOption1Array);
    getEquipmentList("1");
    // handleFetchRequest(`${apiUrl?.get_equipment_list}?status=1&page_no=${activePage}&page_size=10${filters.join("&")}`).then(response=>{
    //     setEquipmentList(response?.data);
    //     setCoachplanPages(response?.pageInfo);
    // });
  };

  const filters = [];
  const filters2 = [];
  if (searchOptionData === "status") {
    if (filterData) {
      filters.push(`&status=${filterData}`);
    }
  }
  if (searchOptionData === "name") {
    if (filterData) {
      filters.push(`&name=${filterData}`);
    }
  }
  if (searchOptionData === "status_2") {
    if (filterData) {
      filters2.push(`&status=${filterData}`);
    }
  }
  if (searchOptionData === "name") {
    if (filterData) {
      filters2.push(`&name=${filterData}`);
    }
  }

  //   quantity update function
  const quantityUpdateHandler = (e, data) => {
    const updatedData = {
      checkout_id: data,
      quantity: e,
    };
    setPageIsLoading(true);

    handleFetchRequest(`${apiUrl?.update_checkout}`, "PUT", updatedData).then(
      (response) => {
        if (response?.status === true) {
          checkoutListHandler();
          setPageIsLoading(false);
          //   toast?.success(response?.message);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
          toast.error(response?.message);
        }
      }
    );

    // }
  };

  const checkoutDeleteHandler = (data) => {
    let equipmentData = {
      equipment_id: data,
      beneficiary_id:
        location?.state?.id ||
        location?.state?.data?.cp_master?.beneficiary?.id,
      // quantity:1,
    };
    setPageIsLoading(true);
    handleFetchRequest(apiUrl?.delete_checkout, "DELETE", equipmentData).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          checkoutListHandler();
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
        }
      }
    );
  };
  console.log(location, "cccccccccccccccccc");
  const checkoutListHandler = () => {
    handleFetchRequest(
      `${apiUrl?.checkout_list}?beneficiary_id=${
        location?.state?.id || location?.state?.data?.cp_master?.beneficiary?.id
      }`
    ).then((response) => {
      if (response?.status === true) {
        setEquipmentCheckoutList(response);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const AssignCheckboxEquipments = (e, data) => {
    let equipmentData;
    console.log(data);

    if (e.target.checked === true) {
      setPageIsLoading(true);
      equipmentData = {
        coach_id: beneficiaryProfile?.assigned_beneficiary?.account?.id,
        equipment_id: data?.id,
        beneficiary_id:
          location?.state?.id ||
          location?.state?.data?.cp_master?.beneficiary?.id,
        quantity: 1,
      };
      handleFetchRequest(apiUrl?.create_checkout, "POST", equipmentData).then(
        (response) => {
          if (response?.status === true) {
            setPageIsLoading(false);
            checkoutListHandler();
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setPageIsLoading(false);
            toast.error(response?.message);
          }
        }
      );
    } else {
      checkoutDeleteHandler(data?.id);
    }
  };

  const grantAccessHandler = (e) => {
    console.log(e.target.checked);
    let eligibility;
    if (e.target.checked) {
      eligibility = 1;
    } else {
      eligibility = 0;
    }
    handleFetchRequest(apiUrl?.grant_access, "POST", {
      beneficiary_id:
        location?.state?.id ||
        location?.state?.data?.cp_master?.beneficiary?.id,
      grant_eligibility: eligibility,
    }).then((response) => {
      if (response?.status === true) {
        toast.success(response?.message);
        setCallBenApi(true);
        checkoutListHandler();
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        toast.error(response?.message);
      }
    });
  };

  const handleQuantityToggle = (quantity, data, operator) => {
    console.log("datadafasasdfc", quantity, data, operator);

    let updatedQuantity;
    if (operator === "-") {
      updatedQuantity = quantity - 1;
    } else if (operator === "+") {
      updatedQuantity = quantity + 1;
    }
    quantityUpdateHandler(updatedQuantity, data);
  };

  const CreateSubventionHandler = () => {
    setPageIsLoading(true);
    let subventionData = {
      coach_id: beneficiaryProfile?.assigned_beneficiary?.account?.id,
      beneficiary_id:
        location?.state?.id ||
        location?.state?.data?.cp_master?.beneficiary?.id,
    };
    handleFetchRequest(apiUrl?.create_subvention, "POST", subventionData).then(
      (response) => {
        if (response?.status === true) {
          setPageIsLoading(false);
          setActiveSubventionPage(1);
          setActivePage(1);
          toast.success(response?.message);
          setShowCheckoutBox(0);
          getSubvention(1);
          checkoutListHandler();
          // navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setPageIsLoading(false);
          toast.error(response?.message);
        }
      }
    );
  };

  useEffect(() => {
    if (showCheckoutBox === 0) {
      getSubvention(1);
    }
    setTabOpen(location?.state?.tabpane || "1");
    checkoutListHandler();

    if (location?.state?.type === "edit_sub") {
      let submitData = {
        id: location?.state?.subvention_id,
      };
      editSubventionHandler(submitData);
    }
    // Business Plan

    handleFetchRequest(
      `${apiUrl?.get_business_plan}?beneficiary_id=${
        location?.state?.id || location?.state?.data?.cp_master?.beneficiary?.id
      }`
    ).then((response) => {
      if (response?.status === true) {
        setBusinessPlanData({ ...businessPlanData, main_form: response?.data });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        //  setCallApi(false);
      }
    });

    //sessionsss
    getCoachPlanList(sessionCurrentPage);
  }, []);

  useEffect(() => {
    if (callBenApi) {
      setPageIsLoading(true);
      handleFetchRequest(
        `${apiUrl?.get_beneficiary_profile}?beneficiary_id=${
          location?.state?.id ||
          location?.state?.data?.cp_master?.beneficiary?.id
        }`
      ).then((response) => {
        if (response?.status === true) {
          setBeneficiaryProfile(response?.data);
          setCallBenApi(false);
          setPageIsLoading(false);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      });
    }
  }, [callBenApi]);

  const backButtonHandler = () => {
    navigate(RoutesLink?.beneficiary_list);
  };

  const getSubvention = (pageItem) => {
    handleFetchRequest(
      `${apiUrl?.get_subvention_list}?beneficiary_id=${
        location?.state?.id || location?.state?.data?.cp_master?.beneficiary?.id
      }&page_no=${pageItem}&page_size=10`
    ).then((response) => {
      if (response?.status === true) {
        setSubventionList(response?.data);
        // setCallApi(false);
        setSubventionPages(response?.pageInfo);
        // setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setActivePage(1);
    setSearchOptionData("");
    setFilterData("");
    setEquipmentList([]);
    setInputCategoryData("###select");
    handleFetchRequest(
      `${apiUrl.get_equipment_list}?page_no=1&page_size=10`
    ).then((response) => {
      if (response?.status === true) {
        setPages(response?.pageInfo);
        setEquipmentList(response.data);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const getEquipmentList = (pageItem) => {
    setIsLoading(true);
    handleFetchRequest(
      `${
        apiUrl?.get_equipment_list
      }?status=1&page_no=${pageItem}&page_size=10${filters2.join("&")}`
    ).then((response) => {
      if (response?.status === true) {
        setEquipmentList(response?.data);
        // setCallApi(false);
        setPages(response?.pageInfo);
        setIsLoading(false);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const sessionFilters = [];

  const applySessionFilter = () => {
    if (searchOptionSessionData === "session_time") {
      const filterValue = filterSessionData
        ? `&started_ts=${convertToEpoch(
            filterSessionData.start_date
          )}&end_ts=${convertToEpoch(filterSessionData.end_date)}`
        : "";
      sessionFilters.push(filterValue);
    } else {
      const filterValue = filterSessionData
        ? `&${searchOptionSessionData}=${filterSessionData}`
        : "";
      sessionFilters.push(filterValue);
    }
  };

  const getCoachPlanList = (pageItem) => {
    setIsLoading(true);
    // applyFilter();
    handleFetchRequest(
      `${apiUrl.get_coach_plan}?beneficiary_id=${
        location?.state?.id || location?.state?.data?.cp_master?.beneficiary?.id
      }&page_no=${pageItem}&page_size=10`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCoachList(response.data);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const ResetSessionAccountHandler = () => {
    setIsLoading(true);
    setInputCategorySessionData("");
    setSessionCurrentPage(1);
    setSearchOptionData("");
    setSearchOptionSessionData("");
    setFilterSessionData("");
    setInputSessionTypeData("select");
    setFilterData("");
    setSessionList([]);
    setInputCategoryData("###select");
    handleFetchRequest(
      `${apiUrl.get_session_list}?coach_plan_id=${location?.state?.coach_plan_id}&page_no=1&page_size=10`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setSessionList(response.data);
        setCoachplanPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const AddCoachPlanHandler = (data) => {
    if (data?.coach_plan === true) {
      navigate(RoutesLink?.add_coach_plan, {
        state: {
          ...data,
          routes: "session_list",
          coach_id:
            location?.state?.assigned_beneficiary?.coach_id ||
            location?.state?.coach_id ||
            beneficiaryProfile?.assigned_beneficiary?.account?.id,
        },
      });
    } else {
      handleFetchRequest(apiUrl.add_coach_plan, "POST", {
        coach_id:
          location?.state?.assigned_beneficiary?.coach_id ||
          location?.state?.coach_id ||
          beneficiaryProfile?.assigned_beneficiary?.account?.id,
        beneficiary_id: location?.state?.id,
      }).then((response) => {
        if (response?.status === true) {
          navigate(RoutesLink?.add_coach_plan, {
            state: {
              ...data,
              coach_plan_id: response?.data?.id,
              routes: "session_list",
              coach_id:
                location?.state?.assigned_beneficiary?.coach_id ||
                location?.state?.coach_id,
              beneficiary_id:
                location?.state?.id ||
                location?.state?.data?.cp_master?.beneficiary?.id,
              // beneficiaryCount: beneficiarySessionList,
            },
          });
        } else {
          toast.error(response.message);
        }
      });
    }
    // to = { RoutesLink?.add_coach_plan } state = { data }
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-12 px-0 px-md-3">
            <div className="card mb-3 mb-md-5">
              <ProfileHeader
                fullName={beneficiaryProfile?.fullname}
                profileImage={beneficiaryProfile?.image}
                backButtonHandler={backButtonHandler}
              />
              <div className="dummypj">
                <div className="position-relative px-2 px-md-5 d-flex justify-content-center justify-content-md-end">
                  {
                    <div className="grant">
                      <h6>{t("Grant_Eligibility_Access")}</h6>
                      <div className="checks">
                        <div className="form-check form-switch">
                          {role === Roles.admin_value ||
                          role === Roles.coach_value
                            ? businessPlanData?.main_form !== "" && (
                                <input
                                  className="form-check-input"
                                  checked={
                                    beneficiaryProfile?.grant_eligibility
                                  }
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  onChange={grantAccessHandler}
                                />
                              )
                            : ""}
                          {beneficiaryProfile?.grant_eligibility === 1 ? (
                            <label
                              className="form-check-label"
                              for="flexSwitchCheckDefault"
                            >
                              {t("Allowed")}
                            </label>
                          ) : (
                            <label
                              className="form-check-label text-danger"
                              for="flexSwitchCheckDefault"
                            >
                              {t("not_Allowed")}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="page-header">
                  <Nav className="nav nav-tabs tabHolder d-flex flex-nowrap overflow-x-auto cursor-pointer">
                    <NavItem className="nav-item">
                      <NavLink
                        aria-current="page"
                        className={
                          tabOpen === "1"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("1")}
                        //  href="#"
                      >
                        <div className="text-center font-weight-bold">
                          Profile
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-item">
                      <NavLink
                        aria-current="page"
                        className={
                          tabOpen === "2"
                            ? " d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("2")}
                        // href="#"
                      >
                        <div>{t("Coach_Assigned")}</div>
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-item">
                      <NavLink
                        aria-current="page"
                        // href="#"
                        className={
                          tabOpen === "3"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("3")}
                      >
                        <div>Business Plan</div>
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-item">
                      <NavLink
                        aria-current="page"
                        className={
                          tabOpen === "4"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("4")}
                      >
                        <div>{t("subvention")}</div>
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-item">
                      <NavLink
                        aria-current="page"
                        className={
                          tabOpen === "5"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("5")}
                      >
                        <div>{t("coaching_plan")}</div>
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-item">
                      <NavLink
                        aria-current="page"
                        className={
                          tabOpen === "6"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("6")}
                      >
                        <div>{t("cooperative")}</div>
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-item">
                      <NavLink
                        aria-current="page"
                        className={
                          tabOpen === "7"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("7")}
                      >
                        <div> {t("beneficiary_monitoring")}</div>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="absolute-border"></div>
                </div>
                <div className="row">
                  <TabContent activeTab={tabOpen}>
                    <TabPane tabId="1">
                      <div className="col-lg-12  px-0 px-md-3">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="pro">
                              <div className="newcon">
                                <div className="d-md-flex justify-content-between align-items-center my-1">
                                  <h4>{t("Personal_information")}</h4>
                                  {role === Roles.admin_value ? (
                                    <Link
                                      to={RoutesLink?.add_beneficiary}
                                      state={{ id: location?.state?.id }}
                                      className="btn btn-primary w-sm-100 mt-2 mt-md-0 mb-3 mb-md-0"
                                    >
                                      {beneficiaryProfile?.fullname
                                        ? "Update Profile"
                                        : "+ Add Profile"}
                                    </Link>
                                  ) : (
                                    ""
                                  )}{" "}
                                </div>
                                {beneficiaryProfile?.fullname ? (
                                  <>
                                    <ul className="uln">
                                      <li>
                                        <h5>{t("Reference_ID")}</h5>{" "}
                                        <span>
                                          {beneficiaryProfile?.reference_id}
                                        </span>
                                      </li>
                                      <li>
                                        <h5>{t("Registeration_Site")}</h5>{" "}
                                        <span>
                                          {
                                            beneficiaryProfile?.registration_site
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <h5>{t("Registeration_Site_ID")}</h5>{" "}
                                        <span>{beneficiaryProfile?.se_id}</span>
                                      </li>
                                      <li>
                                        <h5>{t("lottery")}:</h5>
                                        <span>
                                          {beneficiaryProfile?.loterie}
                                        </span>
                                      </li>
                                      <li>
                                        <h5>{t("sector")}:</h5>
                                        <span className="text-capitalize">
                                          {beneficiaryProfile?.sector ==
                                          "agribusiness"
                                            ? "Agri Business"
                                            : beneficiaryProfile?.sector
                                            ? beneficiaryProfile?.sector
                                            : "N/A"}
                                        </span>
                                      </li>

                                      <li>
                                        <div className="abs-btn">
                                          {/* <button className="absbtn">Assigned</button> */}
                                          {beneficiaryProfile?.status === 1 ? (
                                            <span className="active-button  badge">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="pending-button  badge">
                                              Inactive
                                            </span>
                                          )}
                                        </div>
                                      </li>
                                    </ul>
                                    <div className="newcon mt-3">
                                      <h4>{t("Address")} </h4>
                                      <ul className="uln">
                                        <li>
                                          <h5>{t("province")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.province}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("provinceId")} :</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.province_id}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("commune")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.commune}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("communeId")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.commune_id}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("quartier")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.quartier}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("quertierId")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.quartier_id}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("full_name")}</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.fullname}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("first_name")}</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.firstname}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("nickname")}</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.nickname}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("surname")}</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.surname}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="newcon mt-3">
                                      <h4>{t("telephone")}</h4>
                                      <ul className="uln">
                                        <li>
                                          <h5>{t("Contact_number")}</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.telephone_pref}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>
                                            {t("Alternate_Contact_number_1")}
                                          </h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.telephone_alt1}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>
                                            {t("Alternate_Contact_number_2")}
                                          </h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.telephone_alt2}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="newcon mt-3">
                                      <h4>
                                        Relative 1 Information{" "}
                                        {t("Relative_1_Information")}
                                      </h4>
                                      <ul className="uln">
                                        <li>
                                          <h5>{t("name")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.relative1_name}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Telephone")}:</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.relative1_telephone
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("relation")}:</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.relative1_relation
                                            }
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="newcon mt-3">
                                      <h4>
                                        Relative 2 Information {t("telephone")}
                                      </h4>
                                      <ul className="uln">
                                        <li>
                                          <h5>{t("name")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.relative2_name}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Telephone")}:</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.relative2_telephone
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Relation")}:</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.relative2_relation
                                            }
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="newcon mt-3">
                                      <h4>{t("oth_info")}</h4>
                                      <ul className="uln">
                                        <li>
                                          <h5>{t("sex")}:</h5>
                                          <span>{beneficiaryProfile?.sex}</span>
                                        </li>
                                        <li>
                                          <h5>{t("Age")}:</h5>{" "}
                                          <span>{beneficiaryProfile?.age}</span>
                                        </li>
                                        <li>
                                          <h5>{t("edu_level")}:</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.education_level
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("marital_status")}:</h5>
                                          <span>
                                            {
                                              beneficiaryProfile?.maritial_status
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>
                                            {t("Number_of_eligible_activities")}
                                          </h5>
                                          <span>
                                            {
                                              beneficiaryProfile?.number_eligible_activities
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Eligible_Activities")}</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.eligible_activities
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_1_Company_Name")}</h5>
                                          <span>
                                            {
                                              beneficiaryProfile?.a1_company_name
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_1_Commune")}</h5>
                                          <span>
                                            {beneficiaryProfile?.a1_commune}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_1_Quartier")}</h5>
                                          <span>
                                            {beneficiaryProfile?.a1_quartier}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_1_RCCM_Number")}</h5>
                                          <span>
                                            {beneficiaryProfile?.a1_rccm_number}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_2_Company_Name")}</h5>
                                          <span>
                                            {
                                              beneficiaryProfile?.a2_company_name
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_2_Commune")}</h5>
                                          <span>
                                            {beneficiaryProfile?.a2_commune}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_2_Quartier")}</h5>
                                          <span>
                                            {beneficiaryProfile?.a2_quartier}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Address_2_RCCM_Number")}</h5>
                                          <span>
                                            {beneficiaryProfile?.a2_rccm_number}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Main_Sample")}</h5>
                                          <span>
                                            {beneficiaryProfile?.main_sample}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Replacement_Sample")}</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.replacement_sample
                                            }
                                          </span>
                                        </li>

                                        <li>
                                          <h5>{t("Replacement_Order")}</h5>{" "}
                                          <span>
                                            {
                                              beneficiaryProfile?.replacement_order
                                            }
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("num")}:</h5>
                                          <span>
                                            {beneficiaryProfile?.number}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("presence")}:</h5>{" "}
                                          <span>
                                            {beneficiaryProfile?.presence}
                                          </span>
                                        </li>

                                        <li>
                                          <h5>{t("Loterie_Site")}</h5>
                                          <span>
                                            {beneficiaryProfile?.loterie_site}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Loterie_Status")}</h5>
                                          <span>
                                            {beneficiaryProfile?.statut_loterie}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Latitude")}</h5>
                                          <span>
                                            {beneficiaryProfile?.latitude}
                                          </span>
                                        </li>
                                        <li>
                                          <h5>{t("Longitude")}:</h5>
                                          <span>
                                            {beneficiaryProfile?.longitude}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ) : (
                                  <div className="text-center">
                                    {t("no_data_found")}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="col-lg-12 px-0 px-md-3">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="pro">
                              <div className="newcon">
                                <h4 className="d-flex align-items-center justify-content-between mb-1">
                                  {t("Coach_Assigned")}
                                </h4>
                                {beneficiaryProfile?.assigned_beneficiary ? (
                                  <ul className="uln">
                                    <li>
                                      <h5>ID </h5>{" "}
                                      <span>
                                        {
                                          beneficiaryProfile
                                            ?.assigned_beneficiary?.account
                                            ?.alias_id
                                        }
                                      </span>
                                    </li>
                                    <li>
                                      <h5>{t("Username")}</h5>{" "}
                                      <span>
                                        {
                                          beneficiaryProfile
                                            ?.assigned_beneficiary?.account
                                            ?.username
                                        }
                                      </span>
                                    </li>
                                    <li>
                                      <h5>{t("name")}</h5>{" "}
                                      <span>
                                        {
                                          beneficiaryProfile
                                            ?.assigned_beneficiary?.account
                                            ?.first_name
                                        }{" "}
                                        {
                                          beneficiaryProfile
                                            ?.assigned_beneficiary?.account
                                            ?.last_name
                                        }
                                      </span>
                                    </li>
                                    <li>
                                      <h5>{t("telephone")}</h5>{" "}
                                      <span>
                                        {
                                          beneficiaryProfile
                                            ?.assigned_beneficiary?.account
                                            ?.mobile
                                        }
                                      </span>
                                    </li>
                                    <li>
                                      <div className="abs-btn">
                                        {/* <button className="absbtn">Assigned</button> */}
                                        {beneficiaryProfile?.assigned_beneficiary ? (
                                          <span className="active-button  badge">
                                            Assigned
                                          </span>
                                        ) : (
                                          <span className="pending-button  badge">
                                            Unassigned
                                          </span>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                ) : (
                                  <div className="text-center">
                                    No Coach Assigned
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={"3"}>
                      <div className="col-lg-12 px-0 px-md-3">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="pro">
                              <div className="newcon">
                                <h4 className="d-flex align-items-center justify-content-between mb-1">
                                  Business Plan
                                  {beneficiaryProfile?.assigned_beneficiary ? (
                                    <div className="rbbtns">
                                      {role === Roles.admin_value ||
                                      role === Roles.coach_value
                                        ? businessPlanData?.main_form ===
                                            "" && (
                                            <Link
                                              to={
                                                RoutesLink?.business_plan_form
                                              }
                                              state={{
                                                id: location?.state?.id,
                                                tab: "1",
                                                beneficiaryData:
                                                  beneficiaryProfile,
                                                coach_id:
                                                  location?.state
                                                    ?.assigned_beneficiary
                                                    ?.coach_id,
                                              }}
                                              className="btn btn-primary"
                                            >
                                              {businessPlanData?.main_form ===
                                              ""
                                                ? "+ Add Plan"
                                                : "Update Plan"}
                                            </Link>
                                          )
                                        : ""}
                                    </div>
                                  ) : (
                                    <div>
                                      Assign a coach to add Business Plan
                                    </div>
                                  )}
                                </h4>
                              </div>
                            </div>
                            {
                              <>
                                {businessPlanData?.main_form ? (
                                  <>
                                    <Nav className="nav nav-tabs tabHolder d-flex flex-nowrap overflow-x-auto cursor-pointer">
                                      <NavItem className="nav-item w-auto">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "1"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("1")
                                          }
                                          //  href="#"
                                        >
                                          <div
                                            className=" font-weight-bold"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {" "}
                                            {t("telephone")}
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item w-auto">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "2"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("2")
                                          }
                                          //  href="#"
                                        >
                                          <div
                                            className=" font-weight-bold"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {t("business_model")}
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item w-auto">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "3"
                                              ? " d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("3")
                                          }
                                          // href="#"
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            {t("market_analysis")}
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item">
                                        <NavLink
                                          aria-current="page"
                                          // href="#"
                                          className={
                                            businessActivePage === "4"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("4")
                                          }
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            {t("production_Process")}
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "5"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("5")
                                          }
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            Material Resources
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "6"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("6")
                                          }
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            {t("human_resources")}
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "7"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("7")
                                          }
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            Production Units
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "8"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("8")
                                          }
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            Fixed Cost Breakdown
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "9"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("9")
                                          }
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            Variable Cost Breakdown
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem className="nav-item">
                                        <NavLink
                                          aria-current="page"
                                          className={
                                            businessActivePage === "10"
                                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                                          }
                                          onClick={() =>
                                            setBusinessTabValue("10")
                                          }
                                        >
                                          <div style={{ cursor: "pointer" }}>
                                            {t("Breakeven_Point_Calculation")}
                                          </div>
                                        </NavLink>
                                      </NavItem>
                                    </Nav>
                                    <div className="row mt-2">
                                      <TabContent
                                        activeTab={businessActivePage}
                                      >
                                        <TabPane tabId={"1"}>
                                          {businessPlanData?.main_form ===
                                          "" ? (
                                            <div className="mt-2 text-center">
                                              {t("No_data_found")}
                                            </div>
                                          ) : (
                                            <>
                                              <div className="pro">
                                                <div className="newcon">
                                                  <div className="d-flex mb-2 justify-content-between align-items-center">
                                                    <h4>Business Details </h4>
                                                    {beneficiaryProfile?.assigned_beneficiary ? (
                                                      <div>
                                                        {role ===
                                                          Roles.admin_value ||
                                                        role ===
                                                          Roles.coach_value ? (
                                                          <Link
                                                            to={
                                                              RoutesLink?.business_plan_form
                                                            }
                                                            state={{
                                                              id: location
                                                                ?.state?.id,
                                                              tab: "1",
                                                              beneficiaryData:
                                                                beneficiaryProfile,
                                                            }}
                                                            className="btn btn-primary"
                                                          >
                                                            {t("Update")}
                                                          </Link>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>

                                                  <ul className="uln">
                                                    <li>
                                                      <h5>
                                                        {t("project_name")}
                                                      </h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form
                                                            ?.project_name
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5>
                                                        {t("Entrepreneur_name")}{" "}
                                                      </h5>{" "}
                                                      <span>
                                                        {
                                                          beneficiaryProfile?.fullname
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5>
                                                        {t(
                                                          "Business_Description"
                                                        )}
                                                      </h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form
                                                            ?.business_description
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5>{t("email")}</h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form
                                                            ?.email_id
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5>Telephone No. </h5>{" "}
                                                      <span>
                                                        {" "}
                                                        {
                                                          businessPlanData
                                                            ?.main_form
                                                            ?.telephone_no
                                                        }
                                                      </span>
                                                    </li>
                                                    {/* <li><h5>Alternate Contact number 1</h5> <span>{beneficiaryProfile?.telephone_alt1}</span></li> */}
                                                    {/* <li><h5>Alternate Contact number 2</h5> <span>{beneficiaryProfile?.telephone_alt2}</span></li> */}
                                                    {/* <li><h5>Education Level</h5> <span>{businessPlanData?.main_form?.education_level}</span></li> */}
                                                    {/* <li><h5>Age</h5> <span>{businessPlanData?.main_form?.age}</span></li> */}
                                                    <li></li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div className="pro">
                                                <div className="newcon">
                                                  <h4> {t("address")}</h4>
                                                  <ul className="uln">
                                                    <li>
                                                      <h5>
                                                        {t("address_line1")}
                                                      </h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form?.address
                                                            ?.address_line1
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5>
                                                        {t("address_line2")}
                                                      </h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form?.address
                                                            ?.address_line2
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5> {t("City")}</h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form?.address
                                                            ?.city
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5>{t("State")}</h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form?.address
                                                            ?.state
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5> {t("Country")}</h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form?.address
                                                            ?.country
                                                        }
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <h5>{t("ZIP_code")}</h5>{" "}
                                                      <span>
                                                        {
                                                          businessPlanData
                                                            ?.main_form?.address
                                                            ?.zip_code
                                                        }
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                  <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={() =>
                                                      setBusinessTabValue("2")
                                                    }
                                                  >
                                                    {t("next")}
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </TabPane>
                                        <TabPane tabId={"2"}>
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.business_modal ===
                                                "" ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "2",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {businessPlanData?.business_modal ===
                                          "" ? (
                                            <div className="mt-2 text-center">
                                              {t("No_data_found")}
                                            </div>
                                          ) : (
                                            <div className="pro">
                                              <div className="newcon">
                                                <div className="d-flex mb-2 justify-content-between align-items-center">
                                                  <h4>{t("business_model")}</h4>
                                                  {beneficiaryProfile?.assigned_beneficiary ? (
                                                    <div>
                                                      {role ===
                                                        Roles.admin_value ||
                                                      role ===
                                                        Roles.coach_value ? (
                                                        <Link
                                                          to={
                                                            RoutesLink?.business_plan_form
                                                          }
                                                          state={{
                                                            id: location?.state
                                                              ?.id,
                                                            tab: "2",
                                                            masterId:
                                                              businessPlanData
                                                                ?.main_form?.id,
                                                          }}
                                                          className="btn btn-primary"
                                                        >
                                                          {t("Update")}
                                                        </Link>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>

                                                <ul className="uln">
                                                  <li>
                                                    <h5>
                                                      {t("Customer_Segment")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.customer_segments
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t(
                                                        "Customer_Relationship"
                                                      )}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.customer_relationship
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {" "}
                                                      {t("Key_Activities")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.key_activities
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>{t("Key_Partners")}</h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.key_partners
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t("Key_Resources")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.key_resources
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t("Negative_Impacts")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.negative_impacts
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t("Positive_Impacts")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.positive_impacts
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t("Revenue_Streams")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.revenue_streams
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>{t("Channels")}</h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.channels
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>{t("Cost")}</h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal?.cost
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t("Value_Proposition")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.business_modal
                                                          ?.value_proposition
                                                      }
                                                    </span>
                                                  </li>
                                                  <li></li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("1")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("3")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId={"3"}>
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.market_analysis
                                                ?.data?.length === 0 ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "3",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {businessPlanData?.market_analysis
                                            ?.data?.length === 0 ? (
                                            <div className="mt-2 text-center">
                                              {t("no_data_found")}
                                            </div>
                                          ) : (
                                            <>
                                              <div className="my-3 d-flex justify-content-between align-items-center">
                                                <AppHeading title="Market Analysis" />
                                                {beneficiaryProfile?.assigned_beneficiary ? (
                                                  <>
                                                    {role ===
                                                      Roles.admin_value ||
                                                    role ===
                                                      Roles.coach_value ? (
                                                      <Link
                                                        className="btn btn-primary"
                                                        to={
                                                          RoutesLink?.business_plan_form
                                                        }
                                                        state={{
                                                          id: location?.state
                                                            ?.id,
                                                          tab: "3",
                                                          masterId:
                                                            businessPlanData
                                                              ?.main_form?.id,
                                                        }}
                                                      >
                                                        {t("Update")}
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                className="table-responsive p-0"
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th className="lh-base">
                                                        {t("Customer_segments")}
                                                        <br />
                                                        {t(
                                                          "(Organisations_&_Individuals)"
                                                        )}
                                                      </th>
                                                      <th>
                                                        {t("Product_/_Service")}
                                                      </th>
                                                      <th className="lh-base text-end">
                                                        {/* Number of <br />{" "} */}
                                                        {t(
                                                          "Number_of_product/service"
                                                        )}
                                                      </th>
                                                      <th className="text-end">
                                                        {t(
                                                          "Price_per_unit/Service"
                                                        )}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("total")}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {businessPlanData?.market_analysis?.data?.map(
                                                      (item) => (
                                                        <tr key={item.id}>
                                                          <td>
                                                            {
                                                              item?.customer_segment
                                                            }
                                                          </td>
                                                          <td>
                                                            {item?.product}
                                                          </td>
                                                          <td className="text-end">
                                                            {
                                                              item?.number_of_product
                                                            }
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.price_per_unit
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.total
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    <tr>
                                                      <td className="text-bold">
                                                        <strong>
                                                          {t("total")}
                                                        </strong>
                                                      </td>
                                                      <td></td>
                                                      <td></td>
                                                      <td></td>
                                                      <td className="text-end">
                                                        <strong>
                                                          {CurrencyConverter(
                                                            businessPlanData
                                                              ?.market_analysis
                                                              ?.total_amount
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("2")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("4")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId={"4"}>
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.production_process ===
                                                "" ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "4",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {businessPlanData?.production_process ===
                                          "" ? (
                                            <div className="mt-2 text-center">
                                              {t("no_data_found")}
                                            </div>
                                          ) : (
                                            <div className="pro">
                                              <div className="newcon">
                                                <div className="d-flex mb-2 justify-content-between align-items-center">
                                                  <h4>
                                                    {t("production_Process")}
                                                  </h4>
                                                  {beneficiaryProfile?.assigned_beneficiary ? (
                                                    <div>
                                                      {role ===
                                                        Roles.admin_value ||
                                                      role ===
                                                        Roles.coach_value ? (
                                                        <Link
                                                          to={
                                                            RoutesLink?.business_plan_form
                                                          }
                                                          state={{
                                                            id: location?.state
                                                              ?.id,
                                                            tab: "4",
                                                            masterId:
                                                              businessPlanData
                                                                ?.main_form?.id,
                                                          }}
                                                          className="btn btn-primary"
                                                        >
                                                          {t("Update")}
                                                        </Link>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>

                                                <ul className="uln">
                                                  <li>
                                                    <h5>
                                                      {t("Communication_Sales")}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.communication_sales
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>Achats </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.achats
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>{t("Distribution")}</h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.distribution
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t(
                                                        "Final_Product_Stock_Management"
                                                      )}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.final_product_stock_management
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t(
                                                        "Input_Stock_Management"
                                                      )}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.input_stock_management
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5> {t("Operations")}</h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.operations
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>{t("Other")}</h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.other
                                                      }
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <h5>
                                                      {t(
                                                        "Product_Creation_Process"
                                                      )}
                                                    </h5>{" "}
                                                    <span>
                                                      {
                                                        businessPlanData
                                                          ?.production_process
                                                          ?.product_creation_process
                                                      }
                                                    </span>
                                                  </li>
                                                  {/* <li><h5>Education Level</h5> <span>{businessPlanData?.production_process?.education_level}</span></li>
                                    <li><h5>Age</h5> <span>{businessPlanData?.production_process?.age}</span></li> */}
                                                  <li></li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("3")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("5")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId="5">
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData
                                                ?.material_resources.data
                                                ?.length === 0 ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "5",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {businessPlanData?.material_resources
                                            .data?.length === 0 ? (
                                            <div className="mt-2 text-center">
                                              {t("no_data_found")}
                                            </div>
                                          ) : (
                                            <div className="market-analysys">
                                              <div className="my-3 d-flex justify-content-between align-items-center">
                                                <AppHeading title="Material Resources" />
                                                {beneficiaryProfile?.assigned_beneficiary ? (
                                                  <>
                                                    {role ===
                                                      Roles.admin_value ||
                                                    role ===
                                                      Roles.coach_value ? (
                                                      <Link
                                                        className="btn btn-primary"
                                                        to={
                                                          RoutesLink?.business_plan_form
                                                        }
                                                        state={{
                                                          id: location?.state
                                                            ?.id,
                                                          tab: "5",
                                                          masterId:
                                                            businessPlanData
                                                              ?.main_form?.id,
                                                        }}
                                                        //  onClick={()=>handleAddRow(materialResources,setMaterialResources,{raw_material:"", quantity: '', unit_price:'',amount:"",isEditing: true,main_id:"",editMode:false})}
                                                      >
                                                        {" "}
                                                        {t("Update")}
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div></div>
                                              <div
                                                className="table-responsive p-0"
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th className="lh-base">
                                                        {t("Raw_Material")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Quantity")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Unit_Price")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Amount")}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {businessPlanData?.material_resources?.data?.map(
                                                      (item) => (
                                                        <tr key={item.id}>
                                                          <td>
                                                            {item?.raw_material}
                                                          </td>
                                                          <td className="text-end">
                                                            {item?.quantity}
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.price
                                                            )}
                                                          </td>

                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.amount
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    <tr>
                                                      <td className="text-bold">
                                                        <strong>
                                                          {t("total")}
                                                        </strong>
                                                      </td>
                                                      <td></td>

                                                      <td></td>
                                                      <td className="text-end">
                                                        <strong>
                                                          {CurrencyConverter(
                                                            businessPlanData
                                                              ?.material_resources
                                                              ?.total_amount
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("4")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("6")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId="6">
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.human_resources
                                                .data?.length === 0 ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "6",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {businessPlanData?.human_resources
                                            .data?.length === 0 ? (
                                            <div className="mt-2 text-center">
                                              {t("no_data_found")}
                                            </div>
                                          ) : (
                                            <div className="market-analysys">
                                              <div className="my-3">
                                                <div className="my-3 d-flex justify-content-between align-items-center">
                                                  <AppHeading
                                                    title={t("human_resources")}
                                                  />
                                                  {beneficiaryProfile?.assigned_beneficiary ? (
                                                    <>
                                                      {role ===
                                                        Roles.admin_value ||
                                                      role ===
                                                        Roles.coach_value ? (
                                                        <Link
                                                          className="btn btn-primary"
                                                          to={
                                                            RoutesLink?.business_plan_form
                                                          }
                                                          state={{
                                                            id: location?.state
                                                              ?.id,
                                                            tab: "6",
                                                            masterId:
                                                              businessPlanData
                                                                ?.main_form?.id,
                                                          }}
                                                        >
                                                          {" "}
                                                          {t("Update")}
                                                        </Link>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="table-responsive p-0"
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th className="lh-base">
                                                        Personnel
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Number")}
                                                      </th>
                                                      <th className="text-end">
                                                        Salary (Monthly){" "}
                                                      </th>
                                                      <th className="text-end">
                                                        Total (Annual){" "}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {businessPlanData?.human_resources?.data?.map(
                                                      (item) => (
                                                        <tr key={item.id}>
                                                          <td>
                                                            {item?.personnel}
                                                          </td>
                                                          <td className="text-end">
                                                            {item?.number}
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.salary
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.total
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    <tr>
                                                      <td className="text-bold">
                                                        <strong>
                                                          {" "}
                                                          {t("total")}
                                                        </strong>
                                                      </td>
                                                      <td></td>

                                                      <td></td>
                                                      <td className="text-end">
                                                        <strong>
                                                          {CurrencyConverter(
                                                            businessPlanData
                                                              ?.human_resources
                                                              ?.total_amount
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("5")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("7")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId="7">
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.production_unit
                                                ?.data?.length === 0 ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "7",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {businessPlanData?.production_unit
                                            ?.data?.length === 0 ? (
                                            <div className="mt-2 text-center">
                                              {t("no_data_found")}
                                            </div>
                                          ) : (
                                            <div className="market-analysys">
                                              <div className="my-3 d-flex justify-content-between align-items-center">
                                                <AppHeading title="Production Units" />
                                                {beneficiaryProfile?.assigned_beneficiary ? (
                                                  <>
                                                    {role ===
                                                      Roles.admin_value ||
                                                    role ===
                                                      Roles.coach_value ? (
                                                      <Link
                                                        to={
                                                          RoutesLink?.business_plan_form
                                                        }
                                                        state={{
                                                          id: location?.state
                                                            ?.id,
                                                          tab: "7",
                                                          masterId:
                                                            businessPlanData
                                                              ?.main_form?.id,
                                                        }}
                                                        className="btn btn-primary"
                                                      >
                                                        {t("Update")}
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                className="table-responsive p-0"
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th className="lh-base">
                                                        {t("Output_Units")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Quantity")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Cost_Per_Unit")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t(
                                                          "Unit_Variable_Cost"
                                                        )}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {businessPlanData?.production_unit.data?.map(
                                                      (item) => (
                                                        <tr key={item.id}>
                                                          <td>
                                                            {item?.output_units}
                                                          </td>
                                                          <td className="text-end">
                                                            {item?.quantity}
                                                          </td>

                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.cost_per_unit
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.unit_variable_cost
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}

                                                    <tr>
                                                      <td className="text-bold">
                                                        <strong>
                                                          {" "}
                                                          {t("total")}
                                                        </strong>
                                                      </td>
                                                      <td></td>
                                                      <td></td>
                                                      <td className="text-end">
                                                        <strong>
                                                          {CurrencyConverter(
                                                            businessPlanData
                                                              ?.production_unit
                                                              ?.total_amount
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("6")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("8")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId="8">
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.fixed_cost?.data
                                                ?.length === 0 ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "8",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {businessPlanData?.fixed_cost?.data
                                            ?.length === 0 ? (
                                            <div className="mt-2 text-center">
                                              {t("No_data_found")}
                                            </div>
                                          ) : (
                                            <div className="market-analysys">
                                              <div className="my-3 d-flex justify-content-between align-items-center">
                                                <AppHeading title="Fixed Cost Breakdown" />
                                                {beneficiaryProfile?.assigned_beneficiary ? (
                                                  <>
                                                    {role ===
                                                      Roles.admin_value ||
                                                    role ===
                                                      Roles.coach_value ? (
                                                      <Link
                                                        className="btn btn-primary"
                                                        to={
                                                          RoutesLink?.business_plan_form
                                                        }
                                                        state={{
                                                          id: location?.state
                                                            ?.id,
                                                          tab: "8",
                                                          masterId:
                                                            businessPlanData
                                                              ?.main_form?.id,
                                                        }}
                                                      >
                                                        {t("Update")}
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                className="table-responsive p-0"
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th>
                                                        {" "}
                                                        {t("Fixed_Cost")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Amount")}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {businessPlanData?.fixed_cost?.data?.map(
                                                      (item) => (
                                                        <tr key={item.id}>
                                                          <td>
                                                            {item?.fixed_cost}
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.amount
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    <tr>
                                                      <td className="text-bold">
                                                        <strong>
                                                          {" "}
                                                          {t("total")}
                                                        </strong>
                                                      </td>

                                                      <td className="text-end">
                                                        <strong>
                                                          {CurrencyConverter(
                                                            businessPlanData
                                                              ?.fixed_cost
                                                              ?.total_amount
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("7")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("9")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId="9">
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.variable_cost
                                                .data?.length === 0 ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "9",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {businessPlanData?.main_form === "" ||
                                          businessPlanData?.variable_cost.data
                                            ?.length === 0 ? (
                                            <div className="mt-2 text-center">
                                              {t("No_data_found")}
                                            </div>
                                          ) : (
                                            <div className="market-analysys">
                                              <div className="my-3 d-flex justify-content-between align-items-center">
                                                <AppHeading title="Variable Cost Breakdown" />
                                                {beneficiaryProfile?.assigned_beneficiary ? (
                                                  <>
                                                    {role ===
                                                      Roles.admin_value ||
                                                    role ===
                                                      Roles.coach_value ? (
                                                      <Link
                                                        className="btn btn-primary"
                                                        to={
                                                          RoutesLink?.business_plan_form
                                                        }
                                                        state={{
                                                          id: location?.state
                                                            ?.id,
                                                          tab: "9",
                                                          masterId:
                                                            businessPlanData
                                                              ?.main_form?.id,
                                                        }}
                                                      >
                                                        {t("Update")}
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                className="table-responsive p-0"
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th>
                                                        {" "}
                                                        {t("Variable_Cost")}
                                                      </th>
                                                      <th className="text-end">
                                                        {t("Amount")}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {businessPlanData?.variable_cost?.data?.map(
                                                      (item) => (
                                                        <tr key={item.id}>
                                                          <td>
                                                            {
                                                              item?.variable_cost
                                                            }
                                                          </td>
                                                          <td className="text-end">
                                                            {CurrencyConverter(
                                                              item?.amount
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    <tr>
                                                      <td className="text-bold">
                                                        <strong>
                                                          {" "}
                                                          {t("total")}
                                                        </strong>
                                                      </td>
                                                      <td className="text-end">
                                                        <strong>
                                                          {CurrencyConverter(
                                                            businessPlanData
                                                              ?.variable_cost
                                                              ?.total_amount
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("8")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("10")
                                              }
                                            >
                                              {t("next")}
                                            </button>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId="10">
                                          {beneficiaryProfile?.assigned_beneficiary ? (
                                            <>
                                              {businessPlanData?.main_form !==
                                                "" &&
                                              businessPlanData?.breakeven_point
                                                ?.total_fixed_cost === "" ? (
                                                role === Roles.admin_value ||
                                                role === Roles.coach_value ? (
                                                  <div className="d-flex justify-content-end">
                                                    <Link
                                                      to={
                                                        RoutesLink?.business_plan_form
                                                      }
                                                      state={{
                                                        id: location?.state?.id,
                                                        tab: "10",
                                                        masterId:
                                                          businessPlanData
                                                            ?.main_form?.id,
                                                      }}
                                                      className="btn btn-primary"
                                                    >
                                                      + Add
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {businessPlanData?.breakeven_point
                                            ?.total_fixed_cost === "" ? (
                                            <div className="mt-2 text-center">
                                              {t("No_data_found")}
                                            </div>
                                          ) : (
                                            <div className="market-analysys">
                                              <div className="my-3 d-flex justify-content-between align-items-center">
                                                <AppHeading title="Breakeven Point" />
                                                {beneficiaryProfile?.assigned_beneficiary ? (
                                                  <>
                                                    {role ===
                                                      Roles.admin_value ||
                                                    role ===
                                                      Roles.coach_value ? (
                                                      <Link
                                                        className="btn btn-primary"
                                                        to={
                                                          RoutesLink?.business_plan_form
                                                        }
                                                        state={{
                                                          id: location?.state
                                                            ?.id,
                                                          tab: "10",
                                                          masterId:
                                                            businessPlanData
                                                              ?.main_form?.id,
                                                        }}
                                                      >
                                                        {t("Update")}
                                                      </Link>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div
                                                className="table-responsive p-0"
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                }}
                                              >
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th className="lh-base">
                                                        {t(
                                                          "Breakeven_Point_Calculation"
                                                        )}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        {t("Total_fixed_cost")}
                                                      </td>
                                                      <td className="text-end">
                                                        {" "}
                                                        {businessPlanData
                                                          ?.breakeven_point
                                                          ?.total_fixed_cost &&
                                                          CurrencyConverter(
                                                            businessPlanData?.breakeven_point?.total_fixed_cost?.toFixed(
                                                              2
                                                            )
                                                          )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        {t(
                                                          "Variabe_cost_per_unit"
                                                        )}
                                                      </td>
                                                      <td className="text-end">
                                                        {" "}
                                                        {businessPlanData
                                                          ?.breakeven_point
                                                          ?.cost_per_unit &&
                                                          CurrencyConverter(
                                                            businessPlanData?.breakeven_point?.cost_per_unit?.toFixed(
                                                              2
                                                            )
                                                          )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        {t(
                                                          "Breakeven_point(quantity)"
                                                        )}
                                                      </td>
                                                      <td className="text-end">
                                                        {businessPlanData
                                                          ?.breakeven_point
                                                          ?.breakeven_point &&
                                                          businessPlanData
                                                            ?.breakeven_point
                                                            ?.breakeven_point}
                                                        {/* {businessPlanData?.breakeven_point?.breakeven_point && CurrencyConverter(businessPlanData?.breakeven_point?.breakeven_point?.toFixed(2))} */}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        {t(
                                                          "Margin_per_unit_sold"
                                                        )}
                                                      </td>
                                                      <td className="text-end">
                                                        {businessPlanData
                                                          ?.breakeven_point
                                                          ?.margin_par_unit_sold &&
                                                          CurrencyConverter(
                                                            businessPlanData?.breakeven_point?.margin_par_unit_sold?.toFixed(
                                                              2
                                                            )
                                                          )}{" "}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        {t(
                                                          "Margin_%_per_unit_sold"
                                                        )}
                                                      </td>
                                                      <td className="text-end">
                                                        {businessPlanData
                                                          ?.breakeven_point
                                                          ?.margin_percentage &&
                                                          businessPlanData?.breakeven_point?.margin_percentage?.toFixed(
                                                            2
                                                          )}{" "}
                                                        %{" "}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        {t(
                                                          "Unit_selling_price"
                                                        )}
                                                      </td>
                                                      <td className="text-end">
                                                        {" "}
                                                        {businessPlanData
                                                          ?.breakeven_point
                                                          ?.selling_price &&
                                                          CurrencyConverter(
                                                            businessPlanData?.breakeven_point?.selling_price?.toFixed(
                                                              2
                                                            )
                                                          )}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex justify-content-end mt-2">
                                            <button
                                              className="btn btn-light me-1"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("9")
                                              }
                                            >
                                              {t("back")}
                                            </button>
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              onClick={() =>
                                                setBusinessTabValue("1")
                                              }
                                            >
                                              Go To Business Details{" "}
                                            </button>
                                          </div>
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                  </>
                                ) : (
                                  <div className="text-center">
                                    Business Plan Not Created
                                  </div>
                                )}
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </TabPane>

                    <TabPane tabId={"4"}>
                      {/* {businessPlanData?.main_form === "" || beneficiaryProfile?.grant_eligibility === "0" || beneficiaryProfile?.grant_eligibility === 0 || beneficiaryProfile?.grant_eligibility == null ?
                  <div className="text-center mt-2 py-4">Create a Business Plan To Allow Grant Eligibility </div> :
                   */}
                      <div className="col-lg-12 px-0 px-md-3">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="pro">
                              <div className="newcon">
                                {businessPlanData?.main_form === "" ||
                                beneficiaryProfile?.grant_eligibility === "0" ||
                                beneficiaryProfile?.grant_eligibility === 0 ||
                                beneficiaryProfile?.grant_eligibility ==
                                  null ? (
                                  <>
                                    <div className="text-center my-4">
                                      {" "}
                                      Allow grant eligibility access to create a
                                      new subvention
                                    </div>
                                    <AppHeading title={"Subventions"} />
                                  </>
                                ) : (
                                  <>
                                    <div className="mb-2 d-flex flex-column align-items-end">
                                      <div>
                                        <strong>{t("grant_amount")}: </strong>
                                        <strong className="text-gray">
                                          {showCheckoutBox === 1
                                            ? `$ ${
                                                equipmentCheckoutList?.grant_amount
                                                  ? equipmentCheckoutList?.grant_amount
                                                  : beneficiaryProfile?.grant_amount
                                              } `
                                            : equipmentCheckoutList?.grant_amount
                                            ? CurrencyConverter(
                                                equipmentCheckoutList?.grant_amount
                                              )
                                            : CurrencyConverter(
                                                beneficiaryProfile?.grant_amount
                                              )}
                                        </strong>
                                      </div>
                                      <div>
                                        <strong>Grant Balance: </strong>{" "}
                                        <strong className="text-gray">
                                          {showCheckoutBox === 1
                                            ? `$ ${
                                                equipmentCheckoutList?.grant_balance
                                                  ? equipmentCheckoutList?.grant_balance
                                                  : beneficiaryProfile?.grant_balance
                                              }`
                                            : equipmentCheckoutList?.grant_balance
                                            ? CurrencyConverter(
                                                equipmentCheckoutList?.grant_balance
                                              )
                                            : CurrencyConverter(
                                                beneficiaryProfile?.grant_balance
                                              )}
                                        </strong>
                                      </div>
                                    </div>
                                    {/* <button class="btn backbtn btn-primary fw-bold"><i clxass="mdi mdi-arrow-top-left menu-icon"></i></button> */}

                                    {showCheckoutBox === 0 && (
                                      <Heading
                                        inputTypeData={inputTypeData}
                                        //  btnClickName={role===Roles.admin_value||role===Roles.coach_value?"Create Subvention":""}
                                        btnCickFunction={handleCreateSubvention}
                                        setInputTypeData1={setInputTypeData}
                                        // searchOption1Array={searchStateOption1Array}
                                        addLink={"/add-subventions"}
                                        name={
                                          showCheckoutBox
                                            ? `${t("Equipments")}`
                                            : `${t("subventions")}`
                                        }
                                        // searchOption2Array={searchOption2Array}
                                        // setSearchOptionData={setSearchOptionData}
                                        setMainCallApi={() =>
                                          getEquipmentList("1")
                                        }
                                        // setFilterData={setFilterData}
                                        // backButtonHandler={() => { showCheckoutBox === 1 ? setShowCheckoutBox(0) : navigate(RoutesLink?.beneficiary_list) }}
                                        setData={setEquipmentList}
                                        itemCount={equipmentCheckoutList}
                                        showCheckoutBox={showCheckoutBox}
                                        role={role}
                                        setActivePage={setActiveSubventionPage}
                                        subventionList={subventionList}
                                      />
                                    )}
                                  </>
                                )}
                                {showCheckoutBox === 1 && (
                                  <Heading
                                    inputTypeData={inputTypeData}
                                    //  btnClickName={role===Roles.admin_value||role===Roles.coach_value?"Create Subvention":""}
                                    btnCickFunction={handleCreateSubvention}
                                    setInputTypeData1={setInputTypeData}
                                    searchOption1Array={searchStateOption1Array}
                                    addLink={"/add-subventions"}
                                    name={
                                      showCheckoutBox
                                        ? `${t("Equipments")}`
                                        : `${t("subventions")}`
                                    }
                                    searchOption2Array={searchOption2Array}
                                    setSearchOptionData={setSearchOptionData}
                                    setMainCallApi={() => getEquipmentList("1")}
                                    setFilterData={setFilterData}
                                    backButtonHandler={() => {
                                      if (showCheckoutBox === 1) {
                                        setShowCheckoutBox(0);
                                        setSubventionList([]);
                                        setSubventionPages();
                                      } else {
                                        navigate(RoutesLink?.beneficiary_list);
                                      }
                                    }}
                                    setData={setEquipmentList}
                                    itemCount={equipmentCheckoutList}
                                    showCheckoutBox={showCheckoutBox}
                                    role={role}
                                    filterData={filterData}
                                    setActivePage={setActivePage}
                                    ResetAccountHandler={ResetAccountHandler}
                                    inputCategoryData={inputCategoryData}
                                    setInputCategoryData={setInputCategoryData}
                                  />
                                )}
                                {showCheckoutBox === 0 && (
                                  <div className="tbres">
                                    <div className="w-100">
                                      <PrimaryTable
                                        listData={subventionList}
                                        listHeading={listHeading}
                                        subventionprofile={true}
                                        addData={addHeadingAction}
                                        activePage={activeSubventionPage}
                                        pages={subventionPages}
                                        isLoading={isLoading}
                                        handlePageChange={
                                          handleSubventionPageChange
                                        }
                                        // idClickLink={RoutesLink?.beneficiary_profile}
                                        idClickLink={
                                          RoutesLink?.subvention_detail
                                        }
                                        linkType={"profile"}
                                      />
                                    </div>
                                    {showCheckoutBox === 1 && <CheckoutCard />}
                                  </div>
                                )}
                                {showCheckoutBox === 1 && (
                                  <div className="tbres">
                                    <div
                                      className="databenscs"
                                      style={{ minWidth: "500px" }}
                                    >
                                      <PrimaryTable
                                        listData={equipmentList}
                                        listHeading={equipmentListHeading}
                                        equipment={true}
                                        showCheckoutBox={showCheckoutBox}
                                        checkboxFunction={
                                          AssignCheckboxEquipments
                                        }
                                        showDeleteBox={true}
                                        assignedList={equipmentCheckoutList?.data?.map(
                                          (item) => {
                                            return { id: item?.equipment_id };
                                          }
                                        )}
                                        // addData={addHeadingAction}
                                        activePage={activePage}
                                        setData={setEquipmentList}
                                        pages={pages}
                                        handlePageChange={handlePageChange}
                                        isLoading={isLoading}
                                      />
                                    </div>
                                    {showCheckoutBox === 1 && (
                                      <CheckoutCard
                                        CreateSubventionHandler={
                                          CreateSubventionHandler
                                        }
                                        equipmentCheckoutList={
                                          equipmentCheckoutList
                                        }
                                        checkoutDeleteHandler={
                                          checkoutDeleteHandler
                                        }
                                        quantityUpdateHandler={
                                          quantityUpdateHandler
                                        }
                                        // handleQuantityToggle={
                                        //   handleQuantityToggle
                                        // }
                                        isLoading={pageIsLoading}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div className="col-lg-12 px-0 px-md-3">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="pro">
                              <div className="newcon">
                                <div className="dflex-custom">
                                  <Heading
                                    inputTypeData={inputSessionTypeData}
                                    setInputTypeData1={setInputSessionTypeData}
                                    searchOption1Array={
                                      searchSessionOption1Array
                                    }
                                    // addLink={RoutesLink?.add_user_account}
                                    // addLink={{ link: RoutesLink?.add_user_account, data: "coach" }}
                                    // btnName={role === Roles.admin_value ? "Add Coach" : ""}
                                    name={"Coaching Plan"}
                                    // addArray={addAction1}
                                    inputCategoryData={inputCategorySessionData}
                                    setInputCategoryData={
                                      setInputCategorySessionData
                                    }
                                    setSearchOptionData={
                                      setSearchOptionSessionData
                                    }
                                    searchOption2Array={
                                      searchSessionOption2Array
                                    }
                                    setMainCallApi={() => getCoachPlanList(1)}
                                    setFilterData={setFilterSessionData}
                                    setData={setSessionList}
                                    filterData={filterSessionData}
                                    setActivePage={setSessionCurrentPage}
                                    ResetAccountHandler={
                                      ResetSessionAccountHandler
                                    }
                                    customClassName="customDatePicker"
                                  />
                                  {!coachList?.length && (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => AddCoachPlanHandler()}
                                    >
                                      Add plan
                                    </button>
                                  )}
                                </div>
                                <div className="w-100">
                                  <PrimaryTable
                                    listData={coachList}
                                    listHeading={listHeading}
                                    editPage={"/add-coach"}
                                    addData={addCoachHeadingAction}
                                    mainCoachPlan={true}
                                    pages={pages}
                                    // activePage={currentPage}
                                    handlePageChange={handlePageChange}
                                    isLoading={isLoading}
                                    idClickLink={RoutesLink?.coach_plan_detail}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      <div className="col-lg-12 px-0 px-md-3">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="pro">
                              <div className="newcon">
                                <div className="w-100">
                                  <PrimaryTable
                                    listData={
                                      beneficiaryProfile?.cooperative_participants
                                    }
                                    listHeading={cooperativelistHeading}
                                    // addData={addHeadingAction}
                                    cooperatives_list={true}
                                    // pages={pages}
                                    // activePage={sessionCurrentPage}
                                    handlePageChange={handlePageChange}
                                    isLoading={isLoading}
                                    idClickLink={RoutesLink?.cooperative_detail}
                                    // idClickLink={RoutesLink?.coach_profile}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="7">
                      <div className="col-lg-12 px-0 px-md-3 grid-margin custom-beneficiary">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="row mt-3 mt-sm-0">
                              <div className="col-lg-12 px-0">
                                <div
                                  style={{
                                    borderRadius: "20px",
                                  }}
                                >
                                  <div className="dash-maintp custom-dash-maintp">
                                    <div className="dashmtpa">
                                      <div className="dash-card shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start ">
                                          <div>
                                            <h3
                                              style={{
                                                color: "#7b61c4",
                                                fontSize: "25px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {beneficiaryMonitoringData
                                                ?.session_breakups
                                                ?.total_session
                                                ? beneficiaryMonitoringData
                                                    ?.session_breakups
                                                    ?.total_session
                                                : "0"}
                                            </h3>
                                          </div>
                                        </div>

                                        <h3
                                          className="text-black "
                                          style={{ fontWeight: "600" }}
                                        >
                                          {t("coaching_sessions")}
                                        </h3>
                                        <strong style={{ color: "#3c3838" }}>
                                          {t("total")}
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="dashmtpa">
                                      <div className="dash-card shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start ">
                                          <div>
                                            <h3
                                              style={{
                                                color: "#AB0D82",
                                                fontSize: "25px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {beneficiaryMonitoringData
                                                ?.session_breakups
                                                ?.total_pending_session
                                                ? beneficiaryMonitoringData
                                                    ?.session_breakups
                                                    ?.total_pending_session
                                                : "0"}
                                            </h3>
                                          </div>
                                        </div>

                                        <h3
                                          className="text-black "
                                          style={{ fontWeight: "600" }}
                                        >
                                          {t("coaching_sessions")}
                                        </h3>
                                        <strong style={{ color: "#AB0D82" }}>
                                          {t("pending")}
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="dashmtpa">
                                      <div className="dash-card shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start ">
                                          <div>
                                            <h3
                                              style={{
                                                color: "#00B8F5",
                                                fontSize: "25px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {beneficiaryMonitoringData
                                                ?.session_breakups
                                                ?.total_inprogress_session
                                                ? beneficiaryMonitoringData
                                                    ?.session_breakups
                                                    ?.total_inprogress_session
                                                : "0"}
                                            </h3>
                                          </div>
                                        </div>

                                        <h3
                                          className="text-black "
                                          style={{ fontWeight: "600" }}
                                        >
                                          {t("coaching_sessions")}
                                        </h3>
                                        <strong style={{ color: "#00B8F5" }}>
                                          {t("in_progress")}
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="dashmtpa">
                                      <div className="dash-card shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start ">
                                          <div>
                                            <h3
                                              style={{
                                                color: "#098E7E",
                                                fontSize: "25px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {beneficiaryMonitoringData
                                                ?.session_breakups
                                                ?.total_completed_session
                                                ? beneficiaryMonitoringData
                                                    ?.session_breakups
                                                    ?.total_completed_session
                                                : "0"}
                                            </h3>
                                          </div>
                                        </div>

                                        <h3
                                          className="text-black "
                                          style={{ fontWeight: "600" }}
                                        >
                                          {t("coaching_sessions")}
                                        </h3>
                                        <strong style={{ color: "#098E7E" }}>
                                          {t("completed")}
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 px-0 mt-3">
                                <div
                                  style={{
                                    borderRadius: "20px",
                                  }}
                                >
                                  <div className="dashnps">
                                    <div className="dashbtm">
                                      <div className="dash-card shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start ">
                                          <div>
                                            <div>
                                              <h3
                                                style={{
                                                  color: "rgb(123, 97, 196)",
                                                  fontSize: "25px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {beneficiaryMonitoringData
                                                  ?.human_resources?.total_hr
                                                  ? beneficiaryMonitoringData
                                                      ?.human_resources
                                                      ?.total_hr
                                                  : "0"}
                                              </h3>
                                            </div>
                                            <div>
                                              <h3 className="text-gray pur dash-title">
                                                {t("human_resources")}
                                              </h3>
                                            </div>
                                          </div>
                                          <h3
                                            className="font-weight-bold"
                                            style={{
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              color: "green",
                                            }}
                                          >
                                            {beneficiaryMonitoringData
                                              ?.human_resources?.hr_percentage
                                              ? `${beneficiaryMonitoringData?.human_resources?.hr_percentage}%`
                                              : ""}
                                          </h3>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center ">
                                          <strong
                                            className="text-black"
                                            style={{ fontWeight: "600" }}
                                          >
                                            #{" "}
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dashbtm">
                                      <div className="dash-card shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start ">
                                          <div>
                                            <div>
                                              <h3
                                                style={{
                                                  color: "rgb(123, 97, 196)",
                                                  fontSize: "25px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {beneficiaryMonitoringData
                                                  ?.sales?.total_sales
                                                  ? formatAmount(
                                                      beneficiaryMonitoringData
                                                        ?.sales?.total_sales
                                                    )
                                                  : `${
                                                      currencyType === "usd"
                                                        ? "$"
                                                        : "CDF"
                                                    }0`}
                                              </h3>
                                            </div>
                                            <div>
                                              <h3 className="text-gray pur dash-title">
                                                {t("sales")}{" "}
                                              </h3>
                                            </div>
                                          </div>
                                          <h3
                                            className="font-weight-bold"
                                            style={{
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              color: "green",
                                            }}
                                          >
                                            {beneficiaryMonitoringData?.sales
                                              ?.sales_percentage
                                              ? `${beneficiaryMonitoringData?.sales?.sales_percentage}%`
                                              : ""}
                                            {/* {subventionMonitoringData?.subvention?.ben_with_subvention} */}
                                          </h3>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center ">
                                          <strong
                                            className="text-black"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {t("amount")}
                                            {/* {subventionMonitoringData?.subvention?.ben_with_subvention} */}
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dashbtm">
                                      <div className="dash-card shadow-sm">
                                        <div className="d-flex justify-content-between align-items-start ">
                                          <div>
                                            <div>
                                              <h3
                                                style={{
                                                  color: "rgb(123, 97, 196)",
                                                  fontSize: "25px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {beneficiaryMonitoringData
                                                  ?.profit_loss
                                                  ?.total_profit_loss
                                                  ? formatAmount(
                                                      beneficiaryMonitoringData
                                                        ?.profit_loss
                                                        ?.total_profit_loss
                                                    )
                                                  : `${
                                                      currencyType === "usd"
                                                        ? "$"
                                                        : "CDF"
                                                    }0`}
                                              </h3>
                                            </div>
                                            <div>
                                              <h3 className="text-gray pur dash-title">
                                                {t("profit_loss")}
                                              </h3>
                                            </div>
                                          </div>
                                          <h3
                                            className="font-weight-bold"
                                            style={{
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              color: "green",
                                            }}
                                          >
                                            {beneficiaryMonitoringData
                                              ?.profit_loss
                                              ?.profit_loss_percentage
                                              ? `${beneficiaryMonitoringData?.profit_loss?.profit_loss_percentage}%`
                                              : ""}
                                            {/* {subventionMonitoringData?.subvention?.ben_with_subvention} */}
                                          </h3>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center ">
                                          <strong
                                            className="text-black"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {t("amount")}
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-body px-0">
                                <div className="row">
                                  <div className="col-xl-8 px-0 mb-3 mb-lg-0">
                                    <div
                                      style={{
                                        borderRadius: "20px",
                                      }}
                                    >
                                      <div className="card bg-white shadow-sm row">
                                        <Nav className="nav nav-tabs tabHolder dash-tab px-2">
                                          <NavItem className="nav-item">
                                            <NavLink
                                              aria-current="page"
                                              className={
                                                submenuTabOpen === "1"
                                                  ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                                  : "nav-link d-flex flex-column justify-content-center align-items-center"
                                              }
                                              onClick={() =>
                                                setSubmenuTabValue("1")
                                              }
                                            >
                                              <div>{t("human_resources")}</div>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem className="nav-item">
                                            <NavLink
                                              aria-current="page"
                                              className={
                                                submenuTabOpen === "2"
                                                  ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                                  : "nav-link d-flex flex-column justify-content-center align-items-center"
                                              }
                                              onClick={() =>
                                                setSubmenuTabValue("2")
                                              }
                                            >
                                              {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                                              <div>{t("financial")}</div>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem className="nav-item">
                                            <NavLink
                                              aria-current="page"
                                              className={
                                                submenuTabOpen === "3"
                                                  ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                                                  : "nav-link d-flex flex-column justify-content-center align-items-center"
                                              }
                                              onClick={() =>
                                                setSubmenuTabValue("3")
                                              }
                                            >
                                              {/* <div className="py-2">
                      <img src="assets/image/Grouppe.png" alt="img" />
                    </div> */}
                                              <div>{t("session_table")}</div>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                        <TabContent activeTab={submenuTabOpen}>
                                          <TabPane tabId="1">
                                            <div className="col-12 grid-margin stretch-card p-2 pb-0 mb-0">
                                              <div className="card bg-white">
                                                <h1 className="card-title p-3 dash-title d-none">
                                                  {" "}
                                                  {t("human_resource")}
                                                </h1>
                                                <div id="chart"></div>
                                                {/* <ReactApexChart/> */}
                                                <ReactApexChart
                                                  options={
                                                    beneficiaryMonitoringHumanResourceChart.options
                                                  }
                                                  series={
                                                    beneficiaryMonitoringHumanResourceChart.series
                                                  }
                                                  type="bar"
                                                  height={350}
                                                />
                                              </div>
                                            </div>
                                          </TabPane>
                                          <TabPane tabId="2">
                                            <div className="col-lg-12 grid-margin  p-2 pb-0 mb-0">
                                              <div className="card bg-white">
                                                <h1 className="card-title dash-title p-3 d-none">
                                                  {" "}
                                                  {t("financial")}
                                                </h1>
                                                <ReactApexChart
                                                  options={
                                                    beneficiaryMonitoringFinanceChart.options
                                                  }
                                                  series={
                                                    beneficiaryMonitoringFinanceChart.series
                                                  }
                                                  type="bar"
                                                  height={350}
                                                />
                                              </div>
                                            </div>
                                          </TabPane>
                                          <TabPane tabId="3">
                                            <div className="row d-flex justify-content-start m-2">
                                              <div
                                                className="table-responsive p-0 "
                                                style={{
                                                  border: "1px solid #EFEFEF",
                                                  borderRadius: "20px",
                                                  maxHeight: "400px",
                                                }}
                                              >
                                                {/* {<div className="d-flex justify-content-center"> No Data Found</div>} */}
                                                <table className="table table-hover">
                                                  <thead>
                                                    <tr>
                                                      {benSessionListHeading?.map(
                                                        (data) => (
                                                          <th>{data?.data}</th>
                                                        )
                                                      )}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {benSessionListData?.map(
                                                      (data) => (
                                                        <tr>
                                                          <td>
                                                            {
                                                              data?.beneficiary
                                                                ?.fullname
                                                            }
                                                          </td>
                                                          <td>
                                                            {data?.status ===
                                                            "completed" ? (
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#098E7E",
                                                                  fontSize:
                                                                    "0.875rem",
                                                                }}
                                                              >
                                                                {capitalizeFirstLetter(
                                                                  data?.status
                                                                )}
                                                              </p>
                                                            ) : data?.status ===
                                                              "in progress" ? (
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#00B8F5",
                                                                  fontSize:
                                                                    "0.875rem",
                                                                }}
                                                              >
                                                                {capitalizeFirstLetter(
                                                                  data?.status
                                                                )}
                                                              </p>
                                                            ) : data?.status ===
                                                              "pending" ? (
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#AB0D82",
                                                                  fontSize:
                                                                    "0.875rem",
                                                                }}
                                                              >
                                                                {capitalizeFirstLetter(
                                                                  data?.status
                                                                )}
                                                              </p>
                                                            ) : (
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#AB0D82",
                                                                  fontSize:
                                                                    "0.875rem",
                                                                }}
                                                              >
                                                                {capitalizeFirstLetter(
                                                                  data?.status
                                                                )}
                                                              </p>
                                                            )}
                                                          </td>
                                                          <td className="text-end">
                                                            {
                                                              data?.total_sessions
                                                            }
                                                          </td>
                                                          <td className="text-end">
                                                            <p
                                                              style={{
                                                                color:
                                                                  "#AB0D82",
                                                                fontSize:
                                                                  "0.875rem",
                                                              }}
                                                            >
                                                              {
                                                                data?.total_pending_sessions
                                                              }
                                                            </p>
                                                          </td>
                                                          <td className="text-end">
                                                            <p
                                                              style={{
                                                                color:
                                                                  "#00B8F5",
                                                                fontSize:
                                                                  "0.875rem",
                                                              }}
                                                            >
                                                              {" "}
                                                              {
                                                                data?.total_inprogress_sessions
                                                              }
                                                            </p>
                                                          </td>
                                                          <td className="text-end">
                                                            <p
                                                              style={{
                                                                color:
                                                                  "#098E7E",
                                                                fontSize:
                                                                  "0.875rem",
                                                              }}
                                                            >
                                                              {
                                                                data?.total_completed_sessions
                                                              }
                                                            </p>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </TabPane>
                                        </TabContent>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 px-0">
                                    <div
                                      className="card shadow-sm h-100"
                                      style={{
                                        borderRadius: "20px",
                                      }}
                                    >
                                      <div className="d-flex flex-column gap-3">
                                        <div>
                                          <div
                                            style={{
                                              marginTop: "10px",
                                              padding: "2px",
                                            }}
                                          >
                                            {/* <h1 className="card-title mt-4 font-weight-bold fs-5">Session Breakups </h1> */}
                                            <h1 class="card-title p-3 dash-title">
                                              {t("coaching_sessions")}
                                            </h1>
                                            <div className="d-flex flex-column align-items-center justify-content-center pb-3 pb-lg-0">
                                              {
                                                <Chart
                                                  chartEvents={chartEvents}
                                                  data={
                                                    beneficiarySessionBreakupChartData?.data
                                                  }
                                                  options={
                                                    beneficiarySessionBreakupChartData?.options
                                                  }
                                                  chartType="PieChart"
                                                  width={"280px"}
                                                  height={"280px"}
                                                />
                                              }
                                              <div className="d-flex gap-3 chrtd my-3 justify-content-center">
                                                <p className="m-0">
                                                  {t("pending")}
                                                </p>
                                                <p className="m-0">
                                                  {t("in_progress")}
                                                </p>
                                                <p className="m-0">
                                                  {t("completed")}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <Heading
                                    name={
                                      selectBeneficiaryStatus
                                        ? `${selectBeneficiaryStatus} ${t(
                                            "coaching_sessions"
                                          )}`
                                        : `${t("recent_coaching_sessions")}`
                                    }
                                  />
                                  {selectBeneficiaryStatus !== "" ? (
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        color: "#7b61c4",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handelResetCoachingList()}
                                    >
                                      {t("reset")}
                                    </span>
                                  ) : (
                                    <Link
                                      to={RoutesLink?.coach_plan_list}
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        color: "#7b61c4",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {t("see_all")}
                                    </Link>
                                  )}
                                </div>
                                <div
                                  className="table-responsive p-0"
                                  style={{
                                    border: "1px solid #EFEFEF",
                                    borderRadius: "20px",
                                  }}
                                >
                                  {/* {<div className="d-flex justify-content-center"> No Data Found</div>} */}
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        {beneficiaryMonitoringListHeading?.map(
                                          (item, index) => (
                                            <th
                                              key={index}
                                              className={item?.classType}
                                              style={{
                                                maxWidth: "150px",
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {item?.data}
                                            </th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {beneficiaryMonitoringCoachingListData?.map(
                                        (data) => (
                                          <tr>
                                            {
                                              <td>
                                                <Link
                                                  to={
                                                    RoutesLink?.coach_plan_session_details
                                                  }
                                                  state={{
                                                    data: data,
                                                    tabpane: "3",
                                                    back: "dashboard",
                                                    beneficiary_name:
                                                      data?.cp_master
                                                        ?.beneficiary?.fullname,
                                                    coach_name: `${data?.cp_master?.coach?.first_name} ${data?.cp_master?.coach?.last_name}`,
                                                  }}
                                                >
                                                  {data?.session_id}
                                                </Link>
                                              </td>
                                            }

                                            {
                                              <td>
                                                {
                                                  data?.cp_master?.coach
                                                    ?.first_name
                                                }{" "}
                                                {
                                                  data?.cp_master?.coach
                                                    ?.last_name
                                                }
                                              </td>
                                            }

                                            {
                                              <td>
                                                {
                                                  data?.cp_master?.beneficiary
                                                    ?.fullname
                                                }
                                              </td>
                                            }
                                            <td className="text-end">
                                              {data?.employees
                                                ? data?.employees
                                                : "N/A"}
                                            </td>
                                            <td className="text-end">
                                              {data?.sales
                                                ? CurrencyConverter(data?.sales)
                                                : "N/A"}
                                            </td>
                                            <td className="text-end">
                                              {data?.expenses
                                                ? CurrencyConverter(
                                                    data?.expenses
                                                  )
                                                : "N/A"}
                                            </td>
                                            <td className="text-end">
                                              {data?.purchase
                                                ? CurrencyConverter(
                                                    data?.purchase
                                                  )
                                                : "N/A"}
                                            </td>
                                            <td className="text-end">
                                              {data?.profit_loss
                                                ? CurrencyConverter(
                                                    data?.profit_loss
                                                  )
                                                : "N/A"}
                                            </td>
                                            {
                                              <td>
                                                {data?.session_start_ts
                                                  ? moment(
                                                      TimeStampConverter(
                                                        data?.session_start_ts
                                                      )
                                                    ).format(
                                                      "DD/MM/YYYY hh:mm a"
                                                    )
                                                  : "N/A"}
                                              </td>
                                            }
                                            {
                                              <td>
                                                {data?.session_end_ts
                                                  ? moment(
                                                      TimeStampConverter(
                                                        data?.session_end_ts
                                                      )
                                                    ).format(
                                                      "DD/MM/YYYY hh:mm a"
                                                    )
                                                  : "N/A"}
                                              </td>
                                            }
                                            {
                                              <td>
                                                {data?.status ===
                                                "completed" ? (
                                                  <p
                                                    style={{
                                                      color: "#098E7E",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                ) : data?.status ===
                                                  "in progress" ? (
                                                  <p
                                                    style={{
                                                      color: "#00B8F5",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                ) : data?.status ===
                                                  "pending" ? (
                                                  <p
                                                    style={{
                                                      color: "#AB0D82",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                ) : (
                                                  <p
                                                    style={{
                                                      color: "#AB0D82",
                                                      fontSize: "0.875rem",
                                                    }}
                                                  >
                                                    {capitalizeFirstLetter(
                                                      data?.status
                                                    )}
                                                  </p>
                                                )}
                                              </td>
                                            }
                                          </tr>
                                        )
                                      )}

                                      {isLoading && (
                                        <TableLoader
                                          listHeading={listHeading}
                                        />
                                      )}
                                    </tbody>
                                  </table>

                                  {/* {isLoading && <h4 style={{ textAlign: "center" }}>Loading...</h4>} */}
                                  {/* {!isLoading && sessionsList?.length === 0 ? (
                                    <h4 style={{ textAlign: "center" }}>
                                      No Data Found
                                    </h4>
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                                <div className="col-12 px-3 my-2 d-flex justify-content-between align-items-center">
                                  <div>
                                    {allCoachingListPage?.total ? (
                                      <p>
                                        <strong>{t("total_records")} :</strong>
                                        {allCoachingListPage?.total}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <p>
                                      <strong>
                                        {t("per_page_account")} :{" "}
                                      </strong>
                                      {
                                        beneficiaryMonitoringCoachingListData?.length
                                      }
                                    </p>
                                  </div>
                                  {allCoachingListPage?.totalPage > 1 && (
                                    <Pagination
                                      activePage={currentCoachingListPage}
                                      itemsCountPerPage={10}
                                      totalItemsCount={
                                        allCoachingListPage?.total
                                      }
                                      disabledClass="paginationDisableCustom"
                                      pageRangeDisplayed={5}
                                      onChange={handleCoachingListPageChange}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
            {pageIsLoading && <PageLoader />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BeneficiaryProfile;
