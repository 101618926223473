import { useEffect, useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import Cookies from "js-cookie";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import Heading from "../../../Components/CommonElements/Heading";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { toast } from "react-toastify";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
// import Pagination from "react-js-pagination";
import Roles from "../../../ApiHelper/Roles.json";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useTranslation } from "react-i18next";

const CoachList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [inputCategoryData, setInputCategoryData] = useState("");
  const [pages, setPages] = useState();
  // const [callApi, setCallApi] = useState(true);
  const [coachList, setCoachList] = useState([]);
  const [searchOptionData, setSearchOptionData] = useState();
  const [inputTypeData, setInputTypeData] = useState("select");
  const [filterData, setFilterData] = useState();
  let role = Cookies.get("emp_role");

  const listHeading = [
    { data: `${t("alias_id")}`, classType: "" },
    { data: `${t("first_name")}`, classType: "" },
    { data: `${t("last_name")}`, classType: "" },
    { data: `${t("created")}`, classType: "" },
    { data: `${t("status")}`, classType: "" },
    { data: `${t("beneficiaries_assigned")}`, classType: "text-end" },
    { data: `${t("action")}`, classType: "" },
  ];

  const searchOption1Array = [
    { name: `${t("search_by")}`, value: "", type: "select" },
    { name: `${t("status")}`, value: "status", type: "select" },
    { name: `${t("assign_beneficiairy")}`, value: "assign", type: "select" },
    { name: `${t("username")}`, value: "username", type: "text" },
    { name: `${t("alias_id")}`, value: "aliasid", type: "text" },
  ];

  const searchOptionStatusArray = [
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  let addHeadingAction;

  if (role === Roles.admin_value) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.coach_profile },
      { name: `${t("edit")}`, path: RoutesLink?.add_user_profile },
      {
        name: `${t("assign_beneficiairy")}`,
        path: RoutesLink?.assign_beneficiary,
        type: "assign",
      },
      {
        name: "Unassign Beneficiary",
        path: RoutesLink?.assign_beneficiary,
        type: "unassign",
      },
    ];
  } else if (role === Roles.viewer_value) {
    addHeadingAction = [{ name: "View", path: RoutesLink?.coach_profile }];
  }

  const filters = [];

  if (searchOptionData === "status") {
    if (filterData) {
      filters.push(`&status=${filterData}`);
    }
  }
  // if(searchOptionData=="assign"){
  //   filters.push(`&role=${filterData}`)
  // }
  if (searchOptionData === "username") {
    if (filterData) {
      filters.push(`&username=${filterData}`);
    }
  }
  if (searchOptionData === "aliasid") {
    if (filterData) {
      filters.push(`&alias_id=${filterData}`);
    }
  }

  const handlePageChange = (pageitem) => {
    setCoachList([]);
    getCoachList(pageitem);
    setCurrentPage(pageitem);
  };

  useEffect(() => {
    getCoachList(currentPage);
  }, []);

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setSearchOptionData("");
    setFilterData("");
    setCoachList([]);
    setInputCategoryData("###select");
    handleFetchRequest(
      `${apiUrl.assigned_unassigned_coach}?page_no=1&page_size=10`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCoachList(response.data);
        setPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const getCoachList = (pageItem) => {
    setIsLoading(true);
    if (searchOptionData === "assign") {
      handleFetchRequest(
        `${
          apiUrl.assigned_unassigned_coach
        }?page_no=${pageItem}&page_size=10&assignment=${+filterData}`
      ).then((response) => {
        if (response?.status === true) {
          // setCallApi(false);
          setIsLoading(false);
          setCoachList(response.data);
          setPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          // setCallApi(false);
          console.log(response);
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    } else {
      handleFetchRequest(
        `${
          apiUrl.assigned_unassigned_coach
        }?page_no=${pageItem}&page_size=10${filters.join("&")}`
      ).then((response) => {
        if (response?.status === true) {
          // setCallApi(false);
          setIsLoading(false);
          setCoachList(response.data);
          setPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          // setCallApi(false);
          console.log(response);
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    }
  };

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading
                    inputTypeData={inputTypeData}
                    setInputTypeData1={setInputTypeData}
                    searchOption1Array={searchOption1Array}
                    // addLink={RoutesLink?.add_user_account}
                    addLink={{
                      link: RoutesLink?.add_user_account,
                      data: "coach",
                    }}
                    btnName={
                      role === Roles.admin_value ? `${t("add_coach")}` : ""
                    }
                    name={"Coach"}
                    // addArray={addAction1}
                    inputCategoryData={inputCategoryData}
                    setInputCategoryData={setInputCategoryData}
                    setSearchOptionData={setSearchOptionData}
                    searchOption2Array={searchOption2Array}
                    setMainCallApi={() => getCoachList(1)}
                    setFilterData={setFilterData}
                    setData={setCoachList}
                    filterData={filterData}
                    setActivePage={setCurrentPage}
                    ResetAccountHandler={ResetAccountHandler}
                  />
                  <PrimaryTable
                    listData={coachList}
                    listHeading={listHeading}
                    editPage={"/add-coach"}
                    addData={addHeadingAction}
                    coach={true}
                    pages={pages}
                    activePage={currentPage}
                    handlePageChange={handlePageChange}
                    isLoading={isLoading}
                    idClickLink={RoutesLink?.coach_profile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CoachList;
