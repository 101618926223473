// import ButtonLoader from "./ButtonLoader";

import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";

const AssignEquipmentCard = ({ assignedEquipmentsList, deleteBeneficiaryHandler, cancelAssignedListHandler, submitAssignmentHandler, type, isLoading }) => {
  return (
    <div className="addbenepop">
      {console.log("klkl",assignedEquipmentsList)}
      <div className="assben">
        <div className="behd">
          <h4>{type === "delete" ? "Unassign Equipments" : "Assign Equipments "} 
            <h6>Count: <span className="wqty">{assignedEquipmentsList?.length}</span></h6>
          </h4>
          <ul>
            {assignedEquipmentsList?.map((data) => <li>
              <div className="bens">
                {/* <div className="bnid ben">{data?.alias_id}</div> */}
                <div className="bname  ben">{data?.name}</div>
                {/* <div className="bnemail ben">{data?.telephone_alt1}</div> */}
                <div className="chkbtns ben">
                  <div className="buttons">
                    {/* <button className="correct-btn">
                            <span class="mdi mdi-check"></span>
                          </button> */}
                    <button className="mx-1 close-btn" onClick={() => deleteBeneficiaryHandler(data?.id)}>
                      <span className="mdi mdi-close "></span>
                    </button>
                  </div>
                </div>
              </div>
            </li>)}
          </ul>
        </div>
        <div className="btnboxs">
          <button className="btn btn-light" onClick={cancelAssignedListHandler}>Cancel</button>
          <button className="btn btn-primary" onClick={submitAssignmentHandler}>{isLoading ? <ButtonLoader /> : (type === "delete" ? "Unassign " : "Assign")}</button>
        </div>
      </div>
    </div>
  )
}
export default AssignEquipmentCard;