import { toast } from "react-toastify";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { useForm } from "react-hook-form";
// import texts from "../../ApiHelper/Nomanclatures.json";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrl } from "../../ApiHelper/ApiUrl";
import { RoutesLink } from "../../ApiHelper/RoutesLink";
// import FormLayout from "../../Components/Layout/FormLayout";
import ButtonLoader from "../../Components/CommonElements/ButtonLoader";
import { useState } from "react";
import { validatePassword } from "../../ApiHelper/FormValidation";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../store/actions/UserActions";
import { useDispatch } from "react-redux";
// import axios from "axios";
// import Cookies from "js-cookie";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  console.log(location);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // const onSubmit = (data) => {
  //   setIsLoading(true);
  //   const cancelToken = axios.CancelToken.source();
  //   let password = {
  //     "old_password": data?.old_password,
  //     "new_password": data?.new_password,
  //   }
  //   let tokens;
  //   if (Cookies.get("emp_token")) {
  //     tokens = Cookies.get("emp_token");
  //   }
  //   let headers = {
  //     Authorization: "Bearer " + tokens,
  //     "Content-Type": "application/json",
  //     "X-localization": "en",
  //     ...password,
  //     Accept: 'application/json',
  //     "allow-access-origin": "*",
  //     'ngrok-skip-browser-warning': 'true',
  //   };
  //   axios
  //     .put(
  //       serverUrl + apiUrl.change_password,
  //       data = "",
  //       { headers: headers },
  //       { cancelToken: cancelToken.token }
  //     )
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       if (axios.isCancel(error)) {
  //         // console.log("Request canceled", error.message);
  //       } else {
  //         let errorStatus = JSON.parse(JSON.stringify(error.response));
  //         return errorStatus;
  //       }
  //     });
  // }

  const onSubmit = (data) => {
    setIsLoading(true);
    let password = {
      old_password: data?.old_password,
      new_password: data?.new_password,
    };
    handleFetchRequest(
      apiUrl.change_password,
      "PUT",
      (data = null),
      password
    ).then((response) => {
      if (response.status === true) {
        setIsLoading(false);
        toast.success(response.message, {
          position: "top-right",
        });
        if (location?.state?.role === "coach") {
          navigate(RoutesLink?.add_user_profile, {
            state: { id: location?.state?.id },
          });
        } else {
          navigate(RoutesLink?.dashboard, {
            state: { id: location?.state?.id },
          });
        }
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast.error(response.message, {
          position: "top-right",
        });
      }
    });
  };

  // const backButtonHandler = () => {
  //   navigate(-1);
  // }

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper auth">
          <div className="row d-flex align-item-center justify-content-around py-5">
            <div className="col-lg-5 text-align-center d-flex flex-column ">
              {/* <p className="text-black fs-1 fw-bold font-family-Roboto line-height-normal m-0 px-3">
            {texts?.heading_text?.login_page_heading}
          </p> */}
              <img
                className="py-3 w-80"
                alt="img"
                src="assets/image/image_2024_03_08T10_37_25_875Z.png"
              />
            </div>
            <div className="col-lg-5">
              <div className="auth-form-light text-left p-5 py-5 ">
                {/* <div className="brand-logo d-flex align-items-center justify-content-center gap-3">
              <img src="assets/image/groupp.png" />
            </div> */}
                <h4 className="password-form-heading mb-2">
                  Change Your Password
                </h4>
                {/* <h6 className="font-weight-light"></h6> */}
                <form className="" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="input-label px-3">
                      Current Password <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg bg-light"
                      id="exampleInputEmail1"
                      placeholder="Current Password"
                      {...register("old_password", {
                        required: "Please enter current password*",
                      })}
                    />
                    <span className="text-danger">
                      {errors?.password?.message}
                    </span>

                    {/* <span>hello</span> */}
                  </div>
                  <div className="form-group position-relative">
                    <label className="input-label px-3">
                      New Password <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg bg-light"
                      id="exampleInputPassword1"
                      placeholder="New Password"
                      {...register("new_password", {
                        required: "Please enter new password*",
                        validate: validatePassword,
                      })}
                    />
                    <span className="text-danger">
                      {errors?.new_password?.message}
                    </span>
                  </div>
                  {/* <div className="my-2 d-flex justify-content-end align-items-center">
                <p className="text-secondary fs-6 fw-medium font-family-Roboto  m-0 px-3 py-2">Forgot password?</p>
              </div>   */}
                  <div className="form-check">
                    <label className="form-check-label text-muted" />
                    <div className="log-btn">
                      <button className="btn text-center text-white w-100 fs-6 fw-semibold font-family-Roboto  m-0 ">
                        {isLoading ? <ButtonLoader /> : "Change Password"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
