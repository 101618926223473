import ReactDatePicker from "react-datepicker";
import FormLayout from "../../../Components/Layout/FormLayout";

import { useLocation, useNavigate } from "react-router-dom";
// import { CountryCode } from "../../../ApiHelper/CountryCode";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import {
  ConvertInputTimeToSetting,
  TimeStampConverter,
  convertToEpoch,
} from "../../../ApiHelper/TimeStampConverter";
import moment from "moment";
import Swal from "sweetalert2";
import PageLoader from "../../../Components/CommonElements/PageLoader";

import Heading from "../../../Components/CommonElements/Heading";
import { DatePicker } from "@mui/x-date-pickers";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);
dayjs.extend(timezone);

const AddCoachPlan = () => {
  const location = useLocation();
  const { t } = useTranslation();
  console.log("locococococo", location.state);
  const [sessionData, setSessionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledDateTime, setDisabledDateTime] = useState([]);
  const startYear = 1950;
  const currentYear = new Date().getFullYear();
  const [excludedEndDate, setExcludedEndDate] = useState([]);
  const [startScheduleDate, setStartScheduleDate] = useState("");
  const [modal2, setModal2] = useState(false);
  const [lodingModalBtn, setLodingModalBtn] = useState(false);
  const [selecCoachId, setSelectCoachId] = useState("");
  const [selecCoachPlanId, setSelectCoachPalnId] = useState("");
  const [allCoachList, setAllCoachList] = useState([]);
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    getCoachList();

    handleFetchRequest(
      `${apiUrl?.get_session_list}?coach_plan_id=${
        location?.state?.coach_plan_id ||
        location?.state?.data?.id ||
        location?.state?.id
      }`
    ).then((response) => {
      if (response?.status === true) {
        // console.log(response.data);
        if (response?.data?.length === 0) {
          setSessionData([
            {
              schedule_start_ts: "",
              schedule_end_ts: "",
              remarks: "",
              isEditing: true,
              editMode: false,
              status: "",
            },
          ]);
        }
        setIsLoading(false);
        const newData = response?.data?.map((item, index) => ({
          id: index + 1,
          main_id: item?.id,
          session_id: item?.session_id,
          schedule_start_ts: TimeStampConverter(item?.schedule_start_ts),
          schedule_end_ts: TimeStampConverter(item?.schedule_end_ts),
          remarks: item?.remark,
          session_start_ts:
            item?.session_start_ts &&
            TimeStampConverter(item?.session_start_ts),
          session_end_ts:
            item?.session_end_ts && TimeStampConverter(item?.session_end_ts),
          status: item?.status,
          isEditing: false,
          editMode: true,
          editButton: false,
        }));
        // console.log("useEffectDataaaa......", newData);
        if (response?.data?.length > 0) {
          setSessionData(newData);
        }
        GetCalenderValidationHandler();
      } else {
        setIsLoading(false);
      }
    });

    // getTimeSlotFromStartAndEnd("11:00 AM", "06:00 PM");
  }, []);

  const getCoachList = () => {
    handleFetchRequest(`${apiUrl.get_all_coach}`).then((response) => {
      if (response?.status === true) {
        setAllCoachList(response.data);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
      }
    });
  };

  const handleAddRow = (stateVariable, stateVariableFunction, emptyArray) => {
    const newId = stateVariable.length + 1;
    const newRow = { id: newId, ...emptyArray };
    stateVariableFunction([...stateVariable, newRow]);
  };
  // Function to convert timestamp to JavaScript Date
  // const timestampToDate = (timestamp) => new Date(parseInt(timestamp));

  const handleInputChange = (
    id,
    field,
    value,
    stateVar,
    stateFunction,
    type,
    start_date
  ) => {
    if (type === "date") {
      if (field === "schedule_start_ts") {
        const startDate = new Date(value); // Assuming value is the selected date
        const startOfDay = new Date(startDate);
        startOfDay.setHours(0, 0, 0, 0); // Set the time to 12:00 AM
        const timeSlots = [];
        for (let hour = 0; hour <= startDate.getHours(); hour++) {
          for (let minute = 0; minute < 60; minute++) {
            const slot = new Date(startOfDay);
            slot.setHours(hour, minute, 0, 0);
            timeSlots.push(slot);
          }
        }
        // start_date end_date
        //start_date_ref

        setExcludedEndDate(timeSlots);
        // At this point, timeSlots array will
        // console.log(timeSlots);
      }
      if (field === "schedule_end_ts") {
        const startDate = new Date(start_date); // Assuming value is the selected date (e.g., 10th Jan 2024 3:00 PM)
        const endDate = new Date(value);
        // console.log("dateee....", startDate, endDate);
        startDate.setHours(0, 0, 0, 0); // Set the time to 12:00 AM
        const timeSlots = [];
        if (startDate === endDate) {
          for (let hour = 0; hour <= startDate.getHours(); hour++) {
            for (let minute = 0; minute < 60; minute++) {
              const slot = new Date(startDate);
              slot.setHours(hour, minute, 0, 0);
              timeSlots.push(slot);
            }
          }
          setExcludedEndDate(timeSlots);
        }
        if (startDate !== endDate) {
          setExcludedEndDate([]);
        }
      }
      const todayDate = new Date();
      const compareDate = new Date(value);
      // let date1 = new Date(d1).getTime();
      // let date2 = new Date(d2).getTime();
      let disabledTimes = [];
      // console.log("Dateeeee....", todayDate, compareDate);
      if (new Date(todayDate) == new Date(compareDate)) {
        const timesArray = getTimeSlotFromStartAndEnd(
          "12:00 AM",
          moment(todayDate).format("H:mm A")
        );
        const dateTimeArray = getTimeSlotWithDate(timesArray);
        disabledTimes = [...disabledTimes, ...dateTimeArray];
        setDisabledDateTime(disabledTimes);
        // console.log("yes it is today dateee....", disabledTimes);
      }

      handleFetchRequest(
        `${apiUrl?.get_date_time_validation}?coach_id=${
          location?.state?.data?.coach_id ||
          location?.state?.assigned_beneficiary?.coach_id ||
          location?.state?.coach_id ||
          location?.state?.coach?.id
        }`
      ).then((response) => {
        if (response?.status === true) {
          const disabledTimes = [];
          if (response?.data?.length > 0) {
            for (let i = 0; i < response?.data?.length; i++) {
              let ConvertedStartDate = TimeStampConverter(
                response?.data?.[i]?.schedule_start_ts
              );
              let ConvertedEndDate = TimeStampConverter(
                response?.data[i]?.schedule_end_ts
              );
              // console.log("datatatata.....", response?.data?.[i]?.schedule_start_ts, response?.data[i]?.schedule_end_ts);
              const startedDate = new Date(ConvertedStartDate);
              const endDate = new Date(ConvertedEndDate);
              // console.log("starteddddDateeeeee...", startedDate, endDate, value);
              // Add the start and end times of the session to the disabledTimes array
              if (
                moment(startedDate)?.format("DD/MM/YYYY") ===
                  moment(value)?.format("DD/MM/YYYY") &&
                moment(endDate)?.format("DD/MM/YYYY") ===
                  moment(value)?.format("DD/MM/YYYY")
              ) {
                disabledTimes.push(startedDate);
                disabledTimes.push(endDate);
                //   }
              }
            }
            // console.log("disableeeee timeeeee.......", disabledTimes);
            setDisabledDateTime(disabledTimes);
          }

          // console.log("disableddd timeeeee..... ", disabledTimes);
          const newData = stateVar.map((item) =>
            item.id === id ? { ...item, [field]: value } : item
          );
          stateFunction(newData);
        }
      });
    } else {
      const newData = stateVar.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      );
      stateFunction(newData);
    }
  };

  const handleEditsave = (dataId, apiLink, setStateVariable, data) => {
    const saveData = data;
    console.log("saveeee datataaa", saveData);
    setIsLoading(true);
    if (saveData?.schedule_start_ts && saveData?.schedule_end_ts) {
      handleFetchRequest(apiLink, "PUT", saveData).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          setStartScheduleDate("");
          toast.success(response.message);
          setStateVariable((prevData) => {
            const newData = prevData.map((item) =>
              item.id === dataId
                ? {
                    ...item,
                    isEditing: false,
                    main_id: response?.data?.id,
                    session_id: item?.session_id,
                    editMode: true,
                    editButton: false,
                    session_started_ts:
                      response?.data?.session_start_ts &&
                      TimeStampConverter(response?.data?.session_start_ts),
                    session_end_ts:
                      response?.data?.session_end_ts &&
                      TimeStampConverter(response?.data?.session_end_ts),
                    schedule_end_ts: TimeStampConverter(
                      response?.data?.schedule_end_ts
                    ),
                    schedule_start_ts: TimeStampConverter(
                      response?.data?.schedule_start_ts
                    ),
                    status: item?.status,
                  }
                : item
            );
            // console.log("newwwwwwwew", newData);
            return newData;
          });
          GetCalenderValidationHandler();
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          setStartScheduleDate();
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    } else {
      toast.error("Please enter schedule start time or schedule end time");
    }
  };

  const handleSaveRow = (pageId, stateVariableFunction, apiLink, data) => {
    //  console.log("Dataaaa",data?.id)
    const saveData = data;
    console.log("saveeee datataaa", saveData);
    // setIsLoading(true);
    if (saveData?.schedule_start_ts && saveData?.schedule_end_ts) {
      setIsLoading(true);
      handleFetchRequest(apiLink, "POST", saveData).then((response) => {
        if (response?.status === true) {
          toast.success(response.message);
          setIsLoading(false);
          setStartScheduleDate("");
          stateVariableFunction((prevData) => {
            const newData = prevData.map((item) =>
              item.id === pageId
                ? {
                    ...item,
                    isEditing: false,
                    main_id: response?.data?.id,
                    session_id: response?.data?.session_id,
                    schedule_end_ts: TimeStampConverter(
                      response?.data?.schedule_end_ts
                    ),
                    schedule_start_ts: TimeStampConverter(
                      response?.data?.schedule_start_ts
                    ),
                    editMode: true,
                    editButton: false,
                    session_start_ts: response?.data?.session_start_ts,
                    session_end_ts: response?.data?.session_end_ts,
                    status: response?.data?.status,
                  }
                : item
            );
            // console.log("newwwwwwwew", newData);
            return newData;
          });
          GetCalenderValidationHandler();
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response.message);
        }
      });
    } else {
      toast.error("Please enter schedule start time or schedule end time");
    }
  };

  const handleToggleEdit = (id, stateVariable, setStateVariable) => {
    // setDisabledDateTime([]);
    // setExcludedEndDate([]);

    const newData = stateVariable.map((item) =>
      item.id === id ? { ...item, isEditing: true, editButton: true } : item
    );
    setStateVariable(newData);
  };

  const handleDeleteRow = (
    rowId,
    typeId,
    fetchUrl,
    stateVariable,
    stateVariableFunction
  ) => {
    if (typeId) {
      setIsLoading(true);
      handleFetchRequest(`${fetchUrl}`, "DELETE").then((response) => {
        if (response?.status === true) {
          setIsLoading(false);

          toast.success(response?.message);
          const newData = stateVariable.filter((item) => item.id !== rowId);
          stateVariableFunction(newData);
        } else if (response?.messageCode === 99) {
          setIsLoading(false);
          Swal.fire({
            text: "Some of the data is not compeleted are you sure you want to delete this session ?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
              handleFetchRequest(
                `${fetchUrl}&force_delete=true`,
                "DELETE"
              ).then((response) => {
                if (response?.status === true) {
                  setIsLoading(false);
                  toast.success(response?.message);
                  const newData = stateVariable.filter(
                    (item) => item.id !== rowId
                  );
                  stateVariableFunction(newData);
                }
              });
            }
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      });
    } else {
      const newData = stateVariable.filter((item) => item.id !== rowId);
      stateVariableFunction(newData);
    }
  };

  const backButtonHandler = () => {
    if (
      location?.state?.beneficiaryCount &&
      location?.state?.beneficiaryCount?.length <= 1
    ) {
      navigate(RoutesLink.coach_profile, {
        state: {
          id:
            location.state.assigned_beneficiary?.coach_id ||
            location?.state?.data?.coach_id,
          tabid: "3",
          data: { alias_id: location.state.alias_id },
        },
      });
    } else {
      navigate(-1);
    }
    // navigate(RoutesLink.coach_profile, { state: { id: location.state.id, tabid: "3", data: { alias_id: location.state.alias_id } } })
  };

  const getTimeSlotFromStartAndEnd = (starttime, endtime) => {
    const timeSlot = [];

    const startTimehour = moment(starttime, "hh:mm A").format("HH");
    const endTimehour = moment(endtime, "hh:mm A").format("HH");
    const startTimeObj = moment(starttime, "hh:mm A");
    const endTimeObj = moment(endtime, "hh:mm A");

    // const loopValue = endTimehour - startTimehour;
    while (startTimeObj.isBefore(endTimeObj)) {
      timeSlot.push(startTimeObj.format("hh:mm A"));
      startTimeObj.add(1, "hour");
    }
    timeSlot.push(endtime);
    // getTimeSlotWithDate(timeSlot);

    return timeSlot;
  };

  const getTimeSlotWithDate = (timeslots) => {
    const timeSlotWithDate = [];
    for (let i = 0; i < timeslots.length; i++) {
      timeSlotWithDate.push(
        moment(timeslots[i], "HH:mm A").format(
          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
        )
      );
    }
    // console.log("time slot arrayyyyy... fitlerered", timeSlotWithDate);
    return timeSlotWithDate;
  };

  const GetCalenderValidationHandler = () => {
    let disabledTimes = [];
    const todayDate = new Date();
    const timesArray = getTimeSlotFromStartAndEnd(
      "12:00 AM",
      moment(todayDate).format("H:mm A")
    );
    const dateTimeArray = getTimeSlotWithDate(timesArray);
    disabledTimes = [...disabledTimes, ...dateTimeArray];

    handleFetchRequest(
      `${apiUrl?.get_date_time_validation}?coach_id=${
        location?.state?.assigned_beneficiary?.coach_id ||
        location?.state?.data?.coach_id ||
        location?.state?.coach_id ||
        location?.state?.coach?.id
      }`
    ).then((response) => {
      if (response?.status === true) {
        for (let i = 0; i < response.data.length; i++) {
          let ConvertedStartDate = TimeStampConverter(
            response?.data?.[i]?.schedule_start_ts
          );
          let ConvertedEndDate = TimeStampConverter(
            response?.data[i]?.schedule_end_ts
          );
          const startedDate = new Date(ConvertedStartDate);
          const endDate = new Date(ConvertedEndDate);
          // Add the start and end times of the session to the disabledTimes array
          const todayDate = new Date();

          if (
            moment(startedDate).format("DD/MM/YYYY") ===
              moment(todayDate).format("DD/MM/YYYY") &&
            moment(endDate).format("DD/MM/YYYY") ===
              moment(todayDate).format("DD/MM/YYYY")
          ) {
            const timesArray = getTimeSlotFromStartAndEnd(
              moment(startedDate).format("hh:mm A"),
              moment(endDate).format("hh:mm A")
            );
            const dateTimeArray = getTimeSlotWithDate(timesArray);

            disabledTimes = [...disabledTimes, ...dateTimeArray];
            // disabledTimes.push([...disabledDateTime,dateTimeArray]);
            // disabledTimes.push(new Date(endDate));
            //   }
          }
        }
        disabledTimes = disabledTimes.map((data) => {
          return new Date(data);
        });

        // console.log("real time stam array", disabledTimes);
        setDisabledDateTime(disabledTimes);
        // console.log("disableddd timeeeee..... ", disabledTimes);
      }
    });
  };

  function capitalizeFirstLetter(str) {
    if (str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    }
  }

  const handleStartDateChange = (value, id, field) => {
    // let forcedDate = value.toLocaleString('fi-FI', { timeZone: 'Europe/Helsinki' });
    let forcedDate2 = moment(value)
      .tz("Europe/Helsinki")
      .format("YYYY-MM-DD HH:mm:ss");
    // console.log("forced Date...", forcedDate2);
    if (field === "schedule_start_ts") {
      setStartScheduleDate(value);
      const newData = sessionData.map((item) =>
        item.id === id
          ? {
              ...item,
              schedule_end_ts: new Date(
                new Date(value)?.setHours(new Date(value)?.getHours() + 1)
              ),
            }
          : item
      );
      // console.log("newwwww end Date:::::::", newData);
      setSessionData(newData);
      const startDate = new Date(value); // Assuming value is the selected date
      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0); // Set the time to 12:00 AM
      const timeSlots = [];
      for (let hour = 0; hour <= startDate.getHours(); hour++) {
        for (let minute = 0; minute < 60; minute++) {
          const slot = new Date(startOfDay);
          slot.setHours(hour, minute, 0, 0);
          timeSlots.push(slot);
        }
      }
      // start_date end_date
      //start_date_ref

      setExcludedEndDate(timeSlots);
      // At this point, timeSlots array will
      // console.log(timeSlots);
    }

    if (field === "schedule_end_date") {
      if (
        moment(startScheduleDate).format("DD/MM/YYYY") ===
        moment(value).format("DD/MM/YYYY")
      ) {
        // setStartScheduleDate(value);
        const startDate = new Date(startScheduleDate); // Assuming value is the selected date
        const startOfDay = new Date(startDate);
        startOfDay.setHours(0, 0, 0, 0); // Set the time to 12:00 AM
        const timeSlots = [];
        for (let hour = 0; hour <= startDate.getHours(); hour++) {
          for (let minute = 0; minute < 60; minute++) {
            const slot = new Date(startOfDay);
            slot.setHours(hour, minute, 0, 0);
            timeSlots.push(slot);
          }
        }
        setExcludedEndDate(timeSlots);
      } else {
        // console.log("Excluded end date state emptiedddd//...")
        setExcludedEndDate([]);
      }
    }

    const todayDate = new Date();
    const compareDate = new Date(value);
    // let date1 = new Date(d1).getTime();
    // let date2 = new Date(d2).getTime();
    let disabledTimes = [];
    // console.log("today date....., compareDate: ", todayDate, compareDate)
    if (
      moment(todayDate).format("DD/MM/YYYY") ==
      moment(compareDate).format("DD/MM/YYYY")
    ) {
      const timesArray = getTimeSlotFromStartAndEnd(
        "12:00 AM",
        moment(todayDate).format("H:mm A")
      );
      const dateTimeArray = getTimeSlotWithDate(timesArray);
      disabledTimes = [...disabledTimes, ...dateTimeArray];
      setDisabledDateTime(disabledTimes);
      // console.log("yes it is today dateee....", disabledTimes);
    }

    handleFetchRequest(
      `${apiUrl?.get_date_time_validation}?coach_id=${
        location?.state?.assigned_beneficiary?.coach_id ||
        location?.state?.coach_id ||
        location?.state?.coach?.id
      }`
    ).then((response) => {
      if (response?.status === true) {
        for (let i = 0; i < response.data.length; i++) {
          let ConvertedStartDate = TimeStampConverter(
            response?.data?.[i]?.schedule_start_ts
          );
          let ConvertedEndDate = TimeStampConverter(
            response?.data[i]?.schedule_end_ts
          );
          const startedDate = new Date(ConvertedStartDate);
          const endDate = new Date(ConvertedEndDate);
          // Add the start and end times of the session to the disabledTimes array
          const todayDate = new Date(value);

          if (
            moment(startedDate).format("DD/MM/YYYY") ===
              moment(todayDate).format("DD/MM/YYYY") &&
            moment(endDate).format("DD/MM/YYYY") ===
              moment(todayDate).format("DD/MM/YYYY")
          ) {
            const timesArray = getTimeSlotFromStartAndEnd(
              moment(startedDate).format("HH:mm A"),
              moment(endDate).format("H:mm A")
            );
            const dateTimeArray = getTimeSlotWithDate(timesArray);

            disabledTimes = [...disabledTimes, ...dateTimeArray];
            // disabledTimes.push([...disabledDateTime,dateTimeArray]);
            // disabledTimes.push(new Date(endDate));
            //   }
          }
        }

        disabledTimes = disabledTimes.map((data) => {
          return new Date(data);
        });

        // console.log("real time stam array", disabledTimes);
        setDisabledDateTime(disabledTimes);
        // console.log("disableddd timeeeee..... ", disabledTimes);
      }
    });
    if (field === "schedule_start_ts") {
      const newData = sessionData.map((item) =>
        item.id === id
          ? {
              ...item,
              schedule_start_ts: value,
              schedule_end_ts: new Date(
                new Date(value)?.setHours(new Date(value)?.getHours() + 1)
              ),
            }
          : item
      );
      setSessionData(newData);
    } else if (field === "schedule_end_ts") {
      const newData = sessionData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      );
      setSessionData(newData);
    }
  };

  // const materialDateHandler = (date) => {
  //   console.log('materialDateHandler....... ', date);
  // }

  const toggle2 = () => {
    setModal2(!modal2);
  };
  const handelOpenCoachModel = () => {
    // console.log("dfsdfsd", id)
    setModal2(!modal2);
    setSelectCoachPalnId(location?.state?.coach_plan_id);
    // console.log("khk", location?.state?.data?.coach_id,allCoachList.filter((e) => e.id === location?.state?.data?.coach_id))
    let allCoach = allCoachList.filter(
      (e) => e.id !== location?.state?.data?.coach_id
    );
    setAllCoachList(allCoach);
  };

  const handelChangeCoach = () => {
    if (selecCoachId !== "") {
      let Data = {
        coach_id: selecCoachId,
        plan_id: location?.state?.id
          ? location?.state?.id
          : location?.state?.coach_plan_id,
      };
      setLodingModalBtn(true);
      handleFetchRequest(apiUrl?.change_coach, "PUT", Data).then((response) => {
        if (response?.status === true) {
          setLodingModalBtn(false);
          toast.success("Coach Change Successfully");
          toggle2();
          setSelectCoachId("");
          setSelectCoachPalnId("");
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setLodingModalBtn(false);
          toast.error(response.message);
        }
      });
    } else {
      toast.error("Please select coach");
    }
  };
  console.log(location, "location");
  return (
    <FormLayout>
      <div className="market-analysys">
        <div className="my-3 d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center">
          <Heading
            backButtonHandler={backButtonHandler}
            name={"Add Coaching Plan Sessions"}
          />
          {/* <AppHeading title="" /> */}
          <div className="d-flex flex-column flex-md-row gap-2 w-sm-100">
            {!(location?.state?.data?.status == "completed") && (
              <button
                className="btn btn-primary w-sm-100"
                onClick={() =>
                  handleAddRow(sessionData, setSessionData, {
                    schedule_start_ts: "",
                    schedule_end_ts: "",
                    remarks: "",
                    isEditing: true,
                    editMode: false,
                    status: "",
                  })
                }
              >
                + Add Session
              </button>
            )}

            {/* <button className="btn btn-primary mx-2"
              onClick={() => handelOpenCoachModel()}
            >Change Coach</button> */}
            <button
              className=" btn text-white w-sm-100 bg-danger"
              onClick={() =>
                navigate(RoutesLink.coach_profile, {
                  state: {
                    id:
                      location.state.assigned_beneficiary?.coach_id ||
                      location?.state?.data?.coach_id ||
                      location?.state?.coach_id,
                    tabid: "3",
                    data: { alias_id: location.state.alias_id },
                  },
                })
              }
              // style={{ borderRadius: "50%", height: "40px", width: "40px" }}
            >
              Close
            </button>
          </div>
        </div>
        <div className="my-2">
          <div>
            <strong>{t("beneficiary_name")} : </strong>
            <span>
              {" "}
              {location?.state?.fullname}
              {location?.state?.data?.beneficiary?.fullname}
            </span>
          </div>
          <div>
            <strong>{t("coach_name")} : </strong>
            <span>
              {location?.state?.assigned_beneficiary?.account?.first_name}{" "}
              {location?.state?.assigned_beneficiary?.account?.last_name}
              {location?.state?.data?.coach?.first_name}{" "}
              {location?.state?.data?.coach?.last_name}
            </span>
          </div>
        </div>
        <div
          className="table-responsive p-0 min-heigh-table"
          style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
        >
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Session ID</th>
                <th className="lh-base">
                  {" "}
                  Schedule Start Date <br /> (DD/MM/YYYY)
                </th>
                <th>
                  Schedule End Date
                  <br />
                  (DD/MM/YYYY)
                </th>
                <th className="lh-base">
                  {" "}
                  Session Start Date <br /> (DD/MM/YYYY)
                </th>
                <th>
                  Session End Date
                  <br /> (DD/MM/YYYY)
                </th>
                <th>{t("status")}</th>
                {/* <th>Remarks</th> */}
                <th>{t("action")}</th>
              </tr>
            </thead>
            <tbody>
              {sessionData.map((item, index) => (
                <tr key={item.id}>
                  <td className="text-left">{item?.session_id}</td>
                  <td className="text-left">
                    {item.isEditing ? (
                      <div>
                        <ReactDatePicker
                          // excludeTimes={[new Date("Tue Feb 27 2024 11:00:00 GMT+0530 (India Standard Time)"), new Date("Tue Feb 27 2024 13:00:00 GMT+0530 (India Standard Time)"), new Date("Tue Feb 27 2024 15:00:00 GMT+0530 (India Standard Time)"), new Date("Tue Feb 27 2024 16:00:00 GMT+0530 (India Standard Time)")]}
                          excludeTimes={disabledDateTime}
                          dateFormat="dd/MM/yyyy h:mm aa"
                          onChange={(d) =>
                            handleStartDateChange(
                              d,
                              item.id,
                              "schedule_start_ts"
                            )
                          }
                          // onChange={(d) => handleInputChange(item.id, 'schedule_start_ts', d, sessionData, setSessionData, "date", item?.schedule_start_ts)}
                          selected={
                            item?.schedule_start_ts &&
                            new Date(item?.schedule_start_ts)
                          }
                          style={{ zIndex: 9999 }}
                          className="form-control"
                          placeholderText={"Start Date/Time"}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {/* {console.log("dateeeeeeee..... from ", date)} */}
                              <button
                                onClick={decreaseMonth}
                                type="button"
                                disabled={prevMonthButtonDisabled}
                              >
                                {"<"}
                              </button>
                              <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={months[date?.getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                onClick={increaseMonth}
                                type="button"
                                disabled={nextMonthButtonDisabled}
                              >
                                {">"}
                              </button>
                            </div>
                          )}
                          showTimeSelect
                          timeIntervals={60}
                          // minDate={item?.schedule_start_ts ? new Date(item?.schedule_start_ts) : new Date()}
                          // minDate={new Date()}
                        />
                      </div>
                    ) : (
                      item?.schedule_start_ts &&
                      moment(item?.schedule_start_ts).format(
                        "DD/MM/YYYY h:mm A"
                      )
                    )}
                  </td>
                  <td className="text-start">
                    {item?.isEditing ? (
                      <ReactDatePicker
                        // excludeTimes={["Tue Feb 27 2024 11:00:00 GMT+0530 (India Standard Time)", "Tue Feb 27 2024 13:00:00 GMT+0530 (India Standard Time)", "Tue Feb 27 2024 15:00:00 GMT+0530 (India Standard Time)", "Tue Feb 27 2024 16:00:00 GMT+0530 (India Standard Time)"]}
                        disabled={!startScheduleDate}
                        // autoFocus={startScheduleDate && new Date(startScheduleDate)}
                        excludeTimes={[...disabledDateTime, ...excludedEndDate]}
                        onChange={(d) =>
                          handleStartDateChange(d, item.id, "schedule_end_ts")
                        }
                        // onChange={(d) => handleInputChange(item.id, 'schedule_end_ts', d, sessionData, setSessionData, "date", item?.schedule_start_ts)}
                        // selected={item?.schedule_end_ts ? new Date(item?.schedule_end_ts) : new Date()}

                        selected={
                          item?.schedule_end_ts
                            ? new Date(item?.schedule_end_ts)
                            : item?.schedule_start_ts
                            ? new Date(
                                new Date(item?.schedule_start_ts)?.setHours(
                                  new Date(item.schedule_start_ts)?.getHours() +
                                    1
                                )
                              )
                            : ""
                        }
                        // selected={startDate?.from}
                        className="form-control"
                        placeholderText={"End Date/Time"}
                        // filterDate={(date) => filterEndDate(date, sessionData, index)}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={decreaseMonth}
                              type="button"
                              disabled={prevMonthButtonDisabled}
                            >
                              {"<"}
                            </button>
                            <select
                              value={date.getFullYear()}
                              onChange={({ target: { value } }) =>
                                changeYear(value)
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[date?.getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button
                              onClick={increaseMonth}
                              type="button"
                              disabled={nextMonthButtonDisabled}
                            >
                              {">"}
                            </button>
                          </div>
                        )}
                        showTimeSelect
                        timeIntervals={60}
                        minDate={new Date(item?.schedule_start_ts)}
                      />
                    ) : (
                      item?.schedule_end_ts &&
                      moment(item?.schedule_end_ts).format("DD/MM/YYYY h:mm A")
                    )}
                  </td>
                  <td className="text-start">
                    {item?.session_start_ts &&
                      moment(item?.session_start_ts).format(
                        "DD/MM/YYYY h:mm A"
                      )}
                  </td>
                  <td className="text-start">
                    {item?.session_end_ts &&
                      moment(item?.session_end_ts).format("DD/MM/YYYY h:mm A")}

                    {/* {item?.session_end_ts} */}
                  </td>
                  <td className="text-start">
                    {capitalizeFirstLetter(item?.status)}
                  </td>
                  {/* <td>
                    {item?.isEditing ? (
                      <input
                        placeholder="Remarks"
                        className="form-control"
                        type="text"
                        value={item?.remarks}
                        onChange={(e) => handleInputChange(item.id, 'remarks', e.target.value, sessionData, setSessionData)}
                      />) : <div style={{ width: "100px", overflow: "hidden" }}>{item?.remarks}</div>}
                  </td> */}
                  <td className="text-start">
                    {item?.status === "pending" || item?.status === "" ? (
                      <button
                        className="btn bg-danger text-white fst-normal p-2"
                        onClick={() =>
                          handleDeleteRow(
                            item.id,
                            item.main_id,
                            `${apiUrl?.delete_coach_plan_session}?plan_session_id=${item?.main_id}`,
                            sessionData,
                            setSessionData
                          )
                        }
                      >
                        <span className="mdi mdi-delete"></span>
                        Delete
                      </button>
                    ) : (
                      ""
                    )}
                    {item?.status === "pending" &&
                      item?.editButton === false && (
                        <button
                          className="btn bg-danger text-white fst-normal  p-2 ms-1"
                          onClick={() =>
                            handleToggleEdit(
                              item.id,
                              sessionData,
                              setSessionData
                            )
                          }
                        >
                          Edit
                        </button>
                      )}

                    {item?.editButton === true && (
                      <button
                        className="btn btn-primary  p-2 ms-1"
                        onClick={() =>
                          handleEditsave(
                            item.id,
                            apiUrl?.edit_coach_plan,
                            setSessionData,
                            {
                              // cp_master_id: location?.state?.coach_plan_id || location?.state?.data?.id,
                              plan_session_id: item?.main_id,
                              // coach_id: location?.state?.assigned_beneficiary?.coach_id,
                              // beneficiary_id: location?.state?.id,
                              schedule_start_ts: convertToEpoch(
                                ConvertInputTimeToSetting(
                                  moment(item?.schedule_start_ts).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                              schedule_end_ts: convertToEpoch(
                                ConvertInputTimeToSetting(
                                  moment(item?.schedule_end_ts).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                              // remark: item?.remarks
                            }
                          )
                        }
                      >
                        Save
                      </button>
                    )}

                    {item?.editMode === false && (
                      <button
                        className="btn btn-primary p-2 ms-1"
                        onClick={() =>
                          handleSaveRow(
                            item?.id,
                            setSessionData,
                            apiUrl?.add_session,
                            {
                              // business_master_id: masterId,
                              coach_plan_id:
                                location?.state?.coach_plan_id ||
                                location?.state?.data?.id ||
                                location?.state?.id,
                              // beneficiary_id: location?.state?.id,
                              schedule_start_ts: convertToEpoch(
                                ConvertInputTimeToSetting(
                                  moment(item?.schedule_start_ts).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                              schedule_end_ts: convertToEpoch(
                                ConvertInputTimeToSetting(
                                  moment(item?.schedule_end_ts).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                              // remark: item?.remarks
                            }
                          )
                        }
                      >
                        Save
                      </button>
                    )}
                  </td>
                </tr>
              ))}
              {/* <td>
                <DatePicker
                  onChange={materialDateHandler}
                  timezone="America/New_York"
                  defaultValue={moment.tz('2022-04-17T15:30', 'America/New_York')}
                />
              </td> */}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end pt-2 "></div>
      </div>
      {isLoading && <PageLoader />}

      <Modal isOpen={modal2} centered toggle={toggle2} className=" modal">
        <ModalHeader className="border-bottom " toggle={toggle2}>
          Change Coach
        </ModalHeader>
        <ModalBody className="p-3 pt-4">
          <form>
            <div className="p-2 rounded box-shadow">
              <div className="row">
                <div className="col-12 p-0">
                  <label className="input-label ps-3">
                    Select Coach<span className="text-danger">*</span>
                  </label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => setSelectCoachId(e.target.value)}
                    >
                      <option value={""}>Select Coach</option>
                      {allCoachList &&
                        allCoachList?.map((item, key) => (
                          <option key={key} value={item?.id}>
                            {item?.first_name} {item?.last_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer pt-4">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => toggle2()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handelChangeCoach}
              >
                {lodingModalBtn == true ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  `{t("submit")}`
                )}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </FormLayout>
  );
};
export default AddCoachPlan;
