import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import FormLayout from "../../../Components/Layout/FormLayout";
import { useState } from "react";
import AppHeading from "../../../Components/CommonElements/Headings";
import { useForm } from "react-hook-form";
import texts from "../../../ApiHelper/Nomanclatures.json";
import Autocomplete from "react-google-autocomplete";
import { useEffect } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { CountryCode } from "../../../ApiHelper/CountryCode";
import {
  validateEmail,
  validateMobileNumber,
  validateName,
  validateNegative,
  validateZip,
} from "../../../ApiHelper/FormValidation";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { setKey, geocode, fromLatLng, setDefaults } from "react-geocode";
import { useTranslation } from "react-i18next";

const BusinessPlanForm = () => {
  const dispatch = useDispatch();
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [isInputFocused, setIsInputFocused] = useState({
    countryFocused: false,
    addressFocused: false,
  });
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [filteredCountries2, setFilteredCountries2] = useState(CountryCode);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [callApi, setCallApi] = useState(true);
  const [tabOpen, setTabOpen] = useState("1");
  const [selectedAddress, setSelectedAddress] = useState({
    city: "",
    lat: "",
    lng: "",
  });
  const [masterId, setMasterId] = useState("");
  const [editDataHandler, setEditDataHandler] = useState(false);
  const [calculationData, setCalculationData] = useState(null);
  const [firstSubmitBtn, setFirstSubmitBtn] = useState(false);
  const [marginData, setMarginData] = useState({
    marginshow: true,
    marginEdit: true,
    marginValue: 0,
    marginPercent: 0,
  });
  const [marketAnalysisData, setMarketAnalysisData] = useState([
    {
      id: 1,
      customer_segment: "",
      product: "",
      number_product: "",
      price_per_unit: "",
      total: "",
      isEditing: true,
      main_id: "",
      editMode: false,
      editButton: false,
    },
  ]);

  const [materialResources, setMaterialResources] = useState([
    {
      id: 1,
      raw_material: "",
      quantity: "",
      unit_price: "",
      amount: "",
      isEditing: true,
      main_id: "",
      editMode: false,
      editButton: false,
    },
  ]);

  const [humanResourceData, setHumanResourceData] = useState([
    {
      id: 1,
      personnal: "",
      number: "",
      salary: "",
      total: "",
      isEditing: true,
      main_id: "",
      editMode: false,
      editButton: false,
    },
  ]);

  const [productionUnitData, setProductionUnitData] = useState([
    {
      id: 1,
      output_unit: "",
      quantity: "",
      unit_variable_cost: "",
      cost_per_unit: "",
      isEditing: true,
      main_id: "",
      editMode: false,
      editButton: false,
    },
  ]);

  const [breakDownData, setBreakDownData] = useState([
    {
      id: 1,
      fixed_cost: "",
      amount: "",
      isEditing: true,
      main_id: "",
      editMode: false,
      editButton: false,
    },
  ]);

  const [variableCostData, setVariableCostData] = useState([
    {
      id: 1,
      variable_cost: "",
      amount: "",
      isEditing: true,
      main_id: "",
      editMode: false,
      editButton: false,
    },
  ]);

  useEffect(() => {
    setDefaults({
      key: process.env.REACT_APP_GOOGLE_LOCATION_API,
      language: "en", // Default language for responses.
      region: "es", // Default region for responses.
    });
    setTabOpen(location?.state?.tab);
    console.log("sdjkfasdfjk", location.state.masterId);
    if (location?.state?.tab === "2") {
      businessModalApi(location?.state?.masterId);
    } else if (location?.state?.tab === "3") {
      marketAnalysisApi(location.state.masterId);
    } else if (location?.state?.tab === "4") {
      productionProcessApi(location?.state?.masterId);
    } else if (location?.state?.tab === "5") {
      materialResourcesApi(location?.state?.masterId);
    } else if (location?.state?.tab === "6") {
      humanResourcesApi(location?.state?.masterId);
    } else if (location?.state?.tab === "7") {
      productionUnitApi(location?.state?.masterId);
    } else if (location?.state?.tab === "8") {
      fixedCostApi(location?.state?.masterId);
    } else if (location?.state?.tab === "9") {
      variableCostApi(location?.state?.masterId);
    } else if (location?.state?.tab === "10") {
      calculationApi(location?.state?.masterId);
    }
  }, []);

  const businessModalApi = (data) => {
    handleFetchRequest(`${apiUrl.get_business_canvas}${data}`).then(
      (response) => {
        if (response?.status === true) {
          reset1({
            value_proposition: response?.data?.value_proposition,
            customer_segment: response?.data?.customer_segments,
            channels: response?.data?.channels,
            customer_relationship: response?.data?.customer_relationship,
            revenue_stream: response?.data?.revenue_streams,
            key_resources: response?.data?.key_resources,
            key_activities: response?.data?.key_activities,
            key_partners: response?.data?.key_partners,
            cost: response?.data?.cost,
            positive_impact: response?.data?.positive_impacts,
            negative_impact: response?.data?.negative_impacts,
          });
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      }
    );
  };

  const productionProcessApi = (data) => {
    handleFetchRequest(`${apiUrl.get_production}${data}`).then((response) => {
      if (response?.status === true) {
        reset2({
          product_creation_process: response?.data?.product_creation_process,
          achat: response?.data?.achats,
          input_stock_management: response?.data?.input_stock_management,
          operation_stock_management: response?.data?.operations,
          final_product_stock_management:
            response?.data?.final_product_stock_management,
          communication_sales: response?.data?.communication_sales,
          distribution: response?.data?.distribution,
          other: response?.data?.other,
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const calculationApi = (data) => {
    handleFetchRequest(`${apiUrl.get_calculation}${data}`).then((response) => {
      if (response?.status === true) {
        setCalculationData(response?.data);
        setMarginData({
          ...marginData,
          marginPercent: response?.data?.margin_percentage,
          marginValue: response?.data?.margin_par_unit_sold,
          marginEdit: true,
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
  };

  const marketAnalysisApi = (master_id) => {
    handleFetchRequest(`${apiUrl?.get_market_analysis}${master_id}`).then(
      (response) => {
        if (response?.status === true) {
          const newData = response?.data?.map((item, index) => ({
            id: index + 1,
            customer_segment: item?.customer_segment,
            product: item?.product,
            number_product: item?.number_of_product,
            price_per_unit: item?.price_per_unit,
            total: item?.total,
            isEditing: false,
            main_id: item?.id,
            editMode: true,
            editButton: false,
          }));
          setMarketAnalysisData(newData);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      }
    );
  };

  const materialResourcesApi = (master_id) => {
    handleFetchRequest(`${apiUrl?.get_material_resources}${master_id}`).then(
      (response) => {
        if (response?.status === true) {
          const newData = response?.data?.map(
            (item, index) => ({
              id: index + 1,
              raw_material: item?.raw_material,
              quantity: item?.quantity,
              unit_price: item?.price,
              amount: item?.amount,
              isEditing: false,
              main_id: item?.id,
              editMode: true,
              editButton: false,
            })
            // ({ id:index+1,customer_segment:item?.customer_segment, product: item?.product, number_product:item?.number_of_product,price_per_unit:item?.price_per_unit,total:item?.total,isEditing: false,main_id:"",editMode:true  })
          );
          setMaterialResources(newData);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      }
    );
  };

  const humanResourcesApi = (master_id) => {
    handleFetchRequest(`${apiUrl?.get_human_resources}${master_id}`).then(
      (response) => {
        if (response?.status === true) {
          const newData = response?.data?.map((item, index) =>
            // { id:1,isEditing: true,main_id:"",editMode:false },

            ({
              id: index + 1,
              personnal: item?.personnel,
              number: item?.number,
              salary: item?.salary,
              total: item?.total,
              isEditing: false,
              main_id: item?.id,
              editMode: true,
              editButton: false,
            })
          );
          setHumanResourceData(newData);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      }
    );
  };

  const getLocationName = async (longitude, latitude) => {
    console.log("first", longitude, latitude);
    const response = await fromLatLng(latitude, longitude);
    const locationName = response.results[0];

    return locationName;
  };

  const productionUnitApi = (master_id) => {
    handleFetchRequest(`${apiUrl?.get_production_unit}${master_id}`).then(
      (response) => {
        if (response?.status === true) {
          const newData = response?.data?.map((item, index) => ({
            id: index + 1,
            output_unit: item?.output_units,
            quantity: item?.quantity,
            unit_variable_cost: item?.unit_variable_cost,
            cost_per_unit: item?.cost_per_unit,
            isEditing: false,
            main_id: item?.id,
            editMode: true,
            editButton: false,
          }));
          setProductionUnitData(newData);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      }
    );
  };

  const fixedCostApi = (master_id) => {
    handleFetchRequest(`${apiUrl?.get_fixed_cost}${master_id}`).then(
      (response) => {
        if (response?.status === true) {
          const newData = response?.data?.map((item, index) => ({
            id: index + 1,
            fixed_cost: item.fixed_cost,
            amount: item.amount,
            isEditing: false,
            main_id: item?.id,
            editMode: true,
            editButton: false,
          }));
          setBreakDownData(newData);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      }
    );
  };

  const variableCostApi = (master_id) => {
    handleFetchRequest(`${apiUrl?.get_variable_cost}${master_id}`).then(
      (response) => {
        if (response?.status === true) {
          const newData = response?.data?.map((item, index) => ({
            id: index + 1,
            variable_cost: item?.variable_cost,
            amount: item?.amount,
            isEditing: false,
            main_id: item?.id,
            editMode: true,
            editButton: false,
          }));
          setVariableCostData(newData);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      }
    );
  };

  const getLocation = async (longitude, latitude) => {
    try {
      const location = await getLocationName(longitude, latitude);
      console.log("location", location);
      let cityName;
      let stateName;
      let countryName;
      let address_line_1;
      let address_line_2;
      location?.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          cityName = component.long_name || "";
        } else if (component.types.includes("administrative_area_level_1")) {
          stateName = component.long_name || "";
        } else if (component.types.includes("country")) {
          countryName = component.long_name || "";
        } else if (component.types.includes("plus_code")) {
          address_line_1 = component.long_name || "";
        } else if (
          component.types.includes("sublocality" || "sublocality_level_1")
        ) {
          address_line_2 = component.long_name || "";
        }
      });
      setSearchTerm2(countryName);
      reset({
        city: cityName,
        // country: countryName,
        state: stateName,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
      });
      setSelectedAddress({
        city: location?.formatted_address,
        lat: latitude,
        lng: longitude,
      });
    } catch (error) {
      console.log("hkh", error);
    }
  };

  useEffect(() => {
    console.log("loca", location.state);
    handleFetchRequest(
      `${apiUrl?.get_business_plan}?beneficiary_id=${location?.state?.id}`
    ).then((response) => {
      if (response?.status === true) {
        setEditDataHandler(true);
        setCallApi(false);
        setMasterId(response?.data?.id);
        reset({
          project_name: response?.data?.project_name,
          // enterpreneur_name: response?.data?.entrepreneur_name,
          email: response?.data?.email_id,
          telephone: response?.data?.telephone_no,
          business_description: response?.data?.business_description,
          city: response?.data?.address?.city,
          state: response?.data?.address?.state,
          address_line_1: response?.data?.address?.address_line1,
          address_line_2: response?.data?.address?.address_line2,
          // country:,
          zip_code: response?.data?.address?.zip_code,
        });
        setAutocompleteValue(response?.data?.address?.address_line1);
        setSearchTerm2(response?.data?.address?.country);
        setSelectedAddress({
          city: response?.data?.city_residence,
          lat: response?.data?.geo_codes?.latitude,
          lng: response?.data?.geo_codes?.longitude,
        });

        // businessModalApi(response?.data?.id);
        // productionProcessApi(response?.data?.id);
        if (
          location?.state?.beneficiaryData?.longitude &&
          location?.state?.beneficiaryData?.latitude
        ) {
          console.log(
            ";;;;;;;;;;;;",
            location?.state?.beneficiaryData?.longitude
          );
          getLocation(
            location?.state?.beneficiaryData?.longitude,
            location?.state?.beneficiaryData?.latitude
          );
        }
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setCallApi(false);
        if (
          location?.state?.beneficiaryData?.longitude &&
          location?.state?.beneficiaryData?.latitude
        ) {
          console.log(
            ";;;;;;;;;;;;",
            location?.state?.beneficiaryData?.longitude
          );
          getLocation(
            location?.state?.beneficiaryData?.longitude,
            location?.state?.beneficiaryData?.latitude
          );
        }
      }
    });
  }, [callApi]);

  const {
    register,
    handleSubmit,
    reset,
    // watch,
    formState: { errors: errors, isDirty: isDirty },
  } = useForm({ mode: "onBlur" });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    // watch: watch1,
    formState: { errors: errors1, isDirty: isDirty1 },
  } = useForm({ mode: "onBlur" });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    // watch: watch2,
    formState: { errors: errors2, isDirty: isDirty2 },
  } = useForm({ mode: "onBlur" });

  const setTabValue = (data) => {
    if (editDataHandler) {
      setTabOpen(data);

      if (data === "2") {
        businessModalApi(masterId);
      } else if (data === "3" && marketAnalysisData?.length <= 1) {
        marketAnalysisApi(masterId);
      } else if (data === "4") {
        productionProcessApi(masterId);
      } else if (data === "5" && materialResources?.length <= 1) {
        materialResourcesApi(masterId);
      } else if (data === "6" && humanResourceData?.length <= 1) {
        humanResourcesApi(masterId);
      } else if (data === "7" && productionUnitData?.length <= 1) {
        productionUnitApi(masterId);
      } else if (data === "8" && breakDownData.length <= 1) {
        fixedCostApi(masterId);
      } else if (data === "9" && variableCostData?.length <= 1) {
        variableCostApi(masterId);
      } else if (data === "10") {
        calculationApi(masterId);
      }
    } else {
      toast.error("Please fill Master Business form to proceed Further");
    }
  };

  const handlePlaceSelected = (place) => {
    console.log("placceee", place);
    setSelectedAddress({
      city: place.formatted_address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    console.log("erqwerqwerqw", selectedAddress.city);
    // setAddress(place.formatted_address || '');
    // Extract city, state, and country information from the selected place
    const components = place.address_components || [];
    let cityName;
    let stateName;
    let countryName;
    let address_line_1;
    let address_line_2 = null;

    components.forEach((component) => {
      if (component.types.includes("landmark")) {
        address_line_2 = component.long_name || "";
      } else if (component.types.includes("locality")) {
        cityName = component.long_name || "";
        if (address_line_2 == null) {
          address_line_2 = component.long_name || "";
        }
      } else if (component.types.includes("administrative_area_level_1")) {
        stateName = component.long_name || "";
      } else if (component.types.includes("country")) {
        countryName = component.long_name || "";
      } else if (
        component.types.includes("sublocality" || "sublocality_level_1")
      ) {
        address_line_1 = component.long_name || "";
      }
    });

    setSearchTerm2(countryName);
    console.log("bhj", address_line_1, address_line_2);
    setFirstSubmitBtn(true);
    reset({
      city: cityName,
      // country: countryName,
      state: stateName,
      address_line_1: address_line_1,
      address_line_2: address_line_2,
    });

    // Extract address components from the place object

    const addressComponents = place.address_components || [];
    // Construct the address string

    const address = addressComponents
      .map((component) => component.long_name)
      .join(", ");

    // Update the state with the selected address
    // setSelectedAddress(address);

    // You can also log the entire place object for further inspection
    // console.log(place);
  };

  const mainFormSubmitHandler = (data) => {
    setIsLoading(true);
    if (!autocompleteValue) {
      toast.error("Please select address first");
      setIsLoading(false);
      return;
    }
    let submitData = {
      project_name: data?.project_name,
      // entrepreneur_name: data?.enterpreneur_name,
      email_id: data?.email,
      telephone_no: data?.telephone,
      city_residence: selectedAddress?.city?.split(",")?.[0]?.trim(),
      address: {
        address_line1: data?.address_line_1,
        address_line2: data?.address_line_2,
        city: selectedAddress?.city?.split(",")?.[0]?.trim(),
        state: data?.state,
        country: searchTerm2,
        zip_code: data?.zip_code,
      },
      geo_codes: {
        latitude: selectedAddress?.lat,
        longitude: selectedAddress?.lng,
      },
      business_description: data?.business_description,
      beneficiary_id: location?.state?.id,
    };

    let editData = {
      project_name: data?.project_name,
      // entrepreneur_name: data?.enterpreneur_name,
      email_id: data?.email,
      telephone_no: data?.telephone,
      city_residence: selectedAddress?.city?.split(",")?.[0]?.trim(),
      address: {
        address_line1: data?.address_line_1,
        address_line2: data?.address_line_2,
        city: selectedAddress?.city?.split(",")?.[0]?.trim(),
        state: data?.state,
        country: searchTerm2,
        zip_code: data?.zip_code,
      },
      geo_codes: {
        latitude: selectedAddress?.lat,
        longitude: selectedAddress?.lng,
      },
      business_description: data?.business_description,
      business_master_id: masterId,
    };

    if (editDataHandler) {
      handleFetchRequest(
        apiUrl?.edit_master_business_plan,
        "PUT",
        editData
      ).then((response) => {
        if (response?.status === true) {
          toast.success(response.message);
          setIsLoading(false);
          setTabValue("2");
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          toast.error(response.message);
          setIsLoading(false);
        }
      });
    } else {
      handleFetchRequest(
        apiUrl?.add_master_business_plan,
        "POST",
        submitData
      ).then((response) => {
        if (response?.status === true) {
          // setEditDataHandler(true);
          setIsLoading(false);
          toast.success(response?.message);
          setCallApi(true);

          setTabOpen("2");
          // businessModalApi(response?.data?.id);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      });
    }
  };

  const businessModalSubmitHandler = (data) => {
    setIsLoading(true);
    console.log("hellooo submitted");
    let modalData = {
      business_master_id: masterId,
      key_partners: data?.key_partners,
      key_activities: data.key_activities,
      key_resources: data?.key_resources,
      value_proposition: data?.value_proposition,
      customer_relationship: data?.customer_relationship,
      channels: data?.channels,
      customer_segments: data?.customer_segment,
      cost: data?.cost,
      revenue_streams: data.revenue_stream,
      negative_impacts: data?.negative_impact,
      positive_impacts: data?.positive_impact,
    };

    handleFetchRequest(apiUrl?.add_business_canvas, "POST", modalData).then(
      (response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response?.message);
          setTabValue("3");
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      }
    );
  };

  const productSubmitHandler = (data) => {
    setIsLoading(true);
    console.log(data);
    let productData = {
      business_master_id: masterId,
      product_creation_process: data?.product_creation_process,
      achats: data?.achat,
      input_stock_management: data?.input_stock_management,
      operations: data?.operation_stock_management,
      final_product_stock_management: data?.final_product_stock_management,
      communication_sales: data?.communication_sales,
      distribution: data?.distribution,
      other: data?.other,
    };
    handleFetchRequest(apiUrl?.add_production, "POST", productData).then(
      (response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response?.message);
          setTabValue("5");
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      }
    );
  };

  const handleInputChange = (id, field, value, stateVar, stateFunction) => {
    const newData = stateVar.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );
    stateFunction(newData);
  };

  const handleAddRow = (stateVariable, stateVariableFunction, emptyArray) => {
    const newId = stateVariable.length + 1;
    const newRow = { id: newId, ...emptyArray };
    stateVariableFunction([...stateVariable, newRow]);
  };

  const handleDeleteRow = (
    rowId,
    typeId,
    fetchUrl,
    stateVariable,
    stateVariableFunction
  ) => {
    if (typeId) {
      setIsLoading(true);
      handleFetchRequest(`${fetchUrl}${typeId}`, "DELETE").then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response?.message);
          const newData = stateVariable.filter((item) => item.id !== rowId);
          stateVariableFunction(newData);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      });
    } else {
      const newData = stateVariable.filter((item) => item.id !== rowId);
      stateVariableFunction(newData);
    }
  };

  const handleToggleEdit = (id, stateVariable, setStateVariable) => {
    const newData = stateVariable.map((item) =>
      item.id === id ? { ...item, isEditing: true, editButton: true } : item
    );
    setStateVariable(newData);
  };

  const handleSaveRow = (pageId, stateVariableFunction, apiLink, data) => {
    //  console.log("Dataaaa",data?.id)
    const saveData = data;
    setIsLoading(true);

    handleFetchRequest(apiLink, "POST", saveData).then((response) => {
      if (response?.status === true) {
        toast.success(response.message);
        setIsLoading(false);
        stateVariableFunction((prevData) => {
          const newData = prevData.map((item) =>
            item.id === pageId
              ? {
                  ...item,
                  isEditing: false,
                  main_id: response?.data?.id,
                  editMode: true,
                  editButton: false,
                }
              : item
          );
          // console.log("newwwwwwwew", newData);
          return newData;
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const handleEditsave = (dataId, apiLink, setStateVariable, data) => {
    const saveData = data;
    setIsLoading(true);
    handleFetchRequest(apiLink, "PUT", saveData).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response.message);
        setStateVariable((prevData) => {
          const newData = prevData.map((item) =>
            item.id === dataId
              ? {
                  ...item,
                  isEditing: false,
                  main_id: response?.data?.id,
                  editMode: true,
                  editButton: false,
                }
              : item
          );
          console.log("newwwwwwwew", newData);
          return newData;
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const backButtonHandler = () => {
    navigate(RoutesLink?.beneficiary_profile, {
      state: {
        id: location?.state?.id,
        coach_id: location?.state?.coach_id,
        tabpane: "3",
      },
    });
  };

  const marginChangeHandler = (e, type) => {
    console.log(e.target.value);
    if (type === "percent") {
      let calMarginAmount =
        (calculationData?.cost_per_unit * e.target.value) / 100;
      setMarginData({
        ...marginData,
        marginPercent: e.target.value,
        marginValue: calMarginAmount,
      });
    } else {
      let calMarginPercent =
        (e.target.value * 100) / calculationData?.cost_per_unit;
      setMarginData({
        ...marginData,
        marginPercent: calMarginPercent,
        marginValue: e.target.value,
      });
    }
  };

  const marginEditHandler = (type) => {
    if (type === "edit") {
      setMarginData({ ...marginData, marginEdit: false });
    } else {
      setMarginData({ ...marginData, marginEdit: true });
    }
  };

  const marginSaveHandler = () => {
    handleFetchRequest(apiUrl?.add_business_plan_margin, "POST", {
      business_master_id: masterId,
      margin: marginData?.marginPercent,
    }).then((response) => {
      if (response?.status === true) {
        toast.success(response?.message);
        setMarginData({ ...marginData, marginEdit: true });
        calculationApi(masterId);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        toast.error(response?.message);
      }
    });
  };

  const handleClickHandler2 = () => {
    setFilteredCountries2(CountryCode);
  };
  const handleMouseOut2 = () => {
    setFilteredCountries2([]);
  };

  const handleInputChange2 = (e) => {
    const term = e.target.value;
    let arr = CountryCode.filter((ele) =>
      ele.name.toLowerCase().includes(term.toLowerCase())
    );
    console.log("arr----->>   ", arr);
    setFilteredCountries2(arr);
    // if (term.length == 0) {
    //   setFilteredCountries2([])
    // }
    setSearchTerm2(term);
  };

  const handleInputFocus2 = () => {
    setIsDropdownOpen2(true);
  };

  const addressBlurHandler = () => {
    setIsInputFocused({ ...isInputFocused, addressFocused: true });
  };

  const handleInputBlur2 = () => {
    // Delay closing the dropdown to check if the click event occurs on the dropdown itself
    setTimeout(() => {
      setIsDropdownOpen2(false);
    }, 300);
    setIsInputFocused({ ...isInputFocused, countryFocused: true });
  };

  const handleCountrySelect2 = (country) => {
    setSearchTerm2(`${country.name}`);
    setIsDropdownOpen2(false);
    // setFilteredCountries2([]);
    // Perform any other actions when a country is selected, e.g., update state or trigger an event
  };

  return (
    <FormLayout name={"Business Plan"} backButtonHandler={backButtonHandler}>
      <div
      // className="page-header"
      >
        <div style={{ cursor: "pointer" }}>
          <Nav className="nav nav-tabs tabHolder d-flex flex-nowrap overflow-x-auto ">
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "1"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("1")}
                //  href="#"
              >
                <div className=" font-weight-bold">Business Details</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "2"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("2")}
                //  href="#"
              >
                <div className="font-weight-bold">{t("business_model")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "3"
                    ? " d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("3")}
                // href="#"
              >
                <div>{t("market_analysis")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                // href="#"
                className={
                  tabOpen === "4"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("4")}
              >
                <div>{t("production_Process")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "5"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("5")}
              >
                <div>Material Resources</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "6"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("6")}
              >
                <div>{t("human_resources")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "7"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("7")}
              >
                <div>Production Units</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "8"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("8")}
              >
                <div>Fixed Cost Breakdown</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "9"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("9")}
              >
                <div>Variable Cost Breakdown</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "10"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("10")}
              >
                <div>{t("Breakeven_Point_Calculation")}</div>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="absolute-border"></div>
      </div>

      <div className="row">
        <TabContent activeTab={tabOpen}>
          <TabPane tabId={"1"}>
            <div className="businessPlan">
              <form
                className="form-inline"
                onSubmit={handleSubmit(mainFormSubmitHandler)}
              >
                <div>
                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <label className="input-label ps-3">
                        {t("project_name")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.project_name?.name}
                        {...register(texts?.input_texts?.project_name?.value, {
                          required: texts?.input_texts?.project_name?.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger ">
                        {errors?.project_name?.message}
                      </span>
                    </div>
                    {/* <div className="col-lg-6">
                      <label className="input-label ps-3">{texts?.input_texts?.enterpreneur_name?.name} <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.enterpreneur_name?.name}
                        {...register(texts?.input_texts?.enterpreneur_name?.value, {
                          required: texts?.input_texts?.enterpreneur_name?.error,
                          // validate: validateUsername, 
                        })}
                      />
                      <span className="text-danger">
                        {errors?.enterpreneur_name?.message}
                      </span>
                    </div> */}
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-6">
                      <label className="input-label ps-3">
                        {t("email")}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="email"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.email.name}
                        {...register(texts?.input_texts?.email.value, {
                          // required: texts?.input_texts?.email.error,
                          validate: validateEmail,
                        })}
                      />
                      <span className="text-danger ">
                        {errors?.email?.message}
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <label className="input-label ps-3">
                        {texts?.input_texts?.telephone_no.name}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="number"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.telephone_no.name}
                        {...register(texts?.input_texts?.telephone_no.value, {
                          // required: texts?.input_texts?.telephone_no.error,
                          validate: validateMobileNumber,
                        })}
                        min="0"
                      />
                      <span className="text-danger">
                        {errors?.telephone?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="form-group">
                        <div>
                          <div>
                            <label className="input-label ps-3">
                              {t("address")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {/* <input  className="form-control" placeholder="City" onChange={cityChangeHandler} /> */}
                            <div>
                              <Autocomplete
                                apiKey={
                                  process.env.REACT_APP_GOOGLE_LOCATION_API
                                }
                                //  type={['geocode', 'cities', 'establishment','address']}
                                onPlaceSelected={(data) =>
                                  handlePlaceSelected(data)
                                }
                                className="form-control"
                                onChange={(data) => {
                                  console.log("hello auto", data.target.value);
                                  setAutocompleteValue(data.target.value);
                                }}
                                options={{
                                  types: ["geocode", "establishment"],
                                }}
                                defaultValue={selectedAddress?.city}
                                onBlur={addressBlurHandler}
                                //  types={['geocode','establishment',"store",
                                //  "restaurant",
                                //  "food",
                                //  "point_of_interest",
                                //  "establishment"]}
                              />

                              <div className="text-end">
                                <p className="text-bold">
                                  {" "}
                                  {selectedAddress?.lat &&
                                    `Lat : ${selectedAddress?.lat} Long : ${selectedAddress?.lng}`}
                                </p>
                              </div>
                              {isInputFocused.addressFocused &&
                                (selectedAddress.city === "" ||
                                selectedAddress.city === null ? (
                                  <span className="text-danger">
                                    Please enter Address
                                  </span>
                                ) : (
                                  ""
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <label className="input-label ps-3">
                        {t("address_line1")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.address_line_1.name}
                        {...register(texts?.input_texts?.address_line_1.value, {
                          required: texts?.input_texts?.address_line_1.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger">
                        {errors?.address_line_1?.message}
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <label className="input-label ps-3">
                        {t("address_line2")}
                      </label>
                      <span className="text-danger">*</span>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.address_line_2.name}
                        {...register(texts?.input_texts?.address_line_2.value, {
                          required: texts?.input_texts?.address_line_2.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger">
                        {errors?.address_line_2?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <label className="input-label ps-3">
                        {t("city")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.city.name}
                        {...register(texts?.input_texts?.city.value, {
                          required: texts?.input_texts?.city.error,
                          // validate: validateUsername,
                          validate: validateName,
                        })}
                      />
                      <span className="text-danger">
                        {errors?.city?.message}
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <label className="input-label ps-3">
                        {t("state")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.state.name}
                        {...register(texts?.input_texts?.state.value, {
                          required: texts?.input_texts?.state.error,
                          validate: validateName,
                        })}
                      />
                      <span className="text-danger">
                        {errors?.state?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row  mt-2">
                    <div className="col-lg-6 ">
                      <label className="input-label ps-3">
                        {t("zip_code")}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="number"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.zip_code?.name}
                        {...register(texts?.input_texts?.zip_code.value, {
                          // required: texts?.input_texts?.zip_code.error,
                          validate: validateZip,
                        })}
                      />
                      <span className="text-danger">
                        {errors?.zip_code?.message}
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <label className="input-label px-3">
                        {t("country")}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="position-relative">
                        <input
                          // {...register('physical_address_country')}
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          value={searchTerm2}
                          onChange={handleInputChange2}
                          // onClick={handleClickHandler2}
                          onFocus={handleInputFocus2}
                          onBlur={handleInputBlur2}
                        />
                        {isDropdownOpen2 && (
                          <ul
                            className={
                              isDropdownOpen2
                                ? "dropdown-menu countrydrop d-block w-100"
                                : "dropdown-menu"
                            }
                          >
                            {filteredCountries2.map((country, i) => (
                              <li
                                key={i}
                                onClick={() => handleCountrySelect2(country)}
                              >
                                {country.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {searchTerm2 === "" && isInputFocused.countryFocused && (
                        <span className="text-danger">Enter Country Name</span>
                      )}
                    </div>
                    {/* <div className="col-lg-6">
                      <label className="input-label ps-3">{texts?.input_texts?.country?.name} <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.country?.name}
                        {...register(texts?.input_texts?.country.value, {
                          required: texts?.input_texts?.country.error,
                          // validate: validateUsername, 
                        })}
                      />
                     
                    </div> */}
                  </div>
                  <div className="row  mt-2">
                    <div className="col-12">
                      <label className="input-label ps-3">
                        {t("Business_Description")}
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <textarea
                        className="form-control h-50"
                        style={{ height: "100px" }}
                        placeholder={
                          texts?.input_texts?.business_description?.name
                        }
                        rows="5"
                        {...register("business_description", {
                          // required: "Please enter Business Description",
                          // validate: validateUsername,
                        })}
                      ></textarea>
                      <span className="text-danger">
                        {errors?.business_description?.message}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end py-2 mb-1">
                  <button
                    className="btn bg-danger text-white me-1 rounded-3"
                    onClick={backButtonHandler}
                    type="button"
                  >
                    {t("close")}
                  </button>
                  <button
                    type="submit"
                    className={`btn btn-primary ${
                      isDirty || firstSubmitBtn ? "" : "disabled"
                    }`}
                    disabled={firstSubmitBtn || isDirty ? false : true}
                  >
                    {t("save")}
                  </button>
                </div>
              </form>
            </div>
          </TabPane>
          <TabPane tabId={"2"}>
            <div className="businessModalCanvas">
              <div className="my-3">
                <AppHeading title={t("business_model")} />
              </div>
              <form
                className="form-inline"
                onSubmit={handleSubmit1(businessModalSubmitHandler)}
              >
                <div>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Value_Proposition")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={
                          texts?.input_texts?.value_proposition?.name
                        }
                        {...register1(
                          texts?.input_texts?.value_proposition?.value,
                          {
                            // required: texts?.input_texts?.value_proposition?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger ">
                        {errors1?.value_proposition?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Customer_segments")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.customer_segment?.name}
                        {...register1(
                          texts?.input_texts?.customer_segment?.value,
                          {
                            // required: texts?.input_texts?.customer_segment?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger">
                        {errors1?.customer_segment?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Channels")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.channels?.name}
                        {...register1(texts?.input_texts?.channels?.value, {
                          // required: texts?.input_texts?.channels?.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger">
                        {errors1?.channels?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Customer_Relationship")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          texts?.input_texts?.customer_relationship?.name
                        }
                        {...register1(
                          texts?.input_texts?.customer_relationship?.value,
                          {
                            // required: texts?.input_texts?.customer_relationship?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger">
                        {errors1?.customer_relationship?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Revenue_Streams")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.revenue_stream?.name}
                        {...register1(
                          texts?.input_texts?.revenue_stream?.value,
                          {
                            // required: texts?.input_texts?.revenue_stream?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger">
                        {errors1?.revenue_stream?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Key_Resources")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.key_resources?.name}
                        {...register1(
                          texts?.input_texts?.key_resources?.value,
                          {
                            // required: texts?.input_texts?.key_resources?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger">
                        {errors1?.key_resources?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Key_Activities")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.key_activities?.name}
                        {...register1(
                          texts?.input_texts?.key_activities?.value,
                          {
                            // required: texts?.input_texts?.key_activities?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger">
                        {errors1?.key_activities?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Key_Partners")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.key_partners?.name}
                        {...register1(texts?.input_texts?.key_partners?.value, {
                          // required: texts?.input_texts?.key_partners?.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger">
                        {errors1?.key_partners?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">{t("Cost")} </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder={texts?.input_texts?.cost?.name}
                        {...register1(texts?.input_texts?.cost?.value, {
                          // required: texts?.input_texts?.cost?.error,
                          validate: validateNegative,
                        })}
                      />
                      <span className="text-danger">
                        {errors1?.cost?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Positive_Impacts")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.positive_impact?.name}
                        {...register1(
                          texts?.input_texts?.positive_impact?.value,
                          {
                            // required: texts?.input_texts?.positive_impact?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger">
                        {errors1?.positive_impact?.message}
                      </span>
                    </div>
                    <div className="col-lg-12 mb-4">
                      <label className="input-label ps-3">
                        {t("Negative_Impacts")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={texts?.input_texts?.negative_impact?.name}
                        {...register1(
                          texts?.input_texts?.negative_impact?.value,
                          {
                            // required: texts?.input_texts?.negative_impact?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger">
                        {errors1?.negative_impact?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end pt-2 ">
                  {/* <button className="btn btn-light  rounded-3">Cancel</button> */}
                  <button
                    className="btn bg-danger text-white rounded-3 me-1"
                    onClick={backButtonHandler}
                    type="button"
                  >
                    {t("close")}
                  </button>
                  <button
                    className="btn btn-light me-1"
                    type="button"
                    onClick={() => setTabValue("1")}
                  >
                    {t("back")}
                  </button>
                  <button
                    className="btn btn-light me-1"
                    type="button"
                    onClick={() => setTabValue("3")}
                  >
                    {t("next")}
                  </button>
                  <button
                    type="submit"
                    className={`btn btn-primary ${isDirty1 ? "" : "disabled"}`}
                    disabled={!isDirty1}
                  >
                    {t("save")}
                  </button>
                </div>
              </form>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div className="market-analysys">
              <div className="my-3 d-flex justify-content-between align-items-center">
                <AppHeading title={t("market_analysis")} />
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleAddRow(marketAnalysisData, setMarketAnalysisData, {
                      customer_segment: "",
                      product: "",
                      number_product: "",
                      price_per_unit: "",
                      total: "",
                      isEditing: true,
                      editMode: false,
                    })
                  }
                >
                  + Add Row
                </button>
              </div>
              <div></div>
              <div
                className="table-responsive p-0"
                style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="lh-base">
                        {t("Customer_segments")} <br />
                        {t("(Organisations_&_Individuals)")}
                      </th>
                      <th>{t("Product_/_Service")}</th>
                      <th className="lh-base text-end">
                        {t("Number_of_product/service")}
                      </th>
                      <th className="text-end">
                        {t("Price_per_unit/Service")} ($){" "}
                      </th>
                      <th className="text-end">Total ($)</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketAnalysisData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.isEditing ? (
                            <input
                              className="form-control"
                              type="text"
                              value={item.customer_segment}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "customer_segment",
                                  e.target.value,
                                  marketAnalysisData,
                                  setMarketAnalysisData
                                )
                              }
                            />
                          ) : (
                            item?.customer_segment
                          )}
                        </td>
                        <td>
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="text"
                              value={item.product}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "product",
                                  e.target.value,
                                  marketAnalysisData,
                                  setMarketAnalysisData
                                )
                              }
                            />
                          ) : (
                            item?.product
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.number_product}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "number_product",
                                  e.target.value,
                                  marketAnalysisData,
                                  setMarketAnalysisData
                                )
                              }
                            />
                          ) : (
                            item?.number_product
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.price_per_unit}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "price_per_unit",
                                  e.target.value,
                                  marketAnalysisData,
                                  setMarketAnalysisData
                                )
                              }
                            />
                          ) : (
                            item?.price_per_unit
                          )}
                        </td>
                        <td className="text-end">
                          {item?.price_per_unit * item?.number_product}
                        </td>
                        <td>
                          <button
                            className="btn bg-danger text-white fst-normal p-2"
                            onClick={() =>
                              handleDeleteRow(
                                item.id,
                                item.main_id,
                                `${apiUrl?.delete_market_analysis}?market_analysis_id=`,
                                marketAnalysisData,
                                setMarketAnalysisData
                              )
                            }
                          >
                            <span className="mdi mdi-delete"></span>
                            Delete
                          </button>
                          {item?.editMode === true && (
                            <button
                              className="btn bg-danger text-white fst-normal  p-2 ms-1"
                              onClick={() =>
                                handleToggleEdit(
                                  item.id,
                                  marketAnalysisData,
                                  setMarketAnalysisData
                                )
                              }
                            >
                              {t("edit")}
                            </button>
                          )}
                          {item?.editButton === true && (
                            <button
                              className="btn btn-primary  p-2 ms-1"
                              onClick={() => {
                                if (
                                  item?.number_product > 0 &&
                                  item?.price_per_unit > 0
                                ) {
                                  handleEditsave(
                                    item.id,
                                    apiUrl?.edit_market_analysis,
                                    setMarketAnalysisData,
                                    {
                                      market_analysis_id: item?.main_id,
                                      customer_segment: item?.customer_segment,
                                      product: item?.product,
                                      number_of_product: item?.number_product,
                                      price_per_unit: item?.price_per_unit,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Number of Products and coast per unit should be non-negative"
                                  );
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}

                          {item?.editMode === false && (
                            <button
                              className="btn btn-primary p-2 ms-1"
                              onClick={() => {
                                if (
                                  item?.number_product > 0 &&
                                  item?.price_per_unit > 0
                                ) {
                                  handleSaveRow(
                                    item?.id,
                                    setMarketAnalysisData,
                                    apiUrl?.add_market_analysis,
                                    {
                                      business_master_id: masterId,
                                      customer_segment: item?.customer_segment,
                                      product: item?.product,
                                      number_of_product: item?.number_product,
                                      price_per_unit: item?.price_per_unit,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Number of Products or cost per unit should be non-negative "
                                  );
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                              <td><input type="text" placeholder="Organizations" className="form-control" /></td>
                              <td><input type="text" placeholder="Chair" className="form-control" /></td>
                              <td><input type="text" placeholder="3" className="form-control" /></td>
                              <td><input type="text" placeholder="300" className="form-control" /></td>
                              <td class="text-end"><span>900</span> CDF</td>
                              <td><button className="btn bg-danger text-white fst-normal p-2"><span className="mdi mdi-delete" /> Delete</button></td>
                           
                                  </tr>
                            <tr>
                              <td><input type="text" placeholder="Organizations" className="form-control" /></td>
                              <td><input type="text" placeholder="Table" className="form-control" /></td>
                              <td><input type="text" placeholder="2" className="form-control" /></td>
                              <td><input type="text" placeholder="200" className="form-control" /></td>
                              <td class="text-end"><span>600</span> CDF</td>
                              <td><button className="btn bg-danger text-white fst-normal p-2"><span className="mdi mdi-delete" /> Delete</button></td>
                            </tr>
                            <tr>
                              <td><input type="text" placeholder="Indviduals" className="form-control" /></td>
                              <td><input type="text" placeholder="Box" className="form-control" /></td>
                              <td><input type="text" placeholder="1" className="form-control" /></td>
                              <td><input type="text" placeholder="100" className="form-control" /></td>
                              <td class="text-end"><span>100</span> CDF</td>
                                 <td><button className="btn btn-primary p-2"><span className="mdi mdi-plus" /> Add</button></td>
                        
                            </tr>

                            <tr>
                              <td className="fw-bold">Total sales (CDF/USD)</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="bg bg-secondary fw-bold text-end"><span>1600</span> CDF</td>
                            </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end pt-2 ">
                {/* <button className="btn btn-light  rounded-3">Cancel</button> */}
                <button
                  className="btn bg-danger text-white rounded-3 me-1"
                  onClick={backButtonHandler}
                  type="button"
                >
                  {t("close")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("2")}
                >
                  {t("back")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("4")}
                >
                  {t("next")}
                </button>
                {/* <button type="submit" className="btn btn-primary">
                            Save
                          </button> */}
              </div>
            </div>
          </TabPane>
          <TabPane tabId="4">
            <div className="productionProcess">
              <div className="my-3">
                <AppHeading title={t("production_Process")} />
              </div>
              <form
                className="form-inline"
                onSubmit={handleSubmit2(productSubmitHandler)}
              >
                <div>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Product_Creation_Process")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={
                          texts?.input_texts?.product_creation_process?.name
                        }
                        {...register2(
                          texts?.input_texts?.product_creation_process?.value,
                          {
                            // required: texts?.input_texts?.product_creation_process?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger ">
                        {errors2?.product_creation_process?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {texts?.input_texts?.achat?.name}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.achat?.name}
                        {...register2(texts?.input_texts?.achat?.value, {
                          // required: texts?.input_texts?.achat?.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger ">
                        {errors2?.achat?.error}
                      </span>
                    </div>

                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Input_Stock_Management")}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={
                          texts?.input_texts?.input_stock_management?.name
                        }
                        {...register2(
                          texts?.input_texts?.input_stock_management?.value,
                          {
                            // required: texts?.input_texts?.input_stock_management?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger ">
                        {errors2?.input_stock_management?.message}
                      </span>
                    </div>

                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {texts?.input_texts?.operation_stock_management?.name}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={
                          texts?.input_texts?.operation_stock_management?.name
                        }
                        {...register2(
                          texts?.input_texts?.operation_stock_management?.value,
                          {
                            // required: texts?.input_texts?.operation_stock_management?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger ">
                        {errors2?.operation_stock_management?.message}
                      </span>
                    </div>

                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Final_Product_Stock_Management")}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={
                          texts?.input_texts?.final_product_stock_management
                            ?.name
                        }
                        {...register2(
                          texts?.input_texts?.final_product_stock_management
                            ?.value,
                          {
                            // required: texts?.input_texts?.final_product_stock_management?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger ">
                        {errors2?.final_product_stock_management?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Communication_Sales")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={
                          texts?.input_texts?.communication_sales?.name
                        }
                        {...register2(
                          texts?.input_texts?.communication_sales?.value,
                          {
                            // required: texts?.input_texts?.communication_sales?.error,
                            // validate: validateUsername,
                          }
                        )}
                      />
                      <span className="text-danger ">
                        {errors2?.communication_sales?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">
                        {t("Distribution")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.distribution?.name}
                        {...register2(texts?.input_texts?.distribution?.value, {
                          // required: texts?.input_texts?.distribution?.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger ">
                        {errors2?.distribution?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="input-label ps-3">{t("other")}</label>
                      <input
                        type="text"
                        className="form-control mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder={texts?.input_texts?.other?.name}
                        {...register2(texts?.input_texts?.other?.value, {
                          // required: texts?.input_texts?.other?.error,
                          // validate: validateUsername,
                        })}
                      />
                      <span className="text-danger ">
                        {errors2?.other?.message}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end pt-5 ">
                  <button
                    className="btn bg-danger text-white rounded-3 me-1"
                    onClick={backButtonHandler}
                    type="button"
                  >
                    {t("close")}
                  </button>
                  <button
                    className="btn btn-light me-1"
                    type="button"
                    onClick={() => setTabValue("3")}
                  >
                    {t("back")}
                  </button>
                  <button
                    className="btn btn-light me-1"
                    type="button"
                    onClick={() => setTabValue("5")}
                  >
                    {t("next")}
                  </button>

                  <button
                    type="submit"
                    className={`btn btn-primary ${isDirty2 ? "" : "disabled"}`}
                    disabled={!isDirty2}
                  >
                    {t("save")}
                  </button>
                </div>
              </form>
            </div>
          </TabPane>
          <TabPane tabId="5">
            <div className="market-analysys">
              <div className="my-3 d-flex justify-content-between align-items-center">
                <AppHeading title="Material Resources" />
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleAddRow(materialResources, setMaterialResources, {
                      raw_material: "",
                      quantity: "",
                      unit_price: "",
                      amount: "",
                      isEditing: true,
                      main_id: "",
                      editMode: false,
                    })
                  }
                >
                  + Add Row
                </button>
              </div>
              <div></div>
              <div
                className="table-responsive p-0"
                style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="lh-base">{t("Raw_Material")}</th>
                      <th className="text-end">{t("Quantity")}</th>
                      <th className="lh-base text-end">{t("Unit_Price")}($)</th>
                      <th className="text-end">{t("Amount")}($)</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {materialResources.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.isEditing ? (
                            <input
                              className="form-control"
                              type="text"
                              value={item.raw_material}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "raw_material",
                                  e.target.value,
                                  materialResources,
                                  setMaterialResources
                                )
                              }
                            />
                          ) : (
                            item?.raw_material
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control "
                              type="number"
                              value={item.quantity}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "quantity",
                                  e.target.value,
                                  materialResources,
                                  setMaterialResources
                                )
                              }
                            />
                          ) : (
                            item?.quantity
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.unit_price}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "unit_price",
                                  e.target.value,
                                  materialResources,
                                  setMaterialResources
                                )
                              }
                            />
                          ) : (
                            item?.unit_price
                          )}
                        </td>

                        <td className="text-end">
                          {+item?.quantity * +item?.unit_price}
                        </td>
                        <td>
                          <button
                            className="btn bg-danger text-white fst-normal p-2"
                            onClick={() =>
                              handleDeleteRow(
                                item.id,
                                item.main_id,
                                apiUrl?.delete_material_resources,
                                materialResources,
                                setMaterialResources
                              )
                            }

                            // onClick={() => handleDeleteRow(item)}
                          >
                            <span className="mdi mdi-delete"></span>
                            Delete
                          </button>
                          {item?.editMode === true && (
                            <button
                              className="btn bg-danger text-white fst-normal  p-2 ms-1"
                              onClick={() =>
                                handleToggleEdit(
                                  item.id,
                                  materialResources,
                                  setMaterialResources
                                )
                              }
                            >
                              {t("edit")}
                            </button>
                          )}
                          {item?.editButton === true && (
                            <button
                              className="btn btn-primary fst-normal  p-2 ms-1"
                              onClick={() => {
                                if (
                                  item?.quantity > 0 &&
                                  item?.unit_price > 0
                                ) {
                                  handleEditsave(
                                    item?.id,
                                    apiUrl?.edit_material_resources,
                                    setMaterialResources,
                                    {
                                      material_resource_id: item?.main_id,
                                      raw_material: item?.raw_material,
                                      quantity: item?.quantity,
                                      price: item?.unit_price,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Quantity or Unit Price should be non-negative"
                                  );
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}

                          {item?.editMode === false && (
                            <button
                              className="btn btn-primary p-2 ms-1"
                              onClick={() => {
                                if (
                                  item?.quantity > 0 &&
                                  item?.unit_price > 0
                                ) {
                                  handleSaveRow(
                                    item?.id,
                                    setMaterialResources,
                                    apiUrl?.add_material_resources,
                                    {
                                      raw_material: item?.raw_material,
                                      business_master_id: masterId,
                                      quantity: item?.quantity,
                                      price: item?.unit_price,
                                      price: item?.unit_price,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Quantity or Unit Price should be non-negative"
                                  );
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end pt-2 ">
                {/* <button className="btn btn-light  rounded-3">Cancel</button> */}
                <button
                  className="btn bg-danger text-white rounded-3 me-1"
                  onClick={backButtonHandler}
                  type="button"
                >
                  {t("close")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("4")}
                >
                  {t("back")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("6")}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="6">
            <div className="market-analysys">
              <div className="my-3">
                <div className="my-3 d-flex justify-content-between align-items-center">
                  <AppHeading title={t("human_resources")} />
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleAddRow(
                        humanResourceData,
                        setHumanResourceData,

                        {
                          personnal: "",
                          number: "",
                          salary: "",
                          total: "",
                          isEditing: true,
                          main_id: "",
                          editMode: false,
                        }
                        // {raw_material:"", quantity: '', unit_price:'',amount:"",isEditing: true,main_id:"",editMode:false}
                      )
                    }
                  >
                    + Add Row
                  </button>
                </div>
              </div>
              <div
                className="table-responsive p-0"
                style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="lh-base">Personnel</th>
                      <th className="text-end">{t("num")}</th>
                      <th className="lh-base text-end">Salary (Monthly $)</th>
                      <th className="text-end">Total (Annual $)</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {humanResourceData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.isEditing ? (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Personnel"
                              value={item.personnal}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "personnal",
                                  e.target.value,
                                  humanResourceData,
                                  setHumanResourceData
                                )
                              }
                            />
                          ) : (
                            item?.personnal
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.number}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "number",
                                  e.target.value,
                                  humanResourceData,
                                  setHumanResourceData
                                )
                              }
                            />
                          ) : (
                            item?.number
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.salary}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "salary",
                                  e.target.value,
                                  humanResourceData,
                                  setHumanResourceData
                                )
                              }
                            />
                          ) : (
                            item?.salary
                          )}
                        </td>

                        <td className="text-end">
                          {+item?.number * +item?.salary}
                        </td>
                        <td>
                          <button
                            className="btn bg-danger text-white fst-normal p-2"
                            onClick={() =>
                              handleDeleteRow(
                                item.id,
                                item.main_id,
                                apiUrl?.delete_human_resources,
                                humanResourceData,
                                setHumanResourceData
                              )
                            }

                            // onClick={() => handleDeleteRow(item)}
                          >
                            <span className="mdi mdi-delete"></span>
                            Delete
                          </button>
                          {item?.editMode === true && (
                            <button
                              className="btn bg-danger text-white fst-normal  p-2 ms-1"
                              onClick={() =>
                                handleToggleEdit(
                                  item.id,
                                  humanResourceData,
                                  setHumanResourceData
                                )
                              }
                            >
                              {t("edit")}
                            </button>
                          )}
                          {item?.editButton === true && (
                            <button
                              className="btn btn-primary fst-normal  p-2 ms-1"
                              onClick={() => {
                                if (item?.number > 0 && item?.salary > 0) {
                                  handleEditsave(
                                    item?.id,
                                    apiUrl?.edit_human_resources,
                                    setHumanResourceData,
                                    {
                                      human_resource_id: item?.main_id,
                                      personnel: item?.personnal,
                                      number: item?.number,
                                      salary: item?.salary,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Number or Salary should be non-negative"
                                  );
                                }
                              }}
                            >
                              {" "}
                              {t("save")}
                            </button>
                          )}

                          {item?.editMode === false && (
                            <button
                              className="btn btn-primary p-2 ms-1"
                              onClick={() => {
                                if (item?.number > 0 && item?.salary > 0) {
                                  handleSaveRow(
                                    item?.id,
                                    setHumanResourceData,
                                    apiUrl?.add_human_resources,
                                    {
                                      business_master_id: masterId,
                                      personnel: item?.personnal,
                                      number: item?.number,
                                      salary: item?.salary,
                                      price: item?.unit_price,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Number or Salary should be non-negative"
                                  );
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end pt-2 ">
                {/* <button className="btn btn-light  rounded-3">Cancel</button> */}
                <button
                  className="btn bg-danger text-white rounded-3 me-1"
                  onClick={backButtonHandler}
                  type="button"
                >
                  {t("close")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("5")}
                >
                  {t("back")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("7")}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="7">
            <div className="market-analysys">
              <div className="my-3 d-flex justify-content-between align-items-center">
                <AppHeading title="Production Units" />
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleAddRow(productionUnitData, setProductionUnitData, {
                      output_unit: "",
                      quantity: "",
                      unit_variable_cost: "",
                      cost_per_unit: "",
                      isEditing: true,
                      main_id: "",
                      editMode: false,
                    })
                  }
                >
                  + Add Row
                </button>
              </div>
              <div
                className="table-responsive p-0"
                style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="lh-base">{t("Output_Units")}</th>
                      <th className="text-end">{t("Quantity")}</th>
                      <th className="lh-base text-end">
                        {t("Unit_Variable_Cost")}($)
                      </th>
                      <th className="text-end">Cost Per Unit($)</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productionUnitData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.isEditing ? (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Output Units"
                              value={item.output_unit}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "output_unit",
                                  e.target.value,
                                  productionUnitData,
                                  setProductionUnitData
                                )
                              }
                            />
                          ) : (
                            item?.output_unit
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.quantity}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "quantity",
                                  e.target.value,
                                  productionUnitData,
                                  setProductionUnitData
                                )
                              }
                            />
                          ) : (
                            item?.quantity
                          )}
                        </td>
                        <td className="text-end">
                          {+item?.quantity * +item?.cost_per_unit}
                        </td>

                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.cost_per_unit}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "cost_per_unit",
                                  e.target.value,
                                  productionUnitData,
                                  setProductionUnitData
                                )
                              }
                            />
                          ) : (
                            item?.cost_per_unit
                          )}
                        </td>
                        <td>
                          <button
                            className="btn bg-danger text-white fst-normal p-2"
                            onClick={() =>
                              handleDeleteRow(
                                item.id,
                                item.main_id,
                                apiUrl?.delete_production_unit,
                                productionUnitData,
                                setProductionUnitData
                              )
                            }
                          >
                            <span className="mdi mdi-delete"></span>
                            Delete
                          </button>
                          {item?.editMode === true && (
                            <button
                              className="btn bg-danger text-white fst-normal  p-2 ms-1"
                              onClick={() =>
                                handleToggleEdit(
                                  item.id,
                                  productionUnitData,
                                  setProductionUnitData
                                )
                              }
                            >
                              {t("edit")}
                            </button>
                          )}
                          {item?.editButton === true && (
                            <button
                              className="btn btn-primary fst-normal  p-2 ms-1"
                              onClick={() => {
                                if (
                                  item.cost_per_unit > 0 &&
                                  item.quantity > 0
                                ) {
                                  handleEditsave(
                                    item?.id,
                                    apiUrl?.edit_production_unit,
                                    setProductionUnitData,
                                    {
                                      production_unit_id: item?.main_id,
                                      cost_per_unit: item.cost_per_unit,
                                      output_units: item.output_unit,
                                      quantity: item.quantity,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Cost per unit or Quantity should be non-negative"
                                  );
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}

                          {item?.editMode === false && (
                            <button
                              className="btn btn-primary p-2 ms-1"
                              onClick={() => {
                                if (
                                  item.cost_per_unit > 0 &&
                                  item.quantity > 0
                                ) {
                                  handleSaveRow(
                                    item?.id,
                                    setProductionUnitData,
                                    apiUrl?.add_production_unit,
                                    {
                                      business_master_id: masterId,
                                      cost_per_unit: item.cost_per_unit,
                                      output_units: item.output_unit,
                                      quantity: item.quantity,
                                      price: item?.unit_price,
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Cost per unit or Quantity should be non-negative"
                                  );
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end pt-2 ">
                {/* <button className="btn btn-light  rounded-3">Cancel</button> */}
                <button
                  className="btn bg-danger text-white rounded-3 me-1"
                  onClick={backButtonHandler}
                  type="button"
                >
                  {t("close")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("6")}
                >
                  {t("back")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("8")}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="8">
            <div className="market-analysys">
              <div className="my-3 d-flex justify-content-between align-items-center">
                <AppHeading title="Fixed Cost Breakdown" />
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleAddRow(breakDownData, setBreakDownData, {
                      fixed_cost: "",
                      amount: "",
                      isEditing: true,
                      main_id: "",
                      editMode: false,
                    })
                  }
                >
                  + Add Row
                </button>
              </div>
              <div
                className="table-responsive p-0"
                style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {/* <th className="lh-base">
                              
                              </th> */}
                      <th>{t("Fixed_Cost")}</th>
                      <th className="lh-base text-end">{t("Amount")} ($)</th>
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {breakDownData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.isEditing ? (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Fixed Cost"
                              value={item.fixed_cost}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "fixed_cost",
                                  e.target.value,
                                  breakDownData,
                                  setBreakDownData
                                )
                              }
                            />
                          ) : (
                            item?.fixed_cost
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.amount}
                              placeholder="Amount"
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "amount",
                                  e.target.value,
                                  breakDownData,
                                  setBreakDownData
                                )
                              }
                            />
                          ) : (
                            item?.amount
                          )}
                        </td>
                        <td>
                          <button
                            className="btn bg-danger text-white fst-normal p-2"
                            onClick={() =>
                              handleDeleteRow(
                                item.id,
                                item.main_id,
                                apiUrl?.delete_fixed_cost,
                                breakDownData,
                                setBreakDownData
                              )
                            }
                          >
                            <span className="mdi mdi-delete"></span>
                            Delete
                          </button>
                          {item?.editMode === true && (
                            <button
                              className="btn bg-danger text-white fst-normal  p-2 ms-1"
                              onClick={() =>
                                handleToggleEdit(
                                  item.id,
                                  breakDownData,
                                  setBreakDownData
                                )
                              }
                            >
                              {t("edit")}
                            </button>
                          )}
                          {item?.editButton === true && (
                            <button
                              className="btn btn-primary fst-normal  p-2 ms-1"
                              onClick={() => {
                                if (item?.amount > 0) {
                                  handleEditsave(
                                    item?.id,
                                    apiUrl?.edit_fixed_cost,
                                    setBreakDownData,
                                    {
                                      fixed_cost_id: item?.main_id,
                                      fixed_cost: item?.fixed_cost,
                                      amount: item?.amount,
                                    }
                                  );
                                } else {
                                  toast.error("Amount should be non-negative");
                                }
                              }}
                            >
                              {" "}
                              {t("save")}
                            </button>
                          )}

                          {item?.editMode === false && (
                            <button
                              className="btn btn-primary p-2 ms-1"
                              onClick={() => {
                                if (item?.amount > 0) {
                                  handleSaveRow(
                                    item?.id,
                                    setBreakDownData,
                                    apiUrl?.add_fixed_cost,
                                    {
                                      business_master_id: masterId,
                                      fixed_cost: item?.fixed_cost,
                                      amount: item?.amount,
                                    }
                                  );
                                } else {
                                  toast.error("Amount should be non-negative");
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end pt-2 ">
                {/* <button className="btn btn-light  rounded-3">Cancel</button> */}
                <button
                  className="btn bg-danger text-white rounded-3 me-1"
                  onClick={backButtonHandler}
                  type="button"
                >
                  {t("close")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("7")}
                >
                  {t("back")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("9")}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="9">
            <div className="market-analysys">
              <div className="my-3 d-flex justify-content-between align-items-center">
                <AppHeading title="Variable Cost Breakdown" />
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleAddRow(variableCostData, setVariableCostData, {
                      variable_cost: "",
                      amount: "",
                      isEditing: true,
                      main_id: "",
                      editMode: false,
                    })
                  }
                >
                  + Add Row
                </button>
              </div>
              <div
                className="table-responsive p-0"
                style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>{t("Variable_Cost")}</th>
                      <th className="lh-base text-end">{t("Amount")} ($)</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variableCostData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.isEditing ? (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Variable Cost"
                              value={item.variable_cost}
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "variable_cost",
                                  e.target.value,
                                  variableCostData,
                                  setVariableCostData
                                )
                              }
                            />
                          ) : (
                            item?.variable_cost
                          )}
                        </td>
                        <td className="text-end">
                          {item?.isEditing ? (
                            <input
                              className="form-control"
                              type="number"
                              value={item.amount}
                              placeholder="Amount"
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "amount",
                                  e.target.value,
                                  variableCostData,
                                  setVariableCostData
                                )
                              }
                            />
                          ) : (
                            item?.amount
                          )}
                        </td>
                        <td>
                          <button
                            className="btn bg-danger text-white fst-normal p-2"
                            onClick={() =>
                              handleDeleteRow(
                                item.id,
                                item.main_id,
                                apiUrl?.delete_variable_cost,
                                variableCostData,
                                setVariableCostData
                              )
                            }
                          >
                            <span className="mdi mdi-delete"></span>
                            Delete
                          </button>
                          {item?.editMode === true && (
                            <button
                              className="btn bg-danger text-white fst-normal  p-2 ms-1"
                              onClick={() =>
                                handleToggleEdit(
                                  item.id,
                                  variableCostData,
                                  setVariableCostData
                                )
                              }
                            >
                              {t("edit")}
                            </button>
                          )}
                          {item?.editButton === true && (
                            <button
                              className="btn btn-primary p-2 ms-1"
                              onClick={() => {
                                if (item?.amount > 0) {
                                  handleEditsave(
                                    item?.id,
                                    apiUrl?.edit_variable_cost,
                                    setVariableCostData,
                                    {
                                      variable_cost_id: item?.main_id,
                                      variable_cost: item?.variable_cost,
                                      amount: item?.amount,
                                    }
                                  );
                                } else {
                                  toast.error("Amount should be non-negative");
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}

                          {item?.editMode === false && (
                            <button
                              className="btn btn-primary p-2 ms-1"
                              onClick={() => {
                                if (item?.amount > 0) {
                                  handleSaveRow(
                                    item?.id,
                                    setVariableCostData,
                                    apiUrl?.add_variable_cost,
                                    {
                                      business_master_id: masterId,
                                      variable_cost: item?.variable_cost,
                                      amount: item?.amount,
                                    }
                                  );
                                } else {
                                  toast.error("Amount should be non-negative");
                                }
                              }}
                            >
                              {t("save")}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end pt-2 ">
                {/* <button className="btn btn-light  rounded-3">Cancel</button> */}
                <button
                  className="btn bg-danger text-white rounded-3 me-1"
                  onClick={backButtonHandler}
                  type="button"
                >
                  {t("close")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("8")}
                >
                  {t("back")}
                </button>
                <button
                  className="btn btn-light me-1"
                  type="button"
                  onClick={() => setTabValue("10")}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="10">
            <div className="market-analysys">
              <div className="my-3 d-flex justify-content-between align-items-center">
                <AppHeading title="Breakeven Point" />
                <div>
                  {marginData?.marginEdit === true && (
                    <button
                      className="btn bg-danger text-white fst-normal  p-2 ms-1"
                      onClick={() => marginEditHandler("edit")}
                    >
                      Edit Margin
                    </button>
                  )}
                </div>
              </div>
              {marginData?.marginEdit === false && (
                <div className="text-end d-flex align-items-end py-2">
                  <div className="text-start col-lg-4 mx-2">
                    <label>Margin in %:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={marginData?.marginPercent}
                      onChange={(e) => marginChangeHandler(e, "percent")}
                    />
                  </div>
                  <div className="text-start col-lg-4">
                    <label>Margin in value:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={marginData?.marginValue}
                      onChange={(e) => marginChangeHandler(e, "amount")}
                    />
                  </div>
                  <div className="col-lg-3 ms-5">
                    {
                      <button
                        onClick={marginSaveHandler}
                        className="btn btn-primary fst-normal  p-2 ms-1"
                      >
                        {" "}
                        {t("save")}
                      </button>
                    }
                    {
                      <button
                        onClick={() => marginEditHandler("cancel")}
                        className="btn bg-danger text-white fst-normal  p-2 ms-1"
                      >
                        {" "}
                        {t("cancel")}
                      </button>
                    }
                  </div>
                </div>
              )}
              <div
                className="table-responsive p-0"
                style={{ border: "1px solid #EFEFEF", borderRadius: "20px" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="lh-base">
                        {t("Breakeven_Point_Calculation")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Total_fixed_cost")}</td>
                      <td className="text-end">
                        ${" "}
                        {calculationData?.total_fixed_cost &&
                          parseFloat(calculationData?.total_fixed_cost).toFixed(
                            2
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Variabe_cost_per_unit")}</td>
                      <td className="text-end">
                        ${" "}
                        {calculationData?.cost_per_unit &&
                          parseFloat(calculationData?.cost_per_unit).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Breakeven_point(quantity)")}</td>
                      <td className="text-end">
                        {" "}
                        {calculationData?.breakeven_point
                          ? parseFloat(
                              calculationData?.breakeven_point
                            )?.toFixed(2)
                          : "0"}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Margin_per_unit_sold")}</td>
                      <td className="text-end">
                        {/* {marginData?.marginPercent && <span>Percentage: {marginData?.marginPercent}%</span>} */}
                        {marginData?.marginValue && (
                          <span>
                            {" "}
                            ${" "}
                            {marginData?.marginValue &&
                              parseFloat(marginData?.marginValue)?.toFixed(2)}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Margin_%_per_unit_sold")}</td>
                      <td className="text-end">
                        {marginData?.marginPercent && (
                          <span>
                            {" "}
                            {marginData?.marginPercent &&
                              parseFloat(marginData?.marginPercent).toFixed(2)}
                            %
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Unit_selling_price")}</td>
                      <td className="text-end">
                        ${" "}
                        {calculationData?.selling_price &&
                          parseFloat(calculationData?.selling_price).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="btn bg-danger text-white  me-1 rounded-3"
                  onClick={backButtonHandler}
                  type="button"
                >
                  {t("close")}
                </button>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => setTabValue("9")}
                >
                  {t("back")}
                </button>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
      {isLoading && <PageLoader />}
    </FormLayout>
  );
};

export default BusinessPlanForm;
