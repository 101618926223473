import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../Pages/Auth/Login";
import Dashboard from "../Pages/Admin/Dasboard";
import CoachList from "../Pages/Admin/Coach/CoachList";
import BeneficiaryList from "../Pages/Admin/Beneficiary/BeneficiaryList";
// import CoachPlan from "../Pages/Admin/Coach/CoachPlan";
import AddBeneficiary from "../Pages/Admin/Beneficiary/AddBeneficiary";
import AddEquipment from "../Pages/Admin/Equipments/AddEquipments";
import Equipments from "../Pages/Admin/Equipments/Equipments";
import Suppliers from "../Pages/Admin/Supplier/Supplier";
import Cooperatives from "../Pages/Admin/Cooperatives/Cooperatives";
import Subventions from "../Pages/Admin/Subventions/Subventions";
import Coaching from "../Pages/Admin/Coach/Coaching";
import AddUserProfile from "../Pages/Admin/UserProfile/AddUserProfile";
import AddUserAccount from "../Pages/Admin/UserProfile/AddUserAccount";
import { RoutesLink } from "../ApiHelper/RoutesLink";
import AddCoachPlan from "../Pages/Admin/Coach/AddCoachPlan";
import AddSubventions from "../Pages/Admin/Subventions/AddSubventions";
import UploadData from "../Components/CommonElements/UploadData";
import UserAccount from "../Pages/Admin/UserProfile/UserAccount";
import ResetUserAccount from "../Pages/Admin/UserProfile/ResetUserAccount";
import BeneficiaryProfile from "../Pages/Admin/Beneficiary/BeneficiaryProfile";
import AssignBeneficiary from "../Pages/Admin/Beneficiary/AssignBeneficiary";
import SubventionDetail from "../Pages/Admin/Subventions/SubventionDetail";
import CoachProfilePage from "../Pages/Admin/Coach/CoachProfilePage";
import ProtectedLoginRoute from "../Pages/Auth/ProtectedLoginRoute";
import ProtectedRoute from "../Pages/Auth/ProtectedRoute";
import Notification from "../Pages/Notification/Notificaition";
import Settings from "../Pages/Admin/Settings/Settings";
import BusinessPlanForm from "../Pages/Admin/BusinessPlan/BusinessPlanForm";
import ChangePassword from "../Pages/Auth/ChangePassword";
import CoachPlanList from "../Pages/Admin/Coach/CoachPlanList";
import CoachPlanDetail from "../Pages/Admin/Coach/CoachPlanDetail";
import BeneficiarySessionList from "../Pages/Admin/Coach/BeneficiarySessionList";
import AddCooperatives from "../Pages/Admin/Cooperatives/AddCooperatives";
import CooperativeDetail from "../Pages/Admin/Cooperatives/CooperativeDetail";
import SessionDetails from "../Pages/Admin/Coach/SessionDetails";
import AddSupplier from "../Pages/Admin/Supplier/AddSupplier";
import SupplierDetail from "../Pages/Admin/Supplier/SupplierDetail";
import AssignEquipments from "../Pages/Admin/Equipments/AssignEquipments";
import ScrollToTop from "../ApiHelper/ScrollToTop";

const CommonRoutes = () => {
  return (
    <BrowserRouter
    // basename={process.env.PUBLIC_URL}
    >
      <ScrollToTop />
      <Routes>
        <Route element={<ProtectedLoginRoute />}>
          <Route path={RoutesLink.login} element={<Login />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path={RoutesLink.dashboard} element={<Dashboard />} />
          <Route path={RoutesLink.coach_list} element={<CoachList />} />
          <Route
            path={RoutesLink.beneficiary_list}
            element={<BeneficiaryList />}
          />

          {/* <Route path={RoutesLink.coach_plan} element={<CoachPlan />} /> */}
          <Route path={RoutesLink.coach_plan_detail} element={<CoachPlanDetail />} />
          <Route path={RoutesLink.coach_plan_beneficiaries} element={<BeneficiarySessionList />} />

          <Route path={RoutesLink.add_beneficiary} element={<AddBeneficiary />} />
          <Route path={RoutesLink.add_equipment} element={<AddEquipment />} />
          <Route path={RoutesLink.equipments} element={<Equipments />} />
          <Route path={RoutesLink.suppliers} element={<Suppliers />} />
          <Route path={RoutesLink.add_suppliers} element={<AddSupplier />} />
          <Route path={RoutesLink.suppliers_detail} element={<SupplierDetail />} />
          <Route path={RoutesLink.assign_equipments} element={<AssignEquipments />} />
          <Route path={RoutesLink.cooperatives} element={<Cooperatives />} />
          <Route path={RoutesLink.add_cooperatives} element={<AddCooperatives />} />
          <Route path={RoutesLink.cooperative_detail} element={<CooperativeDetail />} />
          <Route path={RoutesLink.subventions} element={<Subventions />} />
          <Route path={RoutesLink.coaching} element={<Coaching />} />

          <Route
            path={RoutesLink.add_user_profile}
            element={<AddUserProfile />}
          />
          <Route
            path={RoutesLink.coach_plan_session_details}
            element={<SessionDetails />}
          />
          <Route
            path={RoutesLink.add_user_account}
            element={<AddUserAccount />}
          />
          <Route path={RoutesLink.add_coach_plan} element={<AddCoachPlan />} />
          <Route path={RoutesLink.coach_plan_list} element={<CoachPlanList />} />
          <Route path={RoutesLink.add_subvention} element={<AddSubventions />} />
          <Route path={RoutesLink.upload_data} element={<UploadData />} />
          <Route path={RoutesLink.user_account} element={<UserAccount />} />
          <Route
            path={RoutesLink.reset_user_account}
            element={<ResetUserAccount />}
          />
          <Route path={RoutesLink.dashboard} element={<Dashboard />} />
          <Route path={RoutesLink.coach_list} element={<CoachList />} />
          <Route
            path={RoutesLink.beneficiary_list}
            element={<BeneficiaryList />}
          />
          {/* <Route path={RoutesLink.add_coach} element={<AddCoach />} /> */}
          {/* <Route path={RoutesLink.add_coach} element={<AddCoach />} /> */}
          {/* <Route path={RoutesLink.coach_plan} element={<CoachPlan />} /> */}
          <Route path={RoutesLink.add_beneficiary} element={<AddBeneficiary />} />
          <Route path={RoutesLink.add_equipment} element={<AddEquipment />} />
          <Route path={RoutesLink.equipments} element={<Equipments />} />
          <Route path={RoutesLink.suppliers} element={<Suppliers />} />
          <Route path={RoutesLink.cooperatives} element={<Cooperatives />} />
          <Route path={RoutesLink.subventions} element={<Subventions />} />
          <Route path={RoutesLink.coaching} element={<Coaching />} />
          {/* <Route path={RoutesLink.reset_password} element={<ResetPassword />} /> */}
          <Route path={RoutesLink.change_password} element={<ChangePassword />} />
          <Route
            path={RoutesLink.add_user_profile}
            element={<AddUserProfile />}
          />
          <Route
            path={RoutesLink.add_user_account}
            element={<AddUserAccount />}
          />
          <Route path={RoutesLink.add_coach_plan} element={<AddCoachPlan />} />
          <Route path={RoutesLink.add_subvention} element={<AddSubventions />} />
          <Route path={RoutesLink.upload_data} element={<UploadData />} />
          <Route path={RoutesLink.user_account} element={<UserAccount />} />
          <Route
            path={RoutesLink.beneficiary_profile}
            element={<BeneficiaryProfile />}
          />

          <Route
            path={RoutesLink.assign_beneficiary}
            element={<AssignBeneficiary />}
          />
          <Route
            path={RoutesLink.subvention_detail}
            element={<SubventionDetail />}
          />
          <Route path={RoutesLink?.coach_profile} element={<CoachProfilePage />} />
          <Route path={RoutesLink?.notification} element={<Notification />} />
          <Route path={RoutesLink?.settings} element={<Settings />} />
          <Route path={RoutesLink?.business_plan_form} element={<BusinessPlanForm />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default CommonRoutes;
