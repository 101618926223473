const ProfileCard = ({ initials, fullName, profileImage }) => {
  const initialsName = fullName
    ?.split(" ")
    ?.slice(0, 2) // Take only the first two words
    ?.map((word) => word[0])
    ?.join("")
    ?.toUpperCase();
  return (
    <div className="bg-white user-contianer d-flex flex-column justify-content-center align-items-center shadow-sm">
      {profileImage ? (
        <img className="" alt="img" src={profileImage} />
      ) : (
        <div
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            border: "4px solid #6247aa",
            height: "80px",
            width: "80px",
          }}
        >
          <span className="fs-2">{initialsName}</span>
        </div>
      )}
      {/* <img className="" src="assets/image/Base.png" /> */}
      <h4 className="mb-0 ">{fullName}</h4>
      {/* <span className="username">@Username</span> */}
    </div>
  );
};
export default ProfileCard;
