import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { loadState } from "../utility/LocalStorage";
import reducer from "./reducers/Reducers";
const persistedState = loadState();

const rootReducer = {
  user: reducer,
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: [thunk],
});

export default store;