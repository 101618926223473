import { Link } from "react-router-dom";

const ActionDropDown2 = ({ addArray }) => {
  // console.log(addArray);
  return (
    <>
      {addArray?.map((data) =>
        <>
          {
            data?.type !== "function" && <div className="dropdown-item preview-item">
              <Link className="preview-item-content w-100 rounded-3" to={data?.path} state={{ type: data?.state }}>
                <p className="text-muted px-2 my-1">{data.name}</p>
              </Link>
            </div>
          }
          {data?.type === "function" && <div className="dropdown-item preview-item">
            <div className="preview-item-content w-100 rounded-3" >
              <p className="text-muted px-2 my-1" onClick={data?.function}>{data.name}</p></div>
          </div>}
        </>
      )}
    </>
  )
}
export default ActionDropDown2;
