import React from 'react'

const ButtonLoader = () => {

  return (
    <>
      <div className="spinner-border z-1 spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </>
  )
}

export default ButtonLoader;
