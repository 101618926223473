import { NavItem, TabContent, TabPane } from "reactstrap";
import Layout from "../../../Components/Layout/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrimaryTable from "../../../Components/Tables/PrimaryTable";
import { useEffect, useState } from "react";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
// import AppHeading from "../../../Components/CommonElements/Headings";
import Heading from "../../../Components/CommonElements/Heading";
import AssignBeneficiaryCard from "../../../Components/CommonElements/AssignBeneficiaryCard";
import { toast } from "react-toastify";
import ProfileHeader from "../../../Components/CommonElements/ProfileHeader";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import Cookies from "js-cookie";
// import { Roles } from "../../../ApiHelper/Nomanclatures";
import Roles from "../../../ApiHelper/Roles.json";
import { useDispatch } from "react-redux";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import RoleType from "../../../ApiHelper/Roles.json";
import {
  convertToEpoch,
  TimeStampConverter,
  ConvertInputTimeToSetting,
} from "../../../ApiHelper/TimeStampConverter";
import Swal from "sweetalert2";
import moment from "moment";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useTranslation } from "react-i18next";

const CoachProfilePage = () => {
  let currentDate = moment();
  moment.locale("en-GB");
  let localize = momentLocalizer(moment);

  const allViews = Object.keys(Views).map((k) => Views[k]);
  const { t } = useTranslation();
  const [coachList, setCoachList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputSessionTypeData, setInputSessionTypeData] = useState("select");
  const [inputCategoryData, setInputCategoryData] = useState("");
  const [inputSessionCategoryData, setInputSessionCategoryData] = useState("");
  let role = Cookies.get("emp_role");
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [filterData, setFilterData] = useState();
  const [sessionFilterData, setSessionFilterData] = useState();
  const [searchOptionData, setSearchOptionData] = useState("global_search");
  const [searchSessionOptionData, setSearchSessionOptionData] = useState();
  const [inputTypeData, setInputTypeData] = useState("text");
  const [isLoading, setIsLoading] = useState(false);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const roleType = Cookies.get("emp_role");
  const [coachPlanBeneficiaryShow, setCoachPlanBeneficiaryShow] =
    useState(false);
  const [coachMonitoringSessionList, setCoachMonitoringSessionList] = useState(
    []
  );
  const [eventData, setEventData] = useState("");
  const sessionListHeading = [
    { data: "Coaching Plan ID", classType: "" },
    { data: "Beneficiary Name", classType: "" },
    { data: "Coach Username", classType: "" },
    { data: "Coaching Scedule Start Time (DD/MM/YYYY)", classType: "" },
    { data: "Coaching Plan Start Time (DD/MM/YYYY)", classType: "" },
    { data: "Coaching Plan End time (DD/MM/YYYY)", classType: "" },
    { data: "Status", classType: "" },
    { data: "Action", classType: "" },
  ];

  const addCoachPlanData = [
    { name: "Add Coaching Plan", path: RoutesLink.add_coach_plan },
    // {name:"Edit User Profile",path:RoutesLink?.add_user_profile},
    // { name: "Reset Password", path: RoutesLink.reset_user_account}
  ];

  const searchOptionStatusArray = [
    // { name: "Status", value: "", type: "status" },
    { name: "Active", value: 1, type: "status" },
    { name: "Inactive", value: 0, type: "status" },
    { name: "Allowed", value: 1, type: "grant_eligibility" },
    { name: "Not Allowed", value: 0, type: "grant_eligibility" },
    { name: "Unassigned", value: 2, type: "grant_eligibility" },
    { name: "Unassigned", value: 0, type: "assignment" },
    { name: "Assigned", value: 1, type: "assignment" },
    { name: "Agri Business", value: "agribusiness", type: "sector" },
    { name: "Manufacturing", value: "manufacturing", type: "sector" },
    { name: "Services", value: "services", type: "sector" },
  ];

  const searchOptionSessionStatusArray = [
    { name: "In Progress", value: "in progress", type: "status" },
    { name: "Completed", value: "completed", type: "status" },
    { name: "Pending", value: "pending", type: "status" },
    { name: "No Session", value: "no session", type: "status" },
    { name: "Yes", value: 1, type: "assign" },
    { name: "No", value: 0, type: "assign" },
  ];

  const searchOption1Array = [
    { name: "Search By", value: "global_search", type: "text" },
    { name: "Reference ID", value: "reference_id", type: "text" },
    { name: "Name", value: "fullname", type: "text" },
    { name: "Contact Detail", value: "telephone_pref", type: "text" },
    { name: "Province", value: "province", type: "text" },
    { name: "Commune", value: "commune", type: "text" },
    { name: "Quartier", value: "quartier", type: "text" },
    { name: "Grant Status", value: "grant_eligibility", type: "select" },
    { name: "Status", value: "status", type: "select" },
    { name: "Sector", value: "sector", type: "select" },
  ];

  const searchOption1SessionArray = [
    { name: "Search By", value: "", type: "select" },
    { name: "Status", value: "status", type: "select" },
    // { name: "Assign Beneficiary", value: "assign", type: "select" },
    // { name: "Beneficiary Alias ID", value: "beneficiary_aliasId", type: "text" },
    // { name: "Coach Alias ID", value: "coach_aliasId", type: "text" },
    // { name: "Session Time", value: "session_time", type: "date" }
    // { name: " Coaching plan ID", value: "plan_aliasId", type: "text" },
    { name: "Beneficiary Name", value: "beneficiary_name", type: "text" },
    { name: " Coach username", value: "coach_username", type: "text" },
  ];

  const searchOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchOptionData
  );

  const searchSessionOption2Array = searchOptionSessionStatusArray.filter(
    (data) => data?.type === searchSessionOptionData
  );

  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [beneficiarySessionList, setBeneficiarySessionList] = useState([]);
  const [tabOpen, setTabOpen] = useState(location?.state?.tabpane || "1");
  // const tableHeadingArray = ["id", "name"];
  const [callApi, setCallApi] = useState(true);
  const [pages, setPages] = useState();
  const [coachPlanPages, setCoachPlanPages] = useState();
  const [sessionPages, setSessionPages] = useState();
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  // const prevMonthDate = moment(currentDate).subtract(1, "month");
  // const prevMonthConvertedDate = prevMonthDate.startOf("day");
  // const newEndDate = currentDate.endOf("day");
  // const [startDate, setDate] = useState({
  //   from: prevMonthConvertedDate.toDate(),
  //   to: newEndDate?.toDate(),
  // });

  const firstDayOfMonth = moment(currentDate).startOf("month");
  const lastDayOfMonth = moment(currentDate).endOf("month");

  const [startDate, setDate] = useState({
    from: firstDayOfMonth.toDate(),
    to: lastDayOfMonth.toDate(),
  });
  console.log(location, "xxxxxxxxxxxxxxxxxxxx");

  const DeleteCoachPlan = (data) => {
    setIsLoading(true);
    console.log("dataaaaaa...", data);
    handleFetchRequest(
      `${apiUrl?.delete_coach_plan}?coach_planId=${data?.id}`,
      "DELETE"
    ).then((response) => {
      if (response?.status === true) {
        setIsLoading(false);
        toast.success(response.message);
        getCoachPlanList(1);
      } else if (response?.messageCode === 99) {
        setIsLoading(false);
        Swal.fire({
          text: "One or more session exists in this plan are you sure you want to delete this coaching plan?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            handleFetchRequest(
              `${apiUrl?.delete_coach_plan}?coach_planId=${data?.id}&force_delete=true`,
              "DELETE"
            ).then((response) => {
              if (response?.status === true) {
                getCoachPlanList(1);
                setIsLoading(false);
                toast.success(response?.message);
                // const newData = stateVariable.filter((item) => item.id !== rowId);
                // stateVariableFunction(newData);
              }
            });
          }
        });
      }
    });
  };

  let addHeadingAction;
  if (RoleType.coach_value === role || RoleType.viewer_value === role) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.coach_plan_detail },
      // { name: "Edit", path: RoutesLink?.add_coach_plan },
      // { name: "Delete", type: "function", path: DeleteCoachPlan, value: "always" }
      // { name: "Assign Beneficiary", path: RoutesLink?.assign_beneficiary, type: "assign" },
      // { name: "Unassign Beneficiary", path: RoutesLink?.assign_beneficiary, type: "unassign" }
    ];
  } else if (RoleType.admin_value === role) {
    addHeadingAction = [
      { name: "View", path: RoutesLink?.coach_plan_detail },
      // { name: "Edit", path: RoutesLink?.add_coach_plan },
      {
        name: "Delete",
        type: "function",
        path: DeleteCoachPlan,
        value: "always",
      },
      // { name: "Assign Beneficiary", path: RoutesLink?.assign_beneficiary, type: "assign" },
      // { name: "Unassign Beneficiary", path: RoutesLink?.assign_beneficiary, type: "unassign" }
    ];
  }

  const listHeading = [
    { data: `${t("reference_id")}` },
    { data: `${t("full_name")}` },
    { data: `${t("contact_detail")}` },
    { data: `${t("province")}` },
    { data: `${t("grant_status")}` },
    { data: `${t("status")}` },
  ];

  const [assignedBeneficiaryList, setAssignedBeneficiaryList] = useState([]);
  const [coachId, setCoachId] = useState("");

  const [searchCooperativeOptionData, setSearchOptionCooperativeData] =
    useState();
  const [inputCooperativeCategoryData, setInputCooperativeCategoryData] =
    useState("");
  const [inputCooperativeTypeData, setInputCooperativeTypeData] =
    useState("select");
  const [filterCooperativeData, setFilterCooperativeData] = useState();
  const [currentCooperativePage, setCurrentCooperativePage] = useState(1);
  const [CooperativePages, setCooperativePages] = useState();
  const [CooperativeList, setCooperativeList] = useState([]);
  const cooperativelistHeading = [
    { data: "Alias Id", classType: "" },
    { data: "Cooperative Name", classType: "" },
    { data: "Business Type", classType: "" },
    { data: "Sector Activity", classType: "" },
    { data: "Sales", classType: "" },
    { data: "CreatedAts", classType: "" },
    { data: "Status", classType: "" },
  ];

  const deleteHandler = () => {
    // setShowDeleteBox(true);
    navigate(RoutesLink.assign_beneficiary, {
      state: { id: coachId, type: "unassign", param: "profile" },
    });
  };

  const handlePageChange = (pageitem) => {
    setActivePage(pageitem);
  };

  const AssignCheckboxBeneficiaries = (e, data) => {
    if (e.target.checked === true) {
      setAssignedBeneficiaryList((prevData) => [...prevData, data]);
    } else {
      let new_beneficiarierList = assignedBeneficiaryList.filter(
        (item) => item?.id !== data?.id
      );

      setAssignedBeneficiaryList(new_beneficiarierList);
    }
  };

  const assignAllBeneficiaryHandler = (e) => {
    if (e.target.checked === true) {
      const all_assigned_list = [...beneficiaryList];
      setAssignedBeneficiaryList(all_assigned_list);
    } else {
      setAssignedBeneficiaryList([]);
    }
  };

  const setTabValue = (data) => {
    setTabOpen(data);
    console.log(tabOpen);
  };

  const ResetAccountHandler = () => {
    setIsLoading(true);
    setActivePage(1);
    setSearchOptionData("global_search");
    setFilterData("");
    setBeneficiaryList([]);
    setInputCategoryData("###select");
    if (RoleType.coach_value === role) {
      handleFetchRequest(
        `${apiUrl?.get_assigned_beneficiary}?page_no=1&page_size=10`
      ).then((response) => {
        if (response?.status === true) {
          setBeneficiaryList(response?.data);
          setCallApi(false);
          setIsLoading(false);
          setPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        }
      });
    } else if (
      RoleType.admin_value === role ||
      RoleType.viewer_value === role
    ) {
      handleFetchRequest(
        `${apiUrl?.get_assigned_beneficiary}?coach_id=${location?.state?.id}&page_no=1&page_size=10`
      ).then((response) => {
        if (response?.status === true) {
          setBeneficiaryList(response?.data);
          setCallApi(false);
          setIsLoading(false);
          setPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
        }
      });
    }

    // handleFetchRequest(`${apiUrl.beneficiary_list}?page_no=1&page_size=10`).then((response) => {
    //   setPages(response?.pageInfo);
    //   setBeneficiaryList(response.data);
    //   setIsLoading(false);
    // })
  };

  const ResetSessionAccountHandler = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setSearchOptionData("");
    setFilterData("");
    setCoachList([]);
    setInputCategoryData("###select");
    handleFetchRequest(`${apiUrl.get_coach_plan}?page_no=1&page_size=10`).then(
      (response) => {
        if (response?.status === true) {
          // setCallApi(false);
          setIsLoading(false);
          setCoachList(response.data);
          setCoachPlanPages(response?.pageInfo);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          // setCallApi(false);
          console.log(response);
          setIsLoading(false);
          toast.error(response.message);
        }
      }
    );
  };

  const getBeneficiarySessionList = (pageItem) => {
    setIsLoading(true);
    handleFetchRequest(
      `${apiUrl?.get_assigned_beneficiary}?coach_id=${
        location?.state?.id
      }&page_no=${pageItem}&page_size=10&grant_eligibility=1${filters.join(
        "&"
      )}`
    ).then((response) => {
      if (response?.status === true) {
        setBeneficiarySessionList(response?.data);
        setCallApi(false);
        setIsLoading(false);
        setCoachPlanPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getBeneficiaryList = (pageItem) => {
    setIsLoading(true);
    // {
    //   location?.state?.type === "assign" &&
    //     handleFetchRequest(
    //       `${apiUrl.beneficiary_list
    //       }?page_no=${pageItem}&page_size=10&assignment=0${filters.join("&")}`
    //     ).then((response) => {
    //       console.log(response);
    //       if (response?.status === true) {
    //         setIsLoading(false);
    //         setBeneficiaryList(response.data);
    //         setCallApi(false);
    //         setPages(response?.pageInfo);
    //       } else {
    //         setCallApi(false);
    //         setIsLoading(false);
    //         console.log(response);
    //         toast.error(response.message);
    //       }
    //     });
    // }
    applyFilter();
    {
      if (RoleType.coach_value === role) {
        handleFetchRequest(
          `${
            apiUrl?.get_assigned_beneficiary
          }?page_no=${pageItem}&page_size=10${filters.join("&")}`
        ).then((response) => {
          if (response?.status === true) {
            setBeneficiaryList(response?.data);
            setCallApi(false);
            setIsLoading(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
          }
        });
      } else if (
        RoleType.admin_value === role ||
        RoleType.viewer_value === role
      ) {
        handleFetchRequest(
          `${apiUrl?.get_assigned_beneficiary}?coach_id=${
            location?.state?.id
          }&page_no=${pageItem}&page_size=10${filters.join("&")}`
        ).then((response) => {
          if (response?.status === true) {
            setBeneficiaryList(response?.data);
            setCallApi(false);
            setIsLoading(false);
            setPages(response?.pageInfo);
          } else if (response?.messageCode === 3) {
            Cookies.remove("emp_token");
            Cookies.remove("emp_role");
            Cookies.remove("emp_name");
            Cookies.remove("emp_currency");
            Cookies.remove("emp_profile");
            Cookies.remove("emp_data");
            Cookies.remove("emp_conversion");
            Cookies.remove("emp_conversion");
            dispatch(setUserLoggedInDetails({}));
            dispatch(profileImage(""));
            setIsLoading(false);
            navigate(RoutesLink.login);
          } else {
            setIsLoading(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    console.log("locationnnn.....", location.state);
    setCoachId(location?.state?.id);

    if (location?.state?.tabid) {
      setTabOpen(location?.state?.tabid);
    }
    setPageIsLoading(true);

    handleFetchRequest(
      `${apiUrl?.get_coach_profile}?coach_id=${location?.state?.id || ""}`
    ).then((response) => {
      if (response?.status === true) {
        setProfileData(response?.data);
        setPageIsLoading(false);

        {
          roleType === Roles?.coach_value &&
            dispatch(
              profileImage(response?.data?.coach_profile?.profile_image)
            );
          Cookies.set(
            "emp_profile",
            response?.data?.coach_profile?.profile_image
          );
        }
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      }
    });
    if (roleType === Roles?.admin_value || roleType === Roles?.viewer_value) {
      getCoachPlanList(1);
      getBeneficiaryList(activePage);
    }
    getBeneficiarySessionList(activePage);
    getCoachCooperativelist(currentCooperativePage);
  }, []);

  const filters = [];

  const applyFilter = () => {
    const filterValue = filterData ? `&${searchOptionData}=${filterData}` : "";
    filters.push(filterValue);
  };

  const sessionFilters = [];

  const applySessionFilter = () => {
    if (searchSessionOptionData === "session_time") {
      const filterValue = sessionFilterData
        ? `&started_ts=${convertToEpoch(
            sessionFilterData.start_date
          )}&end_ts=${convertToEpoch(sessionFilterData.end_date)}`
        : "";
      sessionFilters.push(filterValue);
    } else {
      const filterValue = sessionFilterData
        ? `&${searchSessionOptionData}=${sessionFilterData}`
        : "";
      sessionFilters.push(filterValue);
    }
  };

  let filtersCooperative = [];

  const searchCooperativeOption1Array = [
    { name: "Search By", value: "", type: "select" },
    { name: "Alias Id", value: "aliasid", type: "text" },
    { name: "Cooperative Name", value: "cooperative_name", type: "text" },
  ];
  const searchCooperativeOption2Array = searchOptionStatusArray.filter(
    (data) => data?.type === searchCooperativeOptionData
  );

  if (searchCooperativeOptionData === "aliasid") {
    if (filterCooperativeData) {
      filtersCooperative.push(`&alias_id=${filterCooperativeData}`);
    }
  }
  if (searchCooperativeOptionData === "cooperative_name") {
    if (filterCooperativeData) {
      filtersCooperative.push(`&cooperative_name=${filterCooperativeData}`);
    }
  }

  const handleCooperativePageChange = (pageitem) => {
    setCooperativeList([]);
    getCoachCooperativelist(pageitem);
    setCurrentCooperativePage(pageitem);
  };

  const ResetCooperativeAccountHandler = () => {
    setIsLoading(true);
    setCurrentCooperativePage(1);
    filtersCooperative = [];
    setSearchOptionCooperativeData("");
    setFilterCooperativeData("");
    setCooperativeList([]);
    setInputCooperativeCategoryData("###select");
    getCoachCooperativelist(1);
  };

  const getCoachCooperativelist = (pageitem) => {
    handleFetchRequest(
      `${apiUrl.get_cooperatives}?coach_id=${
        location?.state?.id
      }&page_no=${pageitem}&page_size=10${filtersCooperative.join("&")}`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCooperativeList(response.data);
        setCooperativePages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  // if (searchOptionData === "contact_detail") {
  //   if (filterData) {
  //     filters.push(`&telephone_pref=${filterData}`)
  //   }
  // }
  // if (searchOptionData === "quartier") {
  //   if (filterData) {
  //     filters.push(`&quartier=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "commune") {
  //   if (filterData) {
  //     filters.push(`&commune=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "province") {
  //   if (filterData) {
  //     filters.push(`&province=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "fullname") {
  //   if (filterData) {
  //     filters.push(`&fullname=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "reference_id") {
  //   if (filterData) {
  //     filters.push(`&reference_id=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "status") {
  //   if (filterData) {
  //     filters.push(`&status=${filterData}`)
  //   }
  // }

  // if (searchOptionData === "grant") {
  //   if (filterData) {
  //     filters.push(`&grant_eligibility=${filterData}`)
  //   }
  // }
  // if (searchOptionData === "assignment") {
  //   if (filterData) {
  //     filters.push(`&assignment=${filterData}`)
  //   }
  // }

  const backButtonHandler = () => {
    navigate(-1);
  };

  const getCoachPlanList = (pageItem) => {
    setIsLoading(true);
    applySessionFilter();
    handleFetchRequest(
      `${apiUrl?.get_coach_plan}?page_no=${pageItem}&page_size=10&coach_id=${
        location?.state?.id
      }${sessionFilters.join("&")}`
    ).then((response) => {
      if (response?.status === true) {
        // setCallApi(false);
        setIsLoading(false);
        setCoachList(response.data);
        setCoachPlanPages(response?.pageInfo);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        // setCallApi(false);
        console.log(response);
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  const renderEvent = (event) => {
    // Render the event title based on the current view of the calendar
    // const { view } = this.props;
    // const { view } = event;
    console.log("helloo this is viewsss....", event);
    if (Views.AGENDA === eventData || Views.DAY === eventData) {
      return (
        <div>
          <div>{`${event.title} (${event?.event?.beneficiary_name})`}</div>
        </div>
      );
    } else {
      return <div>{event.title}</div>;
    }
  };

  const getCoachMonitorCoachingSession = (apiLink) => {
    setIsPageLoading(true);
    handleFetchRequest(apiLink).then((response) => {
      if (response?.status === true) {
        setIsPageLoading(false);
        let sessionData = response?.data?.map((data) => {
          return {
            title: data?.session_id,
            start: new Date(TimeStampConverter(data?.schedule_start_ts)),
            end: new Date(TimeStampConverter(data?.schedule_end_ts)),
            beneficiary_name: data?.cp_master?.beneficiary?.fullname,
            color:
              data?.status === "completed"
                ? "#098E7E"
                : data?.status === "pending"
                ? "#AB0D82"
                : data?.status === "in progress"
                ? "#00B8F5"
                : "",
          };
        });
        // console.log("this is session List..", sessionData);

        setCoachMonitoringSessionList(sessionData);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        navigate(RoutesLink.login);
      } else {
        setIsPageLoading(false);
      }
    });
  };

  useEffect(() => {
    getCoachMonitorCoachingSession(
      `${
        apiUrl?.get_dashboard_coaching_sessions
      }?schedule_start_ts=${convertToEpoch(
        startDate?.from
      )}&schedule_end_ts=${convertToEpoch(startDate?.to)}&coach_id=${
        location?.state?.id
      }`
    );
  }, []);

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-12 px-sm-0">
            <div className="card">
              <ProfileHeader
                profileImage={profileData?.coach_profile?.profile_image}
                fullName={
                  profileData?.first_name
                    ? `${profileData?.first_name} ${profileData?.last_name}`
                    : Cookies.get("emp_name")
                }
                backButtonHandler={backButtonHandler}
              />
              <div className="dummypj">
                <div className="page-header">
                  <ul className="nav nav-tabs tabHolder d-flex flex-nowrap overflow-x-auto ">
                    <NavItem className="nav-item">
                      <div
                        // className={tabOpen == '1' ? "nav-link d-flex flex-column justify-content-center align-items-center active" : "nav-link"}
                        className={
                          tabOpen === "1"
                            ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                            : "nav-link d-flex flex-column justify-content-center align-items-center"
                        }
                        onClick={() => setTabValue("1")}
                      >
                        <div className="text-center font-weight-bold">
                          Profile
                        </div>
                      </div>
                    </NavItem>
                    {roleType === Roles?.admin_value ||
                    roleType === Roles?.viewer_value ? (
                      <NavItem className="nav-item">
                        <div
                          className={
                            tabOpen === "2"
                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                          }
                          onClick={() => setTabValue("2")}
                        >
                          <div className="text-center font-weight-bold">
                            Beneficiaries
                          </div>
                        </div>
                      </NavItem>
                    ) : (
                      ""
                    )}
                    {roleType === Roles?.admin_value ||
                    roleType === Roles?.viewer_value ? (
                      <NavItem className="nav-item">
                        <div
                          className={
                            tabOpen === "3"
                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                          }
                          onClick={() => setTabValue("3")}
                        >
                          <div className="text-center font-weight-bold">
                            {" "}
                            {t("coaching_plan")}
                          </div>
                        </div>
                      </NavItem>
                    ) : (
                      ""
                    )}

                    {roleType === Roles?.admin_value ||
                    roleType === Roles?.viewer_value ? (
                      <NavItem className="nav-item">
                        <div
                          className={
                            tabOpen === "4"
                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                          }
                          onClick={() => setTabValue("4")}
                        >
                          <div className="text-center font-weight-bold">
                            {" "}
                            Calender View
                          </div>
                        </div>
                      </NavItem>
                    ) : (
                      ""
                    )}

                    {roleType === Roles?.admin_value ||
                    roleType === Roles?.viewer_value ? (
                      <NavItem class="nav-item">
                        <div
                          className={
                            tabOpen === "5"
                              ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                              : "nav-link d-flex flex-column justify-content-center align-items-center"
                          }
                          onClick={() => setTabValue("5")}
                        >
                          <div class="text-center font-weight-bold">
                            {t("cooperative")}
                          </div>
                        </div>
                      </NavItem>
                    ) : (
                      ""
                    )}
                  </ul>
                  <div className="absolute-border"></div>
                </div>
                <div className="row">
                  <TabContent activeTab={tabOpen}>
                    <TabPane tabId="1">
                      <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
                        <div className="card-body">
                          <div className="pro">
                            {profileData && (
                              <div className="newcon">
                                <div className="d-flex flex-column flex-md-row gap-2 justify-content-between mb-2">
                                  <h4>Personal information</h4>
                                  {roleType === Roles?.coach_value ||
                                  roleType === Roles?.admin_value
                                    ? tabOpen === "1" && (
                                        <div className="position-relative d-flex justify-content-end">
                                          <div className="grant w-sm-100">
                                            <Link
                                              className="btn btn-primary w-sm-100 mt-2 mt-md-0 mb-3 mb-md-0"
                                              to={RoutesLink?.add_user_profile}
                                              state={{ id: profileData?.id }}
                                            >
                                              Edit Profile
                                            </Link>
                                          </div>
                                        </div>
                                      )
                                    : ""}
                                </div>
                                <ul className="uln">
                                  <li>
                                    <h5>{t("name")}</h5>{" "}
                                    <span>
                                      {profileData?.first_name}{" "}
                                      {profileData?.last_name}
                                    </span>
                                  </li>
                                  <li>
                                    <h5> {t("email")}</h5>{" "}
                                    <span>
                                      {profileData?.coach_profile?.email}
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("user_name")}</h5>{" "}
                                    <span>{profileData?.username}</span>
                                  </li>
                                  <li>
                                    <h5>Date Of Birth</h5>{" "}
                                    <span>
                                      {profileData?.coach_profile?.dob &&
                                        moment(
                                          profileData?.coach_profile?.dob
                                        ).format("DD/MM/YYYY")}
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("Contact_number")}</h5>{" "}
                                    <span>{profileData?.mobile}</span>
                                  </li>
                                  {/* <li><h5>Education Level</h5> <span>{profileData?.}</span></li> */}
                                  <li>
                                    <div className="abs-btn">
                                      {/* { <button className="absbtn">Assigned</button>} */}
                                      {profileData?.status === 1 ? (
                                        <button className="active-button  badge">
                                          Active
                                        </button>
                                      ) : (
                                        <button className="acbtn">
                                          Inactive
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="pro">
                            {profileData && (
                              <div className="newcon mt-3">
                                <h4>Business Address</h4>
                                <ul className="uln">
                                  <li>
                                    <h5> {t("address_line1")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.business_address?.address_line1
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5> {t("address_line2")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.business_address?.address_line2
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("city")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.business_address?.city
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5> {t("ZIP_code")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.business_address?.zip_code
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("State")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.business_address?.state
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("Country")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.business_address?.country
                                      }
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="pro">
                            {profileData && (
                              <div className="newcon mt-3">
                                <h4>Physical Address</h4>
                                <ul className="uln">
                                  <li>
                                    <h5> {t("address_line1")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.physical_address?.address_line1
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("address_line2")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.physical_address?.address_line2
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("city")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.physical_address?.city
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("ZIP_code")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.physical_address?.zip_code
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("State")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.physical_address?.state
                                      }
                                    </span>
                                  </li>
                                  <li>
                                    <h5>{t("Country")}</h5>{" "}
                                    <span>
                                      {
                                        profileData?.coach_profile
                                          ?.physical_address?.country
                                      }
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="row d-flex justify-content-start">
                              <div className="px-0">
                                <Heading
                                  inputTypeData={inputTypeData}
                                  searchOption1Array={searchOption1Array}
                                  addLink={RoutesLink?.assign_beneficiary}
                                  // btnName={"Edit Assign Beneficiary"}
                                  // name={"Beneficiary"}
                                  btnCickFunction={deleteHandler}
                                  deleteBeneficiary={
                                    beneficiaryList.length > 0 ? true : false
                                  }
                                  btnClickName={" Unassign Beneficiaries"}
                                  editBtn={{
                                    btnName: "Assign Beneficiaries",
                                    state: {
                                      id: coachId,
                                      type: "edit",
                                      type: "assign",
                                      param: "profile",
                                    },
                                    route: RoutesLink?.assign_beneficiary,
                                  }}
                                  // downloadToggle={downloadModalToggle}
                                  // addArray={addAction1}
                                  // useraccount={true}
                                  coachId={coachId}
                                  setFilterData={setFilterData}
                                  setSearchOptionData={setSearchOptionData}
                                  searchOption2Array={searchOption2Array}
                                  setInputTypeData1={setInputTypeData}
                                  // setMainCallApi={setCallApi}
                                  setData={setBeneficiaryList}
                                  filterData={filterData}
                                  setActivePage={setActivePage}
                                  setMainCallApi={() => getBeneficiaryList(1)}
                                  ResetAccountHandler={ResetAccountHandler}
                                  inputCategoryData={inputCategoryData}
                                  setInputCategoryData={setInputCategoryData}
                                />
                              </div>
                              <div className="ptable px-0">
                                <div
                                  className={
                                    showDeleteBox
                                      ? "tablcont active"
                                      : "tablcont"
                                  }
                                >
                                  <PrimaryTable
                                    assignedList={assignedBeneficiaryList}
                                    listHeading={listHeading}
                                    listData={beneficiaryList}
                                    beneficiary={true}
                                    showDeleteBox={showDeleteBox}
                                    checkboxFunction={
                                      AssignCheckboxBeneficiaries
                                    }
                                    assignAllCheckbox={
                                      assignAllBeneficiaryHandler
                                    }
                                    activePage={activePage}
                                    pages={pages}
                                    handlePageChange={handlePageChange}
                                    isLoading={isLoading}
                                    idClickLink={
                                      RoutesLink?.beneficiary_profile
                                    }
                                  />
                                </div>
                                {/* {showDeleteBox &&
                            <AssignBeneficiaryCard
                              type="delete"
                              assignedBeneficiaryList={assignedBeneficiaryList}
                              deleteBeneficiaryHandler={deleteBeneficiaryHandler}
                              cancelAssignedListHandler={cancelAssignedListHandler}
                              submitAssignmentHandler={submitAssignmentHandler}
                            />
                          } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
                        <div className="card">
                          <div className="card-body">
                            <div className="row d-flex justify-content-start">
                              <div className="position-relative  d-flex justify-content-end px-sm-0">
                                {RoleType.admin_value === role ? (
                                  <div className="grant mb-2 w-sm-100">
                                    {beneficiaryList?.length > 0 && (
                                      <Link
                                        to={
                                          RoutesLink?.coach_plan_beneficiaries
                                        }
                                        className="btn btn-primary w-sm-100"
                                        state={{
                                          id: location.state.id,
                                          alias_id:
                                            location.state.data?.alias_id,
                                        }}
                                        // onClick={() => setCoachPlanBeneficiaryShow(true)}
                                      >
                                        + Add Coaching Plan{" "}
                                      </Link>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              {beneficiaryList?.length > 0 ? (
                                <>
                                  <div className="px-0">
                                    <Heading
                                      inputTypeData={inputSessionTypeData}
                                      setInputTypeData1={
                                        setInputSessionTypeData
                                      }
                                      searchOption1Array={
                                        searchOption1SessionArray
                                      }
                                      // name={"Coach Plan"}
                                      inputSessionCategoryData={
                                        inputSessionCategoryData
                                      }
                                      setInputSessionCategoryData={
                                        setInputSessionCategoryData
                                      }
                                      setSearchOptionData={
                                        setSearchSessionOptionData
                                      }
                                      searchOption2Array={
                                        searchSessionOption2Array
                                      }
                                      setFilterData={setSessionFilterData}
                                      setData={setCoachList}
                                      filterData={sessionFilterData}
                                      setActivePage={setCurrentPage}
                                      setMainCallApi={() => getCoachPlanList(1)}
                                      ResetAccountHandler={
                                        ResetSessionAccountHandler
                                      }
                                      inputCategoryData={
                                        inputSessionCategoryData
                                      }
                                      setInputCategoryData={
                                        setInputSessionCategoryData
                                      }
                                    />
                                  </div>
                                  <div className="ptable px-0">
                                    <div
                                      className={
                                        showDeleteBox
                                          ? "tablcont active"
                                          : "tablcont"
                                      }
                                    >
                                      <PrimaryTable
                                        listData={coachList}
                                        listHeading={sessionListHeading}
                                        editPage={"/add-coach"}
                                        addData={addHeadingAction}
                                        mainCoachPlan2={true}
                                        pages={coachPlanPages}
                                        activePage={currentPage}
                                        handlePageChange={handlePageChange}
                                        isLoading={isLoading}
                                        idClickLink={
                                          RoutesLink?.coach_plan_detail
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <h4 className="text-center">
                                  Assign Beneficiary to Add Coaching Plan
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="row">
                        {
                          <div
                            style={{ height: "600px" }}
                            // className="custom-cal-container"
                          >
                            <Calendar
                              tooltipAccessor={(event) =>
                                `${event?.title} (${event.beneficiary_name})`
                              }
                              events={coachMonitoringSessionList}
                              localizer={localize}
                              // step={60}
                              // onSelectEvent={event => alert(event)}
                              views={allViews}
                              defaultDate={new Date()}
                              popup={false}
                              // onSelectEvent={(event) => console.log("event Selectedddd", event)}
                              // onNavigate={(event) => console.log("on navigateeee....", event)}
                              onView={(event) => setEventData(event)}
                              eventPropGetter={(
                                coachMonitoringSessionList,
                                start,
                                end,
                                isSelected
                              ) => {
                                // Customize coachMonitoringSessionList style here
                                console.log(
                                  "isss selected",
                                  isSelected,
                                  start,
                                  end
                                );
                                const style = {
                                  backgroundColor:
                                    coachMonitoringSessionList.color, // Use the color specified in the event object
                                };
                                return { style };
                              }}
                              onSelectSlot={(slotInfo) =>
                                console.log("dfasdf slotttteee", slotInfo)
                              }
                              components={{
                                event: renderEvent,
                              }}
                              startAccessor="start"
                              endAccessor="end"
                              // tooltipAccessor={() => <div><p>Static Text</p></div>}
                              // tooltipAccessor={(event) => renderTooltip(event)}
                              // onShowMore={(events, date) => this.setState({ showModal: true, events })}
                            />
                          </div>
                        }
                      </div>
                    </TabPane>

                    <TabPane tabId="5">
                      <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
                        <div className="w-100">
                          <div className="card-body">
                            <div className="pro">
                              <div className="newcon mt-3">
                                <div className="w-100">
                                  <Heading
                                    inputTypeData={inputCooperativeTypeData}
                                    setInputTypeData1={
                                      setInputCooperativeTypeData
                                    }
                                    searchOption1Array={
                                      searchCooperativeOption1Array
                                    }
                                    // addLink={RoutesLink?.add_user_account}
                                    addLink={{
                                      link: RoutesLink?.add_cooperatives,
                                      data: { profileData: profileData },
                                    }}
                                    btnName={
                                      role === Roles.admin_value
                                        ? "Add Cooperative"
                                        : ""
                                    }
                                    name={"Cooperatives"}
                                    // addArray={addAction1}
                                    inputCategoryData={
                                      inputCooperativeCategoryData
                                    }
                                    setInputCategoryData={
                                      setInputCooperativeCategoryData
                                    }
                                    setSearchOptionData={
                                      setSearchOptionCooperativeData
                                    }
                                    searchOption2Array={
                                      searchCooperativeOption2Array
                                    }
                                    setMainCallApi={() =>
                                      getCoachCooperativelist(1)
                                    }
                                    setData={setCooperativeList}
                                    setFilterData={setFilterCooperativeData}
                                    filterData={filterCooperativeData}
                                    setActivePage={setCurrentCooperativePage}
                                    ResetAccountHandler={
                                      ResetCooperativeAccountHandler
                                    }
                                  />
                                  <PrimaryTable
                                    listData={CooperativeList}
                                    listHeading={cooperativelistHeading}
                                    // addData={addHeadingAction}
                                    cooperatives_list={true}
                                    pages={CooperativePages}
                                    activePage={currentCooperativePage}
                                    handlePageChange={handlePageChange}
                                    isLoading={isLoading}
                                    idClickLink={RoutesLink?.cooperative_detail}
                                    // idClickLink={RoutesLink?.coach_profile}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
            {pageIsLoading && <PageLoader />}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CoachProfilePage;
