import { useForm } from "react-hook-form";
import PrimaryInput from "../../../Components/Inputs/PrimaryInput";
import FormLayout from "../../../Components/Layout/FormLayout";
import InputTexts from "../../../ApiHelper/Nomanclatures.json";
// import { currency_list } from "../../../ApiHelper/Currency";
import { handleFetchRequest } from "../../../ApiHelper/ApiHelper";
import { apiUrl } from "../../../ApiHelper/ApiUrl";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import ButtonLoader from "../../../Components/CommonElements/ButtonLoader";
// import AppHeading from "../../../Components/CommonElements/Headings";
// import Layout from "../../../Components/Layout/Layout";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import PageLoader from "../../../Components/CommonElements/PageLoader";
import Cookies from "js-cookie";
import { CurrencyConverter } from "../../../ApiHelper/CurrencyConverter";
import { useDispatch, useSelector } from "react-redux";
import Roles from "../../../ApiHelper/Roles.json";
import { validatePassword } from "../../../ApiHelper/FormValidation";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../../store/actions/UserActions";
import { useNavigate } from "react-router-dom";
import { RoutesLink } from "../../../ApiHelper/RoutesLink";
import { timeZones } from "../../../ApiHelper/TimeZones";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const Settings = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((data) => data?.user?.userDetails?.role);

  let currencyData = Cookies.get("emp_currency");
  let conversionData = Cookies.get("emp_conversion");
  const [tabOpen, setTabOpen] = useState("1");

  const [editHandler, setEditHandler] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let emp_data = Cookies.get("emp_data");
  const [editMode, setEditMode] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng")
  );

  let time_zone = Cookies.get("emp_time_zone");

  const [settingsData, setSettingsData] = useState({
    grant_amount: "",
    grant_edit: false,
    currency: currencyData,
    currency_edit: false,
    conversion_rate: conversionData,
    conversion_edit: false,
    time_zone_edit: false,
    time_zone: time_zone,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    // formState: { errors: errors1 },
  } = useForm();

  // const {
  //   register: register2,
  //   handleSubmit: handleSubmit2,
  //   reset: reset2,
  //   formState: { errors: errors2 },
  // } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm();

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm();

  const {
    register: register5,
    handleSubmit: handleSubmit5,
    reset: reset5,
    formState: { errors: errors5 },
  } = useForm();

  // const passwordSubmit = (data) => {
  //   setIsLoading(true);
  //   let password={
  //     "old_password":data?.old_password,
  //     "new_password":data?.new_password,
  // }
  // handleFetchRequest(apiUrl.change_password,"PUT",data=null,password).then((response)=>{
  //    if(response.status===true){
  //     setIsLoading(false);
  //     toast.success(response.message,{
  //       position: "top-right",
  //     });

  //    }else{
  //     setIsLoading(false);
  //       toast.success(response.message, {
  //       position: "top-right",
  //     });
  //    }
  // })
  // };

  const conversionSubmitHandler = (data) => {
    console.log("conversion data", data);
    let conversionData = {
      type: "rate_conversion",
      amount: data?.conversion_rate,
    };
    setIsLoading(true);
    handleFetchRequest(
      apiUrl?.add_edit_conversion_rate,
      "POST",
      conversionData
    ).then((response) => {
      if (response.status === true) {
        setIsLoading(false);
        Cookies.set("emp_conversion", response?.data?.amount);
        setSettingsData({
          ...settingsData,
          conversion_edit: false,
          conversion_rate: Cookies?.get("emp_conversion"),
        });
        toast.success(response?.message);
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast?.error(response?.message);
      }
    });
  };

  useEffect(() => {
    let currency = Cookies.get("emp_currency");
    let conversion = Cookies.get("emp_conversion");
    // let emp_data = Cookies.get("emp_data");
    let time_zone = Cookies.get("emp_time_zone");

    // console.log("timeeee zoneee", time_zone);
    console.log("time_zone", time_zone);
    reset5({ time_zone: time_zone });
    // +00:00

    setSettingsData({ ...settingsData, time_zone: time_zone });

    reset1({ currencyData: currency });
    reset3({ conversion_rate: conversion });
    setSettingsData({ ...settingsData, currency: currency });
    setSettingsData({ ...settingsData, currency: currency });
    setSettingsData({ ...settingsData, conversion_rate: conversion });

    handleFetchRequest(apiUrl?.get_grant_settings).then((response) => {
      if (response?.status === true) {
        setEditHandler(true);
        setSettingsData({
          ...settingsData,
          grant_amount: response?.data?.amount,
        });
        reset({
          grant_amount: response?.data?.amount,
          // currency_type:response?.data?.currency_type,
        });
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
      }
    });
  }, []);

  const currencyHandler = (data) => {
    console.log("dafasfas", data);
    let currencyData = {
      currency_type: data?.currencyData,
    };
    setIsLoading(true);
    handleFetchRequest(apiUrl?.change_currency, "PUT", currencyData).then(
      (response) => {
        if (response.status === true) {
          setIsLoading(false);
          Cookies.set("emp_currency", response?.data?.currency_type);
          setSettingsData({
            ...settingsData,
            currency_edit: false,
            currency: response?.data?.currency_type,
          });
          toast.success(response?.message);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast?.error(response?.message);
        }
      }
    );
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    console.log(data);
    const grantData = {
      amount: data?.grant_amount,
      // currency_type: data?.currency_type,
    };
    handleFetchRequest(apiUrl.create_grant_settings, "POST", grantData).then(
      (response) => {
        if (response?.status === true) {
          setSettingsData({
            ...settingsData,
            grant_edit: false,
            grant_amount: response?.data?.amount,
          });
          setIsLoading(false);
          reset({
            grant_amount: response?.data?.amount,
          });
          toast?.success(response?.message);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast?.error(response?.message);
        }
      }
    );
  };

  const setTabValue = (data) => {
    setTabOpen(data);
  };

  const cancelHandler = (data) => {
    if (data === "grant") {
      setSettingsData({ ...settingsData, grant_edit: false });
    } else if (data === "conversion") {
      setSettingsData({ ...settingsData, conversion_edit: false });
    } else if (data === "currency") {
      setSettingsData({ ...settingsData, currency_edit: false });
    } else if (data === "time_zone") {
      setSettingsData({ ...settingsData, time_zone_edit: false });
    }
  };

  const grantEditHandler = () => {
    setSettingsData({ ...settingsData, grant_edit: true });
  };

  const currencyEditHandler = () => {
    setSettingsData({ ...settingsData, currency_edit: true });
  };

  const timeZoneEditHandler = () => {
    setSettingsData({ ...settingsData, time_zone_edit: true });
  };

  const conversionEditHandler = () => {
    setSettingsData({ ...settingsData, conversion_edit: true });
  };

  const changePasswordSubmitHandler = (data) => {
    setIsLoading(true);
    let password = {
      old_password: data?.old_password,
      new_password: data?.new_password,
    };
    handleFetchRequest(
      apiUrl.change_password,
      "PUT",
      (data = null),
      password
    ).then((response) => {
      if (response.status === true) {
        setIsLoading(false);
        reset4();
        toast.success(response.message, {
          position: "top-right",
        });
        // if (location?.state?.role === "coach") {
        // navigate(RoutesLink?.add_user_profile, { state: { id: location?.state?.id } });
        // } else {
        // navigate(RoutesLink?.dashboard, { state: { id: location?.state?.id } });
        // }
      } else if (response?.messageCode === 3) {
        Cookies.remove("emp_token");
        Cookies.remove("emp_role");
        Cookies.remove("emp_name");
        Cookies.remove("emp_currency");
        Cookies.remove("emp_profile");
        Cookies.remove("emp_data");
        Cookies.remove("emp_conversion");
        Cookies.remove("emp_conversion");
        dispatch(setUserLoggedInDetails({}));
        dispatch(profileImage(""));
        setIsLoading(false);
        navigate(RoutesLink.login);
      } else {
        setIsLoading(false);
        toast.error(response.message, {
          position: "top-right",
        });
      }
    });
  };

  const timeZoneChangeHandler = (data) => {
    setIsLoading(true);
    handleFetchRequest(apiUrl.time_zone_change, "POST", data).then(
      (response) => {
        if (response?.status === true) {
          setSettingsData({
            ...settingsData,
            time_zone_edit: false,
            time_zone: response?.data?.time_zone,
          });
          setIsLoading(false);
          reset5({
            time_zone: response?.data?.time_zone,
          });
          Cookies.set("emp_time_zone", response?.data?.time_zone);
          toast?.success(response?.message);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast?.error(response?.message);
        }
      }
    );
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleUpdate = () => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
      setEditMode(false);
      handleFetchRequest(`${apiUrl?.update_language}`, "PUT", {
        language: selectedLanguage,
      }).then((response) => {
        if (response?.status === true) {
          setIsLoading(false);
          toast.success(response?.message);
          // navigate(-1);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
          setIsLoading(false);
          toast.error(response?.message);
        }
      });
    }
  };

  const handleCancel = () => {
    setEditMode(false);
  };
  const handleEditOn = () => {
    setEditMode(!editMode);
  };

  const cleanDataHandler = (data) => {
    Swal.fire({
      text: "Are you sure you want to clear all data?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        handleFetchRequest(`${apiUrl.clear_all_data}`, "DELETE").then(
          (response) => {
            if (response?.status === true) {
              setIsLoading(false);
              toast.success(response?.message);
              // setCallApi(true);
              // getSuppliersList(currentPage);
            } else if (response?.messageCode === 3) {
              Cookies.remove("emp_token");
              Cookies.remove("emp_role");
              Cookies.remove("emp_name");
              Cookies.remove("emp_currency");
              Cookies.remove("emp_profile");
              Cookies.remove("emp_data");
              Cookies.remove("emp_conversion");
              Cookies.remove("emp_conversion");
              dispatch(setUserLoggedInDetails({}));
              dispatch(profileImage(""));
              setIsLoading(false);
              navigate(RoutesLink.login);
            } else {
              setIsLoading(false);
              toast.error(response?.message);
            }
          }
        );
      }
    });
  };
  return (
    <FormLayout name={t("settings")}>
      <div className="dummypj">
        <div className="page-header">
          <Nav className="nav nav-tabs tabHolder d-flex flex-nowrap overflow-x-auto ">
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "1"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("1")}
                //  href="#"
              >
                <div className="text-center font-weight-bold">{t("grant")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "2"
                    ? "nav-link d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("2")}
                //  href="#"
              >
                <div className="text-center font-weight-bold">
                  {t("currency")}
                </div>
              </NavLink>
            </NavItem>

            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "3"
                    ? " d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("3")}
                // href="#"
              >
                <div>{t("conversion_rate")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "4"
                    ? " d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("4")}
                // href="#"
              >
                <div>{t("change_password")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "5"
                    ? " d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("5")}
                // href="#"
              >
                <div>{t("time_zone")}</div>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item w-auto">
              <NavLink
                aria-current="page"
                className={
                  tabOpen === "6"
                    ? " d-flex flex-column justify-content-center align-items-center active"
                    : "nav-link d-flex flex-column justify-content-center align-items-center"
                }
                onClick={() => setTabValue("6")}
              >
                <div>Language</div>
              </NavLink>
            </NavItem>
            {role === "admin" && (
              <NavItem className="nav-item w-auto">
                <NavLink
                  aria-current="page"
                  className={
                    tabOpen === "7"
                      ? " d-flex flex-column justify-content-center align-items-center active"
                      : "nav-link d-flex flex-column justify-content-center align-items-center"
                  }
                  onClick={() => setTabValue("7")}
                >
                  <div>Clean Data</div>
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <div className="absolute-border"></div>
        </div>
      </div>
      <div className="row">
        <TabContent activeTab={tabOpen}>
          <TabPane tabId={"1"}>
            <form className="form-sample" onSubmit={handleSubmit(onSubmit)}>
              <div className="row mt-2 mt-md-4">
                <div className="col-lg-12 px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="input-label p-0">
                      {InputTexts?.input_texts?.grant_amount?.name}
                    </label>
                    <div>
                      {Roles?.admin_value === role ? (
                        <>
                          {settingsData?.grant_edit === false && (
                            <button
                              className="btn btn-primary ms-1"
                              onClick={grantEditHandler}
                            >
                              {t("edit")}
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <span className="">
                    {settingsData?.grant_edit
                      ? `$ ${settingsData?.grant_amount}`
                      : CurrencyConverter(settingsData?.grant_amount)}
                  </span>
                  {settingsData?.grant_edit === true && (
                    <>
                      <PrimaryInput
                        type="number"
                        className="form-control"
                        placeholder={
                          InputTexts?.input_texts?.grant_amount?.name
                        }
                        register={register("grant_amount", {
                          required: "Please enter Grant Amount",
                        })}
                      />
                      <span className="text-danger">
                        {errors?.grant_amount?.message}
                      </span>
                    </>
                  )}
                </div>
              </div>
              {settingsData?.grant_edit === true && (
                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary me-2">
                    {editHandler ? (
                      isLoading ? (
                        <ButtonLoader />
                      ) : (
                        `${t("Update")}`
                      )
                    ) : isLoading ? (
                      <ButtonLoader />
                    ) : (
                      `${t("submit")}`
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => cancelHandler("grant")}
                  >
                    {t("cancel")}
                  </button>
                </div>
              )}
            </form>
          </TabPane>
          <TabPane tabId={"2"}>
            <form
              className="form-sample"
              onSubmit={handleSubmit1(currencyHandler)}
            >
              <div className="row mt-2 mt-md-4">
                <div className="col-lg-12 px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="input-label p-0">
                      {t("currency_(view_mode)")}
                    </label>
                    <div>
                      {settingsData?.currency_edit === false && (
                        <button
                          type="button"
                          className="btn btn-primary ms-1"
                          onClick={currencyEditHandler}
                        >
                          {t("edit")}
                        </button>
                      )}
                    </div>
                  </div>
                  <span className="text-uppercase">
                    {settingsData?.currency}
                  </span>
                  {settingsData?.currency_edit === true && (
                    <select
                      className="form-control"
                      {...register1("currencyData", {})}
                    >
                      <option value="usd">USD</option>
                      <option value="cdf">CDF</option>
                    </select>
                  )}
                  {settingsData?.currency_edit === true && (
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn btn-primary me-2">
                        {editHandler ? (
                          isLoading ? (
                            <ButtonLoader />
                          ) : (
                            `${t("Update")}`
                          )
                        ) : isLoading ? (
                          <ButtonLoader />
                        ) : (
                          `${t("submit")}`
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => cancelHandler("currency")}
                      >
                        {t("cancel")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </TabPane>
          <TabPane tabId={"3"}>
            <form
              className="form-sample"
              onSubmit={handleSubmit3(conversionSubmitHandler)}
            >
              <div className="row mt-2 mt-md-4">
                <div className="col-lg-12 px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    {
                      <label className="input-label ps-0">
                        {InputTexts?.input_texts?.conversion_rate?.name}
                      </label>
                    }
                    <div>
                      {role === Roles?.admin_value ? (
                        <>
                          {settingsData?.conversion_edit === false && (
                            <button
                              type="button"
                              className="btn btn-primary ms-1"
                              onClick={conversionEditHandler}
                            >
                              {t("edit")}
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <span className="">
                    USD 1 = CDF {settingsData?.conversion_rate}
                  </span>
                  {settingsData?.conversion_edit === true && (
                    <>
                      <div className="d-flex mt-3 align-items-center">
                        <span style={{ width: "80px" }}> 1 USD = </span>
                        <PrimaryInput
                          type="number"
                          className="form-control"
                          placeholder={
                            InputTexts?.input_texts?.conversion_rate?.name
                          }
                          register={register3(
                            InputTexts?.input_texts?.conversion_rate?.value,
                            {
                              required:
                                InputTexts?.input_texts?.conversion_rate?.error,
                            }
                          )}
                        />
                      </div>
                    </>
                  )}
                  <span className="text-danger ">
                    {errors3?.conversion_rate?.message}
                  </span>
                </div>
              </div>
              {settingsData?.conversion_edit === true && (
                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary me-2">
                    {editHandler ? (
                      isLoading ? (
                        <ButtonLoader />
                      ) : (
                        `${t("Update")}`
                      )
                    ) : isLoading ? (
                      <ButtonLoader />
                    ) : (
                      `${t("submit")}`
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => cancelHandler("conversion")}
                  >
                    {t("cancel")}
                  </button>
                </div>
              )}
            </form>
          </TabPane>
          <TabPane tabId={"4"}>
            <form
              className="form-inline mt-3"
              onSubmit={handleSubmit4(changePasswordSubmitHandler)}
            >
              <div className="">
                <label className="input-label px-3">
                  {t("current_password")} <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control form-control-lg bg-light"
                  id="exampleInputEmail1"
                  placeholder="Current Password"
                  {...register4("old_password", {
                    required: "Please enter current password*",
                  })}
                />
                <span className="text-danger">
                  {errors4?.password?.message}
                </span>

                {/* <span>hello</span> */}
              </div>
              <div className="mt-2">
                <label className="input-label px-3">
                  {t("new_password")} <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control form-control-lg bg-light"
                  id="exampleInputPassword1"
                  placeholder={t("new_password")}
                  {...register4("new_password", {
                    required: "Please enter new password*",
                    validate: validatePassword,
                  })}
                />
                <span className="text-danger">
                  {errors4?.new_password?.message}
                </span>
              </div>
              {/* <div className="my-2 d-flex justify-content-end align-items-center">
                <p className="text-secondary fs-6 fw-medium font-family-Roboto  m-0 px-3 py-2">Forgot password?</p>
              </div>   */}
              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-primary text-center text-white fw-semibold font-family-Roboto  m-0 ">
                  {"Change Password"}
                </button>
              </div>
              <div className="">
                <label className="form-check-label text-muted" />
                <div className="bg-info rounded-3"></div>
              </div>
            </form>
          </TabPane>
          <TabPane tabId={"5"}>
            <form
              className="form-sample"
              onSubmit={handleSubmit5(timeZoneChangeHandler)}
            >
              <div className="row mt-2 mt-md-4">
                <div className="col-lg-12 px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="input-label p-0">Time Zone</label>
                    <div>
                      {settingsData?.time_zone_edit === false && (
                        <button
                          type="button"
                          className="btn btn-primary ms-1"
                          onClick={timeZoneEditHandler}
                        >
                          {t("edit")}
                        </button>
                      )}
                    </div>
                  </div>
                  <span className="text-uppercase">
                    {settingsData?.time_zone}
                  </span>
                  {settingsData?.time_zone_edit === true && (
                    <select
                      className="form-control"
                      {...register5("time_zone", {})}
                    >
                      {timeZones?.map((data) => (
                        <option value={data?.text}>
                          {data?.value} {data?.text}
                        </option>
                      ))}
                    </select>
                  )}
                  {settingsData?.time_zone_edit === true && (
                    <div className="d-flex justify-content-end mt-4">
                      <button type="submit" className="btn btn-primary me-2">
                        {editHandler ? (
                          isLoading ? (
                            <ButtonLoader />
                          ) : (
                            `${t("Update")}`
                          )
                        ) : isLoading ? (
                          <ButtonLoader />
                        ) : (
                          `${t("submit")}`
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => cancelHandler("time_zone")}
                      >
                        {t("cancel")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </TabPane>
          <TabPane tabId={"6"}>
            <div className="row mt-2 mt-md-4">
              <div className="col-lg-12 px-0">
                <div className="d-flex justify-content-between align-items-center">
                  <label className="input-label p-0">Language</label>
                  {!editMode && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary ms-1"
                        onClick={handleEditOn}
                      >
                        {t("edit")}
                      </button>
                    </div>
                  )}
                </div>
                <span className="text-ppercase">
                  {localStorage.getItem("i18nextLng") === "fr"
                    ? "French"
                    : "English"}
                </span>
                {editMode && (
                  <select
                    className="form-control"
                    onChange={handleLanguageChange}
                    defaultValue={localStorage.getItem("i18nextLng")}
                  >
                    <option value="en">English</option>
                    <option value="fr">French</option>
                  </select>
                )}
                {editMode && (
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      onClick={handleUpdate}
                    >
                      {t("Update")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={handleCancel}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </TabPane>
          <TabPane tabId={"7"}>
            <div className="row mt-2 mt-md-4">
              <div className="col-lg-12 px-0">
                <div className="d-flex justify-content-between align-items-center">
                  <label className="input-label p-0">Clear all data</label>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary ms-1"
                      onClick={() => cleanDataHandler()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
      {isLoading && <PageLoader />}
    </FormLayout>
  );
};
export default Settings;
