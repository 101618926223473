import { useEffect, useState } from "react";
import Heading from "../../Components/CommonElements/Heading";
import Layout from "../../Components/Layout/Layout";
import { handleFetchRequest } from "../../ApiHelper/ApiHelper";
import { useInView } from "react-intersection-observer";
import { TimeStampConverter } from "../../ApiHelper/TimeStampConverter";
import moment from "moment";
import Cookies from "js-cookie";
import {
  profileImage,
  setUserLoggedInDetails,
} from "../../store/actions/UserActions";
import { RoutesLink } from "../../ApiHelper/RoutesLink";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const { t } = useTranslation();

  const [notificationList, setNotificationList] = useState({
    Today: { today_label: "Today", today_data: [] },
    yesterday: { yesterday_label: "Yesterday", yesterday_data: [] },
    earlier: { earlier_label: "Earlier", earlier_data: [] },
  });

  const { ref: myRef, inView: visibleElement } = useInView();

  useEffect(() => {
    if (visibleElement) {
      setActivePage((prevData) => prevData + 1);
      handleFetchRequest(
        `notification/v1/all-list?page_size=10&page_no=${activePage}`
      ).then((response) => {
        if (response?.status === true) {
          let today = new Date();
          let yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          let countryCode = process.env.REACT_APP_TIME_ZONE;
          const formattedToday = moment(today)
            .tz(countryCode)
            .format("DD/MM/YYYY");

          const formattedYesterday = moment(yesterday)
            .tz(countryCode)
            .format("DD/MM/YYYY");

          const updatedNotificationList = {
            Today: {
              today_data: [...notificationList.Today.today_data],
            },
            yesterday: {
              yesterday_data: [...notificationList.yesterday.yesterday_data],
            },
            earlier: {
              earlier_data: [...notificationList.earlier.earlier_data],
            },
          };

          response.data.forEach((item) => {
            const formattedDataDate = moment(
              TimeStampConverter(item?.createdAt)
            ).format("DD/MM/YYYY");

            if (formattedToday === formattedDataDate) {
              updatedNotificationList.Today.today_data.push(item);
            } else if (formattedYesterday === formattedDataDate) {
              updatedNotificationList.yesterday.yesterday_data.push(item);
            } else {
              updatedNotificationList.earlier.earlier_data.push(item);
            }
          });

          setNotificationList(updatedNotificationList);

          // const categorizedData = response.data.reduce((acc, item) => {
          //   const formattedDataDate = moment(TimeStampConverter(item?.createdAt)).format("DD/MM/YYYY");

          //   if (formattedToday === formattedDataDate) {
          //     return {
          //       ...acc,
          //       Today: {
          //         ...acc.Today,
          //         today_data: [...acc.Today.today_data, item]
          //       }
          //     };
          //   }
          //   else if (formattedYesterday === formattedDataDate) {
          //     return {
          //       ...acc,
          //       yesterday: {
          //         ...acc.yesterday,
          //         yesterday_data: [...acc.yesterday.yesterday_data, item]
          //       }
          //     };
          //   }
          //   else {
          //     return {
          //       ...acc,
          //       earlier: {
          //         ...acc.earlier,
          //         earlier_data: [...acc.earlier.earlier_data, item]
          //       }
          //     };
          //   }
          // }, { Today: { today_data: [] }, yesterday: { yesterday_data: [] }, earlier: { earlier_data: [] } });

          // setNotificationList(categorizedData);

          // for (let i = 0; i < response.data.length; i++) {
          //   const formattedDataDate = moment(TimeStampConverter(response?.data[i]?.createdAt)).format("DD/MM/YYYY");

          //   if (formattedToday == formattedDataDate) {
          //     console.log("today Dateee", formattedToday, "formatted date", formattedDataDate);
          //     console.log("responseDate ....", response.data[i]);
          //     setNotificationList({
          //       ...notificationList,
          //       Today: {
          //         ...notificationList.Today,
          //         today_data: [...notificationList.Today.today_data, response.data[i]]
          //       }
          //     });
          //   }
          //   else if (formattedYesterday === formattedDataDate) {
          //     console.log("yesterday Dateee", formattedYesterday, "formatted date", formattedDataDate);
          //     setNotificationList({
          //       ...notificationList,
          //       yesterday: {
          //         ...notificationList.yesterday,
          //         yesterday_data: [...notificationList.yesterday.yesterday_data, response.data[i]]
          //       }
          //     });
          //   }
          //   else {
          //     setNotificationList({
          //       ...notificationList,
          //       earlier: {
          //         ...notificationList.earlier,
          //         earlier_data: [...notificationList.earlier.earlier_data, response.data[i]]
          //       }
          //     });
          //   }
          // }
          // response.data?.map((data) => {
          //   if (formattedToday === moment(TimeStampConverter(data.createdAt)).format("DD/MM/YYYY")) {
          //     setNotificationList({
          //       ...notificationList,
          //       Today: {
          //         ...notificationList.Today,
          //         today_data: [...notificationList.Today.today_data, ...response.data]
          //       }
          //     });
          //   }
          //   else if (formattedYesterday === moment(TimeStampConverter(data.createdAt)).format("DD/MM/YYYY")) {
          //     setNotificationList({
          //       ...notificationList,
          //       yesterday: {
          //         ...notificationList.yesterday,
          //         yesterday_data: [...notificationList.yesterday.yesterday_data, ...response.data]
          //       }
          //     });
          //   } else {
          //     setNotificationList({
          //       ...notificationList,
          //       earlier: {
          //         ...notificationList.earlier,
          //         earlier_data: [...notificationList.earlier.earlier_data, ...response.data]
          //       }
          //     });
          //   }

          // })

          // let todayData = [...notificationList?.Today?.today_data]
          // setNotificationList((prevData) => {...prevData,})
          // setNotificationList((prevData) => [...prevData, ...response?.data]);
        } else if (response?.messageCode === 3) {
          Cookies.remove("emp_token");
          Cookies.remove("emp_role");
          Cookies.remove("emp_name");
          Cookies.remove("emp_currency");
          Cookies.remove("emp_profile");
          Cookies.remove("emp_data");
          Cookies.remove("emp_conversion");
          Cookies.remove("emp_conversion");
          dispatch(setUserLoggedInDetails({}));
          dispatch(profileImage(""));
          // setIsLoading(false);
          navigate(RoutesLink.login);
        } else {
        }
      });
    }
  }, [visibleElement]);

  return (
    <Layout>
      <div className="content-wrapper-main">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card px-0 px-md-3">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-start">
                  <Heading
                    // inputTypeData={inputTypeData}
                    // searchOption1Array={searchOption1Array}
                    // addLink={RoutesLink?.add_beneficiary}
                    // btnName={"Add Beneficiary"}
                    name={t("notifications")}
                    // downloadToggle={downloadModalToggle}
                    // addArray={addAction1}
                    //Filter Props
                    // setFilterData={setFilterData}
                    // setSearchOptionData={setSearchOptionData}
                    // setInputTypeData1={setInputTypeData}
                    // setMainCallApi={setCallApi}
                    // setData={setBeneficiaryList}
                  />

                  <div className="notificationpg px-0 px-md-3">
                    {notificationList?.Today?.today_data?.length > 0 && (
                      <ul className="list-inline">
                        <h4>Today</h4>
                        {notificationList?.Today?.today_data?.map((item) => (
                          <li>
                            <div
                              className="card rounded-4 my-1 my-md-3 p-3 "
                              style={{ backgroundColor: "#f8f9fa" }}
                            >
                              <p className="m-0 ntf-msg">{item?.message}</p>
                              <div className="d-flex justify-content-end text-gray">
                                <p>
                                  {moment(
                                    TimeStampConverter(item?.createdAt)
                                  ).format("DD/MM/YY HH:mm:ss")}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    {notificationList?.yesterday?.yesterday_data?.length >
                      0 && (
                      <ul className="list-inline">
                        <h4>Yesterday</h4>
                        {notificationList?.yesterday?.yesterday_data?.map(
                          (item) => (
                            <li>
                              <div
                                className="card rounded-4 my-1 my-md-3 p-3"
                                style={{ backgroundColor: "#f8f9fa" }}
                              >
                                <p className="m-0 ntf-msg">{item?.message}</p>
                                <div className="d-flex justify-content-end text-gray">
                                  <p>
                                    {moment(
                                      TimeStampConverter(item?.createdAt)
                                    ).format("DD/MM/YY HH:mm:ss")}
                                  </p>
                                </div>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                    {notificationList?.earlier?.earlier_data?.length > 0 && (
                      <ul className="list-inline">
                        <h4>Earlier</h4>
                        {notificationList?.earlier?.earlier_data?.map(
                          (item) => (
                            <li>
                              <div
                                className="card rounded-4 my-1 my-md-3 p-3 "
                                style={{ backgroundColor: "#f8f9fa" }}
                              >
                                <p className="m-0 ntf-msg">{item?.message}</p>
                                <div className="d-flex justify-content-end text-gray">
                                  <p>
                                    {moment(
                                      TimeStampConverter(item?.createdAt)
                                    ).format("DD/MM/YY HH:mm:ss")}
                                  </p>
                                </div>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                    <div ref={myRef} id="scroll"></div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Notification;
